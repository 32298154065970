import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UniversityAction } from "../../redux/common/action";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import StarRatings from "react-star-ratings";
import { Rating } from 'react-simple-star-rating'
import { URL } from "../../redux/common/url";
import { Modal } from 'react-responsive-modal';
import { toast } from "react-toastify";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

function Reviews() {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlsforShare = window.location.href;
  const id: any = useLocation();
  const lastLocation = useLocation();
  const uniSlug: any = useParams()
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [rating, setRating] = useState<any>() // initial rating value
  const [comment, setcomment] = useState<any>({
    university_id: "",
    student_id: "",
  });
  const baseurl = URL.API_BASE_URL
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const universityComment: any[] = useSelector((state: any) =>
    state.unversity.universityComment ? state.unversity.universityComment : []
  );

  const universitybySlug: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );

  const handleRating = (rate: any) => {

    const rt = rate / 20
    setRating(rt)
    // other logic
  }
  comment.rating = rating

  //   console.log(universityComment[0]?.reviews);


  // console.log(comment{logback:1});

  console.log(universitybySlug);


  const otherUni = (e: any) => {
    console.log(e);

    history.push("/university/" + e, {
      university_id: e,
    });
    dispatch(UniversityAction.getUniversityBySlug(e));
    return () => { };

  }

  const updateCommentFilter = universityComment[0]?.reviews && universityComment[0]?.reviews?.filter((data: any) => {
    return data?.student_id == user?.id ? user?.id : "";
  })

  //  console.log( updateCommentFilter && updateCommentFilter[0]?.comment );


  console.log(comment);
  const upcom = updateCommentFilter?.length > 0 ? updateCommentFilter[0]?.comments : ""

  useEffect(() => {
    setcomment({ comments: upcom && upcom })
  }, [universityComment]);


  const universityCommentup: any[] = useSelector((state: any) =>
    state.unversity.addUniversityComment ? state.unversity.addUniversityComment : []
  );

  const university: any = useSelector((state: any) =>
    state.unversity.universityById ? state.unversity.universityById : []
  );

  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );


  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };



  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast.success("Copied!");

  }


  comment.university_id = universitybySlug?.id
  comment.student_id = user?.id




  useEffect(() => {
    dispatch(
      UniversityAction.getAllUniversitiesComment({ university_id: id?.state?.university_id })
    );
    return () => { };
  }, [universityCommentup]);

  // useEffect(() => {
  //   dispatch(UniversityAction.getUniversityById(id?.state?.university_id));
  //   return () => {};
  // }, [universityComment]);


  useEffect(() => {
    dispatch(UniversityAction.getUniversityBySlug(uniSlug?.slug));
    return () => { };
  }, [uniSlug?.slug, universityComment]);


  useEffect(() => {
    dispatch(
      UniversityAction.getuniversities({
        search_by: "",
        offset: 0,
        country_id: [universitybySlug?.country_id],
        limit: 6,
      })
    );
    return () => { };
  }, [university]);


  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setcomment({ ...comment, [name]: value });
  };

  const addCommenthendle = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      dispatch(UniversityAction.addUniversityComment(comment));
      setcomment({ comments: updateCommentFilter?.length > 0 ? updateCommentFilter[0]?.comment : "" })
      return () => { };
    }


  };

  var slidercont = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="unishareIcon">
          <FacebookShareButton
            url={urlsforShare}
          // quote={blog?.heading}

          >
            <FacebookIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </FacebookShareButton>

          <TwitterShareButton
            url={urlsforShare}
          >
            <TwitterIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </TwitterShareButton>

          <LinkedinShareButton
            url={urlsforShare}
          >
            <LinkedinIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </LinkedinShareButton>
        </div>
        <div className="shareLinkInModel"> <p><b>{urlsforShare}</b></p></div>
        <div>
          <div className="sharebtncos" onClick={(() => { copy(); onCloseModal(); })} >{!copied ? "Copy link" : "Copied!"}</div>
        </div>
      </Modal>
      <section className="uni-list-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-pull-10 order-lg-2">
              <div className="uni-sidebar">
                <button
                  type="button"
                  className="btn simple mb-3 w-100"
                  data-toggle="modal"
                  data-target="#reviewModal"

                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="12.99"
                    viewBox="0 0 21 20.99"
                  >
                    <g
                      id="_4836495"
                      data-name="4836495"
                      transform="translate(-80 431.913)"
                    >
                      <path
                        id="Path_6588"
                        data-name="Path 6588"
                        d="M95.83-431.823a13.776,13.776,0,0,0-1.288,1.2c-1.652,1.658-1.682,1.431.567,3.7l1.682,1.682-6.2,6.2-6.2,6.2H81.909v-2.475l5.213-5.219c5.147-5.153,5.219-5.225,5.255-5.493a.859.859,0,0,0-.078-.543.9.9,0,0,0-.865-.573l-.352-.03-5.451,5.451c-3,3-5.493,5.535-5.541,5.636A9.58,9.58,0,0,0,80-413.8c0,2.064,0,2.088.137,2.344a.924.924,0,0,0,.4.4c.256.137.28.137,2.344.137a9.459,9.459,0,0,0,2.266-.089c.107-.048,3.7-3.6,7.986-7.885,6.955-6.96,7.8-7.825,7.831-8.028.1-.537.131-.5-2.105-2.738-1.139-1.139-2.153-2.117-2.255-2.165A.9.9,0,0,0,95.83-431.823Zm1.646,3.453,1.223,1.223-.286.28-.28.286L96.9-427.814l-1.241-1.241.268-.268a1.733,1.733,0,0,1,.3-.268C96.241-429.592,96.8-429.043,97.476-428.369Z"
                        transform="translate(0 0)"
                      />
                      <path
                        id="Path_6589"
                        data-name="Path 6589"
                        d="M209.572-111.775a1.167,1.167,0,0,0-.346.316.713.713,0,0,0-.119.567.858.858,0,0,0,.573.859c.149.048,1.485.06,4.259.048,4-.018,4.038-.018,4.2-.143a1,1,0,0,0,0-1.6c-.167-.125-.209-.125-4.265-.143C209.977-111.882,209.769-111.876,209.572-111.775Z"
                        transform="translate(-121.4 -300.95)"
                      />
                    </g>
                  </svg>{" "}
                  &nbsp;  {updateCommentFilter?.length == 0 ? "write a review" : "edit your reviews"}  {/* write a review */}
                </button>
                {/* <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <img src="/assets/img/university-icon.svg" alt="" />
                  <p>
                    <small>are you interested?</small>
                  </p>
                  <button type="button" className="btn simple" onClick={handleFreeCoaching}>
                    get free counselling
                  </button>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <p>
                    <small>
                      follow & share this college to get information about
                      admission.
                    </small>
                  </p>
                  <button type="button" className="btn simple wfollow" disabled style={{ cursor: "not-allowed" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.822"
                      height="10.18"
                      viewBox="0 0 12.822 11.18"
                    >
                      <g
                        id="Icon_feather-user-plus"
                        data-name="Icon feather-user-plus"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_6294"
                          data-name="Path 6294"
                          d="M9.561,25.893V24.762A2.208,2.208,0,0,0,7.411,22.5H3.649A2.208,2.208,0,0,0,1.5,24.762v1.131"
                          transform="translate(-1.5 -15.714)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6295"
                          data-name="Path 6295"
                          d="M11.049,6.762A2.208,2.208,0,0,1,8.9,9.025,2.208,2.208,0,0,1,6.75,6.762,2.208,2.208,0,0,1,8.9,4.5,2.208,2.208,0,0,1,11.049,6.762Z"
                          transform="translate(-4.869 -4.5)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6296"
                          data-name="Path 6296"
                          d="M30,12v3.393"
                          transform="translate(-19.79 -9.173)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6297"
                          data-name="Path 6297"
                          d="M28.724,16.5H25.5"
                          transform="translate(-16.902 -11.976)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                      </g>
                    </svg>{" "}
                    Follow
                  </button>{" "}
                  <button type="button" className="btn simple wshare" onClick={onOpenModal}>
                    <i className="fa fa-share-alt"></i> Share
                  </button>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5">
                  <div className="headgreenbg text-center">
                    colleges in {universitybySlug?.Country?.country_name && universitybySlug?.Country?.country_name}
                    {/* Dubai, UAE */}
                  </div>
                  <ul className="universitein">
                    {universities &&
                      universities?.map((data: any, i: any) => {
                        console.log(data);
                        return data?.id !== universitybySlug.id ? (
                          <li key={i} onClick={(e) => { otherUni(data?.slug) }}>
                            <div className="imglogo" style={{ cursor: "pointer" }}>
                              {data.university_logo != null && data.university_logo ? <img src={baseurl + data.university_logo} alt="" /> : <img src="/assets/img/university-icon.png" alt="" />}
                            </div>
                            <div className="unitextwidth1">
                              <p className="__hover" style={{ cursor: "pointer" }}>{data?.university_name && data?.university_name}</p>
                              <p>
                                {/* <small>Dubai Knowledge Park,</small> */}
                              </p>
                            </div>
                            <div className="unitextwidth2">
                              <p>
                                <a href="">{data?.rating == null ? "0" : data?.rating?.toFixed(1)}</a>
                              </p>
                              <p>
                                <small>{data?.review_count && data?.review_count} review</small>
                              </p>
                            </div>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    {/* <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo02.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo01.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo02.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo01.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo02.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo01.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="imglogo">
                        <img src="assets/img/unilogo02.png" alt="" />
                      </div>
                      <div>
                        <p>Heriot-Watt University</p>
                        <p>
                          <small>Dubai Knowledge Park,</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <a href="">4.5</a>
                        </p>
                        <p>
                          <small>4 review</small>
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>

            <div className="col-lg-10 col-md-push-2">
              {universityComment[0]?.reviews == 0 ? (
                <div className="nodataFound">
                  <h4>No reviews found!</h4>
                </div>
              ) : (
                ""
              )}

              {universityComment[0]?.reviews &&
                universityComment[0]?.reviews?.map((data: any, i: any) => {

                  return (
                    <div className="shade1 reviewstops" key={i}>
                      <div className="row">
                        <div className="col-12">
                          <div className="reviews_list">
                            <img src="/assets/img/user_blank.png" alt="" />

                            <div className="reviews_item">
                              <h6>
                                {" "}
                                <b className="color-green">
                                  {" "}
                                  {data?.Student?.name && data?.Student?.name}
                                </b>
                                <span>
                                  {/* <small> batch : 2017</small> */}{" "}
                                </span>
                                <p className="itemrate">
                                  <i className="fa fa-star"></i>
                                  {data?.rating}/5
                                </p>
                              </h6>
                              <p className="pt-1 pb-1">
                                {data?.comments && data?.comments}
                                {/* Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to makea type specimen , but also the
                          leap into electronic typesetting, remaining
                          essentially unchanged .book. It has survived not only
                          five centuries, but also the leap into
                          electronictypesetting, remaining essentially
                          unchanged.It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem. */}
                              </p>
                              <p>
                                <a className="active" href="">
                                  <i
                                    className="fa fa-thumbs-up"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {data?.like_count == 0
                                    ? ""
                                    : data?.like_count}
                                </a>{" "}
                                <a href="">
                                  <i
                                    className="fa fa-thumbs-o-down"
                                    aria-hidden="true"
                                  ></i>
                                  {data?.unlike_count == 0
                                    ? ""
                                    : data?.unlike_count}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Modal --> */}
      <div
        className="modal modal-review fade"
        id="reviewModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content review_model">
            <div className="modal-header">
              <button
                type="button"
                className="close color-green"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <img src="/assets/img/review_img.png" alt="" />
              <h6 className="color-green">rate us</h6> 
              <fieldset className="rating"> 

                <Rating
                  onClick={handleRating}
                  size={25}
                  ratingValue={
                    parseFloat(updateCommentFilter && updateCommentFilter[0]?.rating)
                      ? parseFloat(updateCommentFilter && updateCommentFilter[0]?.rating)
                      : 0
                  } /* Available Props */
                />
              </fieldset>
              <div className="form-group">
                <textarea
                  name="comments"
                  className="form-control"
                  id="t1"
                  rows={3}
                  placeholder="write review"
                  value={comment?.comments}
                  onChange={(e) => {
                    hendlereviews(e);
                  }}
                ></textarea>
              </div>
              <div
                className="all-center mb-3"
                data-dismiss="modal"
                aria-label="Close"
              >
                <a onClick={addCommenthendle} className="view-more readbtn">
                  submit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
