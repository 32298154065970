import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_ARTICALE_LIST,
  GET_ARTICALE_BY_ID,
  GET_REVIEW_LIST,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getarticlelistAction = (data: any) => {
  return { type: GET_ARTICALE_LIST, payload: data };
};
const getreviewlistAction = (data: any) => {
  return { type: GET_REVIEW_LIST, payload: data };
};
const getarticlebyidAction = (data: any) => {
  return { type: GET_ARTICALE_BY_ID, payload: data };
};

export const getarticlelist = (user_id: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.get(`${URL.getarticlelist}?user_id=${user_id}`);
      dispatch(getarticlelistAction(response?.data?.data?.blogs));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getreviewlist = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.getreviewlist}`, data);
      dispatch(getreviewlistAction(response?.data?.data));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getarticlebyid = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getarticlebyid}?id=${data}`);
      dispatch(getarticlebyidAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
