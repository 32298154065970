import React, { useState, useEffect } from "react";
import Testimonial from "../../Home/component/Testimonial";
import { Link, useHistory } from "react-router-dom";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

function TermsAndConditions() {
  const history = useHistory();
  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="assets/img/Term&Conditions.jpg"></img>
            <div className="container">
              <div className="tag-l">
                <h1>services</h1>
                {/* <p>lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/>be distracted by the readable content of a page when looking at its layout.</p> */}
              </div>
            </div>
          </div>
        </section>

        <section className="categories section choosetxt">
          <div className="container">
          <Breadcrumb page={"Services"} data={{slug: "", path: ""}}/>
            <div className="headingmains text-center">
              <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                services
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                    <path id="mortarboard" d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z" transform="translate(0 -42.469)" fill="#fbb415"></path>
                  </svg>
                </span>
              </h2>
              <p className="text-center pl-2 pr-2">Admissify is there for the benefit of the dreamers. We provide exclusive services to make you achieve what you wish.
              </p>
            </div>

            <div className="all-center sub-section pb-0">
              <div className="col-md-12 all-center pb-5">
                <div className="why_slide">
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/funding"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/funding-1.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">funding</h5>
                        <p className="service-c" style={{ color: "rgb(109 109 109)" }}>You've determined to look overseas and find out the globe, that's
                         great! Let's start now with an inventory of estimations so that you recognize wherein your cash got to be spent.
                          Our recommendation will assist you with withinside the layout and control of your budgets without annoying you in the least. 
                          And indeed, the straightforward fees, which include accommodations, food, fitness, communication, and journey,
                           had been paid through us! Here's the value to think about for Study Abroad The expected fees are calculated through every faculty to line up the extent of the economic necessity of a scholar. Total tuition, compulsory prices, rooms, boards (wherein vital) and books, and different dwelling fees (and once during a while journey expenses) are calculated for school kids on the given faculty. Separate determinations are often made at the discretion of the group for school kids who occupy domestic or independently. Funding sources Some colleges, public and private, offer economic funding to school students. The bulk of the institutional help presented to foreign places college students in particular for undergraduate education withinside the form of help and bursaries. As assist to foreign places undergraduates is usually uncommon for few of the universities, such bursaries are typically extraordinarily aggressive. Please take care to check together alongside your college to utilize any additions or exemptions that they will give, the insight that every personal and public university can waive software fees. Special skills, abilities, or abilities are wont to supply merit-primarily based totally scholarships. You’ll also get TOEFL-primarily based scholarships, educational data, innovative abilities, musical or athletic skills. Merit-primarily based scholarships are regularly pretty aggressive than want-primarily based scholarships. You've got to show wonderful competence withinside the place vital to be taken under consideration. Based on economic necessities, scholarship funding is provided. Students with a described stage who can display want could also be eligible for this type of assist. University educational departments might offer presents to help foreign places college students with specific necessities and/or abilities. There are distinctive kinds of supply funds. Here they're
                        </p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/funding"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/accommodation"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/accomadation-1.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">accommodation</h5>
                        <p className="service-c" style={{ color: "rgb(109 109 109)" }}>Are you planning to study in another country and need to find suitable accommodation? Accommodation is almost certainly one of the first things that come to mind when thinking about reading abroad, and your final decision may be dependent entirely on a variety of criteria. There are numerous options for international scholar housing, as well as various concerns and checkpoints you must go through before making your choice - which is almost certain to be partially based entirely on personal desire. Whatever your budget, whether you're looking for college housing or anything in the private sector, there may be an option for you. Simply look through a few of the greatest accommodations on our website to make the procedure as simple and painless as possible.
                        </p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/accommodation"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/mentor"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/coach-1.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">mentor</h5>
                        <p className="service-c">Should a mentor's goal be to become perfect? This is the ideal workout, training program, or practice routine. A perfect sequence of signals or progressions. It's a seductive goal and one that can be classified as aspirational. The goal of positivism, like that of a scientist's experimental design, is to create a perfect world. But, just as that promise has proven elusive, and with it, the growth of knowing and doing as more philosophical and paradoxical than certain and certain (at least to some), can't striving for perfection as a mentor be problematic as well? Besides, the perfect mentor is rational and productive… As a result, giving learners the space and time, they need to develop more suitably may gradually decrease. Even the well-meaning coaching mantra of 'focus on the process aimed at learners has come to mean 'training in a specific way' rather than learning and developing according to the unique context of 'need' and 'reality'.
                        </p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/mentor"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="all-center sub-section pb-0">
              <div className="col-md-12 all-center pb-5">
                <div className="why_slide">
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/internship-placement"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/placement23.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">internship & placement
                        </h5>
                        <p className="service-c">Guide On Internship And Placement From Admissify When aspiring to study abroad, you must have some plans to get a bright future. No matter your discipline, a satisfying career will be waiting as you get a degree from a prestigious institution.</p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/internship-placement"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/counsellor"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/counsellor-1.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">counsellor</h5>
                        <p className="service-c">You've labored tough to urge during which you're now. You understand you've got the potential to be among the first-rate for your field. You would like to form the right selections approximately your subsequent steps now if you would like to get your goals. But there are such tons of alternatives to be had it's tough to know what's first-rate for you. With a considerable sort of enjoying and expert careers steerage qualifications, we permit you to pick the right professional direction with inside the twenty-first century. For school students who're uncertain of which profession first-rate fits their capabilities and aptitudes, we'll assist in slender your alternatives. For those who've selected their direction, we'll advocate at the right publications and maximum suitable profile-constructing sports to beautify your possibilities of success. Studying overseas could also be high-priced and involve a large investment. Applying to the 'proper' institutes can maximize your possibilities of getting want-primarily based economic resources or merit-primarily based totally scholarships. Our counselors assist you in filling the specified bureaucracy and checking your documents, advising you with the first-rate techniques to urge the simplest feasible economic resource and scholarships from institutes overseas.
                        </p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/counsellor"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  ><Link to={"/scholarship"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/scholarship-1.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">exclusive scholarships</h5>
                        <p className="service-c">Interested candidates can apply for different scholarship schemes. They are available under various courses. We will tell you the eligibility criteria to apply for a particular scheme and the related benefits. Hurry today and be a member of Admissify.
                        </p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/scholarship"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-center sub-section pb-0">
              <div className="col-md-12 all-center pb-5">
                <div className="why_slide">
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/free-admissify-common-app"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/Common-Apps22.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">free admissify common app
                        </h5>
                        <p className="service-c"><p>Get the Admissify app for free. It is a common platform for all aspirants. The immigration process for further studies becomes smooth with the professionals by your side. Download the free Admissify common app today and enjoy the world-class infrastructure.</p></p>
                      </figcaption>
                    </Link>
                      <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <a onClick={() => { history.push("/free-admissify-common-app"); }} className="view-more readbtn">know more</a>
                      </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/alumni-chat-details"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/alumini-chat24.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">alumni chat</h5>
                        <p className="service-c">You've labored tough to urge during which you're now. You understand you've got the potential to be among the first-rate for your field. You would like to form the right selections approximately your subsequent steps now if you would like to get your goals. But there are such tons of alternatives to be had it's tough to know what's first-rate for you. With a considerable sort of enjoying and expert careers steerage qualifications, we permit you to pick the right professional direction with inside the twenty-first century. For school students who're uncertain of which profession first-rate fits their capabilities and aptitudes, we'll assist in slender your alternatives. For those who've selected their direction, we'll advocate at the right publications and maximum suitable profile-constructing sports to beautify your possibilities of success. Studying overseas could also be high-priced and involve a large investment. Applying to the 'proper' institutes can maximize your possibilities of getting want-primarily based economic resources or merit-primarily based totally scholarships. Our counselors assist you in filling the specified bureaucracy and checking your documents, advising you with the first-rate techniques to urge the simplest feasible economic resource and scholarships from institutes overseas.
                        </p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/alumni-chat-details"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  ><Link to={"/test-preparation"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/Test-Preperatin21.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">test preparation</h5>
                        <p className="service-c">Forget about the tension of speaking in English. We give you lessons of IELTS and GMAT to crack them with flying colors. Get admission to your preferred institute faster and get the cut-off marks.</p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/test-preparation"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonial />

      </div>
    </>
  )
}
export default TermsAndConditions
