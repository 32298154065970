import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import Contact  from "./ContactForm";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

function Alumni() {
   const urlsforShare=window.location.href;
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Alumni chat", path: "/services"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     alumni chat
                     <span>
                        <img className="service-funding" src="assets/img/alumini-chat21.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              You've labored tough to urge during which you're now. You understand you've
                              got the potential to be among the first-rate for your field. You would like to form the right
                              selections approximately your subsequent steps now if you would like to get your goals. But there are
                              such tons of alternatives to be had it's tough to know what's first-rate for you. With a considerable
                              sort of enjoying and expert careers steerage qualifications, we permit you to pick the right
                              professional direction with inside the twenty-first century. For school students who're uncertain of
                              which profession first-rate fits their capabilities and aptitudes, we'll assist in slender your
                              alternatives. For those who've selected their direction, we'll advocate at the right publications and
                              maximum suitable profile-constructing sports to beautify your possibilities of success. Studying
                              overseas could also be high-priced and involve a large investment. Applying to the 'proper' institutes
                              can maximize your possibilities of getting want-primarily based economic resources or merit-primarily
                              based totally scholarships. Our counselors assist you in filling the specified bureaucracy and
                              checking your documents, advising you with the first-rate techniques to urge the simplest feasible
                              economic resource and scholarships from institutes overseas.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/acc1.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Details Of Advantages With Alumni Chat</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Are you ready to witness something highly exciting
                                    in the field of education? Here is the greatest opportunity to study in a foreign country
                                    of your choice. The experts are present to guide you at every single step. Therefore, be
                                    with us and intimate your thoughts about studying abroad. Admissify believes in equal
                                    distribution of education for everyone. Hence, it gives you all the knowledge to enrol
                                    your names on the list of the selected universities. <br />The first step to joining an
                                    institute abroad is selecting a particular university or college. However, you need to
                                    find out first whether the criteria for the respective institute match your profile or
                                    not. Delegate this responsibility to the professionals of Admissify. Your burden will come
                                    down drastically.</div>
                              </div>

                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/acc2.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Chat With The Faculty
                                 </h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The alumni of Admissify will let you learn all the
                                    hidden secrets about the admission techniques. Moreover, you can express your thoughts
                                    freely with the help of the Alumni Chat option. You will get this on the portal of
                                    Admissify. It is readily available on the common app also. Scroll through the Home Page
                                    and choose the chat option. Enter your name and query. Rest will be the responsibility of
                                    the faculty. <br />Do not hesitate to converse frankly with the alumni. They already know
                                    the hardships in getting admissions to the selected university. If you cannot qualify on
                                    the first attempt, there are certain tricks to succeed in the next chance. Therefore, do
                                    not lose hope when the masters are there. <br />The Alumni Association of Admissify
                                    conducts special classes for the benefit of the students. Moreover, they will ensure that
                                    all the aspirants apply without fear. In the first step, they will go through the profiles
                                    well to identify each student's strengths. However, you must register yourself with the
                                    organization to get all the updates on time.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/support-11.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">24/7 Support From The Experts</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">An online chatting facility enables you to contact
                                    the alumni anytime you want. Therefore, the services are available 24/7. The executives of
                                    Admissify will ensure that you get replies within 24 hours at the maximum. You can also
                                    make personal contact numbers from any individual alumni or faculty to discuss anything.
                                    At Admissify, we always focus on your comfort. Please be frank with all of us to
                                    understand what your wish is.
                                    Explain the reason for studying abroad and your preferred course. The alumni can remove
                                    all your confusion by suggesting the correct means of getting admission. If you cannot
                                    select a suitable course, ask for expert advice. They have immense knowledge in
                                    identifying the capabilities of an aspirant. Hence, the best choice will come up according
                                    to your choice and profile.
                                    With the introduction of the Alumni Chat option on the Admissify app, students are really
                                    happy. Every day, they come up with new queries. As a result, the path of studying abroad
                                    will get smoother with every passing time. You can also take up sessions for personal
                                    grooming and suitable accommodations in the concerned country. Compare the tuition fees
                                    and living expenses also for making an appropriate decision.
                                    With one click on the Alumni Chat option, you can instantly communicate with the alumni
                                    members. Learn about the tricks to get good marks in the admission test and the English
                                    proficiency exams. You must also inquire about the applicable laws of different countries.
                                    It is essential to survive in a foreign land for elongated days.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/inquiry-11.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Inquiry About Work Abroad
                                 </h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Not only the studies, but the alumni can also give
                                    tips on work abroad opportunities. Furthermore, many institutions provide work permits
                                    during the courses. Hence, you can join any part-time job or internship to earn additional
                                    pennies. Such money will greatly support the foreign country as the living costs are quite
                                    high.
                                    The alumni can also support you in selecting the best scholarship schemes applicable in
                                    your case. Learn the conditions to get through this to obtain the funds from the concerned
                                    governments. Most of these schemes will cover tuition fees, health insurance, and living
                                    costs of staying in the country. <br />
                                    Apart from this, you may also know about the procedures for a visa. Admissify is very
                                    quick to get a student visa to facilitate a hassle-free journey and stay in the country.
                                    The institution will not take any additional charges for the same. You need to choose a
                                    package for the whole course, including visa procedures.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>


                  </div>
               </div>
            </section>
            <br />

            <Contactus />
         </div>
      </>
   )
}
export default Alumni
