import React from "react";

function ChooseAdmissify() {
  return (
    <div>
      <section className="categories section choosetxt">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline" data-aos="flip-left">
              Why choose admissify?
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.032"
                  height="35.894"
                  viewBox="0 0 43.032 35.894"
                >
                  <path
                    id="mortarboard"
                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                    transform="translate(0 -42.469)"
                    fill="#fbb415"
                  />
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
            admissify is a UK & India based company with offices in London, Boston, Hanover, Vancouver, Sydney & across India. Our international presence allows us to provide in-country support post- arrival, including internship assistance & immigration or job services.
              <br />
              <br />
              Whether you’re looking to study abroad free or just looking for study abroad consultants in Delhi or anywhere in India - look no further! admissify is the best overseas education consultant in India - Study in Germany, UK, USA, Canada or Australia and get post study job assistance too!
              <br />
              <br />
              Wondering how to apply direct to study abroad? Download the admissify app & apply for cashback on tuition & accommodation fees!
            </p>
          </div>
          <div className="all-center sub-section pb-0">
            <div className="col-md-12 all-center pb-5">
              <div className="why_slide">
                <div
                  className="why-choose-ad"
                  data-aos="fade-up-left"
                  data-aos-duration="1000"
                >
                  <figure className="feat-text1">
                    <img className="scale" src="assets/img/why01.png" />
                  </figure>
                  <figcaption className="content-box">
                    <h5 className="pb-2">
                      internationally certified counsellers
                    </h5>
                    <p>
                    Our counsellors are trained from our London Head Office and comprise Alumni from the World’s most reputed universities. With first hand experience they can guide you about the best universities, courses, cities and tips for living abroad.
                    </p>
                  </figcaption>
                </div>
                <div
                  className="why-choose-ad"
                  data-aos="fade-down-right"
                  data-aos-duration="1000"
                >
                  <figure className="feat-text1">
                    <img className="scale" src="assets/img/why02.png" />
                  </figure>
                  <figcaption className="content-box">
                    <h5 className="pb-2">global presence</h5>
                    <p>
                    Our International offices in London, Boston & Hanover provide assistance with student accommodation, internships & placements even after you land. With you every step of the way, that’s the admissify way!
                    </p>
                  </figcaption>
                </div>

                <div
                  className="why-choose-ad"
                  data-aos="fade-up-left"
                  data-aos-duration="1000"
                >
                  <figure className="feat-text1">
                    <img className="scale" src="assets/img/why03.png" />
                  </figure>
                  <figcaption className="content-box">
                    <h5 className="pb-2">end to end</h5>
                    <p>
                    admissify is with you from AI based searching of over 3000 reputed international universities to counsellor chat, easy e-application, coaching, financial services, student accommodation, and post arrival services via admissify's international offices, we're with you all the way!
                    </p>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChooseAdmissify;
