import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

var other_details: any = [];
function SAT() {
   const urlsforShare = window.location.href;
   const dispatch = useDispatch();
   const history = useHistory();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );


   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "SAT-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();

      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();
      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;
      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#satIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));
      // toast.success('Thanks for showing your interest, we will get back to you soon.');
      // window.location.href = "/thank-you";
   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "SAT", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     SAT
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              The SAT Examination is a standardized test that most students who want to go
                              for undergraduate studies take. The broad form of the SAT is Scholastic Aptitude Test. Some people
                              also refer to it as the Scholastic Assessment Test. It mainly targets evaluating the writing, verbal,
                              and mathematical skills of the candidates. All those students who want to pursue an undergraduate
                              program in the US and Canada are supposed to take the test.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/sat01.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">What Is the Importance Of SAT?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">SAT scores are important as they help various
                                    colleges differentiate amongst students from different high schools. The scores mainly
                                    show the students capacity and readiness for college work. The colleges in the US and
                                    Canada have stringent guidelines when it comes to choosing international students. The SAT
                                    scores also enable students to get scholarships based on merit.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/sat02.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Types of SAT Exam</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">There are two types of SAT exams, the SAT 1 is also
                                    called the Scholastic Assessment Test is required to be taken by students who want to seek
                                    admission to undergraduate courses. It is a general test that aims to determine the
                                    writing abilities, verbal abilities, and mathematical abilities of students.
                                    The second type, which is known as SAT 2, is a more subject-based test. Students who are
                                    looking for a particular course, are required to the take the SAT subject-based test. It
                                    helps them demonstrate their knowledge of the subject. However, the latter is discontinued
                                    now by the College Boards. Now, aspirants can take only SAT 1 to get admission to the
                                    stream of their choice.

                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/sat03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">There Are Four Categories Under SAT Exam:</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The SAT examination comprises four sections. They
                                    are reading, writing, language, mathematics, and essay. These sections mainly allow the
                                    authorities to determine, a students’ suitability for a particular stream. The reading
                                    section mainly analyses the reading comprehension and vocabulary understanding of the
                                    student. The writing test analyses the grammatical parts and style of writing.
                                    The SAT mathematics section mainly tests the student’s ability to perform real-world
                                    mathematical calculations. It also measures the problem-solving capabilities, which are
                                    essential for college work. For one section, students are allowed to use a calculator and
                                    none for the other.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/coun11.jpg" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Who Accepts SAT Scores?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Universities in the US accept SAT scores, and so do Canadian universities. Some of the other countries also accept SAT scores. They are the UK, Australia, Germany, and Singapore. SAT scores allow candidates to apply for undergraduate courses in around 800+ universities globally. You can also get guidance on the exam from Admissify.

                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">HOW TO REGISTER FOR THE SAT EXAMINATION?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> Students who are willing to sit for the SAT
                                    examination need to apply in advance for the same. The application process is available
                                    offline as well as online. If the student is applying through the online method, then they
                                    are required to create a login ID on the official site.
                                    The steps after that are self-explanatory. You can read them below. </div>
                                 <h4 className="pb-3 card-head1">Online</h4>
                                 <span className="border-bt"></span>
                                 <div className="terms-s">
                                    <p style={{ fontSize: "14px", lineHeight: "25px" }}>
                                       The student has to visit the college site.
                                       <br />Create an account.
                                       <br />Then the student is required to fill in all the details.
                                       <br />The student has to choose the subject for which he or she is interested.
                                       <br />The site asks the student to upload the most recent photograph.
                                       <br />The student needs to pay the application fee.
                                    </p>

                                 </div>
                                 <h4 className="pb-3 card-head1">Offline</h4>
                                 <span className="border-bt"></span>
                                 <div className="terms-s">
                                    <p style={{ fontSize: "14px", lineHeight: "25px" }}>
                                       If the student is applying offline, a SAT registration form is required, which is available in the Guide. The form comes with a return envelope. You, as a student, need to send the form duly filled up along with the demand draft. The forms are directed to the local regional office for processing.
                                    </p>

                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/sat04.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of GRE test</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Aids high-scoring applicants in gaining admission
                                    and scholarships to top-ranked universities and business schools.
                                    The GRE General Test is treated the same way as other graduate admissions examinations by
                                    the world's leading business schools. It gives colleges a thorough evaluation of the
                                    talents you'll need to succeed in MBA and master's degree programmes.
                                    Unlike other examinations, you can preview and skip questions within each section and go
                                    back to review and amend answers. This can make you feel more at ease and help you attain
                                    your best test results.
                                    The results are valid for five years. That means you can take it now and still have plenty
                                    of time to decide where and when you want to continue your degree.
                                    GRE scores are accepted by over 1,300 business institutions worldwide for admission to MBA
                                    and speciality master's programmes. You can attend top colleges in the United States or
                                    famous programmes throughout Europe.
                                    Want to take the GRE but don't know where to start? Admissify is here to assist you! With
                                    offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India,
                                    Admissify is India's globally recognized education consultancy. After you arrive, we may
                                    provide in-country assistance such as internship aid, immigration, and career services,
                                    thanks to our global network.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country}>
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input
                                                autoComplete="new-off" type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" id="satIn" className="bggreadent apply-sub-btn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default SAT
