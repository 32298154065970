import React, { useState, useEffect } from "react";
import {courseAction } from '../../redux/common/action'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
const Exam = (props:any) => {  
    const isAuthenticated: any = getToken();
    const baseurl = URL.API_BASE_URL;
    const dispatch = useDispatch(); 
    const history = useHistory();

    var user: any = isAuthenticated && jwt_decode(isAuthenticated); 

const country: any = useSelector((state: any) =>state.country.countryDetail ? state.country.countryDetail : {}); 
 
  const exams: any = useSelector((state: any) =>
  state.course.countryExam ? state.course.countryExam : []
); 
useEffect(() => { 
    dispatch(
        courseAction.getCountryExam({})
    ); 
    return () => {}
    },[])
    return (
        <div className="course-offer-uni">
            <div className="row">
                <div className="col-12">
                    <div className="headingmains text-center">
                      <h2 className="titlewithline yellow uniDetailsH2">
                        exams for {country?.country_name?.toLowerCase()}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33.333"
                            height="33.667"
                            viewBox="0 0 33.333 33.667"
                          >
                            <path
                              id="Icon_material-local-offer"
                              data-name="Icon material-local-offer"
                              d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                              transform="translate(-3 -3)"
                              fill="#fbb415"
                            ></path>
                          </svg>
                        </span>
                      </h2>
                    </div>
                    <div className="all-center sub-section pb-0">
                        <div className='row'>
                {exams &&
                      exams?.map((data: any, i: any) => {
                        return (
                            <div className="col-md-4">
                                <div className='exam-box'>
                                <h5 className='pb-2'>{data?.exam_name}</h5>
                                <p> 
                                    {data?.exam_details} 
                                </p>
                                </div>
                            </div>
                        );
                      })} 
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Exam
