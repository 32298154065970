import { 
  LOADING,
  GET_TESTIMONIAL_lIST,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  testimonialList: any;
 }
const initialState : stateType = {
    loading:false,
    testimonialList: []
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_TESTIMONIAL_lIST:
      return { ...state, testimonialList: action.payload};
    default:
      return state;
  }
};