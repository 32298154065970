import React, { useState, useEffect } from "react";
import { courseAction } from '../../redux/common/action'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";

const CCourse = () => {
    const isAuthenticated: any = getToken();
    const baseurl = URL.API_BASE_URL;
    const dispatch = useDispatch();
    const history = useHistory();

    var user: any = isAuthenticated && jwt_decode(isAuthenticated);

    const country: any = useSelector((state: any) => state.country.countryDetail ? state.country.countryDetail : {});

    const course: any = useSelector((state: any) =>
        state.course.countryCourse ? state.course.countryCourse : []
    );
    useEffect(() => {
        dispatch(
            courseAction.getAllCountryCourses({
                CountryId: country?.id,
            })
        );
        return () => { }
    }, [country?.id])
    return (
        <div className="course-offer-uni">
            <div className="row">
                <div className="col-12">
                    <div className="headingmains text-center">
                        {course?.length > 0 ?
                            <h2 className="titlewithline yellow uniDetailsH2">
                                choose your course

                                < span >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35"
                                        height="31.457"
                                        viewBox="0 0 35 31.457"
                                    >
                                        <path
                                            id="library-building"
                                            d="M34.259,50.572H.741a.741.741,0,0,1,0-1.483H34.259a.741.741,0,0,1,0,1.483ZM32.9,47.192a.741.741,0,0,1-.741-.741V30.066H26.179a.741.741,0,1,1,0-1.483H32.9a.741.741,0,0,1,.741.741s0,.01,0,.015V46.451A.742.742,0,0,1,32.9,47.192Zm-3.7,0h-4.13a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741H29.2a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,29.2,47.192Zm-3.388-1.483h2.647V42.572H25.815Zm0-4.621h2.647V37.951H25.815Zm0-4.621h2.647V33.33H25.815Zm-4.51,10.724a.741.741,0,0,1-.741-.741V37.1H14.436v9.347a.741.741,0,1,1-1.483,0V36.363a.742.742,0,0,1,.864-.731.743.743,0,0,1,.123-.01h7.118a.753.753,0,0,1,.123.01.742.742,0,0,1,.864.731V46.451A.742.742,0,0,1,21.305,47.192Zm-11.379,0H5.8a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741h4.13a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,9.926,47.192ZM6.538,45.709H9.185V42.572H6.538Zm0-4.621H9.185V37.951H6.538Zm0-4.621H9.185V33.33H6.538ZM2.1,47.192a.741.741,0,0,1-.741-.741V29.341c0-.005,0-.011,0-.016a.741.741,0,0,1,.741-.741h6.72a.741.741,0,0,1,0,1.483H2.843V46.451A.741.741,0,0,1,2.1,47.192Zm15.4-3.928a.741.741,0,0,1-.741-.741V41.077a.741.741,0,1,1,1.483,0v1.446A.741.741,0,0,1,17.5,43.264Zm-.085-9.906a7.13,7.13,0,0,1-7.122-7.122v-6.38a.741.741,0,0,1,.741-.741H23.8a.741.741,0,0,1,.741.741v6.38A7.13,7.13,0,0,1,17.415,33.359ZM11.776,20.6v5.639a5.639,5.639,0,1,0,11.278,0V20.6ZM17.5,29.323h0a.739.739,0,0,1-.191-.025H17.3l-.026-.008-3.3-1.031a.741.741,0,0,1-.52-.708V22.734a.741.741,0,0,1,.963-.708l3.08.962,3.08-.962a.741.741,0,0,1,.963.708v4.816a.741.741,0,0,1-.52.708l-3.3,1.031A.741.741,0,0,1,17.5,29.323Zm-2.559-2.318,1.818.568V24.311l-1.818-.568Zm3.3-2.695v3.263l1.818-.568V23.743Z"
                                            transform="translate(0 -19.115)"
                                            fill="#fbb415"
                                        />
                                    </svg>
                                </span>
                            </h2> : ""}
                        {/* <p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p> */}
                    </div>
                    <div className="all-center sub-section pb-0">
                        <div className='row c_course_list'>
                            {course &&
                                course?.map((data: any, i: any) => {
                                    return (
                                        <div className='col-md-3 col-sm-6 country_course'>
                                            <div className='text-center'>
                                                {data?.stream_icon !== null ?
                                                    <img src={baseurl + data?.stream_icon} alt="" className='course_icon' onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/step1_icon02.png" }} /> :
                                                    <img src="/assets/img/step1_icon02.png" alt="" className='course_icon' />
                                                }
                                            </div>
                                            <h4>{data?.stream_name}</h4>
                                            <p>{data?.university_count} college</p>
                                        </div>
                                    );
                                })}
                            {/* <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div>
                            <div className='col-md-3 col-sm-6 country_course'>
                                <div className='text-center'><img src={"/assets/img/steps/step1_icon02.png"} className='course_icon'/></div>
                                <h4>Medical</h4>
                                <p>1143 college</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CCourse