import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_ALL_COUPON,
  SCHOLARSHIP_GET_ALL_DETAIL,
  FIND_BY_SCHOLAR_SLUG,
  GET_OFFER_AND_SCHOLAR,
  GET_APPLIED_SCHOLAR
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getAllCouponAction = (data:any) => {
  return { type: GET_ALL_COUPON, payload: data };
};
const scholarshipGetAllDetailsAction = (data:any) => {
  return { type: SCHOLARSHIP_GET_ALL_DETAIL, payload: data };
};

const fineByScholarSlugAction = (data:any) => {
  return { type: FIND_BY_SCHOLAR_SLUG, payload: data };
};
const getOffersAndScholarAction = (data:any) => {
  return { type: GET_OFFER_AND_SCHOLAR, payload: data };
};
const getAppliedScholarAction = (data:any) => {
  return { type: GET_APPLIED_SCHOLAR, payload: data };
}
export const getAllCoupon = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getAllCoupon}`);
          dispatch(getAllCouponAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const scholarshipGetAllDetails = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.scholarshipGetAllDetails}`);
      dispatch(scholarshipGetAllDetailsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const fineByScholarSlug = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.fineByScholarSlug}?slug=${data}`);
      dispatch(fineByScholarSlugAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getOffersAndScholar = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getOffersAndScholar}`);
          dispatch(getOffersAndScholarAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const applyScholar = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.post(`${URL.applyScholar}`,data);
          dispatch(loading(false));
          window.location.reload();
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAppliedScholar = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.post(`${URL.getAppliedScholar}`, data);
          dispatch(getAppliedScholarAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};