import React, { useState } from 'react'
import Modal from 'react-responsive-modal';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon,
  } from "react-share";
import { toast } from 'react-toastify';

const ShareModal = (props:any) => {
    const {open, onCloseModal, urlforShare} = props;
    const [copied, setCopied] = useState(false);

    function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
        toast.success("Copied!");
      }
  return (
    <Modal open={open} onClose={onCloseModal} center>
        <div className="unishareIcon">
          <FacebookShareButton
            url={urlforShare}
          // quote={blog?.heading}
          >
            <FacebookIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={urlforShare}
          >
            <TwitterIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </TwitterShareButton>
          <LinkedinShareButton
            url={urlforShare}
          >
            <LinkedinIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </LinkedinShareButton>
        </div>
        <div className="shareLinkInModel"> <p><b>{urlforShare}</b></p></div>
        <div>
          <div className="sharebtncos" onClick={(() => { copy(); onCloseModal(); })} >{!copied ? "Copy link" : "Copied!"}</div>
        </div>
      </Modal>
  )
}

export default ShareModal