import { 
  LOADING,
  GET_FAQ_CATEGORY_LIST,
  GET_FAQ_BY_ID,GET_FAQ_LIST,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  faqCatList: any[];
  faqById: any[];
  faqList: any[];

 }
const initialState : stateType = {
    loading:false,
    faqCatList: [],
    faqById: [],
    faqList: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_FAQ_CATEGORY_LIST:
      return { ...state, faqCatList: action.payload};
      case GET_FAQ_BY_ID:
      return { ...state, faqById: action.payload};
      case GET_FAQ_LIST:
        return { ...state, faqList: action.payload};
    default:
      return state;
  }
};