import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { officeAction } from '../../../redux/common/action';
import { getAllCountry } from '../../../redux/country/action';
import { validateExamForm } from '../../../validation/Validation';

const ExamForm = (props:any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [values, setValue] = useState<any>({});
    const [errors, setErrors] = useState<any>(undefined);
const user: any = useSelector((state:any) => state?.student?.getstudentById || {});
    const country: any[] = useSelector((state: any) =>
        state.country.countryList ? state.country.countryList : []
    );
    const handleOnChnage = (e:any) => {
        let name:any = e.target.name;
        let value:any = e.target.value;
        if(name === "mobile" && /\D/g.test(value)) {
            return
        }
        setValue({...values, [name]:value});
    }
    const handleOnSubmit = (e:any) => {
        const validationErrors = validateExamForm(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data:any = {...values};
            data.name = user?.name;
            data.email = user?.email;
            data.mobile = user.mobile;
            data.other_details = [
                { name: "year", label: "tentative course intake(month and year)", value: data?.intake || "N/A" },
                { name: "country", label: "preferred country", value: data?.country || "N/A" }
            ]
            data.service_name = props?.service;
            data.thank = true;
            delete data.intake;
            delete data.country;
            dispatch(officeAction.submitQuery(data, history));
        }
    }
    useEffect(()=> {
        dispatch(getAllCountry(""));
    },[])
  return (
    <div className="apply-box">
        <div className="container p-2">
            <div className="row">
                <div className="col-md-11">
                    <h5 className="apply-head">apply now</h5>
                </div>
                <div className="col-md-12 col-lg-12">
                    <div className="fields-wrap">
                        <form id="fundingForm">
                            {/* <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text"
                                            name="name" id="Name" value={values?.name || ""} onChange={handleOnChnage} />
                                            <label>name</label>
                                            <span className="fa fa-user p-2"></span>
                                    </div>
                                    {errors?.name && <p className='error-class'>{errors?.name}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field"><input autoComplete="new-off" type="text"
                                            name="mobile" maxLength={10} id="Mobile"
                                            value={values?.mobile || ""} onChange={handleOnChnage} /><label>mobile</label><span
                                            className="fa fa-phone p-2"></span></div>
                                            {errors?.mobile && <p className='error-class'>{errors?.mobile}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field"><input autoComplete="new-off" type="text"
                                            name="email" id="Email" value={values?.email || ""} onChange={handleOnChnage} /><label>email</label><span
                                            className="fa fa-envelope p-2"></span></div>
                                            {errors?.email && <p className='error-class'>{errors?.email}</p>}
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <span className="fa fa-map-marker p-2"></span>
                                        <select className="form-control c-select" name="country" value={values?.country || ""} onChange={handleOnChnage} >
                                            <option value="">preferred country</option>
                                            {country && country?.map((data: any, i: any) => {
                                                return (
                                                <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors?.country && <p className='error-class'>{errors?.country}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field"><input autoComplete="new-off" type="text"
                                            name="intake" value={values?.intake || ""}onChange={handleOnChnage} />
                                        <label>tentative course intake(month and year)</label>
                                        <span className="fa fa-calendar p-2 r-20"></span>
                                    </div>
                                    {errors?.intake && <p className='error-class'>{errors?.intake}</p>}
                                </div>
                            </div>

                            <div className="field"><textarea autoComplete="new-off"
                                    className="ques-msg border__" rows={4} cols={50} placeholder="message"
                                    name="message" value={values?.message || ""} onChange={handleOnChnage} ></textarea>
                                    {errors?.message && <p className='error-class'>{errors?.message}</p>}
                            </div>
                            <br/><br/><br/><br/>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="fieldbtn text-center">
                                        <input type="button" className="bggreadent-n" id="fundingIn"
                                            value="submit" onClick={(e)=> handleOnSubmit(e)}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExamForm