import React, { useState } from "react";
import logo from "../../assets/img/Admissfy_Logo.png";
import browserLogo from "../../assets/img/browser-logo.jpeg";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Popup = (props: any) => {
  const [agent, setAgent] = useState("");

  const redirectToAppStore = () => {
    // Replace with your App Store URL
    window.location.href =
      "https://apps.apple.com/in/app/admissify/id1235485892";
  };

  const redirectToGooglePlay = () => {
    // Replace with your Google Play Store URL
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.admissify&pli=1";
  };

  useEffect(() => {
    const navagent = navigator.userAgent;
    // console.log(navagent);
    setAgent(navagent);
  }, []);

  const handleRedirection = () => {
    // console.log(agent);
    sessionStorage.setItem("itemClicked", "true");
    if (agent.match(/iPhone|iPad/i)) {
      redirectToAppStore();
    }
    if (agent.match(/Android/i)) {
      redirectToGooglePlay();
    }
  };

  const handleContinue = () => {
    sessionStorage.setItem("itemClicked", "true");
    props.myFunction(false);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-bg">
        <div className="popup-content">
          <h6>Switch to the Admissify App for a better experience</h6>
          <p style={{ fontSize: 10 }}>
            Explore top universities, courses, and scholarships for your study
            abroad journey.
          </p>
          <div className="browsing-option-1">
            <div className="logo">
              {/* <img src={logo} alt="" /> */}

              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  // border: "1px solid red",
                  // marginRight: "-10px",
                  // width: "auto",
                }}
              >
                Open In App
              </p>
            </div>
            <button
              style={{ backgroundColor: "#11767A", color: "white" }}
              className="browser"
              onClick={handleRedirection}
            >
              {" "}
              Download{" "}
            </button>
            {/* <p>App</p> */}
          </div>
          <div className="browsing-option-2 mt-2">
            <div className="browserImg">
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  // border: "1px solid red",
                  // marginRight: "-10px",
                  // width: "auto",
                }}
              >
                Continue Browsing
              </p>
            </div>
            {/* <p>Browser</p> */}
            <button className="browser" onClick={handleContinue}>
              {" "}
              Continue{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
