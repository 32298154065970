import React, { useEffect } from 'react'
import MobileRoute from '../route/MobileRoute';
import   "./assets/css/mobilestyle.css";
import ScrollToTop from '../Component/ScrollToTop/ScrollToTop';
import { useSelector } from 'react-redux';
import LoadingScreen from '../Component/LoadingScreen/LoadingScreen';
import { removeChatWidget } from '../Utils/Common/Common';

const MobileLayout = (props:any) => {
  removeChatWidget();
  const commenreducer: any = useSelector((state: any) =>
    state.commenreducer.loading && state.commenreducer.loading
  );
  return (
    <div className='mobile_css'>
      <ScrollToTop />
      <MobileRoute/>
      {commenreducer && <LoadingScreen />}
    </div>
  )
}

export default MobileLayout