import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UniversityAction,
  countryAction,
  UniversityCourseAction,
} from "../../redux/common/action";
import { Link, useHistory, useLocation } from "react-router-dom";
import AdSlider from "../AdSlider/AdSlider";
import StarRatings from "react-star-ratings";
import CourseFilter from "../coursefilter/CourseFilter";
import MenuOutSideClick from "../../layout/header/outsideClick";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import dateFormat from "dateformat";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import SocialShareButton from "../SocialShareButton";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import { addInlineStylesToHtml } from "../../Utils/Common/Common";
function AboutCourse() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const id: any = useLocation();
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [readMore, setreadMore] = useState<any>(5);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const [studylevel, setstudylevel] = useState<any>("1");
  const [sortBy, setosrtBy] = useState<any>();
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [course, setcourse] = useState<any[]>([]);
  const [courseid, setcourseid] = useState<any[]>([]);
  const [countryOpne, setcountryOpne] = useState<any>(false);
  const [unicount, setunicount] = useState<any>(false);
  const [indexMore, setIndexMore] = useState<any>("");
  const baseurl = URL.API_BASE_URL;
  const [relatedCourseFlag, setRelatedCourseFlag] = useState<any>(
    isMobile ? true : false
  );

  const relatedCourse: any = useSelector((state: any) =>
    state.course.allCourse ? state.course.allCourse : []
  );

  const courseList =
    relatedCourse &&
    relatedCourse?.filter((data: any) => {
      return data.level == studylevel;
    });

  const activecourse_ =
    courseList &&
    courseList?.filter((data: any) => {
      return data?.status == 1;
    });
  const activecourse = activecourse_.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );
  const university: any[] = useSelector((state: any) =>
    state.unversity.universityList ? state.unversity.universityList : []
  );

  const countvalue: any =
    country?.length !== 0 ? true : course?.length !== 0 ? true : false;

  // const countrys: any[] = useSelector((state: any) =>
  //   state.country.countryList ? state.country.countryList : []
  // );

  //Getting Slug From URL
  var url = window.location.pathname;
  // var urlString = url.substring(url.lastIndexOf("/") + 2);
  var SplitString = url.split("/");
  // var a = SplitString[0].replace(/[^a-zA-Z ]/g, "");
  var Slug = SplitString[2];
  var Level =
    SplitString[3] && SplitString[3] == "ug"
      ? 1
      : SplitString[3] == "pg"
      ? 2
      : SplitString[3];

  // const contrybackId = id?.state?.country_id !== null ? id?.state?.country_id.split(',') : []
  const coursebackId =
    id?.state?.course_id !== null ? id?.state?.course_id : "";
  const courseLevel = id?.state?.level !== null ? id?.state?.level : "";

  const courseDetail: any = useSelector((state: any) =>
    state.universitycourse.courseDetail
      ? state.universitycourse.courseDetail
      : {}
  );

  const similarCourse: any = useSelector((state: any) =>
    state.course.similarCourse ? state.course.similarCourse : []
  );
  const filSimilarCourse = similarCourse.filter((data: any) => {
    return data.course_level == Level;
  });

  const _similarCourse = filSimilarCourse.sort(function (a: any, b: any) {
    if (sortBy === "asc") {
      return (
        a?.first_yr_fee?.replace(/,/g, "") - b?.first_yr_fee?.replace(/,/g, "")
      );
    }
    if (sortBy === "desc") {
      return (
        b?.first_yr_fee?.replace(/,/g, "") - a?.first_yr_fee?.replace(/,/g, "")
      );
    }
  });
  const sortByName = (e: any) => {
    setosrtBy(e);
  };

  const levelofstudy = (e: any) => {
    setstudylevel(e);
  };
  //Getting Slug From URL
  // var url = window.location.pathname;
  // // var urlString = url.substring(url.lastIndexOf("/") + 2);
  // var SplitString = url.split("/");
  // // var a = SplitString[0].replace(/[^a-zA-Z ]/g, "");
  // var Slug = SplitString[2];
  // var Level = SplitString[3];

  useEffect(() => {
    if (Slug) {
      dispatch(
        UniversityCourseAction.getCourseDetails({
          CourseSlug: coursebackId ? coursebackId : Slug,
          Level: courseLevel ? courseLevel : Level,
        })
      );
    }
    return () => {};
  }, [Slug]);

  useEffect(() => {
    if (courseDetail?.id)
      dispatch(
        UniversityCourseAction.getSimilarCourses({
          CourseId: courseDetail?.id ? courseDetail?.id : "",
          SearchTerm: "",
          Offset: 0,
          Limit: 10,
        })
      );
    return () => {};
  }, [courseDetail?.id]);

  const otherUni = (e: any) => {
    var SplitString = e.split("/");
    var a = SplitString[0];
    var b = SplitString[1];
    let level = b == 1 ? "ug" : b == 2 ? "pg" : "";
    history.push("/course/" + a + "/" + level, {
      university_id: e,
    });
    dispatch(
      UniversityCourseAction.getCourseDetails({
        CourseSlug: a,
        Level: b,
      })
    );
    return () => {};
  };

  const handleRelatedCourseFlag = (e: any) => {
    if (relatedCourseFlag == true) {
      setRelatedCourseFlag(false);
    } else {
      setRelatedCourseFlag(true);
    }
  };
  const getTitle = (cName: any) => {
    return `${Level == 2 ? "Masters" : "Bachelors"} in ${
      cName || ""
    } - Course, Syllabus, Fees, Top Colleges & Ranking`;
  };
  const getDes = (cName: any) => {
    return `Pursue a ${Level == 2 ? "Masters" : "Bachelors"} in ${
      cName || ""
    } with Admissify, your trusted study abroad partner. Check out Course details, fees, top colleges & Syllabus.`;
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
  });

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={getTitle(courseDetail?.title)}
        meta_description={getDes(courseDetail?.title)}
        meta_keywords={""}
        modified_time={courseDetail?.updated_at || courseDetail?.created_at}
        image={
          courseDetail?.course_icon != null
            ? baseurl + courseDetail?.course_icon
            : ""
        }
      />
      <section className="unifilter-page">
        <div className="container">
          <Breadcrumb
            page={"Course"}
            data={{ slug: courseDetail?.title, path: "/course" }}
          />
          <div className="row">
            <div className="col-lg-10 col-md-pull-10 mob-mt-160">
              {seo?.header_content && (
                <div className="about-uni-text shade1">
                  <SeoHeaderContent />
                </div>
              )}
              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  <div className="course-offer-uni shade1 pb-0">
                    <div className="text-right">
                      <SocialShareButton
                        urlsforShare={urlsforShare}
                        data={""}
                      />
                    </div>
                    <div className="headingmains text-center">
                      <h4 className="titlewithline course-head mob-mt-0">
                        about {courseDetail?.title}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33.333"
                            height="33.667"
                            viewBox="0 0 33.333 33.667"
                          >
                            <path
                              id="Icon_material-local-offer"
                              data-name="Icon material-local-offer"
                              d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                              transform="translate(-3 -3)"
                              fill="#fbb415"
                            ></path>
                          </svg>
                        </span>
                      </h4>
                    </div>
                    {courseDetail?.description != null &&
                    courseDetail?.description != "" ? (
                      <div className="about-uni-text shade1 course-n-pd">
                        <div className="row">
                          <div className="col-12 text">
                            <h2 className="course-n mb-2">overview</h2>
                            {/* <p>{courseDetail?.description}</p> */}
                            <div
                              className=" mb-4 tinyEditor"
                              dangerouslySetInnerHTML={{
                                __html: courseDetail?.description
                                  ? addInlineStylesToHtml(
                                      courseDetail?.description
                                    )
                                  : "",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {courseDetail?.specialization != null &&
                    courseDetail?.specialization != "" ? (
                      <div className="about-uni-text shade1 course-n-pd">
                        <div className="row">
                          <div className="col-12 text">
                            <h2 className="course-n mb-2">specialization</h2>
                            <p>{courseDetail?.specialization}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {courseDetail?.scope != null &&
                    courseDetail?.scope != "" ? (
                      <div className="about-uni-text shade1 course-n-pd">
                        <div className="row">
                          <div className="col-12 text">
                            <h2 className="course-n mb-2">scope</h2>
                            {/* <p>{courseDetail?.scope}</p> */}
                            <div
                              className="mb-4 "
                              dangerouslySetInnerHTML={{
                                __html: courseDetail?.scope
                                  ? addInlineStylesToHtml(courseDetail?.scope)
                                  : "",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col-12">
                        <div className="all-center sub-section pb-0 m-0 p-0">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="page-top pull-left mb-4">
                                <h2 className="course-n mb-2">
                                  similar courses
                                </h2>
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              style={{
                                display:
                                  _similarCourse.length > 0 ? "" : "none",
                              }}
                            >
                              <div className="page-top pull-right mb-4">
                                <select
                                  className="awe-select"
                                  onChange={(e: any) =>
                                    sortByName(e.target.value)
                                  }
                                >
                                  <option>sort by</option>
                                  <option value="asc">lowest fees</option>
                                  <option value="desc">highest fees</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {_similarCourse?.map((data: any, index: any) => {
                              let date =
                                data?.application_last_date != "0000-00-00" &&
                                data?.application_last_date != null &&
                                data?.application_last_date != "Invalid date"
                                  ? dateFormat(
                                      data?.application_last_date,
                                      "d-mmm-yyyy"
                                    )
                                  : "N/A";
                              return (
                                <div className="col-md-6" key={index}>
                                  <div className="studywrp shade1">
                                    <div className="bgstudy">
                                      <div className="row ">
                                        <div className="col-sm-12 col-lg-7 text-left">
                                          <h6>
                                            {data?.university_course} <br />
                                            {data?.course_type == 1
                                              ? "full time"
                                              : data?.course_type == 2
                                              ? "part time"
                                              : data?.course_type == 3
                                              ? "weekend"
                                              : data?.course_type == 4
                                              ? "other"
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="col-sm-12 col-lg-5">
                                          <h5>
                                            <span>
                                              {data?.fee_currency_code}
                                            </span>{" "}
                                            {data?.first_yr_fee}/year
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="detailstudy">
                                      <div className="row">
                                        <div className="col-sm-12 col-lg-12 text-left">
                                          <table
                                            width="100%"
                                            style={{ border: "0" }}
                                            cellSpacing="0"
                                            cellPadding="0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/time.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          <b>
                                                            {data?.duration}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            course&nbsp;duration
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/date.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          <b>{date}</b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            application&nbsp;deadline
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    style={{ border: "0" }}
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <tbody className="course__">
                                                      <tr>
                                                        <td rowSpan={2}>
                                                          <img
                                                            src="/assets/img/library-building.png"
                                                            alt=""
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          {data?.entry_requirements ? (
                                                            indexMore ===
                                                              data?.id &&
                                                            data?.entry_requirements?.split(
                                                              " "
                                                            ).length <
                                                              readMore ? (
                                                              <p className="course__font">
                                                                {
                                                                  data?.entry_requirements
                                                                }
                                                              </p>
                                                            ) : (
                                                              <p className="course__font">
                                                                {" "}
                                                                {data?.entry_requirements
                                                                  ?.split(" ")
                                                                  .splice(0, 5)
                                                                  .join(" ")}
                                                                .{" "}
                                                                <a
                                                                  className="read-more-new"
                                                                  href="#"
                                                                  onClick={(
                                                                    e: any
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    setreadMore(
                                                                      data?.entry_requirements?.split(
                                                                        " "
                                                                      )
                                                                        .length &&
                                                                        data?.entry_requirements?.split(
                                                                          " "
                                                                        )
                                                                          .length +
                                                                          1
                                                                    );
                                                                    setIndexMore(
                                                                      data?.id
                                                                    );
                                                                  }}
                                                                >
                                                                  read more
                                                                </a>
                                                              </p>
                                                            )
                                                          ) : (
                                                            <p></p>
                                                          )}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            entry&nbsp;requirement
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <div className="examscore">
                                            {/* exam scores &nbsp;{" "}
                                              {typeof exam == "object"
                                                ? exam?.map(
                                                    (datas: any, i: any) => {
                                                      return (
                                                        <span key={i}>
                                                          {" " + datas?.title}{" "}
                                                          <b>{datas?.value}</b>{" "}
                                                          {i > 0 && i - 1
                                                            ? ""
                                                            : "|"}
                                                        </span>
                                                      );
                                                    }
                                                  )
                                                : ""} */}
                                            exam scores &nbsp; IELTS{" "}
                                            <b>
                                              {data?.iselts_score
                                                ? data?.iselts_score
                                                : 0}
                                            </b>{" "}
                                            | TOEFL{" "}
                                            <b>
                                              {data?.toefl_scrore
                                                ? data?.toefl_scrore
                                                : 0}
                                            </b>{" "}
                                            | PTE{" "}
                                            <b>
                                              {data?.pte_score
                                                ? data?.pte_score
                                                : 0}
                                            </b>
                                          </div>
                                          <div className="option">
                                            <span>options available</span>
                                            M.B.A Entrepreneurship
                                          </div>
                                          <div className="option">
                                            Offered by{" "}
                                            <p className="color-green">
                                              {
                                                data?.ad_university
                                                  ?.university_name
                                              }
                                              {/* Harvard Business School */}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-3">
                                          {/* <a href="" className="checkbtn">
                                              check eligibility
                                            </a> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {_similarCourse && _similarCourse.length > 0 ? (
                              <div
                                className="col-md-12 col-sm-12 col-lg-12 all-center aos-init aos-animate"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-offset="200"
                                style={{ marginBottom: "15px" }}
                              >
                                <a
                                  href={"/course"}
                                  className="view-more readbtn"
                                >
                                  view all courses
                                </a>
                              </div>
                            ) : (
                              <div className="text-center col-12">
                                <p className="mb-3">
                                  <strong>No similar courses found!</strong>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {seo?.footer_content && (
                <div className="about-uni-text shade1">
                  <SeoFooterContent />
                </div>
              )}
            </div>
            <div className="col-lg-2 col-md-push-2">
              <div className="page-sidebar">
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg" onClick={handleRelatedCourseFlag}>
                    related <b>course</b>{" "}
                    {relatedCourseFlag == true ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-up" aria-hidden="true"></i>
                    )}
                  </h5>
                  <ul
                    style={{ backgroundColor: "#fff" }}
                    className={relatedCourseFlag == true ? "d-none" : ""}
                  >
                    <div className="lavelbox text-left">
                      {/* <b>level of study</b> */}
                      <input
                        style={{ marginLeft: "0" }}
                        type="radio"
                        name="RadioGroup1"
                        value="1"
                        onClick={(e: any) => levelofstudy(e.target.value)}
                        id="RadioGroup1_0"
                        defaultChecked
                      />
                      <label htmlFor="RadioGroup1_0"> bachelor's </label>

                      <input
                        type="radio"
                        name="RadioGroup1"
                        value="2"
                        onClick={(e: any) => levelofstudy(e.target.value)}
                        id="RadioGroup1_1"
                      />
                      <label htmlFor="RadioGroup1_1"> master's</label>
                    </div>
                    {relatedCourseFlag == false &&
                      activecourse &&
                      activecourse?.map((data: any, i: any) => {
                        // if (data?.title !== courseDetail?.title && data?.level !== courseDetail?.level)
                        return (
                          <li
                            key={i}
                            className="__course"
                            style={{
                              display:
                                data?.title == courseDetail?.title
                                  ? "none"
                                  : "",
                            }}
                            onClick={(e) => {
                              otherUni(data?.code + "/" + data?.level);
                            }}
                          >
                            <p>
                              <a>{data?.title && data?.title}</a>
                            </p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutCourse;
