import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaqAction } from "../../redux/common/action";
import Seo from "../Seo/Seo";
import SocialShareButton from "../SocialShareButton";
import Breadcrumb from "../Breadcrumb";

function Faqs() {
  const urlsforShare=window.location.href;
  const dispatch = useDispatch();
  const [faqdata, setfaqdata] = useState();
  const [LastCategoryId, setLastCategoryId] = useState<any>(null);
  const faqCatList: any[] = useSelector((state: any) =>
    state.faq.faqCatList ? state.faq.faqCatList : []
  );

  // const faq: any[] = useSelector((state: any) =>
  //   state.faq.faqById ? state.faq.faqById : []
  // );

  const faqList: any[] = useSelector((state: any) =>
    state.faq.faqList ? state.faq.faqList : []
  );

  useEffect(() => {
    dispatch(FaqAction.getfaqcategory({}));
    return () => { };
  }, []);

  useEffect(() => {
    faqCatList.map((data, index) => {
      if (index == 0)
        setLastCategoryId(data?.id);
    });
  }, [faqCatList]);

  useEffect(() => {
    dispatch(FaqAction.getfaqlist({}));
    return () => { };
  }, []);

  const onchangefaq = (event: any, data: any) => {
    setLastCategoryId(data);
  };

  return (
    <div>
      <Seo slug={window.location.pathname}/>
      <section className="categories section faqpage bg-grey-light">
        <div className="container">
        <Breadcrumb page={"Faq's"} data={{slug: "", path: ""}}/>
          <div className="row">
            <div className="col-md-6 col-lg-7 faqleft">
              <div className="heading">
                <h1>
                  <span>Faq's</span>
                </h1>
                <p>
                  Here you’ll find a collection of the most frequently asked questions regarding how to study abroad. Find information on where to study, how to apply, entry requirements, getting a student visa, tuition fees and funding, finding study abroad scholarships and other practical information.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="faqright">
                <img src="assets/img/faq-right.png" className="scale" alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 mt-5">
                <div className="d-flex justify-content-end mb-5">
                  <SocialShareButton urlsforShare={urlsforShare} data={""}/>
                </div>
              <div className="contentbx choachwhat faqtabs">
                <ul className="nav nav-tabs" role="tablist">
                  {faqCatList && faqCatList?.map((data, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <a
                          className={
                            LastCategoryId == data?.id ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          href={"#tabs-1"}
                          onClick={(event) => onchangefaq(event, data?.id)}
                          role="tab"
                        >
                          {data?.category && data?.category}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="accordion" id="faq">
                      {faqList && faqList?.map((data, index) => {
                        if (data?.faq_category_id == LastCategoryId) {
                          return (
                            <div className="card" key={index}>
                              <div
                                className="card-header"
                                id={"faqhead1" + data?.id}
                              >
                                <a
                                  href="#"
                                  className={
                                    index == 0
                                      ? "btn btn-header-link collapsed"
                                      : "btn btn-header-link collapsed"
                                  }
                                  data-toggle="collapse"
                                  data-target={"#faq1" + data?.id}
                                  aria-expanded="true"
                                  aria-controls={"faq1" + data?.id}
                                >
                                  <div className="w-97">{data?.title}</div>
                                </a>
                              </div>

                              <div
                                id={"faq1" + data?.id}
                                className={
                                  index == 0 ? "collapse " : "collapse"
                                }
                                aria-labelledby={"faqhead1" + data?.id}
                                data-parent="#faq"
                              >
                                <div className="card-body">
                                  {data?.description && data?.description}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}

                    </div>
                  </div>

                  {/* <div className="tab-pane" id="tabs-2" role="tabpanel">
                    <div className="accordion" id="faq2a">
                      <div className="card">
                        <div className="card-header" id="faqhead12a">
                          <a
                            href="#"
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target="#faq12a"
                            aria-expanded="true"
                            aria-controls="faq12a"
                          >
                            How to find university(admission)?
                          </a>
                        </div>

                        <div
                          id="faq12a"
                          className="collapse show"
                          aria-labelledby="faqhead12a"
                          data-parent="#faq2a"
                        >
                          <div className="card-body">
                            oon tempor, sunt aliqua put a bird on it squid
                            single-origin coffee nulla assumenda shoreditch et.
                            Nihil anim keffiyeh helvetica, craft beer labore wes
                            anderson cred nesciunt sapiente ea proident. Ad Anim
                            pariatur cliche reprehenderit, enim eiusmod high
                            life accusamus terry richardson ad squid. 3 wolf
                            moon officia aute, non cupidatat skateboard dolor
                            brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf mvegan excepteur butcher vice lomo.
                            Leggings occaecat craft beer farm-to-table, raw
                            denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus labore sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead22a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq22a"
                            aria-expanded="true"
                            aria-controls="faq22a"
                          >
                            How to find university(admission)?
                          </a>
                        </div>

                        <div
                          id="faq22a"
                          className="collapse"
                          aria-labelledby="faqhead22a"
                          data-parent="#faq2a"
                        >
                          <div className="card-body">
                            sunt aliqua put a bird on it squid single-origin
                            coffee nulla assumenda shoreditch et. Nihil anim
                            keffiyeh helvetica, craft beer labore wes anderson
                            cred nesciunt sapiente ea proident. Ad vegan
                            excepteur butcher vice lomo. Leggings occaecat craft
                            beer farm-to-table, raw denim aesthetic synth
                            nesciAnim pariatur cliche reprehenderit, enim
                            eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia aute, non cupidatat
                            skateboard dolor brunch. Food truck quinoa nesciunt
                            laborum eiusmod. Brunch 3 wolf moon tempor, unt you
                            probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead32a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq32a"
                            aria-expanded="true"
                            aria-controls="faq32a"
                          >
                            How to find university(admission)?
                          </a>
                        </div>

                        <div
                          id="faq32a"
                          className="collapse"
                          aria-labelledby="faqhead32a"
                          data-parent="#faq2a"
                        >
                          <div className="card-body">
                            moon tempor, sunt aliqua put a bird on it squid
                            single-origin coffee nulla assumenda shoreditch et.
                            Nihil anim keffiyeh helvetica, craft beer labore wes
                            anderson cred nesciunt sapiente ea proident. Ad
                            veganAnim pariatur cliche reprehenderit, enim
                            eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia aute, non cupidatat
                            skateboard dolor brunch. Food truck quinoa nesciunt
                            laborum eiusmod. Brunch 3 wolf excepteur butcher
                            vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="tab-pane" id="tabs-3" role="tabpanel">
                    <div className="accordion" id="faq3a">
                      <div className="card">
                        <div className="card-header" id="faqhead13a">
                          <a
                            href="#"
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target="#faq13a"
                            aria-expanded="true"
                            aria-controls="faq13a"
                          >
                            How to find SAT university?
                          </a>
                        </div>

                        <div
                          id="faq13a"
                          className="collapse show"
                          aria-labelledby="faqhead13a"
                          data-parent="#faq3a"
                        >
                          <div className="card-body">
                            3 wolf moon tempor, sunt aliqua put a bird on it
                            squid single-origin coffee nulla assumenda
                            shoreditch et. Nihil aniAnim pariatur cliche
                            reprehenderit, enim eiusmod high life accusamus
                            terry richardson ad squid. 3 wolf moon officia aute,
                            non cupidatat skateboard dolor brunch. Food truck
                            quinoa nesciunt laborum eiusmod. Brunch m keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead23a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq23a"
                            aria-expanded="true"
                            aria-controls="faq23a"
                          >
                            How to find SAT university?
                          </a>
                        </div>

                        <div
                          id="faq23a"
                          className="collapse"
                          aria-labelledby="faqhead23a"
                          data-parent="#faq3a"
                        >
                          <div className="card-body">
                            3 wolf moon tempor, sunt aliqua put a bird on it
                            squid single-origin coffee nulla assumenda
                            shoreditch et. Nihil anim keffiyeh Anim pariatur
                            cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid. 3 wolf moon
                            officia aute, non cupidatat skateboard dolor brunch.
                            Food truck quinoa nesciunt laborum eiusmod. Brunch
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead33a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq33a"
                            aria-expanded="true"
                            aria-controls="faq33a"
                          >
                            How to find SAT university?
                          </a>
                        </div>

                        <div
                          id="faq33a"
                          className="collapse"
                          aria-labelledby="faqhead33a"
                          data-parent="#faq3a"
                        >
                          <div className="card-body">
                            h 3 wolf moon tempor, sunt aAnim pariatur cliche
                            reprehenderit, enim eiusmod high life accusamus
                            terry richardson ad squid. 3 wolf moon officia aute,
                            non cupidatat skateboard dolor brunch. Food truck
                            quinoa nesciunt laborum eiusmod. Bruncliqua put a
                            bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="tab-pane" id="tabs-4" role="tabpanel">
                    <div className="accordion" id="faq4a">
                      <div className="card">
                        <div className="card-header" id="faqhead14a">
                          <a
                            href="#"
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target="#faq14a"
                            aria-expanded="true"
                            aria-controls="faq14a"
                          >
                            How to find IELTS university?
                          </a>
                        </div>

                        <div
                          id="faq14a"
                          className="collapse show"
                          aria-labelledby="faqhead14a"
                          data-parent="#faq4a"
                        >
                          <div className="card-body">
                            3 wolf moon tempor, sunt aliqua put a bird on it
                            squid single-origin coffee nulla assumenda
                            shorediAnim pariatur cliche reprehenderit, enim
                            eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia aute, non cupidatat
                            skateboard dolor brunch. Food truck quinoa nesciunt
                            laborum eiusmod. Brunch tch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead24a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq24a"
                            aria-expanded="true"
                            aria-controls="faq24a"
                          >
                            How to find IELTS university?
                          </a>
                        </div>

                        <div
                          id="faq24a"
                          className="collapse"
                          aria-labelledby="faqhead24a"
                          data-parent="#faq4a"
                        >
                          <div className="card-body">
                            itch et. Nihil anim keffiyeh helvetica, craft beer
                            labore wes anderson cred nesciunt sapiente ea
                            proAnim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoredident. Ad vegan excepteur butcher
                            vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead34a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq34a"
                            aria-expanded="true"
                            aria-controls="faq34a"
                          >
                            How to find IELTS university?
                          </a>
                        </div>

                        <div
                          id="faq34a"
                          className="collapse"
                          aria-labelledby="faqhead34a"
                          data-parent="#faq4a"
                        >
                          <div className="card-body">
                            icia aute, non cupidatat skateboard dolor brunch.
                            Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                            wolf moon tempor, sunt aliqua put a bird on it squid
                            single-origin coffee nulla assumenda shorediAnim
                            pariatur cliche reprehenderit, enim eiusmod high
                            life accusamus terry richardson ad squid. 3 wolf
                            moon offtch et. Nihil anim keffiyeh helvetica, craft
                            beer labore wes anderson cred nesciunt sapiente ea
                            proident. Ad vegan excepteur butcher vice lomo.
                            Leggings occaecat craft beer farm-to-table, raw
                            denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus labore sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="tab-pane" id="tabs-5" role="tabpanel">
                    <div className="accordion" id="faq5a">
                      <div className="card">
                        <div className="card-header" id="faqhead15a">
                          <a
                            href="#"
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target="#faq15a"
                            aria-expanded="true"
                            aria-controls="faq15a"
                          >
                            How to find TOEFL/PTuniversity?
                          </a>
                        </div>

                        <div
                          id="faq15a"
                          className="collapse show"
                          aria-labelledby="faqhead15a"
                          data-parent="#faq5a"
                        >
                          <div className="card-body">
                            oon tempor, sunt aliqua put a bird on it squid
                            single-origin coffee nulla assumenda shoreditch et.
                            Nihil anim keffiyeh helvetica, craft beer labore wes
                            anderson cred nesciunt sapiente ea proident. AAnim
                            pariatur cliche reprehenderit, enim eiusmod high
                            life accusamus terry richardson ad squid. 3 wolf
                            moon officia aute, non cupidatat skateboard dolor
                            brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf md vegan excepteur butcher vice lomo.
                            Leggings occaecat craft beer farm-to-table, raw
                            denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus labore sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead25a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq25a"
                            aria-expanded="true"
                            aria-controls="faq25a"
                          >
                            How to find TOEFL/PTuniversity?
                          </a>
                        </div>

                        <div
                          id="faq25a"
                          className="collapse"
                          aria-labelledby="faqhead25a"
                          data-parent="#faq5a"
                        >
                          <div className="card-body">
                            moon tempor, sunt aliqua put a bird on it squid
                            single-origin coffee nulla assumenda shoreditch et.
                            Nihil anim keffiyeh helvetica, craft beer labore wes
                            anderson cred nesciunt sapiente ea proident. Ad
                            vAnim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf egan excepteur butcher vice
                            lomo. Leggings occaecat craft beer farm-to-table,
                            raw denim aesthetic synth nesciunt you probably
                            haven't heard of them accusamus labore sustainable
                            VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead35a">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq35a"
                            aria-expanded="true"
                            aria-controls="faq35a"
                          >
                            How to find TOEFL/PTuniversity?
                          </a>
                        </div>

                        <div
                          id="faq35a"
                          className="collapse"
                          aria-labelledby="faqhead35a"
                          data-parent="#faq5a"
                        >
                          <div className="card-body">
                            , sunt aliqua put a bird on it squid single-origin
                            coffee nulla assumenda shoreditch et. Nihil anim
                            keffiyeh helvetica, craft beer labore wes anderson
                            cred nesciunt sapiente ea proident. Ad vegan exc
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon temporepteur butcher
                            vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faqs;
