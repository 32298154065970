import { 
  LOADING,
  GET_ALL_UNI_COURSE,
  GET_ALL_COURSE,
  COUNTRY_COURSE,
  COUNTRY_EXAM,
  GET_COURSE,
  COURSE_DETAIL,
  GET_SIMILAR_COURSE
} from "../common/constant";
 type stateType = {
  loading:boolean;
  universitycourse: any;
 allcourse: any;
 countryCourse: any;
 countryExam: any;
 uniCourseName: any;
 courseDetail: any;
 similarCourse:any
 }
const initialState : stateType = {
    loading:false,
    universitycourse: [],
    allcourse : [],
    countryCourse : [],
    countryExam : [],
    uniCourseName : [],
    courseDetail : [],
    similarCourse:[]
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case COURSE_DETAIL:
      return { ...state, courseDetail: action.payload};
    case COUNTRY_COURSE:
      return { ...state, countryCourse: action.payload};
    case GET_COURSE:
      return { ...state, uniCourseName: action.payload};
    case COUNTRY_EXAM:
      return { ...state, countryExam: action.payload};
    case GET_ALL_UNI_COURSE:
      return { ...state, universitycourse: action.payload};
    case GET_ALL_COURSE:
      return { ...state, allCourse : action.payload};
    case GET_SIMILAR_COURSE:
    return { ...state, similarCourse : action.payload};
    default:
      return state;
  }
};