import { 
  LOADING,
  GET_PACKAGE_lIST,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  packageList: any;
 }
const initialState : stateType = {
    loading:false,
    packageList: []
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_PACKAGE_lIST:
      return { ...state, packageList: action.payload};
    default:
      return state;
  }
};