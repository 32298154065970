import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function SeoFooterContent() {
  const seo: any = useSelector((state: any) => state.setting.seo);
  const [showFullContent, setShowFullContent] = useState(false);
  const wordCount = seo?.footer_content.split(/\s+/).length;

  const toggleContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowFullContent(!showFullContent);
  };

  return (
    <>
      <section className="imp-instruction">
        {seo?.footer_content.length <= 90 || showFullContent ? (
          <div className='seo-footer-content'
            dangerouslySetInnerHTML={{
              __html: seo?.footer_content
                ? seo?.footer_content
                : "",
            }}
          ></div>
        ) : (
          <div dangerouslySetInnerHTML={{
            __html: (seo?.footer_content ? seo?.footer_content : "").split(/\s+/).slice(0, 90).join(' '), // Display the first 200 words (or any desired length)
          }} />
        )}
        {seo?.footer_content && wordCount > 90 && (
          <button className="view-more readbtn-dynamic text-center" onClick={toggleContent}>
            {showFullContent ? 'Read Less' : 'Read More'} {showFullContent ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
          </button>
        )}
      </section>
    </>
  );
}

export default SeoFooterContent;