import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

var other_details: any = [];
function TOEFL() {
   const urlsforShare = window.location.href;
   const dispatch = useDispatch();
   const history = useHistory();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );


   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "TOEFL-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();

      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();

      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;
      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#toeflIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));
      // toast.success('Thanks for showing your interest, we will get back to you soon.');
      // window.location.href = "/thank-you";
   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "TOEFL", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     TOEFL
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p> The 'Exam of English as a Foreign Language' (TOEFL) is administered by the
                              Educational Testing Service. The TOEFL exam is available in two formats: iBT (internet-based test) and
                              PBT (paper-based test). The TOEFL iBT, released in 2005, is an updated version of the TOEFL CBT
                              (Computer Based Test). Even though the scoring on each part differs between the two versions (iBT and
                              PBT), both have the same exam design. Among the two, the TOEFL iBT is preferred by 97 per cent of
                              students.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/toefl01.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">What is the importance of the TOEFL Exam?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">TOEFL is one of the most widely accepted
                                    English-language examinations for students wishing to study abroad, with over 11,000
                                    universities and institutions worldwide accepting it. Universities in Australia, Canada,
                                    New Zealand, the United Kingdom, the United States, and across Europe and Asia are a few
                                    who accept TOEFL. Students who take the TOEFL test may very well be eligible for a variety
                                    of scholarships and assistance with the visa application process for students and
                                    individuals seeking employment overseas.s
                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/gre01.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Types of TOEFL Exam</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">There are two basic forms of TOEFL exams: PBT and
                                    IBT and alternative TOEFL tests like TOEFL Junior and IPT.
                                 </div> <br />
                                 <h6 className="pb-3 card-head1"><b> TOEFL PBT</b></h6>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">TOEFL PBT stands for Paper-Based Test.

                                    The Paper-based examination is one of the most prevalent varieties of the TOEFL exam and
                                    is taken in offline mode. It is only available for those who do not have access to the
                                    internet and has a total duration of 2 hours 25 minutes. It is divided into three sections
                                    and has a total duration of 2 hours 25 minutes.</div><br />
                                 <h6 className="pb-3 card-head1"><b> TOEFL IBT</b></h6>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> TOEFL iBT in its full form is "Internet-Based
                                    Test."
                                    The Internet-based TOEFL is divided into four sections: reading, listening, speaking, and
                                    writing, with a total duration of four hours. It is one of the most popular TOEFL exam
                                    formats, and it has been in use since 2006, when the computer-based test was phased out.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/toefl02.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Who accepts TOEFL scores</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">According to the official TOEFL ETS website, TOEFL
                                    scores are accepted by over 9,000 schools (colleges, community colleges, and universities)
                                    in over 130 countries. TOEFL scores are now widely accepted as the most reliable indicator
                                    of English competence in speaking, writing, reading, and listening. Here are some more
                                    organisations that accept TOEFL results in addition to academic institutions like
                                    universities:
                                    They are accepted by immigration authorities to process residence and work permits.
                                    Boards of Pharmacy, Dentistry, Physical Therapy, and other areas of medicine
                                    Is anyone interested in determining their English proficiency level?


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">How to register for TOEFL Exam</h4>
                                 <span className="border-bt"></span>

                                 <div className="postcard__preview-txt ft-15">Visit the Official Website for registration.
                                    <br />Create a profile for the TOEFL exam taker.
                                    <br />Fill out the TOEFL application form.
                                    <br />Choose your TOEFL exam day and location, or choose TOEFL at Home.
                                    <br />Pay the $190 TOEFL registration cost.
                                    <br />Confirm your TOEFL application and submit it.
                                    <br />Take a printout of your TOEFL confirmation page or save it to your computer for
                                    future reference.
                                    <br />You can register for the exam via phone, online, mail, or in person at the official
                                    website too. The TOEFL exam can be taken at any time of the year. TOEFL applicants who
                                    choose to take the TOEFL exam at an authorised TOEFL test centre can choose from various
                                    test centres.
                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/toefl03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Where can TOEFL Exam take you?
                                 </h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">More than 11,000 colleges, universities, and other
                                    organisations across 150 countries accept TOEFL, including universities in the US,
                                    universities in the UK, universities in Australia, universities in New Zealand, France's
                                    universities, Germany's universities, and Canada's universities.


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/gre03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of TOEFL Exam</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The TOEFL is a well-known English language exam. It
                                    is recognised by more than 9,000 colleges and universities in more than 130 countries.
                                    The TOEFL test is administered over 50 times each year in over 4,500 test centres in over
                                    165 countries worldwide. Because the test is only for one day, it saves time.
                                    One of the main reasons this test is so popular among universities and colleges is that it
                                    accurately assesses English-language abilities. Hence, it is extremely dependable.

                                    Want to take the TOEFL but don't know where to start? Don't be concerned! Admissify is
                                    ready to help you! Admissify is India's top foreign education consultancy, with offices in
                                    London, Boston, Hanover, Vancouver, Sydney, and other cities. By giving aid and
                                    counselling tailored to their personal needs, we hope to empower students to pursue their
                                    ambition of studying abroad.

                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country}>
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input
                                                autoComplete="new-off" type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" id="toeflIn" className="bggreadent apply-sub-btn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default TOEFL
