import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CounsellorList from "../CounsellorList/CounsellorList";
import { Link, useHistory } from "react-router-dom";
import Seo from "../Seo/Seo";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
function PageNotFound() {
  const history = useHistory();
  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="/assets/img/pagenotfound.jpg"></img>
          </div>
        </section>
        <div className="headingmains text-center ofc_add">
          <h1>
            page not found!
          </h1><br></br>
          <p>unfortunately the page you were looking for could not be found. It may be temporarily unavailable or no longer exists.</p><br></br>
        </div>

      </div >
    </>
  )
}
export default PageNotFound
