import { 
  LOADING,
  GET_JOB_LIST, 
  APPLY_FOR_JOB,
} from "../common/constant";
 type stateType = {
  loading:boolean; 
  jobList: any;
  applyJob: any[];
 }
const initialState : stateType = {
    loading:false,
    jobList: [],
    applyJob: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_JOB_LIST:
      return { ...state, jobList: action.payload}; 
      case APPLY_FOR_JOB:
      return { ...state, applyJob: action.payload};
    default:
      return state;
  }
};