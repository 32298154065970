import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

var other_details: any = [];
function GMAT() {
   const urlsforShare = window.location.href;
   const history = useHistory();
   const dispatch = useDispatch();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );



   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "GMAT-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();
      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();

      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;
      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#gmatIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));
      // toast.success('Thanks for showing your interest, we will get back to you soon.');
      // window.location.href = "/thank-you";

   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "GMAT", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     GMAT
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              The GMAT is a computer-adaptive test that assesses a candidate's critical
                              writing, quantitative, oral, and reading skills in standard written English. This exam is used to get
                              admission to a graduate management programme at one of the world's top business institutions, such as
                              an MBA or a Masters in Finance-related degree.
                              GMAC, a testmaker, created and administers the GMAT to give business schools standardised evaluations
                              of applicants' readiness for graduate-level academic work.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/gmat01.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">What is the importance of the GMAT?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">It is the world's most widely accepted MBA entrance
                                    exam. No other exam is as widely accepted as the GMAT; according to the GMAC, GMAT scores
                                    account for 9 out of 10 MBA enrollments worldwide. Furthermore, GMAT scores are accepted
                                    by over 2,300 schools. This is because the GMAT Exam allows you to demonstrate the most
                                    important skills in business school and your career.
                                 </div><br />
                                 <h6 className="pb-3 card-head1"><b> Eligibility</b></h6>
                                 <div className="postcard__preview-txt ft-15">
                                    <ul className="condition-list-1">
                                       <li>Candidates should be aware of the qualifying requirements before registering. The
                                          following criteria must be met to take the GMAT</li>
                                       <li>Applicants must possess a minimum educational qualification from a recognised
                                          institution or college.</li>
                                       <li>Candidates must be at least 18 years old; if a candidate is under 18, a
                                          no-objection certificate from a parent or guardian is required.</li>
                                       <li>To appear for the GMAT, an applicant must have a valid passport.</li>
                                       <li>The GMAT can only be taken five times per year and eight times in a lifetime.</li>
                                       <li>Only 16 days following the first exam candidates can take the GMAT.</li>
                                       <li>Although work experience is not required, leading business schools worldwide desire
                                          it.</li>

                                    </ul>
                                 </div>



                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/gmat02.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Who accepts GMAT scores?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Aspirants for the GMAT can apply to over 7000
                                    programmes at over 2300 business schools worldwide. Furthermore, leading business schools
                                    worldwide accept GMAT scores of over 720, including Harvard, INSEAD, and Stanford.
                                    Furthermore, management institutions should award students a degree for GMAT results to be
                                    accepted. GMAT scores are one of the essential variables in the admissions process to top
                                    business schools throughout the world.


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">How to register for the GMAT Exam?</h4>
                                 <span className="border-bt"></span>

                                 <div className="postcard__preview-txt ft-15">According to GMAC, you can register for the GMAT
                                    entrance exam six months before the Graduate Management Admission Test date or no later
                                    than 24 hours before the date of the GMAT 2022 exam; however, the slot is not available at
                                    the last minute. As a result, it is recommended that you reserve your desired slot well in
                                    advance of the scheduled exam day.
                                    <br />GMAT registration options include:
                                    <br />Phone
                                    <br />Postal mail
                                    <br />Online
                                    <br /> Candidates can now register for the PTE exam over the phone, in addition to the
                                    online method. Students from India who desire to take the PTE Exam should contact the PTE
                                    Academic Center to register for the exam.

                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/gmat03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Where can GMAT take you?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Your GMAT cutoff scores allow you to apply to some
                                    of the world's best business schools. Your GMAT cutoff scores allow you to apply to some
                                    of the world's best business schools. University of Pennsylvania (Wharton), Stanford
                                    University (CA), Yale University (CT), Harvard University (MA), Columbia University (NY),
                                    and the University of California are just a few among them.


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/concul.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of GMAT</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The difficulty of the following question on the GMAT
                                    exam is determined by adaptive computer testing (CAT), based on the candidate's previous
                                    response and ability. The GMAT employs CAT across the Quantitative and Verbal sections,
                                    resulting in a more efficient, secure, and accurate assessment of a candidate's abilities.
                                    Schools know they'll have a pipeline of individuals devoted to a business education if
                                    they take the GMAT exam.
                                    The GMAT Total Score is an industry-standard that ETS uses to provide a comparison score.
                                    A comprehensive total score allows colleges to look at a single figure to measure a
                                    candidate's competence while still allowing specific section scores to be considered.
                                    The free benchmarking data in GMAT score reports assists institutions in making better
                                    admissions decisions and understanding their market and competitors. For your convenience,
                                    information is updated daily and made available online.
                                    Want to take the GMAT but not sure how? are you stumped by a slew of protocols? Admissify
                                    is here to assist you! We are India's leading international education consultant with
                                    offices in London, Boston, Hanover, Vancouver, Sydney, and India. Thanks to our global
                                    network, we provide in-country assistance such as internship aid, immigration, and career
                                    services.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country} >
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" className="bggreadent apply-sub-btn" id="gmatIn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default GMAT
