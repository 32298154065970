import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { UniversityAction } from "../../redux/common/action";
import { getCountryBySlug } from "../../redux/country/action";
import Articals from "./Articals";
import CountrySlider from "./CountrySlider";
import CCourse from "./Course";
import Exam from "./Exam";
import { URL } from "../../redux/common/url";
import Seo from "../../Component/Seo/Seo";
import "rc-pagination/assets/index.css";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
const Country = () => {
  const { slug } = useParams<any>();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const country: any = useSelector((state: any) =>
    state.country.countryDetail ? state.country.countryDetail : {}
  );
  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );
  // console.log(universities);
  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/counsellor-and-mentor");
    }
  };

  useEffect(() => {
    dispatch(
      getCountryBySlug({
        slug: slug && slug?.replace("study-in-", "")?.toUpperCase(),
      })
    );
    return () => {};
  }, []);
  useEffect(() => {
    if (country?.id) {
      dispatch(
        UniversityAction.getuniversities({
          offset: 0,
          country_id: [country?.id],
          limit: 10,
        })
      );
    }
    return () => {};
  }, [country?.id]);

  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
  });
  return (
    <div>
      <Seo slug={window.location.pathname} />
      <section
        className="headslider"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <CountrySlider country={country} />
        <section className="univercitylist">
          <div className="container">
            <Breadcrumb
              page={slug?.toUpperCase()}
              data={{ slug: "", path: "" }}
            />
             {seo?.header_content && (
              <div className="about-uni-text shade1 mt-3">
                <SeoHeaderContent />
              </div>
            )}
            <Articals />
            <div className="about-uni-text shade1">
            <div
              className="country-details mb-4 editor_css imp-instruction"
              dangerouslySetInnerHTML={{
                __html: country?.CountryDetail?.other_details
                  ? country?.CountryDetail?.other_details
                  : "",
              }}
            ></div>
            </div>
            <CCourse />
            <div className="about-uni">
              <div className="row">
                <div className="col-12">
                  <div className="headingmains text-center pb-4">
                    {universities?.length > 0 ? (
                      <h2 className="titlewithline yellow uniDetailsH2">
                        {" "}
                        choose your university
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="31.457"
                            viewBox="0 0 35 31.457"
                          >
                            <path
                              id="library-building"
                              d="M34.259,50.572H.741a.741.741,0,0,1,0-1.483H34.259a.741.741,0,0,1,0,1.483ZM32.9,47.192a.741.741,0,0,1-.741-.741V30.066H26.179a.741.741,0,1,1,0-1.483H32.9a.741.741,0,0,1,.741.741s0,.01,0,.015V46.451A.742.742,0,0,1,32.9,47.192Zm-3.7,0h-4.13a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741H29.2a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,29.2,47.192Zm-3.388-1.483h2.647V42.572H25.815Zm0-4.621h2.647V37.951H25.815Zm0-4.621h2.647V33.33H25.815Zm-4.51,10.724a.741.741,0,0,1-.741-.741V37.1H14.436v9.347a.741.741,0,1,1-1.483,0V36.363a.742.742,0,0,1,.864-.731.743.743,0,0,1,.123-.01h7.118a.753.753,0,0,1,.123.01.742.742,0,0,1,.864.731V46.451A.742.742,0,0,1,21.305,47.192Zm-11.379,0H5.8a.741.741,0,0,1-.741-.741V32.589a.741.741,0,0,1,.741-.741h4.13a.741.741,0,0,1,.741.741V46.451A.741.741,0,0,1,9.926,47.192ZM6.538,45.709H9.185V42.572H6.538Zm0-4.621H9.185V37.951H6.538Zm0-4.621H9.185V33.33H6.538ZM2.1,47.192a.741.741,0,0,1-.741-.741V29.341c0-.005,0-.011,0-.016a.741.741,0,0,1,.741-.741h6.72a.741.741,0,0,1,0,1.483H2.843V46.451A.741.741,0,0,1,2.1,47.192Zm15.4-3.928a.741.741,0,0,1-.741-.741V41.077a.741.741,0,1,1,1.483,0v1.446A.741.741,0,0,1,17.5,43.264Zm-.085-9.906a7.13,7.13,0,0,1-7.122-7.122v-6.38a.741.741,0,0,1,.741-.741H23.8a.741.741,0,0,1,.741.741v6.38A7.13,7.13,0,0,1,17.415,33.359ZM11.776,20.6v5.639a5.639,5.639,0,1,0,11.278,0V20.6ZM17.5,29.323h0a.739.739,0,0,1-.191-.025H17.3l-.026-.008-3.3-1.031a.741.741,0,0,1-.52-.708V22.734a.741.741,0,0,1,.963-.708l3.08.962,3.08-.962a.741.741,0,0,1,.963.708v4.816a.741.741,0,0,1-.52.708l-3.3,1.031A.741.741,0,0,1,17.5,29.323Zm-2.559-2.318,1.818.568V24.311l-1.818-.568Zm3.3-2.695v3.263l1.818-.568V23.743Z"
                              transform="translate(0 -19.115)"
                              fill="#fbb415"
                            />
                          </svg>
                        </span>
                      </h2>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3">
              {universities &&
                universities.length > 0 &&
                universities?.map((data: any, i: any) => {
                  const banners = data?.UniversityDetail?.banner || "";
                  return (
                    <div className="uni-item-list" key={i}>
                      <div className="item-media">
                        <div
                          className="image-cover uni-listupr"
                          onClick={() => {
                            history.push("/university/" + data?.slug, {
                              university_id: data?.id,
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {data?.UniversityDetail?.banners !== null ? (
                            <img
                              src={baseurl + banners}
                              alt=""
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/img/noImage.jpeg";
                              }}
                              className="scale"
                            />
                          ) : (
                            <img
                              src="/assets/img/noImage.jpeg"
                              alt=""
                              className="scale"
                            />
                          )}
                        </div>
                      </div>
                      <div className="item-body">
                        <div className="item-title">
                          <h5>
                            <a
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                            >
                              {data?.university_name}
                            </a>
                          </h5>
                        </div>
                        <div className="item-footer">
                          <div className="item-hotel-star">
                            <div>
                              <StarRatings
                                rating={
                                  parseFloat(data?.rating)
                                    ? parseFloat(data?.rating)
                                    : 0
                                }
                                starRatedColor="#fcbd30"
                                starDimension="13px"
                                starSpacing="0px"
                              />

                              <span>
                                |{" "}
                                {data?.review_count > 500
                                  ? "500+"
                                  : data?.review_count}{" "}
                                student google reviews
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item-address">
                          postgraduate - business
                        </div>
                        <div className="item-abouttxt">
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td rowSpan={2}>
                                          <img
                                            src="/assets/img/icons/intake.svg"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>intake</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span>
                                            {data?.intake > 0
                                              ? data?.intake
                                              : "NA"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td rowSpan={2}>
                                          <img
                                            src="/assets/img/icons/review.svg"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>reviews</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {data?.review_count &&
                                              data?.review_count}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="ranking01  top75 top54">
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td rowSpan={2}>
                                          <img
                                            src="/assets/img/icons/ranking.svg"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>ranking</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {data?.ranking}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="country01 top131 top108">
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td rowSpan={2}>
                                          <img
                                            src="/assets/img/icons/country1.svg"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>country</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow text-uppercase">
                                            {data?.Country?.country_name}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="item-btn-more">
                        <div className="btnbox">
                          <a
                            href={
                              data?.UniversityDetail?.brochure == "" ||
                              data?.UniversityDetail?.brochure == null
                                ? "javascript:void(0)"
                                : data?.UniversityDetail?.brochure &&
                                  baseurl + data?.UniversityDetail?.brochure
                            }
                            target=""
                            download
                            className={
                              data?.UniversityDetail?.brochure == "" ||
                              data?.UniversityDetail?.brochure == null
                                ? "awe-btnbook download brochuredis"
                                : "awe-btnbook download"
                            }
                          >
                            download brochure{" "}
                            <i className="iconx">
                              <img src="/assets/img/icons/download.svg" />
                            </i>
                          </a>
                          <a
                            onClick={handleFreeCoaching}
                            className="awe-btnbook"
                          >
                            get free counselling
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Exam exams={country?.CountryDetail?.exams} />
            <div className="course-offer-uni">
              <div className="row">
                <div className="col-12">
                <div className="about-uni-text shade1">
                  <div
                    className="country-details mb-4 editor_css imp-instruction"
                    dangerouslySetInnerHTML={{
                      __html: country?.CountryDetail?.description
                        ? country?.CountryDetail?.description
                        : "",
                    }}
                  ></div>
                  </div>
                </div>
              </div>
            </div>
            {seo?.footer_content && (
              <div className="about-uni-text shade1">
            <SeoFooterContent />
              </div>
               )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default Country;
