import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../Component/Breadcrumb';

const Thankyou = () => {
    const history = useHistory();
    const handelBack = (e: any) => {
        history.push("/universities");
    }
    const getSetting: any = useSelector((state: any) =>
        state.setting.getSetting ? state.setting.getSetting : []
    );
    return (
            <div>
                <section className="tag-term">
                {/* <Breadcrumb page={"Thank-you"} data={{slug: "", path: ""}}/> */}
               <div
                  style={{ paddingBottom: "100px" }}
                  className="form-container animated steps9 active flipInX"
               >
                  <h2>
                     <span>Thank You</span> {" "}
                     <video src="../../assets/video/thumb.mp4" style={{ width: "4%" }} loop muted autoPlay playsInline={true}>
                     </video>
                  </h2>
                  <h5>
                     Thanks for showing your interest, we will get back to you soon.
                  </h5>
                  <br />
                  {/* <strong className="__mr-10">Contact our experts on</strong>
                  <p><span><span className="fa fa-envelope icon_color"></span> </span> <a className="__hyperlinks comp_url" href={"mailto:" + getSetting[0]?.email}>{getSetting[0]?.email}</a></p>
                  <p><span><span className="fa fa-phone-square icon_color"></span> </span>  <a className="__hyperlinks comp_url" href={"tel:" + getSetting[0]?.phone}> {getSetting[0]?.phone}</a></p> */}
                  {/* <div className="form-group text-center mar-b-0 mt-4">
                     <button  onClick={handelBack} className="view-more btn2">
                        Back to home
                     </button>
                  </div> */}
               </div>
            </section>
            </div>
    )
}

export default Thankyou