import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props: any) => {
  const { page, data } = props;
  return (
    <ol className="breadcrumb mb-0 bg-transparent">
      <li className="breadcrumb-item">
        <Link to={"/"}>Home</Link>
        {page && <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>}
      </li>
      {!data?.slug || !data?.path ? (
        <li className="breadcrumb-item active">{page}</li>
      ) : (
        <>
          <li className="breadcrumb-item">
            <Link to={data?.path}>{page}</Link>
            <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>
          </li>
          {!data?.slug2 || !data?.path2 ? (
            <li className="breadcrumb-item active">{data?.slug}</li>
          ) : (
            <>
              <li className="breadcrumb-item">
                <Link to={data?.path2}>{data?.slug}</Link>
                <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>
              </li>
              <li className="breadcrumb-item active">{data?.slug2}</li>
            </>
          )}
        </>
      )}
    </ol>
  );
};

export default React.memo(Breadcrumb);
