import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { packageAction } from "../../redux/common/action";
import Seo from "../Seo/Seo";

function Packages() {
  const dispatch = useDispatch();
  const [title, settitle] = useState<any>("packages");
  const packages:any[] = useSelector((state: any) =>
    state.package.packageList ? state.package.packageList : []
  );

  useEffect(() => {
    dispatch(packageAction.getPackagelist({}));
    return () => {};
  }, []);




  return (
    <div>
            <Seo settitle={settitle} title={title}/>
      <section className="categories section packages_wrp">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline">
              packages
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33.333"
                  height="33.667"
                  viewBox="0 0 33.333 33.667"
                >
                  <path
                    id="Icon_material-local-offer"
                    data-name="Icon material-local-offer"
                    d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                    transform="translate(-3 -3)"
                    fill="#fbb415"
                  ></path>
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate thbe used as a placeholder
              before final copy is available.{" "}
            </p>
          </div>

          <div className="row packagelist">
            <div className="col-md-12 col-lg-4 item-package">
              <div className="card h-100 text-center">
                <div className="card-header">
                  <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                  <ul>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                  </ul>
                </div>
                <div className="card-footer bg-white border-0 pt-0">
                  <div className="all-center pb-2">
                    <a href="#" className="view-more readbtn">
                      request price
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 item-package">
              <div className="card h-100 text-center">
                <div className="pricebox">
                  <p className="color-green">
                    <small>INR</small>
                    <br />
                    5000 <br />
                    <small className="text-danger">
                      <s>7000</s>
                    </small>
                  </p>
                </div>
                <div className="card-header">
                  <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                  <ul>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                  </ul>
                </div>
                <div className="card-footer bg-white border-0 pt-0">
                  <div className="all-center pb-2">
                    <a href="#" className="view-more readbtn">
                      pay now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 item-package">
              <div className="card h-100 text-center">
                <div className="card-header">
                  <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                  <ul>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                    <li>travel insurancefacilitation services</li>
                  </ul>
                </div>
                <div className="card-footer bg-white border-0 pt-0">
                  <div className="all-center pb-2">
                    <a href="#" className="view-more readbtn">
                      request now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Packages;
