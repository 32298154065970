import React, { useState, useEffect } from "react";
import Seo from "../../Component/Seo/Seo";
import { JobAction, cityAction } from "../../redux/common/action";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { URL } from "../../redux/common/url";
import SocialShareButton from "../SocialShareButton";
import { getToken } from "../../Utils/Auth/Token";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
const token = getToken();
function Career() {
  const history = useHistory();
  const urlsforShare = window.location.href;
  const [files, setfiles] = useState<any>();
  const [Job, setJob] = useState<any>();
  const [resume, setResume] = useState<any>("");
  const dispatch = useDispatch();

  const baseurl = URL.API_BASE_URL;

  const jobList: any = useSelector((state: any) =>
    state.jobs.jobList ? state.jobs.jobList : []
  );

  const cities: any = useSelector((state: any) =>
    state.city.cityList ? state.city.cityList : []
  );

  const hendlefiles = async (e: any) => {
    const file = e.target.files[0];
    const fileInput = document.querySelector("#resume");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    const res = await axios(
      baseurl + "/api/fileUpload/upload?module_name=career",
      {
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data; ",
          token: token || "",
        },
      }
    );
    let response = res.data.fileName;
    setResume(response);
  };
  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setJob({ ...Job, [name]: value });
  };

  const applyForOpenJob = (e: any) => {
    var inputs = $("#" + e).find("input");
    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (inputs.length > 0) {
      $.each(inputs, function (k, v) {
        if (!v.value) {
          $(this).after(
            '<div class="error-class">This field is required.</div>'
          );
          isError = true;
        }
      });
      if (inputs[1].value == "") {
        $("#mob").after(
          '<div class="error-class">This field is required.</div>'
        );
        isError = true;
      }
      if (inputs[1].value) {
        if (inputs[1].value.length != 10) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[1].value) {
        if (phoneno.test(inputs[1].value) === false) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[2].value) {
        if (regex.test(inputs[2].value) === false) {
          $("#email").after('<div class="error-class">Invalid email.</div>');
          isError = true;
        }
      }
    }
    if (isError) {
      return false;
    }
    dispatch(
      JobAction.applyForJob(
        { ...Job, resume: resume ? "/career/" + resume : "" },
        history
      )
    );
  };

  useEffect(() => {
    dispatch(JobAction.jobList({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(cityAction.getAllCity({}));
    return () => {};
  }, []);

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   })

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="assets/img/career-banner.jpg" />
            <div className="container">
              <div className="tag-l">
                <h1>career</h1>
                <p style={{ color: "white" }}>
                  <>
                    build a glorious career with admissify by your side. The
                    organization grants all the privileges
                    <br />
                    to make you join your favorite universities abroad.
                    Promising job opportunities are waiting for you.
                  </>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <Breadcrumb page={"Career"} data={{ slug: "", path: "" }} />
        </div>
        <section className="career-c">
          <div className="d-flex justify-content-end pb-4 mr-3">
            <SocialShareButton urlsforShare={urlsforShare} data={""} />
          </div>
          <div className="headingmains text-center">
            <h4 className="titlewithline">
              career with us
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33.333"
                  height="33.667"
                  viewBox="0 0 33.333 33.667"
                >
                  <path
                    id="Icon_material-local-offer"
                    data-name="Icon material-local-offer"
                    d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                    transform="translate(-3 -3)"
                    fill="#fbb415"
                  ></path>
                </svg>
              </span>
            </h4>
          </div>
          <div className="container">
            <div className="row">
              <p className="career-p">
                admissify is changing the face of the overseas education
                consultancy experience. Our revolutionary web platform works in
                conjunction with the innovative mobile application, available on
                iOS & android, to empower students to pursue their ambition of
                studying abroad - tailored exactly to fit the individual
                requirements for assistance & guidance.
              </p>
            </div>
          </div>
          <div className="all-center my-2">
            <a href="#c-form" className="view-more readbtn m-4">
              view open position
            </a>
          </div>
        </section>
        <section className="header-footer-content">
          <div className="container">
            {seo?.header_content && (
              <div className="about-uni-text shade1">
                <SeoHeaderContent />
              </div>
            )}
          </div>
        </section>
        <section className="culture">
          <div className="container">
            <div className="row pt-50">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img className="culture-f-img" src="assets/img/cr_1-min_.jpg" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mtop-20">
                <h3 style={{ color: "white" }}>our culture</h3>
                <div className="culture-right-c">
                  <p>
                    Admissify always believes in the potential of the students.
                    The primary strength of this organization is
                    diversification. We focus on encouraging all people to
                    identify their strengths. Along with varied cultural
                    backgrounds, languages and courses, we offer a grand union
                    at one point. Admissify has a common vision throughout the
                    enterprise. An inclusive culture binds people together to
                    achieve a common target. We are highly flexible and allow
                    people to work in a dynamic environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="row pt-50 coldir">
              <div className="col-sm-12 col-md-12 col-lg-6 mtop-20">
                <h3 className="pb-10" style={{ color: "white" }}>
                  value we have
                </h3>
                <div className="culture-right-c">
                  <h6 style={{ color: "white" }}>
                    Values are the main driving force for our organization. Our
                    primary key factors for success include:-
                  </h6>
                  <p>
                    Transparency: We always observe transparency in every work.
                    Our team adhere to all the ethical requirements always.
                  </p>
                  <p>
                    Continuous Improvement: We strive for excellence and
                    continue to improve for the betterment of services.
                  </p>
                  <p>
                    Fair Leadership: Your trust is our strength. We comply with
                    all the standards to maintain the fairness of leadership
                    grounds.
                  </p>
                  <p>
                    Customer Value: We maintain consistency in supporting our
                    students and enhancing customer value in the long run.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img className="culture-f-img" src="assets/img/cr_2-min_.jpg" />
              </div>
            </div>
            <div className="row pt-50">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img className="culture-f-img" src="assets/img/cr3.jpg" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mtop-20">
                <h3 style={{ color: "white" }}>we believe in diversity</h3>
                <div className="culture-right-c">
                  <p>
                    People from different backgrounds approach us to fulfil
                    their dreams. Our target always remains to satisfy them and
                    provide some innovative approaches. It is not difficult to
                    ensure an excellent career by going abroad. Get the best
                    immigration facilities from our team members. It is the sole
                    reason why people from multiple backgrounds, genders and
                    nationalities come to us. A diversified system is what we
                    believe in.
                  </p>
                </div>
              </div>
            </div>
            <div className="row pt-50 coldir">
              <div className="col-sm-12 col-md-12 col-lg-6 mtop-20">
                <h3 style={{ color: "white" }}>equal chance to everything</h3>
                <div className="culture-right-c">
                  <p>
                    admissify gives equal chances to every student for studying
                    abroad. Our commitment to observe fair and transparent means
                    for future endeavours will help the students in going ahead
                    to achieve the goals. There can be discrimination regarding
                    male and female students, caste and creed, financial status,
                    and many more. However, at Admissify, our work ethics are
                    different.
                  </p>
                  <p>
                    We believe that every student is equal and do not
                    underestimate their ca. Higher education in foreign
                    countries can give students enough opportunities to build a
                    successful career. Our team offers excellent coaching to all
                    the aspirants for making them confident in this journey.
                    Getting in touch with us is also very easy when a phone is
                    with you.
                  </p>
                  <p>
                    We value your opinions and let you dream big. Spread your
                    wings for a bright future ahead. Here lies the chance to
                    embrace life differently. admissify understands the wishes
                    of the aspirants and formulates the strategies to make them
                    a reality.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img className="culture-f-img" src="assets/img/cr_1-min_.jpg" />
              </div>
            </div>
            <div className="row pt-50">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img className="culture-f-img" src="assets/img/cr3-min_.jpg" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mtop-20">
                <h3 style={{ color: "white" }}>something beyond education</h3>
                <div className="culture-right-c">
                  <p>Learn, live and spread the wings.</p>
                  <p>
                    Only giving the exams will not be enough if you are not
                    educated from within. We always try to provide you with
                    in-depth insights into every subject. It becomes quite
                    convenient for the students to learn the theories beforehand
                    for increasing the chances of getting admission. The actual
                    treasure lies in the knowledge.
                  </p>
                  <p>
                    We are available on the website admissify.com and also in
                    the form of a mobile app. Download Admissify and take mock
                    tests before applying for any foreign institution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="opening-sec" id="c-form">
          <div className="headingmains text-center ofc_add">
            <h4 className="titlewithline course-head m-4">
              current openings
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.032"
                  height="35.894"
                  viewBox="0 0 43.032 35.894"
                >
                  <path
                    id="mortarboard"
                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                    transform="translate(0 -42.469)"
                    fill="#fbb415"
                  ></path>
                </svg>
              </span>
            </h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-7">
                <div
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 24 24" id="expand-more">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="close">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                  </svg>
                </div>
                {jobList &&
                  jobList?.map((data: any, i: any) => {
                    return (
                      <div className="c-que">
                        <h1>{data?.role}</h1>
                        <h2>
                          {data?.location} | Posted{" "}
                          {moment(data?.createdAt).format("Do MMM YYYY")}
                        </h2>
                        <details>
                          <summary>
                            {data?.job_title}
                            <svg
                              className="control-icon control-icon-expand"
                              width="24"
                              height="24"
                              role="presentation"
                            >
                              <use href="#expand-more" />
                            </svg>
                            <svg
                              className="control-icon control-icon-close"
                              width="24"
                              height="24"
                              role="presentation"
                            >
                              <use href="#close" />
                            </svg>
                          </summary>
                          {/* <p>Totally. Totally does.</p> */}
                          <p>
                            <div
                              className="country-details mb-4 editor_css"
                              dangerouslySetInnerHTML={{
                                __html: data?.responsibilities
                                  ? data?.responsibilities
                                  : "",
                              }}
                            ></div>
                          </p>
                          <p>
                            <summary>Skills:</summary>
                          </p>
                          <p>
                            <div
                              className="country-details mb-4 editor_css"
                              dangerouslySetInnerHTML={{
                                __html: data?.skills ? data?.skills : "",
                              }}
                            ></div>
                          </p>
                          <p>
                            <summary>Job Description:</summary>
                            <div
                              className="country-details mb-4"
                              dangerouslySetInnerHTML={{
                                __html: data?.jd ? data?.jd : "",
                              }}
                            ></div>
                          </p>
                          <p>
                            <summary>No. of Positions:</summary>{" "}
                            {data?.no_positions}
                          </p>
                          <p>
                            <summary>Experience:</summary> {data?.experience}
                          </p>
                        </details>
                        {/* <details>
                        <summary>
                            Does this product have what I need?
                            <svg className="control-icon control-icon-expand" width="24" height="24" role="presentation"><use  href="#expand-more" /></svg>
                            <svg className="control-icon control-icon-close" width="24" height="24" role="presentation"><use  href="#close" /></svg>
                        </summary>
                        <p>Totally. Totally does.</p>
                    </details> */}
                      </div>
                    );
                  })}
                {/* <div className="c-que">
                    <h1>Marketing Manager</h1>
                    <h2>Delhi | Posted 19 Mar 2020</h2>
                    <details>
                        <summary>
                            Does this product have what I need?
                            <svg className="control-icon control-icon-expand" width="24" height="24" role="presentation"><use  href="#expand-more" /></svg>
                            <svg className="control-icon control-icon-close" width="24" height="24" role="presentation"><use  href="#close" /></svg>
                        </summary>
                        <p>Totally. Totally does.</p>
                    </details>
                    <details>
                        <summary>
                            Does this product have what I need?
                            <svg className="control-icon control-icon-expand" width="24" height="24" role="presentation"><use  href="#expand-more" /></svg>
                            <svg className="control-icon control-icon-close" width="24" height="24" role="presentation"><use  href="#close" /></svg>
                        </summary>
                        <p>Totally. Totally does.</p>
                    </details>
                </div> */}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-5">
                <div className="apply-bx">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="apply-n">apply now</h5>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="fields-wrp">
                          <form id="careerPage">
                            <div className="field">
                              <input
                                type="text"
                                name="name"
                                value={Job?.name}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>name</label>
                              <span className="fa fa-user p-2"></span>
                            </div>
                            <div className="field">
                              <input
                                type="text"
                                name="mobile"
                                maxLength="10"
                                id="mob"
                                value={Job?.mobile}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>mobile</label>
                              <span className="fa fa-phone p-2"></span>
                            </div>
                            <div className="field">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                value={Job?.email}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>email</label>
                              <span className="fa fa-envelope p-2"></span>
                            </div>
                            <div className="field">
                              <span className="fa fa-user p-2"></span>
                              <select
                                className="form-control c-select"
                                name="job_id"
                                value={Job?.job_id}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              >
                                <option value="">choose position</option>
                                {jobList &&
                                  jobList?.map((data: any, i: any) => {
                                    return (
                                      <option value={data?.id} key={i}>
                                        {data?.job_title}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <div className="field">
                                  <select
                                    className="form-control c-select"
                                    required
                                    name="experience"
                                    value={Job?.experience}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  >
                                    <option value="">experience</option>
                                    <option className="" value="0 Years">
                                      0 Years
                                    </option>
                                    <option className="" value="1 Years">
                                      1 Years
                                    </option>
                                    <option className="" value="2 Years">
                                      2 Years
                                    </option>
                                    <option className="" value="3 Years">
                                      3 Years
                                    </option>
                                    <option className="" value="4 Years">
                                      4 Years
                                    </option>
                                    <option className="" value="5 Years">
                                      5 Years
                                    </option>
                                    <option className="" value="6 Years">
                                      6 Years
                                    </option>
                                    <option className="" value="7 Years">
                                      7 Years
                                    </option>
                                    <option className="" value="8 Years">
                                      8 Years
                                    </option>
                                    <option className="" value="9 Years">
                                      9 Years
                                    </option>
                                    <option className="" value="10 Years">
                                      10 Years
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6">
                                <div className="field">
                                  <input
                                    type="text"
                                    name="city_name"
                                    value={Job?.city_name}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>city</label>
                                  <span className="fa fa-map-marker p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="field choose-f choose_file">
                              <input
                                type="file"
                                id="resume"
                                name="file"
                                accept="application/pdf"
                                onChange={(e) => {
                                  hendlefiles(e);
                                }}
                              />
                              <label>upload resume</label>
                              <span className="fa fa-upload p-2 r-20"></span>
                            </div>
                            <div className="fieldbtn text-center">
                              <input
                                type="button"
                                data-toggle="modal"
                                onClick={(e) => {
                                  applyForOpenJob("careerPage");
                                }}
                                className="bggreadent apply-sub-btn"
                                value="submit"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {seo?.footer_content && (
                <div className="about-uni-text shade1 mt-5">
                  <SeoFooterContent />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Career;
