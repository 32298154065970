import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_ALL_CITY,
  GET_CITY_BY_ID,
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getAllCityAction = (data:any) => {
  return { type: GET_ALL_CITY, payload: data };
};
const getCityByIdAction = (data:any) => {
  return { type: GET_CITY_BY_ID, payload: data };
};

export const getAllCity = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getAllCity}`);
          dispatch(getAllCityAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCityById = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getCityById}`);
          dispatch(getCityByIdAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};