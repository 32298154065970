import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import CounsellorSlider from "./CounsellorSlider";
import { Link, useHistory } from "react-router-dom";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
import Seo from "../../Component/Seo/Seo";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";

function Coaching() {
  const history = useHistory();
  const [available, setavailable] = useState<any[]>([]);
  const [availableid, setavailableid] = useState<any[]>([]);

  const [fees, setfees] = useState<any[]>([]);
  const [feesid, setfeesid] = useState<any[]>([]);
  const [experience, setexperience] = useState<any[]>([]);
  const [experienceid, setexperienceid] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [availableOpne, setavailableOpne] = useState<any>(false);
  const [feesOpne, setfeesOpne] = useState<any>(false);
  const [experienceOpne, setexperienceOpne] = useState<any>(false);
  const [countryOpne, setcountryOpne] = useState<any>(false);
  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const hendleavailableOpne = (e: any) => {
    if (availableOpne == true) {
      setavailableOpne(false);
    } else {
      setavailableOpne(true);
    }
  };
  const hendlefeesOpne = (e: any) => {
    if (feesOpne == true) {
      setfeesOpne(false);
    } else {
      setfeesOpne(true);
    }
  };
  const hendleexperienceOpne = (e: any) => {
    if (experienceOpne == true) {
      setexperienceOpne(false);
    } else {
      setexperienceOpne(true);
    }
  };

  const [counsellorandcoach, setcounsellorandcoach] =
    useState<any>("counsellor");
  const dispatch = useDispatch();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );

  const coun = (e: any, data: any) => {
    setcounsellorandcoach(data.data);
  };

  const shortBy = (e: any) => {
    console.log(e);

    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  };

  // useEffect(() => {
  //   dispatch(counselloerAction.getAllCounsellor({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(countryAction.getAllCountry({}));
    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(coachAction.getAllcoach({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);

  const hendlClickAvailable = (event: any) => {
    let array = [...available];
    let arrayid = [...availableid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.value);
      setavailable(array);
      setavailableid(arrayid);
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setavailable(array);
        setavailableid(arrayid);
      }
    }
  };

  const remove = (event: any, value: any) => {
    let array = [...available];
    let arrayid = [...availableid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setavailable(array);
      setavailableid(arrayid);
    }
  };

  const hendlClickfees = (event: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setfees(array);
      setfeesid(arrayid);
      console.log(arrayid?.length);

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setfees(array);
        setfeesid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removefees = (event: any, value: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setfees(array);
      setfeesid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };

  const hendlClickexperience = (event: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setexperience(array);
      setexperienceid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);
      console.log(coun);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setexperience(array);
        setexperienceid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removeexperience = (event: any, value: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setexperience(array);
      setexperienceid(arrayid);
      console.log();

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };

  const hendlClickCountry = (event: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcountry(array);
        setcountryid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removecountry = (event: any, value: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };
  var singleslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
  });
  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section
          className="headslider simpleslider_wrp"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="sliderbox">
            <div className="slidercont owl-carousel111">
              <div>
                <img
                  className="scale"
                  src="assets/img/coaching-header.jpg"
                  alt=""
                />
                <div className="content simpleslider_head">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="textwrp">
                          <h4 className="yellow">
                            <b>coaching</b>
                          </h4>
                          <p className="text-white">
                            <b>
                              admissify brings you world className professional
                              teaching staff, including former professors of
                              leading international institutions who are
                              extremely experienced at helping students achieve
                              the highest marks and provide the best online
                              coaching in delhi & india.
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- /Page Header --> */}

        <section className="categories section">
          <div className="container">
            <div className="headingmains text-center">
              <h2 className="titlewithline">
                What Do We Mentor
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.5"
                    height="34.382"
                    viewBox="0 0 30.5 34.382"
                  >
                    <g id="Accept" transform="translate(-40 -8)">
                      <path
                        id="Path_6334"
                        data-name="Path 6334"
                        d="M71.291,49.273H48V16H66.855l4.436,4.436Z"
                        transform="translate(-7.445 -7.445)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6335"
                        data-name="Path 6335"
                        d="M320,16v4.436h4.436"
                        transform="translate(-260.591 -7.445)"
                        fill="#cca400"
                      />
                      <g
                        id="Group_4345"
                        data-name="Group 4345"
                        transform="translate(43.327 15.209)"
                      >
                        <path
                          id="Path_6336"
                          data-name="Path 6336"
                          d="M88,112h17.745v2.218H88Z"
                          transform="translate(-88 -112)"
                          fill="#047475"
                        />
                        <path
                          id="Path_6337"
                          data-name="Path 6337"
                          d="M88,176h17.745v2.218H88Z"
                          transform="translate(-88 -171.564)"
                          fill="#047475"
                        />
                        <path
                          id="Path_6338"
                          data-name="Path 6338"
                          d="M88,240h17.745v2.218H88Z"
                          transform="translate(-88 -231.127)"
                          fill="#047475"
                        />
                        <ellipse
                          id="Ellipse_154"
                          data-name="Ellipse 154"
                          cx="5.5"
                          cy="6"
                          rx="5.5"
                          ry="6"
                          transform="translate(14.672 12.791)"
                          fill="#047475"
                        />
                      </g>
                      <path
                        id="Path_6339"
                        data-name="Path 6339"
                        d="M67.327,464H64.555a.555.555,0,0,0,0,1.109h2.773a.555.555,0,0,0,0-1.109Z"
                        transform="translate(-22.336 -424.391)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6340"
                        data-name="Path 6340"
                        d="M129.109,464h-.555a.555.555,0,0,0,0,1.109h.555a.555.555,0,0,0,0-1.109Z"
                        transform="translate(-81.9 -424.391)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6341"
                        data-name="Path 6341"
                        d="M80.555,107.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,104H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,107.327Zm.555-2.218H97.745v1.109H81.109Z"
                        transform="translate(-37.227 -89.345)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6342"
                        data-name="Path 6342"
                        d="M80.555,171.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,168H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,171.327Zm.555-2.218H97.745v1.109H81.109Z"
                        transform="translate(-37.227 -148.909)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6343"
                        data-name="Path 6343"
                        d="M80.555,235.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,232H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,235.327Zm.555-2.218H97.745v1.109H81.109Z"
                        transform="translate(-37.227 -208.473)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6344"
                        data-name="Path 6344"
                        d="M91.091,296H80.555a.555.555,0,0,0,0,1.109H91.091a.555.555,0,1,0,0-1.109Z"
                        transform="translate(-37.227 -268.036)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6345"
                        data-name="Path 6345"
                        d="M87.764,320H80.555a.555.555,0,0,0,0,1.109h7.209a.555.555,0,0,0,0-1.109Z"
                        transform="translate(-37.227 -284.827)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6346"
                        data-name="Path 6346"
                        d="M81.664,345.109a.555.555,0,0,0,0-1.109H80.555a.555.555,0,0,0,0,1.109Z"
                        transform="translate(-37.227 -312.709)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6347"
                        data-name="Path 6347"
                        d="M121.109,345.109a.555.555,0,0,0,0-1.109h-.555a.555.555,0,0,0,0,1.109Z"
                        transform="translate(-74.455 -312.709)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6348"
                        data-name="Path 6348"
                        d="M70.5,34.064a6.663,6.663,0,0,0-6.1-6.631V12.991a.555.555,0,0,0-.162-.392L59.8,8.162A.555.555,0,0,0,59.409,8H40.555A.555.555,0,0,0,40,8.555V41.827a.555.555,0,0,0,.555.555H63.845a.555.555,0,0,0,.555-.555V40.695A6.663,6.663,0,0,0,70.5,34.064ZM59.964,9.893l2.543,2.543H59.964ZM41.109,41.273V9.109H58.855v3.882a.555.555,0,0,0,.555.555h3.882V27.432a6.655,6.655,0,0,0,0,13.262v.578Zm22.736-1.664a5.545,5.545,0,1,1,5.545-5.545A5.545,5.545,0,0,1,63.845,39.609Z"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_6349"
                        data-name="Path 6349"
                        d="M343.6,352.162a.555.555,0,0,0-.784,0l-3.576,3.576-2.4-1.441a.555.555,0,0,0-.571.951l2.773,1.664a.555.555,0,0,0,.677-.083l3.882-3.882A.555.555,0,0,0,343.6,352.162Z"
                        transform="translate(-275.481 -320.154)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </span>
              </h2>
              <p className="text-center pl-2 pr-2">
                Admissify brings you world className professional teaching
                staff, including former professors of leading international
                institutions who are extremely experienced at helping students
                achieve the highest marks and provide the best online coaching
                in Delhi & India.
              </p>
            </div>
            {seo?.header_content && (
              <div className="about-uni-text shade1">
                <SeoHeaderContent />
              </div>
            )}

            <div className="row">
              <div className="col-sm-12 ">
                <div className="contentbx shade1 choachwhat mt-5">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        GMAT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        GRE
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                      >
                        SAT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-4"
                        role="tab"
                      >
                        IELTS
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-5"
                        role="tab"
                      >
                        TOEFL/PT
                      </a>
                    </li>
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="tabs-1"
                      role="tabpanel"
                    >
                      <p>
                        The GMAT (Graduate Management Admission Test) is a
                        3½-hour standardized exam designed to predict how
                        students will perform academically in their MBA. GMAT
                        scores are used by graduate business schools to make
                        admission decisions. Discover the best GMAT coaching in
                        Delhi & India
                      </p>
                      {/* <!-- ITEM --> */}
                      <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                        {/* <!-- ITEM --> */}

                        <Slider {...singleslider}>
                          <CounsellorSlider />
                        </Slider>
                      </div>

                      <div
                        className="all-center my-2"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                      >
                        <a
                          href={"/counsellor-and-mentor"}
                          className="view-more readbtn"
                        >
                          view all
                        </a>
                      </div>
                    </div>
                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                      <p>
                        {" "}
                        The GRE (Graduate Record Exam) Test is the standardised
                        test used to get admissions in various graduate schools
                        or business graduate schools in various English speaking
                        countries, especially the United States.
                      </p>
                      <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                        {/* <!-- ITEM --> */}

                        <Slider {...singleslider}>
                          <CounsellorSlider />
                        </Slider>
                      </div>

                      <div
                        className="all-center my-2"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                      >
                        <a
                          href={"/counsellor-and-mentor"}
                          className="view-more readbtn"
                        >
                          view all
                        </a>
                      </div>
                    </div>
                    <div className="tab-pane" id="tabs-3" role="tabpanel">
                      <p>
                        {" "}
                        The SAT is a standardized test widely used for college
                        admissions in the United States. Introduced in 1926 it
                        is intended to assess students' readiness for college.
                        It takes 3 hours and costs approx $50. The ACT exam is
                        similar
                      </p>
                      <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                        {/* <!-- ITEM --> */}

                        <Slider {...singleslider}>
                          <CounsellorSlider />
                        </Slider>
                      </div>

                      <div
                        className="all-center my-2"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                      >
                        <a
                          href={"/counsellor-and-mentor"}
                          className="view-more readbtn"
                        >
                          view all
                        </a>
                      </div>
                    </div>
                    <div className="tab-pane" id="tabs-4" role="tabpanel">
                      <p>
                        The (IELTS) International English Language Testing
                        System is the world's most popular English language
                        proficiency test for study, work and migration. It
                        includes verbal, listening, reading and writing
                        components
                      </p>
                      <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                        {/* <!-- ITEM --> */}

                        <Slider {...singleslider}>
                          <CounsellorSlider />
                        </Slider>
                      </div>

                      <div
                        className="all-center my-2"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                      >
                        <a
                          href={"/counsellor-and-mentor"}
                          className="view-more readbtn"
                        >
                          view all
                        </a>
                      </div>
                    </div>
                    <div className="tab-pane" id="tabs-5" role="tabpanel">
                      <p>
                        {" "}
                        TOEFL is a popular English proficiency examination
                        widely recognised amongst American and Canadian
                        universities. It includes verbal, listening, reading and
                        writing components.
                      </p>
                      <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                        {/* <!-- ITEM --> */}

                        <Slider {...singleslider}>
                          <CounsellorSlider />
                        </Slider>
                      </div>

                      <div
                        className="all-center my-2"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                      >
                        <a
                          href={"/counsellor-and-mentor"}
                          className="view-more readbtn"
                        >
                          view all
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec_post">
          <div className="postwrap bluedark">
            <div className="container py-2">
              <article className="postcard">
                <img
                  className="postcard__img"
                  src="assets/img/coaching_01.png"
                  alt=""
                />
                <div className="postcard__text">
                  <h4 className="pb-3">virtual classNameroom</h4>
                  <div className="postcard__preview-txt">
                    <img
                      className="scale"
                      src="assets/img/coaching_icon01.png"
                      alt=""
                    />{" "}
                    <p className="p">
                      Our advanced virtual classNamerooms allow you to fully
                      interact with your faculty & learning material. Put your
                      hand up & ask questions at will! Record sessions & save
                      presentations too! Discover the best IELTS online
                      coaching.
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="postwrap">
            <div className="container py-2">
              <article className="postcard">
                <img
                  className="postcard__img"
                  src="assets/img/coaching_02.png"
                  alt=""
                />
                <div className="postcard__text">
                  <h4 className="pb-3">interactive & local</h4>
                  <div className="postcard__preview-txt">
                    <img
                      className="scale"
                      src="assets/img/coaching_icon02.png"
                      alt=""
                    />{" "}
                    <p className="p">
                      Learn and interact with the best teaching faculty from
                      around the world and all over India from the comfort of
                      your home, via your phone or PC, or at one of our regional
                      offices, in person!
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="postwrap bluelight">
            <div className="container py-2">
              <article className="postcard">
                <img
                  className="postcard__img"
                  src="assets/img/coaching_03.png"
                  alt=""
                />
                <div className="postcard__text">
                  <h4 className="pb-3">record & playback</h4>
                  <div className="postcard__preview-txt">
                    <img
                      className="scale"
                      src="assets/img/coaching_icon03.png"
                      alt=""
                    />{" "}
                    <p className="p">
                      Easily Record amazing learning sessions so you can make
                      notes and review material for great exam preparation. You
                      never have to miss any important learning again !
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="postwrap">
            <div className="container py-2">
              <article className="postcard">
                <img
                  className="postcard__img"
                  src="assets/img/coaching_04.png"
                  alt=""
                />
                <div className="postcard__text">
                  <h4 className="pb-3">manage & schedule</h4>
                  <div className="postcard__preview-txt">
                    <img
                      className="scale"
                      src="assets/img/coaching_icon04.png"
                      alt=""
                    />{" "}
                    <p className="p">
                      Effortlessly manage your session schedule from the
                      Admissify app, You can even record sessions on the go so
                      you never have to miss a session.
                    </p>
                  </div>
                </div>
              </article>
              {seo?.footer_content && (
                <div className="about-uni-text shade1 mt-5">
                  <SeoFooterContent />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Coaching;
