import { 
  LOADING,
  GET_ALL_COUPON,
  SCHOLARSHIP_GET_ALL_DETAIL,
  FIND_BY_SCHOLAR_SLUG,
  GET_OFFER_AND_SCHOLAR,
GET_APPLIED_SCHOLAR
} from "../common/constant";
 type stateType = {
  loading:boolean;
  getAllCoupon: any;
  getAllScholarship: any;
  findBySlugScholar: any;
  getOFferAndScholar: any;
  appliedScholarShip: any;
 }
const initialState : stateType = {
    loading:false,
    getAllCoupon: [],
    getAllScholarship: [],
    findBySlugScholar: [],
    getOFferAndScholar: [],
    appliedScholarShip: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_ALL_COUPON:
      return { ...state, getAllCoupon: action.payload};
      case SCHOLARSHIP_GET_ALL_DETAIL:
      return { ...state, getAllScholarship: action.payload};
      case FIND_BY_SCHOLAR_SLUG:
      return { ...state, findBySlugScholar: action.payload};
        case GET_OFFER_AND_SCHOLAR:
      return { ...state, getOFferAndScholar: action.payload};
      case GET_APPLIED_SCHOLAR:
      return { ...state, appliedScholarShip: action.payload};
    default:
      return state;
  }
};