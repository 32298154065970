import {
  LOADING,
  GET_BLOG_LIST,
  GET_BLOG_BY_ID,
  GET_BLOG_CATEGORY,
  BLOG_UPDATE_LIKE_COMMENT,
  BLOG_LIKE_UNLIKE,
  GET_USER_ACTION_FOR_BLOG_DETAILS,
  BLOG_BY_SLUG,
  BLOG_COMMENT_ADD,
  BLOG_COMMENT_FIND_ALL,
  COUNTRY_CATEGORY,
  COUNTRY_BLOG,
  V_BLOG,
} from "../common/constant";
type stateType = {
  loading: boolean;
  blogList: any;
  blogById: any;
  blogCategory: any;
  blogUpdateLikeComment: any;
  blogLikeUnlike: any;
  getUserActionForBlogDetails: any;
  blogBySlug: any;
  blogCommentAdd: any;
  blogCommentFindAll: any;
  blogCountryFindAll: any;
  blogCountryCateFindAll: any;
  vBlog: any;
}
const initialState: stateType = {
  loading: false,
  blogList: [],
  blogById: [],
  blogCategory: [],
  blogUpdateLikeComment: [],
  blogLikeUnlike: [],
  getUserActionForBlogDetails: [],
  blogBySlug: [],
  blogCommentAdd: [],
  blogCommentFindAll: [],
  blogCountryFindAll: [],
  blogCountryCateFindAll: [],
  vBlog: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case V_BLOG:
      return { ...state, vBlog: action.payload };
    case GET_BLOG_LIST:
      return { ...state, blogList: action.payload };
    case GET_BLOG_BY_ID:
      return { ...state, blogList: action.payload };
    case COUNTRY_CATEGORY:
      return { ...state, blogCountryCateFindAll: action.payload };
    case COUNTRY_BLOG:
      return { ...state, blogCountryFindAll: action.payload };
    case GET_BLOG_CATEGORY:
      return { ...state, blogCategory: action.payload };
    case BLOG_UPDATE_LIKE_COMMENT:
      return { ...state, blogUpdateLikeComment: action.payload };
    case BLOG_LIKE_UNLIKE:
      return { ...state, blogLikeUnlike: action.payload };
    case GET_USER_ACTION_FOR_BLOG_DETAILS:
      return { ...state, getUserActionForBlogDetails: action.payload };
    case BLOG_BY_SLUG:
      return { ...state, blogBySlug: action.payload };
    case BLOG_COMMENT_ADD:
      return { ...state, blogCommentAdd: action.payload };
    case BLOG_COMMENT_FIND_ALL:
      return { ...state, blogCommentFindAll: action.payload };

    default:
      return state;
  }
};