import React from 'react'
import ContactUsForm from '../../Component/ContactUsForm/ContactUsForm'

const CotactUsMobile = () => {
  return (
    <>
        <section className="contact-page">
        <div className="container">
            <div className="mainsheading text-center">
                <h4 className="headingwithline m-4">head offices<span><svg xmlns="http://www.w3.org/2000/svg" width="43.032"
                            height="35.894" viewBox="0 0 43.032 35.894">
                            <path id="mortarboard"
                                d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                                transform="translate(0 -42.469)" fill="#fbb415"></path>
                        </svg></span></h4>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.264547691526!2d-71.0645401843073!3d42.35820104321106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3709b5982d8d9%3A0x702b1729ac91e40f!2s21%20Beacon%20St%20%233f%2C%20Boston%2C%20MA%2002108%2C%20USA!5e0!3m2!1sen!2sin!4v1642683689901!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>BOSTON</h1>
                            <p>21 Beacon Street, Suite 3F, Boston, MA</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+44 3301130031</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.9211446324593!2d-0.1693442840762963!3d51.4596042220175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760596ff9b7171%3A0xc960760f4ca7d494!2s10%20Cairns%20Rd%2C%20London%20SW11%201ES%2C%20UK!5e0!3m2!1sen!2sin!4v1642683755071!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>LONDON</h1>
                            <p>10 Cairns road, London .SW11 1ES</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+44 779 244 6697</a>
                                </p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.9585822597255!2d77.14787371508324!3d28.66095878240663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d025798555555%3A0x6615718388035056!2sAdmissify%20Edutech!5e0!3m2!1sen!2sin!4v1642682517030!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>NEW DELHI</h1>
                            <p>71/4, Shivaji Marg, Najafgarh Road, New Delhi</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> 91 11 41219999</a>
                                </p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mainsheading text-center">
                    <h4 className="headingwithline">regional offices<span><svg xmlns="http://www.w3.org/2000/svg"
                                width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                                <path id="mortarboard"
                                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                                    transform="translate(0 -42.469)" fill="#fbb415"></path>
                            </svg></span></h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.968480030097!2d91.77753261502922!3d26.16515598345533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a594929de55b9%3A0x9b75dbd42835bb12!2sStudy%20Abroad%20Consultants%20in%20Guwahati%20%7C%20Admissify!5e0!3m2!1sen!2sin!4v1650698607072!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>GUWAHATI</h1>
                            <p>4th Floor, Guwahati Central, RG Baruah Rd, Shraddhanjali Park, Manik Nagar, Guwahati,
                                Assam 781005</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        70860 70045</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email:
                                        </span>guwahati@admissify.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14032.398242783172!2d77.0438803!3d28.446415!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10af3aa1d5090adf!2sAdmissify%2C%20Study%20Abroad%20Consultants%20Gurugram!5e0!3m2!1sen!2sin!4v1642683067575!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>GURUGRAM</h1>
                            <p>Plot no.-55P, Institutional Area, Sec-44, Gurugram, Haryana 122003</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.297317105311!2d76.79140841513158!3d30.73814898163525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fedb41463b9f5%3A0xba45c901339f78f1!2sAdmissify%20Chandigarh%20Canada%20immigration%20%26%20Study%20Abroad%20consultants%20in%20Chandigarh!5e0!3m2!1sen!2sin!4v1642683116187!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>CHANDIGARH</h1>
                            <p>Sco- 156-160, Second floor, Office no. 203, Back side of Sindhi Sweets, Madhya Marg,
                                Sector 8C</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14005.832856991778!2d77.1780068!3d28.6459957!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4d45b87ef07a1959!2sStudy%20Abroad%20Consultants%2C%20Study%20in%20UK%20%7C%20Admissify!5e0!3m2!1sen!2sin!4v1642683160024!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>NEW DELHI</h1>
                            <p>1310, Hemkunt Tower, 13th Floor, 6, Rajendra Palace, New delhi, Delhi 110008</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13999.851410536614!2d77.1508082!3d28.6907578!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x565761cc10604d4b!2sStudy%20Abroad%20Consultants%20%7C%20IELTS%20Online%20Coaching%20in%20New%20Delhi!5e0!3m2!1sen!2sin!4v1642683202101!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>NEW DELHI</h1>
                            <p>110 to 118, 1st Floor, KLJ Tower NBU, Netaji Subhash Place, New Delhi, Delhi 110034</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15231.195155085841!2d78.5114185!3d17.3734125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ed11524aae8595a!2sAdmissify%2C%20Best%20Overseas%20Education%20Consultant%20in%20Hyderabad!5e0!3m2!1sen!2sin!4v1642683243661!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>HYDERABAD</h1>
                            <p>Saleem Nagar Colony, Malakpet Extension, New Malakpet, Hyderabad, Telangana 500036</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14879.6224495037!2d72.7967486!3d21.1959082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cf5197f35b5ae8f!2sAdmissify%20Surat%20-%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20GRE%20Coaching!5e0!3m2!1sen!2sin!4v1642683285739!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>SURAT</h1>
                            <p>S -101/102 Jai Complex, Anand Mahal Road, Adajan, Surat, Gujarat 395009</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15125.008484688375!2d73.8218698!3d18.6077264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3920e1fd8aa5ea9a!2sAdmissify%20Pune%20-%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20Coaching!5e0!3m2!1sen!2sin!4v1642683324600!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>PUNE</h1>
                            <p>12-13 A Wing, 1st Floor Sagar Complex, Opp Kasarwadir Rail Station, Mumbai-Pune Road,
                                Nasik Phata, Pune, Maharashtra 411034</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.4436966440608!2d72.83808941489939!3d18.95600238715684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf21b7d5ba5b%3A0x63684ac97d809ef6!2sAdmissify%20Mumbai%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20Coaching!5e0!3m2!1sen!2sin!4v1642683360532!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>MUMBAI</h1>
                            <p>209 Sterling Chamber, Dana Bunder, Poona Street, Masjid East, Mumbai, Maharashtra 400009
                            </p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3484.0035757911774!2d75.71943481509476!3d29.16456688221089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39123359b5980c8f%3A0xd956404e66b88228!2sAdmissify%20Hisar%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20GRE%20Coaching!5e0!3m2!1sen!2sin!4v1642683401493!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>HISAR</h1>
                            <p>19/3, Tilak Bazaar, Shastri Nagar, Hisar, Haryana 125001</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.408918162088!2d77.32379251508361!3d28.677411982400056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb84f88394db%3A0x34f1a974a3c54f8f!2sAdmissify%20Ghaziabad%2C%20Study%20Abroad%20Consultant%20in%20Ghaziabad!5e0!3m2!1sen!2sin!4v1642683449634!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>GHAZIABAD</h1>
                            <p>201, 202 2nd Floor, Apsara Complex, Delhi UP Border, P.O. Chikamberpur, Sahibabad,
                                Ghaziabad, Uttar Pradesh 201006</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.7882139561157!2d77.28874831507527!3d28.30473898254815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdbfba117053d%3A0xd15f71a72fdaa8bc!2sAdmissify%20Faridabad%20%7C%20Study%20Abroad%20Consultant%20in%20Faridabad%2C%20Overseas%20Education!5e0!3m2!1sen!2sin!4v1642683484413!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>FARIDABAD</h1>
                            <p>Plot No 61, Sector - 58, Transport Nagar, BALLABHGARH, FARIDABAD, Haryana 121004</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span>+91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.6848630174522!2d80.20362101482358!3d13.119139490759196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265a82ba45a2f%3A0x28f45a82804e62da!2sAdmissify%20Chennai%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20GRE%20Coaching!5e0!3m2!1sen!2sin!4v1642683520603!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>CHENNAI</h1>
                            <p>Plot no. 81 second main road thillai nagar kolthur, chennai, Tamil Nadu 600099</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13766.413480414172!2d76.7942136!3d30.3906187!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4e643cee937c4994!2sAdmissify%20Ambala%20Study%20Abroad%20Consultants%2C%20Immigration%20Lawyers%2C%20IELTS%20GMAT%20GRE%20Coaching!5e0!3m2!1sen!2sin!4v1642683556241!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>AMBALA</h1>
                            <p>50/12, First Floor, Near Railway Crossing, Baldev Nagar, Ambala City, Ambala City,
                                Haryana 134007</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="shade-c1 text-center"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14692.42653255614!2d72.6003788!3d22.983106!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ebbaf1380371431!2sAdmissify%20Ahmedabad%20%7C%20Study%20Abroad%20Consultant%20in%20Ahmedabad!5e0!3m2!1sen!2sin!4v1642683601618!5m2!1sen!2sin"
                            width="100%" height="200" allowFullScreen={true} loading="lazy" style={{border: "0px"}}></iframe>
                        <div className="add-details">
                            <h1>AHMEDABAD</h1>
                            <p>302, 3rd floor, Z-block, Dev Castle Near jaimala, Bus Stop, Isanpur, Ahmedabad, Gujarat
                                382443</p>
                            <div className="c-infor">
                                <p><a href="tel:+9101141219999"><span className="c-tag">phone: </span> +91 11 41219999, +91
                                        9999 127085</a></p>
                                <p><a href="tel:+9101141219999"><span className="c-tag">email: </span>info@admissify.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ContactUsForm/>
    </>
  )
}

export default CotactUsMobile