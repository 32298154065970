import { 
  LOADING,
  GET_ALL_CITY,
  GET_CITY_BY_ID,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  cityList: any;
  cityById: any;
 }
const initialState : stateType = {
    loading:false,
    cityList: [],
    cityById: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_ALL_CITY:
      return { ...state, cityList: action.payload};
      case GET_CITY_BY_ID:
      return { ...state, cityById: action.payload};
    default:
      return state;
  }
};