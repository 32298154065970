import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  counselloerAction,
  coachAction,
  countryAction,
} from "../../redux/common/action";
import { Link, useHistory, useLocation } from "react-router-dom";

import StarRatings from "react-star-ratings";
import Slider from "react-slick";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";

function CounsellorList(props: any) {
  const history = useHistory();

  const lastLocation = useLocation();
  const baseurl = URL.API_BASE_URL;
  const dispatch = useDispatch();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const counsellorinfo: any[] = useSelector((state: any) =>
    state.counsellor.counsellorinfo ? state.counsellor.counsellorinfo : []
  );

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);


  const activeCounselloer =
    counsellorinfo &&
    counsellorinfo?.filter((data: any) => {
      return data?.status == 1;
    });

  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );


  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );

  // useEffect(() => {
  //   dispatch(counselloerAction.getAllCounsellor({}));
  //   return () => {};
  // }, []);
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <div className="filter-page__content">
        <div className="filter-item-wrapper counsellorlist">.

          <Slider {...settings}>
            {activeCounselloer &&
              activeCounselloer?.map((data: any, i: any) => {
                var preferred_country =
                  data?.preferred_country !== null
                    ? data?.preferred_country.split(",")
                    : "";
                return (
                  i > 1 && (
                    <div>
                      <div className="uni-item-list " key={i}>
                        <div className="item-media">
                          <div className="image-cover">
                            <div
                              onClick={() => {
                                history.push("/counsellor/" + data?.slug, {
                                  counselloer_id: data?.id,
                                });
                              }}
                              className="counsellorAneCoachCover"
                              style={{
                                background: `url(${baseurl + data?.profile_image})`,
                                cursor: "pointer"
                              }}
                            >
                              {/* <img
                        src={baseurl+data?.profile_image}
                        alt=""
                        className="scale"
                      /> */}
                              {/* <button type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="42"
                              height="42"
                              viewBox="0 0 42 42"
                            >
                              <circle
                                id="Ellipse_267"
                                data-name="Ellipse 267"
                                cx="21"
                                cy="21"
                                r="21" 
                                fill="#047475"
                              />
                              <path
                                id="Icon_awesome-rocketchat"
                                data-name="Icon awesome-rocketchat"
                                d="M25.193,5.648A19.25,19.25,0,0,0,11.513,3.2c-.171-.166-4.826,1.148-5.465,1.992A22.43,22.43,0,0,0,3.355,7.316a8.361,8.361,0,0,0-.184,12.1C3.785,22.25,0,25.48,0,25.48a9.882,9.882,0,0,0,11.513-1.924A19.273,19.273,0,0,0,25.193,21.1c6.177-3.96,6.2-11.474,0-15.456Zm-9.956,15.43a17.516,17.516,0,0,1-4.564-.591l-1.024.993a9.312,9.312,0,0,1-1.9,1.425,7.39,7.39,0,0,1-2.678.766c.052-.093.1-.185.143-.273a6.587,6.587,0,0,0,.829-5.133,6.324,6.324,0,0,1-2.693-4.9c0-4.265,5.321-7.72,11.882-7.72S27.118,9.1,27.118,13.368,21.8,21.077,15.237,21.077ZM9.536,15.2a1.775,1.775,0,1,1,1.746-1.811v.027A1.765,1.765,0,0,1,9.536,15.2Zm3.821-1.754a1.775,1.775,0,0,1,3.549-.062v.032A1.775,1.775,0,0,1,13.357,13.45ZM20.783,15.2a1.775,1.775,0,1,1,1.746-1.811v.027A1.759,1.759,0,0,1,20.783,15.2Z"
                                transform="translate(7.5 7.465)"
                                fill="#fff"
                              />
                            </svg>
                          </button> */}
                            </div>
                          </div>
                        </div>

                        <div className="item-body">
                          <div className="item-title">
                            <div className="pricedegree">
                              <p>
                              {data?.rate_per_hr ? 
                              `INR ${data?.rate_per_hr}/60 min`
                              :
                              "Free"}
                              {" "}
                                {data?.admissify_certified == 1 ? (
                                  <span>
                                    {" "}
                                    <img src="assets/img/certificate.svg" alt="" />
                                  </span>
                                ) : (
                                  ""
                                )}
                                {data?.premium == 1 ? (
                                  <span>
                                    {" "}
                                    <img src="assets/img/degree.svg" alt="" />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <h5
                              onClick={() => {
                                history.push("/counsellor/" + data?.slug, {
                                  coach_id: data?.id,
                                });
                              }}
                              style={{ cursor: "pointer" }}>
                              {data?.gender == 1 ? "Mr." : "Ms."} {data?.name}
                              {data?.admissify_verified == 1 ? (
                                <sup className="color-green">
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  ></i>
                                </sup>
                              ) : (
                                ""
                              )}
                              <span className="online">
                              <i className="fa fa-circle" aria-hidden="true" style={{color: data?.is_online ? 'green' : 'red'}}></i>{" "}
                              {data?.is_online ?  'online' : 'offline'}
                            </span>
                            </h5>
                            <p>
                              <small>counsellor</small>
                            </p>
                            <p className="yellow">
                              <small>
                                {data?.passout_university &&
                                  data?.passout_university}
                              </small>
                            </p>
                          </div>
                          <div className="item-footer mt-2">
                            <div className="item-hotel-star">
                              <div>
                                <StarRatings
                                  rating={
                                    parseFloat(data?.rating)
                                      ? parseFloat(data?.rating)
                                      : 0
                                  }
                                  starRatedColor="#fcbd30"
                                  starDimension="13px"
                                  starSpacing="0px"
                                />{" "}
                                <span style={{ fontWeight: "normal" }}>
                                  | {data?.counselled && data?.counselled > 500
                                    ? "500+"
                                    : data?.counselled} student google reviews
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="item-abouttxt coachAndCounselloer">
                            <table
                              width="100%"
                              style={{ border: "0" }}
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      width="100%"
                                      style={{ border: "0" }}
                                      cellSpacing="0"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td rowSpan={2}>
                                            <img
                                              src="assets/img/icons/exp.png"
                                              alt=""
                                              className="scale"
                                            />
                                          </td>
                                          <td>experience</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="yellow">
                                              {data?.experience == null
                                                ? ""
                                                : data?.experience + " yr"}{" "}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td>
                                    <table
                                      width="100%"
                                      style={{ border: "0" }}
                                      cellSpacing="0"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td rowSpan={2}>
                                            <img
                                              src="assets/img/icons/location.png"
                                              alt=""
                                              className="scale"
                                            />
                                          </td>
                                          <td>country preference </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {typeof preferred_country == "object"
                                              ? preferred_country?.map(
                                                (datas: any, index: any) => {
                                                  const countryPre: any =
                                                    countrys?.filter(
                                                      (data: any) => {
                                                        return data?.id == datas;
                                                      }
                                                    );

                                                  return (
                                                    <span
                                                      className="yellow"
                                                      key={index}
                                                    >
                                                      {
                                                        countryPre[0]
                                                          ?.country_name
                                                      }
                                                      {index > 0 && index - 1
                                                        ? ""
                                                        : ", "}
                                                    </span>
                                                  );
                                                }
                                              )
                                              : ""}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td>
                                    <table
                                      width="100%"
                                      style={{ border: "0" }}
                                      cellSpacing="0"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td rowSpan={2}>
                                            <img
                                              src="assets/img/icons/edu.png"
                                              alt=""
                                              className="scale"
                                            />
                                          </td>
                                          <td>higheset education</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="yellow">
                                              {data?.qualification &&
                                                data?.qualification}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="item-btn-more">
                          <div className="btnbox">
                            {/* <a href="javascript:void(0)" className="awe-btnbook" style={{ cursor: "not-allowed" }}>
                              Vblog
                            </a> */}

                            <a
                              onClick={() => {
                                history.push("/counsellor/" + data?.slug, {
                                  counselloer_id: data?.id,
                                });
                              }}
                              className="awe-btnbook download"
                            >
                              view profile
                            </a>
                            {/* <a onClick={handleFreeCoaching} className="awe-btnbook">
                          book appointment 
                        </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default CounsellorList;
