import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

var other_details: any = [];
function PTE() {
   const urlsforShare = window.location.href;
   const dispatch = useDispatch();
   const history = useHistory();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );


   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "PTE-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();
      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();

      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;
      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#pteIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));
      // toast.success('Thanks for showing your interest, we will get back to you soon.');
      // window.location.href = "/thank-you";
   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "PTE", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     PTE
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              Students and aspirants who want to study or immigrate to a major English-speaking country take the PTE-Academic English test. In its complete form, PTE stands for Pearson Test of English. To demonstrate their English language skills, students must take the PTE exam.
                              It's the world's first computer-based English language test, and it evaluates your English speaking, reading, writing, and listening skills to ensure that you can study in an international setting. The PTE test uses Artificial Intelligence (AI) technology to provide a quick and easy assessment while eliminating human bias. The PTE results are accessible within 48 hours of taking the test. On a scale of ten to ninety points, the PTE score is calculated.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/pte02.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">What is the importance of the PTE Exam?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">PTE Academic is a language aptitude test used by educational institutions, professionals, and government agencies that demand a standard of academic English language proficiency for entrance purposes.
                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/coun11.jpg" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Types of PTE Exam</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The PTE exam is available in two formats: Academic and General.
                                 </div> <br />
                                 <h6 className="pb-3 card-head1"><b> PTE Academic</b></h6>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">This test style assesses the candidate's reading, writing, speaking, and listening abilities. </div><br />
                                 <h6 className="pb-3 card-head1"><b> PTE General</b></h6>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> This test has two parts: a written paper and a spoken test, similar to an interview, in which both sections measure the test taker's reading, writing, speaking, and listening skills in a more realistic manner. </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/pte03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Who accepts PTE Scores?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Australia, the United States, the United Kingdom, Ireland, Singapore, Canada, and New Zealand are some nations that recognise the PTE exam and are popular with non-native applicants looking to study or immigrate. Apart from these, Russia, Spain, South Africa, Germany, France, Poland, Argentina, Italy, Japan, the Czech Republic, China, and Brazil are among the nations that accept PTE scores.


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">How to register for PTE academic exam?</h4>
                                 <span className="border-bt"></span>

                                 <div className="postcard__preview-txt ft-15">log into PTE Academic's official website and create your account
                                    <br />You will receive an email with your login information 48 hours after your registration.
                                    <br />Locate a PTE test centre near you.
                                    <br />Choose a convenient time and date to take the PTE exam.
                                    <br />After that, pay the PTE Exam Fee.
                                    <br /> Candidates can now register for the PTE exam over the phone, in addition to the online method. Students from India who desire to take the PTE Exam should contact the PTE Academic Center to register for the exam.

                                 </div>

                              </div>
                           </article>
                        </div>
                     </div>

                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/pte04.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Where can PTE Exam take you?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Thousands of academic programmes worldwide accept PTE Academic for study applications, and the Australian, UK, and New Zealand governments have recognised PTE Academic for all student visa and migration applications.
                                    You can use PTE Academic to prove your English ability as part of all Australian visa applications; it is also accepted by most Canadian universities, is accepted by 98 per cent of UK universities, and is approved by the UK Home Office for all visa and immigration applications; is accepted by thousands of academic programmes in the United States. Apart from these, several well-known colleges worldwide, including those in South Korea, Germany and China, have begun to accept PTE results.


                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/ielts04.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of PTE academic</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">More than 6000 organisations throughout the world accept and recognise the PTE score.
                                    Within five business days of the exam date, PTE sends out scores. As a result, PTE is a great way for students to avoid waiting for their test results before applying to international universities.
                                    Candidates' replies are checked automatically using the software. In the checking and marking of tests, no human intervention is authorised.
                                    There are no uncertain questions.
                                    Are you interested in studying abroad and wondering where to begin? Admissify is here to assist you! With offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India, Admissify is India's leading international education consultant. We seek to empower individuals to realise their dream of studying abroad by providing assistance and counselling targeted to their specific needs.


                                    Are you interested in studying abroad and wondering where to begin? Admissify is here to assist you! With offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India, Admissify is India's leading international education consultant. We seek to empower individuals to realise their dream of studying abroad by providing assistance and counselling targeted to their specific needs.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country}>
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input
                                                autoComplete="new-off" type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" id="pteIn" className="bggreadent apply-sub-btn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default PTE
