import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  counselloerAction,
  DiscussionAction,
  UniversityAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import AdSlider from "../AdSlider/AdSlider";
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import Seo from "../Seo/Seo";
import axios from "axios";
import Slider from "react-slick";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";

var arrayId: any[] = [];
function DiscussionList() {
  const lastLocation = useLocation<any>();
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const [openStart, setOpenStart] = useState<boolean>(false);
  const [modelData, setmodelData] = useState<any>("");
  const [categoryOpen, setcategoryOpen] = useState<any>(
    isMobile ? false : true
  );
  const [Categories, setcategories] = useState<any[]>([]);
  const [discussion, setdiscussion] = useState<any[]>([]);
  const [discussionFilter, setdiscussionFilter] = useState<any>({});
  const [discussionComment, setdiscussionComment] = useState<any>("");
  const [CommentBox, setCommentBox] = useState<any>("");
  const [viewAll, setviewAll] = useState<any>(9);
  const [loadmore, setloadmore] = useState<any>(50);
  const [offset, setoffset] = useState<any>(0);
  const [searchRe, setsearchRe] = useState<any>("");
  const [searchByTag, setSearchByTag] = useState<any>("");
  const [Discussion, setDiscussion] = useState<any>("");
  const [CategoryParentId, setCategoryParentId] = useState<any>("");

  const isAuthenticated = getToken();

  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const discuss: any[] = useSelector((state: any) =>
    state.discussion.discussionList ? state.discussion.discussionList : []
  );

  const activeDiscuss =
    discuss &&
    discuss?.filter((data: any) => {
      return data?.status == 1;
    });

  const getDiscussions: any[] = useSelector((state: any) =>
    state.discussion.getDiscussion ? state.discussion.getDiscussion : []
  );

  const trendingDiscussion: any[] = useSelector((state: any) =>
    state.discussion.getTrendingDiscussion
      ? state.discussion.getTrendingDiscussion
      : []
  );

  const getDiscussionCategory: any[] = useSelector((state: any) =>
    state.discussion.getDiscussionCategory
      ? state.discussion.getDiscussionCategory
      : []
  );

  const likeUnlike: any = useSelector((state: any) =>
    state.discussion.likeUnlike ? state.discussion.likeUnlike : []
  );
  const getDiscussionComment: any = useSelector((state: any) =>
    state.discussion.getDiscussionComment
      ? state.discussion.getDiscussionComment
      : []
  );

  const activegetDiscussionCategory =
    getDiscussionCategory &&
    getDiscussionCategory?.filter((data: any) => {
      return data?.status == 1;
    });

  /**
   * Hendle Like Unilike
   */

  const hendleLike = (e: any, data: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login");
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        DiscussionAction.discussionLikeUnlike({
          discussion_id: data,
          student_id: user?.id,
        })
      );
    }
  };

  const trendingUniversities: any[] = useSelector((state: any) =>
    state.unversity.trendingUniversities
      ? state.unversity.trendingUniversities
      : []
  );
  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const activeCounselloer =
    counsellor &&
    counsellor?.filter((data: any) => {
      return data?.status == 1;
    });

  // console.log(activeCounselloer, "active c");

  const filterdiscussion =
    discussionFilter.length > 0 ? discussionFilter : discuss;

  const searchDiscussion = (e: any) => {
    setsearchRe(e);
    dispatch(
      DiscussionAction.getDiscussion({ searchinput: e, limit: 50, offset: 0 })
    );
    return () => {};
  };

  useEffect(() => {
    dispatch(counselloerAction.getAllCounsellor({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      UniversityAction.getTrendingUniversities({
        search_by: "",
        offset: 0,
        country_id: "",
        is_trending: "",
        limit: 10,
      })
    );
    return () => {};
  }, []);

  const handleLoadMore = async (e: any) => {
    setloadmore(loadmore);
    setoffset(offset + 50);
    dispatch(
      DiscussionAction.getDiscussion({
        category_id:
          arrayId?.length == 0 ? "" : arrayId ? arrayId : searchByTag,
        limit: loadmore,
        offset: offset + 50,
        searchinput: searchRe ? searchRe : "",
      })
    );
    return () => {};
  };

  useEffect(() => {
    dispatch(DiscussionAction.getDisCategoryList({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (lastLocation?.state?.id) {
      handleByTag(lastLocation?.state?.id, lastLocation?.state?.name);
      history.replace({ state: {} });
    } else {
      dispatch(DiscussionAction.getDiscussion({ limit: 50, offset: 0 }));
    }
    return () => {};
  }, [likeUnlike, getDiscussionComment]);

  useEffect(() => {
    dispatch(DiscussionAction.getTrendingDiscussion({}));
    return () => {};
  }, []);

  const hendleComment = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        DiscussionAction.discommentAdd({
          discussion_id: e,
          user_type: 1,
          user_id: user?.id ? user?.id : null,
          comments: discussionComment,
        })
      );
      dispatch(DiscussionAction.getdiscommentlist({}));
      setdiscussionComment("");
      return () => {};
    }
  };
  const hendleCommentBox = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      if (CommentBox == e) {
        setCommentBox("");
      } else {
        setCommentBox(e);
        setdiscussionComment("");
      }
    }
  };

  const hendlClickCategories = (event: any) => {
    let array = [...Categories];
    if (event.target.checked) {
      var dataId = $(event.target).attr("dataid");
      array.push(event.target.value);
      arrayId.push(dataId);
      setcategories(array);
      dispatch(
        DiscussionAction.getDiscussion({
          category_id: arrayId ? arrayId : "",
          offset: 0,
          limit: 50,
        })
      );
      return () => {};
    } else {
      var dataId = $(event.target).attr("dataid");
      let coun = array.indexOf(event.target.value);
      let coun1 = arrayId.indexOf(dataId);
      if (coun1 > -1) {
        arrayId.splice(coun1, 1);
        dispatch(
          DiscussionAction.getDiscussion({
            category_id: arrayId.length > 0 ? arrayId : "",
            offset: 0,
            limit: 50,
          })
        );
      }
      if (coun > -1) {
        array.splice(coun, 1);
        setcategories(array);
        return () => {};
      }
    }
  };

  const remove = (event: any, value: any) => {
    let array = [...Categories];
    let coun = array.indexOf(value);
    var dataId = $('._c_filter[value="' + value + '"]').attr("dataid");
    let coun1 = arrayId.indexOf(dataId);
    if (coun1 > -1) {
      arrayId.splice(coun1, 1);
      dispatch(
        DiscussionAction.getDiscussion({
          category_id: arrayId.length > 0 ? arrayId : "",
          offset: 0,
          limit: 50,
        })
      );
    }
    if (coun > -1) {
      array.splice(coun, 1);
      setcategories(array);
    }
  };

  const handleByTag = (id: any, name: any) => {
    arrayId = [];
    arrayId.length = 0;
    arrayId.push(id.toString());
    let arr: any = [];
    arr.push(name);
    setcategories(arr);
    setSearchByTag(id);
    dispatch(
      DiscussionAction.getDiscussion({
        category_id: [id],
        offset: 0,
        limit: 50,
      })
    );
  };
  // filter

  const filterdiscu = (e: any) => {
    const discussionvalue = e.target.value.trim().toLowerCase();
    const discussionFil = getDiscussions?.filter((data) => {
      return data?.topic?.toLowerCase().match(discussionvalue);
    });
    setdiscussionFilter(discussionFil);
  };

  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const handleReview = (e: any, type = null) => {
    if (type === "category") {
      setCategoryParentId(e.target.value);
    }
    const { name, value } = e.target;
    setDiscussion({
      ...Discussion,
      [name]: value,
      user_id: user?.id,
      user_type: 2,
      auther_name: studentProfile?.name,
    });
  };
  const submitDiscussion = (e: any) => {
    const Category = $("#category_id").val();
    const SubCategory = $("#sub_category_id").val();
    const Topic = $("#topic").val();
    const Question = $("#description").val();
    $(".error-class").remove();
    var isError = false;
    if (Category == "select category") {
      $("#category_id").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (SubCategory == "select sub category") {
      $("#sub_category_id").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (!Topic.trim()) {
      $("#topic").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (!Question) {
      $("#description").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }
    dispatch(DiscussionAction.addDiscussion(Discussion));
    window.location.reload();
  };
  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);

  var singleslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });
    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };

  const handleCategoryOpen = (e: any) => {
    if (categoryOpen == true) {
      setcategoryOpen(false);
    } else {
      setcategoryOpen(true);
    }
  };
  function getProfileImg(data: any) {
    if (data?.User && data?.User?.profile_photo) {
      return data?.User?.profile_photo;
    } else if (data?.Student && data?.Student?.profile_image) {
      return data?.Student?.profile_image;
    } else if (data?.Counsellor && data?.Counsellor?.profile_image) {
      return data?.Counsellor?.profile_image;
    } else if (data?.Coach && data?.Coach?.profile_image) {
      return data?.Coach?.profile_image;
    } else if (data?.user_icon) {
      return data?.user_icon;
    }
  }
  const handleAddDiscussion = () => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      setOpenStart(true);
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   })
  return (
    <div>
      <Seo slug={window.location.pathname} />
      <section className="unifilter-page">
        <div className="container">
          <Breadcrumb page={"Discussion"} data={{ slug: "", path: "" }} />
          <div className="row">
            <div
              className="col-lg-2 col-md-pull-10"
              // style={{ border: "1px solid red" }}
            >
              <div
                className="page-sidebar mob-mt-160"
                // style={{ border: "1px solid red" }}
              >
                <div className="widget sidebar-title">
                  <h5>
                    <span>
                      selected <b>filters</b> <i className="fa fa-angle-up"></i>
                    </span>{" "}
                  </h5>
                  <ul
                    className={
                      Categories?.length !== 0
                        ? "clear-filter"
                        : "clear-filter filDisNone"
                    }
                  >
                    {Categories &&
                      Categories.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data}
                            <a onClick={(e: any) => remove(e, data)}>
                              <sup>
                                <i className="fa fa-close"></i>
                              </sup>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                {/* <!-- WIDGET --> */}
                <div
                  className="widget widget_has_radio_checkbox"
                  // style={{ border: "1px solid red" }}
                >
                  <h5>
                    <span onClick={handleCategoryOpen}>
                      <b>Categories</b>{" "}
                      {categoryOpen == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={categoryOpen == false ? "p-0" : ""}>
                    {categoryOpen == true &&
                      activegetDiscussionCategory &&
                      activegetDiscussionCategory?.map((data: any, i: any) => {
                        if (data?.parent_id == 0)
                          return (
                            <li key={i}>
                              <label>
                                <input
                                  className="_c_filter vertical_align_checkbox"
                                  type="checkbox"
                                  value={data?.category_name}
                                  dataid={data?.id}
                                  checked={Categories.includes(
                                    data?.category_name
                                  )}
                                  onChange={(e: any) => hendlClickCategories(e)}
                                />
                                <i className="awe-icon awe-icon-check"></i>
                                <span className="vertical_align">
                                  {data?.category_name}
                                </span>
                              </label>
                            </li>
                          );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">counsellor </h5>
                  <ul className="bg-white">
                    {activeCounselloer &&
                      activeCounselloer?.map((data: any, i: any) => {
                        return (
                          i < 6 && (
                            <li key={i}>
                              <div className="counsellorBox">
                                {data.profile_image ? (
                                  <div
                                    className="relatedCounsellor"
                                    style={{
                                      background: `url(${
                                        baseurl + data?.profile_image
                                      })`,
                                      cursor: "pointer",
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      borderRadius: "50%",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  >
                                    {data.name.slice(0, 1)}
                                  </div>
                                )}
                              </div>
                              {/* <img src="assets/img/testi.png" alt="" /> */}
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    history.push("/counsellor/" + data?.slug, {
                                      counselloer_id: data?.id,
                                    });
                                  }}
                                >
                                  {data?.gender == 1 ? "Mr." : "Ms. "}{" "}
                                  {data?.name}{" "}
                                </h6>
                                <div className="feat-inn discussiondetails">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                </div>
                                <p>
                                  <small>
                                    {data?.counselled ? data?.counselled : 0}{" "}
                                    student counselled
                                  </small>
                                </p>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
              <div className="banner-section1">
                <VerticalAddSlider />
              </div>
            </div>
            <div className="col-lg-2 order-lg-2">
              <div className="page-sidebar">
                {/* <!-- WIDGET --> */}
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    trending <b>discussion</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {trendingDiscussion &&
                      trendingDiscussion?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <p>
                              <a
                                className="__hover"
                                onClick={() => {
                                  history.push(
                                    "/about-discussion/" + data?.slug + "",
                                    {
                                      discussion_id: data?.slug,
                                    }
                                  );
                                }}
                              >
                                {data?.topic && data?.topic}
                              </a>
                            </p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center">
                  <div className="singleslider">
                    <Slider {...singleslider}>
                      {getOFferAndScholar &&
                        getOFferAndScholar?.map((data: any, i: any) => {
                          if (data?.type == "Offers")
                            return (
                              <div className="offers-item">
                                <figure className="feat-text">
                                  <img
                                    className="scale"
                                    src={baseurl + data?.coupon_icon}
                                  />
                                </figure>
                                <figcaption className="content-box">
                                  <a
                                    onClick={(e) => {
                                      data?.type == "Offers"
                                        ? opneModel(data?.id)
                                        : history.push(
                                            "/scholarships/" + data?.sch_slug
                                          );
                                    }}
                                    data-toggle="modal"
                                    data-target="#offer-pop"
                                    className="mt_btn_yellow"
                                  >
                                    view details
                                  </a>
                                  <h3>
                                    Get{" "}
                                    <span>
                                      {data?.coupon_type == 1
                                        ? data?.max_off + "%"
                                        : data?.max_off}{" "}
                                      OFF
                                    </span>{" "}
                                    {data?.coupon_title}
                                  </h3>
                                </figcaption>
                              </div>
                            );
                        })}
                    </Slider>
                  </div>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget counsellor discussion shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    trending <b>university</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {trendingUniversities?.map((data: any, i: any) => {
                      return (
                        <li key={i}>
                          {data?.university_logo !== null ? (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                              src={baseurl + data?.university_logo}
                              alt=""
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "/assets/img/university-icon.png";
                              }}
                            />
                          ) : (
                            <img
                              src="/assets/img/university-icon.png"
                              alt=""
                              className="trending-img"
                            />
                          )}
                          <div className="counsellorName">
                            <h6
                              className="__hover"
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                            >
                              {data?.university_name && data?.university_name}
                            </h6>
                            <div className="feat-inn">
                              <ul>
                                <StarRatings
                                  rating={
                                    parseFloat(data?.rating)
                                      ? parseFloat(data?.rating)
                                      : 0
                                  }
                                  starRatedColor="#fcbd30"
                                  starDimension="13px"
                                  starSpacing="0px"
                                />
                              </ul>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-push-2">
              {seo?.header_content && (
                <div className="about-uni-text shade1">
                  <SeoHeaderContent />
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <div className="program-search py-0">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="input-group mb-3 searchreviewtop"
                          onClick={() => handleAddDiscussion()}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="start new discussion"
                            data-toggle="modal"
                            data-target="#show_add_discussion_form"
                            readOnly
                            style={{ cursor: "pointer" }}
                          />
                          <div
                            className="btnedit"
                            data-toggle="modal"
                            data-target="#show_add_discussion_form"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-pencil"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="program-search">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <input
                            ref={anchorRef1}
                            type="text"
                            className="form-control"
                            placeholder="search"
                            value={searchRe}
                            onChange={(e) => {
                              searchDiscussion(e.target.value);
                            }}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-secondary" type="button">
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {getDiscussions && getDiscussions?.length == 0 ? (
                <div className="nodataFound">
                  <img src="/assets/img/noDataFound.jpeg" alt="" />
                </div>
              ) : (
                ""
              )}
              {getDiscussions &&
                getDiscussions?.length > 0 &&
                getDiscussions?.map((data: any, i: any) => {
                  const result: any = data?.DiscussionLikes?.some(
                    (obj: any) => obj?.student_id == user?.id && user?.id
                  );
                  let date = dateFormat(data?.created_at, "dd-mmm-yyyy");
                  return (
                    <>
                      {(() => {
                        if (i == 3) {
                          i = 1;
                          return getDiscussions?.length > 0 ? <AdSlider /> : "";
                        } else {
                          i++;
                        }
                      })()}
                      <div key={i}>
                        {" "}
                        <div className="shade1 reviewstops bg-grey-light review-discussion">
                          <div className="row">
                            <div className="col-12">
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    history.push(
                                      "/about-discussion/" + data?.slug + "",
                                      {
                                        discussion_id: data?.slug,
                                      }
                                    );
                                  }}
                                >
                                  <b>{data?.topic ? data?.topic : " "}</b>
                                </h6>
                              </div>
                              <div className="reviews_list mt-2">
                                {getProfileImg(data) !== null ||
                                getProfileImg(data) ? (
                                  <img
                                    src={baseurl + getProfileImg(data)}
                                    alt=""
                                    onError={(e: any) => {
                                      e.target.onerror = null;
                                      e.target.src = "/assets/img/noImage.jpeg";
                                    }}
                                  />
                                ) : (
                                  <img src="/assets/img/noImage.jpeg" alt="" />
                                )}
                                <div className="reviews_item">
                                  <h6>
                                    {" "}
                                    <a
                                      className={
                                        (data?.user_type === 4 &&
                                          data?.Counsellor?.slug) ||
                                        (data?.user_type === 5 &&
                                          data?.Coach?.slug)
                                          ? ""
                                          : "cursorPointer"
                                      }
                                      onClick={() => {
                                        if (
                                          data?.user_type === 4 &&
                                          data?.Counsellor &&
                                          data?.Counsellor?.slug
                                        ) {
                                          history.push(
                                            "/counsellor/" +
                                              data?.Counsellor?.slug
                                          );
                                        } else if (
                                          data.user_type === 5 &&
                                          data?.Coach &&
                                          data?.Coach?.slug
                                        ) {
                                          history.push(
                                            "/mentor/" + data?.Coach?.slug
                                          );
                                        } else {
                                          {
                                          }
                                        }
                                      }}
                                    >
                                      <b className="yellow">
                                        {data?.auther_name
                                          ? data?.auther_name
                                          : "Guest"}
                                      </b>
                                    </a>
                                    <span>
                                      {" "}
                                      <small>{date}</small>{" "}
                                    </span>
                                  </h6>
                                  <h6>
                                    <p className="profileeva">
                                      <i className="fa fa-tag"></i>{" "}
                                      <small
                                        className="pointer"
                                        onClick={(e: any) =>
                                          handleByTag(
                                            data?.category_id,
                                            data?.category?.category_name
                                          )
                                        }
                                      >
                                        {data?.category?.category_name}
                                      </small>
                                    </p>
                                  </h6>

                                  <a
                                    onClick={() => {
                                      history.push(
                                        "/about-discussion/" + data?.slug + "",
                                        {
                                          discussion_id: data?.slug,
                                        }
                                      );
                                    }}
                                  >
                                    <p className="pt-1 pb-1">
                                      {data?.description
                                        ? data?.description + " "
                                        : " "}
                                    </p>
                                  </a>
                                  <ul className="iconscomments">
                                    <li>
                                      <a
                                        onClick={(e) => {
                                          hendleLike(true, data?.id);
                                        }}
                                      >
                                        <i
                                          className={
                                            result == true
                                              ? "fa fa-thumbs-o-up active"
                                              : "fa fa-thumbs-o-up"
                                          }
                                          aria-hidden="false"
                                        ></i>
                                        {/* <img
                                        className="scale"
                                        src="assets/img/thumbs-up.png"
                                      /> */}{" "}
                                        {data?.likes ? data?.likes : "0"}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={(e) => {
                                          hendleCommentBox(
                                            "comment" + data?.id
                                          );
                                        }}
                                        tabIndex={0}
                                      >
                                        <img
                                          className="scale"
                                          src="assets/img/comment.png"
                                        />{" "}
                                        {/* {data?.comments ? data?.comments : "0"} */}
                                        {data?.DiscussionsComments?.length}
                                      </a>
                                    </li>
                                    <li>
                                      <span tabIndex={0}>
                                        <img
                                          className="scale"
                                          src="assets/img/file.png"
                                        />{" "}
                                        {data?.views ? data?.views : "0"}
                                      </span>
                                    </li>
                                  </ul>
                                  {"comment" + data?.id == CommentBox ? (
                                    <div className="program-search px-0">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="input-group mb-3">
                                            <input
                                              type="text"
                                              className="form-control rounded-0"
                                              placeholder="comment"
                                              autoComplete="off"
                                              value={discussionComment}
                                              onChange={(e: any) => {
                                                setdiscussionComment(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <button
                                                className={
                                                  discussionComment?.trim()
                                                    ?.length > 0
                                                    ? "btn btn-secondary"
                                                    : "btn btn-secondary discuDiseble"
                                                }
                                                type="button"
                                                onClick={(e) => {
                                                  hendleComment(data?.id);
                                                }}
                                              >
                                                comment
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <p
                                    style={{
                                      display:
                                        data?.DiscussionsComments?.length > 0
                                          ? ""
                                          : "none",
                                    }}
                                  >
                                    <b>
                                      {data?.DiscussionsComments?.length == 1
                                        ? "comment"
                                        : "comments"}
                                    </b>
                                  </p>
                                  {data?.DiscussionsComments &&
                                    data?.DiscussionsComments?.map(
                                      (Data: any, i: any) => {
                                        let commentdate = dateFormat(
                                          Data?.created_at,
                                          " dd-mmm-yyyy"
                                        );
                                        return (
                                          i < 1 && (
                                            <div
                                              className="reviews_list_new mt-2 mb-0"
                                              key={i}
                                            >
                                              <img
                                                src={
                                                  Data?.user_type == 1
                                                    ? Data?.Student
                                                        ?.profile_image
                                                      ? baseurl +
                                                        Data?.Student
                                                          ?.profile_image
                                                      : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                    : Data?.User?.profile_photo
                                                    ? baseurl +
                                                      Data?.User?.profile_photo
                                                    : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                }
                                                alt=""
                                              />
                                              <div className="reviews_item">
                                                <h6>
                                                  {" "}
                                                  <span className="color-green">
                                                    {(Data?.user_type == 1
                                                      ? Data?.Student?.name
                                                      : Data?.User?.name) ||
                                                      "Guest"}
                                                  </span>
                                                  <span>
                                                    <small>
                                                      {commentdate &&
                                                        commentdate}
                                                    </small>{" "}
                                                  </span>
                                                </h6>
                                                <p className="pt-1 pb-1">
                                                  {Data?.comments &&
                                                    Data?.comments}
                                                </p>
                                                <ul className="iconscomments subsomment pb-0"></ul>
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                  <div
                                    className="col-lg-10 text-right"
                                    style={{
                                      display:
                                        data?.DiscussionsComments?.length > 0
                                          ? ""
                                          : "none",
                                    }}
                                  >
                                    <a
                                      className="view_comment"
                                      onClick={() => {
                                        history.push(
                                          "/about-discussion/" +
                                            data?.slug +
                                            "",
                                          {
                                            discussion_id: data?.slug,
                                          }
                                        );
                                      }}
                                    >
                                      <b> view more</b>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        {/* <AdSlider /> */}
                      </div>
                    </>
                  );
                })}

              {getDiscussions &&
                getDiscussions?.map((data: any, i: any) => {
                  const result: any = data?.DiscussionLikes?.some(
                    (obj: any) => obj?.student_id == user?.id && user?.id
                  );
                  let date = dateFormat(data?.created_at, "dd-mmm-yyyy");
                  return (
                    i > 3 &&
                    i < loadmore &&
                    i < offset && (
                      <>
                        <div
                          className="shade1 reviewstops bg-grey-light review-discussion"
                          key={i}
                        >
                          <div className="row">
                            <div className="col-12">
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    history.push(
                                      "/about-discussion/" + data?.slug + "",
                                      {
                                        discussion_id: data?.slug,
                                      }
                                    );
                                  }}
                                >
                                  <b>{data?.topic ? data?.topic : ""}</b>
                                </h6>
                              </div>
                              <div className="reviews_list mt-2">
                                {getProfileImg(data) !== null ? (
                                  <img
                                    src={baseurl + getProfileImg(data)}
                                    alt=""
                                    onError={(e: any) => {
                                      e.target.onerror = null;
                                      e.target.src = "/assets/img/noImage.jpeg";
                                    }}
                                  />
                                ) : (
                                  <img src="/assets/img/noImage.jpeg" alt="" />
                                )}

                                <div className="reviews_item">
                                  <a
                                    className={
                                      data?.user_type === 4 ||
                                      data?.user_type === 5
                                        ? ""
                                        : "cursorPointer"
                                    }
                                    onClick={() => {
                                      if (
                                        data?.user_type === 4 &&
                                        data?.Counsellor &&
                                        data?.Counsellor?.slug
                                      ) {
                                        history.push(
                                          "/counsellor/" +
                                            data?.Counsellor?.slug
                                        );
                                      } else if (
                                        data?.user_type === 5 &&
                                        data?.Coach &&
                                        data?.Coach?.slug
                                      ) {
                                        history.push(
                                          "/mentor/" + data?.Coach?.slug
                                        );
                                      } else {
                                        {
                                        }
                                      }
                                    }}
                                  >
                                    <h6>
                                      {" "}
                                      <b className="yellow">
                                        {data?.auther_name
                                          ? data?.auther_name
                                          : "Guest"}
                                      </b>
                                      <span>
                                        {" "}
                                        <small>{date}</small>{" "}
                                      </span>
                                    </h6>
                                  </a>
                                  <h6>
                                    <p className="profileeva">
                                      <i className="fa fa-tag"></i>{" "}
                                      <small
                                        className="pointer"
                                        onClick={(e: any) =>
                                          handleByTag(
                                            data?.category_id,
                                            data?.category?.category_name
                                          )
                                        }
                                      >
                                        {data?.category?.category_name &&
                                          data?.category?.category_name}
                                      </small>
                                    </p>
                                  </h6>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        "/about-discussion/" + data?.slug + "",
                                        {
                                          discussion_id: data?.slug,
                                        }
                                      );
                                    }}
                                  >
                                    <p className="pt-1 pb-1">
                                      {data?.description
                                        ? data?.description + " "
                                        : " "}
                                    </p>
                                  </a>
                                  <ul className="iconscomments">
                                    <li>
                                      <a
                                        onClick={(e) => {
                                          hendleLike(true, data?.id);
                                        }}
                                      >
                                        <i
                                          className={
                                            result == true
                                              ? "fa fa-thumbs-o-up active"
                                              : "fa fa-thumbs-o-up"
                                          }
                                          aria-hidden="false"
                                        ></i>{" "}
                                        {data?.likes ? data?.likes : "0"}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={(e) => {
                                          hendleCommentBox(
                                            "comment" + data?.id
                                          );
                                        }}
                                        tabIndex={0}
                                      >
                                        <img
                                          className="scale"
                                          src="assets/img/comment.png"
                                        />{" "}
                                        {data?.comments ? data?.comments : "0"}
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" tabIndex={0}>
                                        <img
                                          className="scale"
                                          src="assets/img/file.png"
                                        />{" "}
                                        {data?.views ? data?.views : "0"}
                                      </a>
                                    </li>
                                  </ul>

                                  {"comment" + data?.id == CommentBox ? (
                                    <div className="program-search px-0">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="input-group mb-3">
                                            <input
                                              type="text"
                                              name={"comment" + data?.id}
                                              className="form-control rounded-0"
                                              placeholder="comment"
                                              autoComplete="off"
                                              value={discussionComment}
                                              onChange={(e: any) => {
                                                setdiscussionComment(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <button
                                                className={
                                                  discussionComment?.trim()
                                                    ?.length > 0
                                                    ? "btn btn-secondary"
                                                    : "btn btn-secondary discuDiseble"
                                                }
                                                type="button"
                                                onClick={(e) => {
                                                  hendleComment(data?.id);
                                                }}
                                              >
                                                comment
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    " "
                                  )}

                                  {data?.DiscussionsComments &&
                                    data?.DiscussionsComments?.map(
                                      (Data: any, i: any) => {
                                        let commentdate = dateFormat(
                                          Data?.created_at,
                                          " dd-mmm-yyyy"
                                        );
                                        return (
                                          i < 1 && (
                                            <div
                                              className="reviews_list_new mt-2 mb-0"
                                              key={i}
                                            >
                                              <img
                                                src={
                                                  Data?.user_type == 1
                                                    ? Data?.Student
                                                        ?.profile_image
                                                      ? baseurl +
                                                        Data?.Student
                                                          ?.profile_image
                                                      : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                    : Data?.User?.profile_photo
                                                    ? baseurl +
                                                      Data?.User?.profile_photo
                                                    : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                }
                                                alt=""
                                              />
                                              <div className="reviews_item">
                                                <h6>
                                                  {" "}
                                                  <span className="color-green">
                                                    {(Data?.user_type == 1
                                                      ? Data?.Student?.name
                                                      : Data?.User?.name) ||
                                                      "Guest"}
                                                  </span>
                                                  <span>
                                                    <small>
                                                      {commentdate &&
                                                        commentdate}
                                                    </small>{" "}
                                                  </span>
                                                </h6>
                                                <p className="pt-1 pb-1">
                                                  {Data?.comments &&
                                                    Data?.comments}
                                                </p>
                                                <ul className="iconscomments subsomment pb-0"></ul>
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                  <div
                                    className="col-lg-10 text-right"
                                    style={{
                                      display:
                                        data?.DiscussionsComments?.length > 0
                                          ? ""
                                          : "none",
                                    }}
                                  >
                                    <a
                                      className="view_comment"
                                      onClick={() => {
                                        history.push(
                                          "/about-discussion/" +
                                            data?.slug +
                                            "",
                                          {
                                            discussion_id: data?.slug,
                                          }
                                        );
                                      }}
                                    >
                                      <b> view more</b>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  );
                })}
              {getDiscussions?.length > 0 ? <AdSlider /> : ""}
              {getDiscussions?.length == 50 ? (
                <div className="all-center my-2">
                  <a
                    onClick={(e) => {
                      handleLoadMore(e);
                    }}
                    className="view-more readbtn"
                  >
                    Load More
                  </a>
                </div>
              ) : (
                ""
              )}

              {seo?.footer_content && (
                <div className="about-uni-text shade1 mt-5">
                  <SeoFooterContent />
                </div>
              )}
            </div>
          </div>
          {openStart && (
            <div
              id="show_add_discussion_form"
              className="modal fade offer_detailpopup"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog"
                role="document"
                style={{ maxWidth: "600px" }}
              >
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="off-detail-item">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className=" mt-3">
                        <h5>
                          <strong>start new discussion</strong>
                        </h5>
                        <hr></hr>
                      </div>
                      <div className="profile_field fields-wrp discussion-form">
                        <form id="discussion" className="row">
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <select
                                className="form-control c-select"
                                name="category_id"
                                id="category_id"
                                onChange={(e) => {
                                  handleReview(e, "category");
                                }}
                              >
                                <option>category</option>
                                {activegetDiscussionCategory &&
                                  activegetDiscussionCategory?.map(
                                    (data: any, i: any) => {
                                      if (data?.parent_id == 0)
                                        return (
                                          <option value={data?.id}>
                                            {data?.category_name}
                                          </option>
                                        );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                              <select
                                className="form-control c-select"
                                name="sub_category_id"
                                id="sub_category_id"
                                onChange={(e) => {
                                  handleReview(e);
                                }}
                              >
                                <option>sub category</option>
                                {activegetDiscussionCategory &&
                                  activegetDiscussionCategory?.map(
                                    (data: any, i: any) => {
                                      if (data?.parent_id != 0)
                                        return (
                                          <option
                                            style={{
                                              display:
                                                CategoryParentId ==
                                                data?.parent_id
                                                  ? "block"
                                                  : "none",
                                            }}
                                            value={data?.id}
                                          >
                                            {data?.category_name}
                                          </option>
                                        );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12">
                            <div className="form-group field">
                              <input
                                type="text"
                                className="form-control c-select"
                                name="topic"
                                id="topic"
                                onChange={(e) => {
                                  handleReview(e);
                                }}
                              />
                              <label>topic</label>
                              <span className="fa fa-pencil p-2"></span>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                              <textarea
                                className="form-control c-select"
                                placeholder="details"
                                name="description"
                                id="description"
                                onChange={(e) => {
                                  handleReview(e);
                                }}
                                rows="7"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-12">
                            <div className="fieldbtn text-center mb-3">
                              <input
                                type="button"
                                onClick={(e) => {
                                  submitDiscussion("discussion");
                                }}
                                className="bggreadent discussion_btn"
                                value="submit"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content offer_model">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    Get
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code: <span>{modelData[0]?.coupon_code}</span>{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscussionList;
