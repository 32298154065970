import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { scholershipAndOfferAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import moment from "moment";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import SocialShareButton from "../SocialShareButton";

function OffersScholarship() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [title, settitle] = useState<any>("offers-and-scholarship");

  const baseurl = URL.API_BASE_URL;
  const [modelData, setmodelData] = useState<any>("");

  const getAllScholarship: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getAllScholarship
      ? state.scholershipAndOffer.getAllScholarship
      : []
  );
  const getAllCoupon: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getAllCoupon
      ? state.scholershipAndOffer.getAllCoupon
      : []
  );
  const appliedScholarData: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.appliedScholarShip
      ? state.scholershipAndOffer.appliedScholarShip
      : []
  );

  const hendleApplyNow = (e: any, id: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      let _dt = {
        student_id: user?.id,
        scholarship_id: id,
      };
      dispatch(scholershipAndOfferAction.applyScholar(_dt));
    }
  };

  const activeScholarShip =
    getAllScholarship &&
    getAllScholarship?.filter((data: any) => {
      return data?.status == 1;
    });
  const activeCoupon =
    getAllCoupon &&
    getAllCoupon?.filter((data: any) => {
      return data?.status == 1;
    });

  const opneModel = (e: any) => {
    const modeldata =
      getAllCoupon &&
      getAllCoupon?.filter((data: any) => {
        return data?.id == e;
      });
    setmodelData(modeldata);
  };

  const hadleDisableApply = (sch_id: any) => {
    let _dt = appliedScholarData.find((ele) => ele?.scholarship_id == sch_id);
    if (_dt) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    dispatch(scholershipAndOfferAction.scholarshipGetAllDetails({}));
    if (user?.id) {
      dispatch(
        scholershipAndOfferAction.getAppliedScholar({ student_id: user?.id })
      );
    }
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getAllCoupon({}));
    return () => {};
  }, []);

  return (
    <div>
      <Seo slug={window.location.pathname} />
      <section
        className="headslider simpleslider_wrp"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont owl-carousel111">
            <div>
              <img className="scale" src="assets/img/offer-header.jpg" alt="" />
              <div className="content simpleslider_head">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="textwrp">
                        <h4 className="yellow">
                          <b>offers & scholarship</b>
                        </h4>
                        <p className="text-white">
                          <b>
                            Discover how we achieve upto 100% Fee Scholarships
                            to the best international universities for our
                            students. Learn their stories!
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="categories section">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline">
              Admissify Offers & Scholarship
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33.333"
                  height="33.667"
                  viewBox="0 0 33.333 33.667"
                >
                  <path
                    id="Icon_material-local-offer"
                    data-name="Icon material-local-offer"
                    d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                    transform="translate(-3 -3)"
                    fill="#fbb415"
                  />
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
              Wondering how to apply direct to study abroad? Download the
              admissify app & apply for cashback on tuition & accommodation
              fees!{" "}
            </p>
          </div>
          <div className="row all-center sub-section pb-0 offer_sholarship">
            <div className="col-md-12 all-center pb-5 skew-tabs2">
              <ul className="nav nav-tabs" role="tablist">
                <li>
                  <a
                    className="active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                  >
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="35.929"
                          height="35.929"
                          viewBox="0 0 35.929 35.929"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_3279"
                                data-name="Rectangle 3279"
                                width="40.929"
                                height="35.929"
                                transform="translate(0 0)"
                                fill="#a6a6a6"
                                stroke="#272725"
                                strokeWidth="1"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_5"
                            data-name="Mask Group 5"
                            transform="translate(-2 0)"
                            clip-path="url(#clip-path)"
                          >
                            <g id="discount" transform="translate(0 0)">
                              <path
                                id="Path_6878"
                                data-name="Path 6878"
                                d="M14.55-17.911a4.014,4.014,0,0,0-1.46.767l-.439.335-1.244.007-1.244.007-.581.283a3.525,3.525,0,0,0-1.757,1.869l-.372.774-.626.313A3.611,3.611,0,0,0,4.46-9.533l.082.685-.454.678a3.726,3.726,0,0,0-.827,2.286,3.411,3.411,0,0,0,.752,2.219l.424.573v1.154A3.371,3.371,0,0,0,5,.438a3.862,3.862,0,0,0,1.75,1.378l.6.253.447.841A3.719,3.719,0,0,0,9.553,4.794a3.6,3.6,0,0,0,2.174.328l.685-.074.745.484a3.656,3.656,0,0,0,4.669-.112l.424-.32.841.052a3.563,3.563,0,0,0,3.924-2.2l.335-.737.812-.417A3.586,3.586,0,0,0,26.39-2.131l-.082-.663.447-.7a8.015,8.015,0,0,0,.6-1.08,3.57,3.57,0,0,0-.581-3.537l-.417-.551.052-.938a4.056,4.056,0,0,0-.082-1.422,3.725,3.725,0,0,0-2.584-2.629c-.238-.1-.32-.216-.685-.923A3.63,3.63,0,0,0,20.753-16.7a3.4,3.4,0,0,0-1.43-.112l-.923.052-.685-.447a7.553,7.553,0,0,0-1.1-.6A4.054,4.054,0,0,0,14.55-17.911Zm1.765,1.6c.186.1.64.372,1.005.626.745.5.685.491,2.048.38.722-.052.819-.045,1.154.1.566.246.827.551,1.355,1.594s.514,1.028,1.6,1.46a2.7,2.7,0,0,1,1.273,1.08c.156.29.164.387.141,1.616l-.022,1.3.462.6a2.441,2.441,0,0,1,.752,1.7c0,.566-.1.819-.76,1.824a1.972,1.972,0,0,0-.439,1.966A2.257,2.257,0,0,1,24.848-.94c-.194.663-.558,1.005-1.69,1.579-1.035.529-.961.447-1.407,1.519A2.243,2.243,0,0,1,20.2,3.64a5.9,5.9,0,0,1-1.3.007,6.991,6.991,0,0,0-1.08-.022,7.568,7.568,0,0,0-.767.514,2.576,2.576,0,0,1-1.631.7,2.694,2.694,0,0,1-1.616-.655c-1.035-.693-1.043-.7-1.675-.6-1.817.261-2.39-.052-3.254-1.78L8.429.914,7.61.557A2.617,2.617,0,0,1,6.09-.62c-.156-.29-.164-.387-.141-1.586l.015-1.273-.253-.365C5-4.835,4.854-5.088,4.78-5.453c-.141-.64-.015-1.035.67-2.078a4.3,4.3,0,0,0,.588-1.1c0-.1-.037-.529-.082-.938-.171-1.437.156-1.951,1.757-2.778l.968-.5.305-.745a2.87,2.87,0,0,1,.707-1.162c.551-.566.8-.626,2.227-.566l1.1.052.365-.261c.194-.141.529-.372.737-.521a3.782,3.782,0,0,1,.67-.38A2.2,2.2,0,0,1,16.315-16.31Z"
                                transform="translate(3.606 17.998)"
                                fill="#a6a6a6"
                              />
                              <path
                                id="Path_6879"
                                data-name="Path 6879"
                                d="M14.033-15.6a2.273,2.273,0,0,0-1.221.834c-.491.715-6.054,10.448-6.143,10.745A2.278,2.278,0,0,0,7.882-1.4,2.35,2.35,0,0,0,9.8-1.432a2.582,2.582,0,0,0,.655-.484c.417-.506,6.233-10.663,6.33-11.058A2.25,2.25,0,0,0,14.033-15.6Zm1.1,1.638c.395.4.447.29-2.785,5.89a51.74,51.74,0,0,1-3.187,5.28.736.736,0,0,1-1.072-.566c-.03-.223.268-.782,2.912-5.354,1.623-2.807,3.008-5.16,3.075-5.242A.713.713,0,0,1,15.135-13.957Z"
                                transform="translate(7.317 20.598)"
                                fill="#a6a6a6"
                              />
                              <path
                                id="Path_6880"
                                data-name="Path 6880"
                                d="M7.217-15.095a2.329,2.329,0,0,0-1.258,1.184,1.738,1.738,0,0,0-.238,1.057,2.312,2.312,0,0,0,1.363,2.137,2.477,2.477,0,0,0,1.981.022A2.4,2.4,0,0,0,9.138-15,2.716,2.716,0,0,0,7.217-15.095Zm1.482,1.6a.647.647,0,0,1,.253.558,1.347,1.347,0,0,1-.112.521,1.035,1.035,0,0,1-.8.454.992.992,0,0,1-.774-.573.782.782,0,0,1,.238-.983.734.734,0,0,1,.6-.231A.655.655,0,0,1,8.7-13.494Z"
                                transform="translate(6.352 21.095)"
                                fill="#a6a6a6"
                              />
                              <path
                                id="Path_6881"
                                data-name="Path 6881"
                                d="M11.834-11.439a2.3,2.3,0,0,0-1.765,2.45,2.144,2.144,0,0,0,.663,1.534,2,2,0,0,0,1.705.64,1.724,1.724,0,0,0,1.1-.216,2.337,2.337,0,0,0,1.236-2.13,2.28,2.28,0,0,0-1.795-2.271A1.731,1.731,0,0,0,11.834-11.439Zm.938,1.489a.877.877,0,0,1,.447,1.191.865.865,0,0,1-.864.491A.647.647,0,0,1,11.8-8.52a.65.65,0,0,1-.253-.588.883.883,0,0,1,.551-.841A.652.652,0,0,1,12.773-9.949Z"
                                transform="translate(11.206 25.221)"
                                fill="#a6a6a6"
                              />
                              <path
                                id="Path_6882"
                                data-name="Path 6882"
                                d="M21.986-5.624c-3.7.424-3.686.424-6.441.022a22.741,22.741,0,0,0-3.515-.305l-1.7-.037-.261.261a.627.627,0,0,0-.134.879.753.753,0,0,0,.313.283,14.38,14.38,0,0,0,1.795.1,20.77,20.77,0,0,1,3.1.261c3.075.469,2.837.469,6.97.022,3.619-.395,3.626-.395,3.872-.253a.777.777,0,0,1,.357.908c-.127.313-.5.544-1.646,1.02-1.1.462-3.962,1.422-4.833,1.631-.678.164-.968.41-.968.8a.833.833,0,0,0,.566.745A20.315,20.315,0,0,0,24.018-.6c2.063-.774,2.919-1.206,3.329-1.7.171-.2.32-.253,1.512-.544.722-.171,2.51-.6,3.969-.961s2.748-.64,2.859-.64a.875.875,0,0,1,.827,1.258c-.141.268-.343.395-1.095.685-.343.134-1.817.722-3.269,1.311-7.879,3.209-9.83,3.932-11.319,4.2a40.511,40.511,0,0,1-6.121-.037,9.781,9.781,0,0,0-2.644-.03,3.809,3.809,0,0,0-.812,1.065,5.708,5.708,0,0,1-.789,1.057,33.276,33.276,0,0,1-4.431.1H1.775l-.149-.194a.74.74,0,0,1-.156-.38,23.715,23.715,0,0,1,1.311-2.5A23.776,23.776,0,0,1,5.022-1.521,2.319,2.319,0,0,0,5.387-2.1.748.748,0,0,0,4.493-3C4.032-2.884,3.22-1.722,1.6,1.145-.071,4.116.018,3.908.018,4.608a1.57,1.57,0,0,0,.223,1,1.837,1.837,0,0,0,1.132.946A88.082,88.082,0,0,0,11,6.5c.447-.2.73-.5,1.281-1.348l.529-.812.305.037c.171.022,1.608.1,3.195.164a27.306,27.306,0,0,0,3.842.037c2.033-.186,3.358-.64,11.751-4.051,2.174-.886,4.155-1.69,4.393-1.772A2.742,2.742,0,0,0,37.5-2.027a2.3,2.3,0,0,0-.715-3.619c-.856-.417-1.117-.387-5.339.64-1.958.477-3.567.856-3.582.849s-.067-.179-.141-.372a2.553,2.553,0,0,0-1.08-1.206C26.051-6.056,25.612-6.041,21.986-5.624Z"
                                transform="translate(-0.017 31.447)"
                                fill="#a6a6a6"
                              />
                              <path
                                id="Path_6883"
                                data-name="Path 6883"
                                d="M3.331-5.325a.742.742,0,0,0,0,1.028.852.852,0,0,0,.983.082.827.827,0,0,0,.007-1.177A.83.83,0,0,0,2.331-4.325Z"
                                transform="translate(3.454 31.93)"
                                fill="#a6a6a6"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      offers
                    </p>
                  </a>
                </li>
                <li>
                  <a className="" data-toggle="tab" href="#tabs-2" role="tab">
                    <p>
                      scholarship{" "}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35.929"
                          height="35.968"
                          viewBox="0 0 35.929 35.968"
                        >
                          <g id="advisory" transform="translate(0 0)">
                            <g
                              id="education_1_"
                              data-name="education (1)"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_6884"
                                data-name="Path 6884"
                                d="M64.633-508.416c-3.457,1.975-6.352,3.668-6.437,3.766a.64.64,0,0,0-.007.836c.091.1,1.349.85,2.8,1.679l2.635,1.5.035,2.593c.035,2.445.042,2.607.176,2.839a57.251,57.251,0,0,0,6.4,4.181,3.216,3.216,0,0,0,1.75,0,56.392,56.392,0,0,0,6.387-4.181c.141-.232.148-.387.183-2.832l.035-2.593,1.651-.942c.906-.52,1.848-1.061,2.094-1.195l.436-.246v2.235l-.351.162a2.8,2.8,0,0,0-1.145,1.174c-.155.33-.155.4-.176,3.6a25.854,25.854,0,0,0,.063,3.464c.162.4.2.408,2.326.408h1.946l.2-.169.2-.169v-3.4c0-3.338,0-3.4-.155-3.724a2.775,2.775,0,0,0-1.145-1.181l-.351-.162v-1.848c0-1.813,0-1.848-.155-2.024C83.781-504.917,71.337-512,71.1-512,70.978-512,68.688-510.728,64.633-508.416Zm11.349.7c2.614,1.49,4.743,2.726,4.75,2.754s-1.722.056-3.83.056h-3.83l-.07-.176a2.586,2.586,0,0,0-1.068-1.019,2.272,2.272,0,0,0-1.665,0,2.309,2.309,0,0,0-1.174,1.321,2.175,2.175,0,0,0,1.11,2.523,3.044,3.044,0,0,0,1.806,0,2.607,2.607,0,0,0,.956-.963l.126-.281h7.673L75.96-500.75c-2.719,1.553-4.856,2.733-4.919,2.712-.232-.07-10.793-6.148-10.779-6.2.028-.105,10.73-6.2,10.857-6.191C71.189-510.426,73.375-509.2,75.982-507.714Zm-4.469,3a.688.688,0,0,1,.035,1.075.7.7,0,0,1-1.124-.436.646.646,0,0,1,.422-.724A.609.609,0,0,1,71.512-504.713Zm-3.492,6.591c1.6.906,2.972,1.651,3.064,1.651.225,0,.351-.07,3.38-1.8,1.441-.829,2.635-1.5,2.656-1.5s.028.864.028,1.918v1.925l-2.684,1.743c-2.916,1.9-3.1,2-3.7,1.834a24.566,24.566,0,0,1-3.015-1.834l-2.691-1.75v-1.918c0-1.054.014-1.918.035-1.918S66.432-499.028,68.02-498.122Zm16.014-1.166c.415.288.422.358.422,3.253v2.656H82.489v-2.635a14.587,14.587,0,0,1,.1-2.874,1.088,1.088,0,0,1,.639-.555A1.173,1.173,0,0,1,84.034-499.288Z"
                                transform="translate(-53.994 512)"
                                fill="#b1b1b1"
                              />
                              <path
                                id="Path_6885"
                                data-name="Path 6885"
                                d="M33.224-204.353a2.979,2.979,0,0,0-.7.253c-.141.091-1.567,1.279-3.162,2.649s-2.909,2.48-2.923,2.466a3.23,3.23,0,0,1-.141-.4,2.982,2.982,0,0,0-1.848-1.771c-.429-.133-.646-.141-3.331-.141H18.243l-.583-.365a11.2,11.2,0,0,0-4.019-1.581,12.215,12.215,0,0,0-3.359.007,8.3,8.3,0,0,0-2.951,1.209,3.036,3.036,0,0,1-.358.239,1.1,1.1,0,0,1-.07-.3,1.462,1.462,0,0,0-.654-.97c-.267-.169-.267-.169-2.551-.169s-2.284,0-2.551.169a1.848,1.848,0,0,0-.457.45l-.19.288v11.327l.19.288a1.848,1.848,0,0,0,.457.45c.26.169.3.169,2.389.19a18.238,18.238,0,0,0,2.41-.056,1.5,1.5,0,0,0,.569-.358l.274-.274H9.53c2.937,0,3.022-.007,3.2-.358a.671.671,0,0,0,.014-.661c-.169-.379-.246-.386-3.148-.386H6.93v-7.708l.3-.281a6.709,6.709,0,0,1,5.432-1.778,8.878,8.878,0,0,1,4.265,1.469l.949.569h3.015c2.762,0,3.036.014,3.3.133a1.538,1.538,0,0,1,.878,1.384,1.668,1.668,0,0,1-.731,1.188l-.344.211-3.935.021c-4.343.021-4.181.007-4.336.471a.58.58,0,0,0,.028.492c.2.464.162.457,4.237.457,2,0,3.83-.028,4.062-.07.794-.126,1.089-.344,5.172-3.865,2.129-1.834,3.921-3.366,3.984-3.4a1.27,1.27,0,0,1,1.708.548,1.176,1.176,0,0,1,0,1.061c-.091.19-1.3,1.441-3.738,3.872-3.879,3.858-4.146,4.09-5.362,4.617-1.258.541-1.412.562-4.61.59a26.66,26.66,0,0,0-3.043.105.742.742,0,0,0-.4.618.763.763,0,0,0,.386.618,45.746,45.746,0,0,0,6.043-.021,8.965,8.965,0,0,0,2.452-.738c1.286-.618,1.448-.759,5.565-4.877,3.7-3.7,3.886-3.893,4.033-4.293a2.654,2.654,0,0,0,.077-1.785A2.623,2.623,0,0,0,33.224-204.353Zm-27.7,7.694v5.2H1.87v-10.4H5.524Z"
                                transform="translate(-0.5 226.031)"
                                fill="#b1b1b1"
                              />
                              <path
                                id="Path_6886"
                                data-name="Path 6886"
                                d="M201.555-29.773a.7.7,0,0,0,.344,1.307.744.744,0,0,0,.7-.7A.708.708,0,0,0,201.555-29.773Z"
                                transform="translate(-187.107 63.741)"
                                fill="#b1b1b1"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </p>
                  </a>
                </li>
              </ul>
              {/* <!-- Tab panes --> */}
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  <div className="offers_list">
                    {activeCoupon &&
                      activeCoupon?.map((data: any, i: any) => {
                        console.log(data);

                        return (
                          <div className="offers-item">
                            <figure className="feat-text">
                              {data?.coupon_icon !== null ? (
                                <img
                                  src={baseurl + data?.coupon_icon}
                                  alt=""
                                  width="100%"
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  src="/assets/img/noImage.jpeg"
                                  alt=""
                                  width="100%"
                                />
                              )}
                            </figure>
                            <figcaption className="content-box">
                              <div className="bttns">
                                <a
                                  onClick={(e) => {
                                    opneModel(data?.id);
                                  }}
                                  data-toggle="modal"
                                  data-target="#offer-pop"
                                  className="mt_btn_yellow"
                                >
                                  view details
                                </a>

                                <a href="" className="tnc">
                                  t&c apply
                                </a>
                              </div>
                              <h3>
                                Get{" "}
                                <span>
                                  {data?.coupon_type == 1
                                    ? data?.max_off + "%"
                                    : data?.max_off}{" "}
                                  OFF
                                </span>{" "}
                                {data?.coupon_title?.length > 16
                                  ? `${data?.coupon_title.substring(0, 15)}.`
                                  : data?.coupon_title}
                              </h3>
                            </figcaption>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  className="tab-pane sholarship-wrp"
                  id="tabs-2"
                  role="tabpanel"
                >
                  <div className="offers_list">
                    {activeScholarShip &&
                      activeScholarShip?.map((data: any, i: any) => {
                        console.log(data);

                        return (
                          <div className="s-item">
                            <figcaption className="content-head bggreadent-rev">
                              <div className="hadsec">
                                {data?.sch_icon !== null ? (
                                  <img
                                    src={baseurl + data?.sch_icon}
                                    alt=""
                                    onError={(e: any) => {
                                      e.target.onerror = null;
                                      e.target.src = "/assets/img/noImage.jpeg";
                                    }}
                                  />
                                ) : (
                                  <img src="/assets/img/noImage.jpeg" alt="" />
                                )}

                                <h6>
                                  {data?.sch_title && data?.sch_title}
                                  {/* WMG Excellence Scholarships 2021 */}
                                </h6>
                              </div>
                            </figcaption>
                            <figure className="feat-text">
                              <div
                                className="ScholarshipAboutimg"
                                style={{
                                  background: `url(${
                                    data?.sch_image !== null
                                      ? baseurl + data?.sch_image
                                      : "/assets/img/noImage.jpeg"
                                  })`,
                                }}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.style.background =
                                    "/assets/img/noImage.jpeg";
                                }}
                              >
                                {/* { data?.sch_image !== null ?
                        <img
                          className="scale"
                          src={baseurl + data?.sch_image} onError={(e:any)=>{e.target.onerror = null; e.target.src="/assets/img/noImage.jpeg"}}
                        />:
                        <img
                          className="scale"
                          src="assets/img/sholarship01.jpg"
                        />} */}
                              </div>
                            </figure>
                            <figcaption className="content-box">
                              <h3>
                                <span>Award</span>
                                {data?.awards && data?.awards}
                                {/* Certificate of completion of the
                          PDR Programm */}
                                <p className="text-left text-white pt-10 mt-4 ml-1">
                                  <small>
                                    {data?.apply_end_date != null
                                      ? Math.floor(
                                          moment(data?.apply_end_date).diff(
                                            new Date(),
                                            "days",
                                            true
                                          )
                                        ) + " day to go"
                                      : ""}
                                  </small>
                                </p>
                              </h3>

                              <h3>
                                <span>Eligibility</span>
                                <p className="eligibility_content">
                                  {data?.eligibility && data?.eligibility}
                                </p>
                                {/* Students pursuing
                          non-professional courses at UG */}
                                <div className="bttns">
                                  <a
                                    onClick={() => {
                                      history.push(
                                        "/scholarships/" + data?.sch_slug
                                      );
                                    }}
                                    className="mt_btn_yellow"
                                  >
                                    view details
                                  </a>
                                  {/* <Link
                              to={"/scholarships"}
                              className="mt_btn_yellow"
                            >
                              view details
                            </Link> */}
                                </div>
                              </h3>
                            </figcaption>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content offer_model">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.coupon_icon !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.coupon_icon}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    Get{" "}
                    <span>
                      {modelData[0]?.coupon_type == 1
                        ? modelData[0]?.max_off + "%"
                        : modelData[0]?.max_off}{" "}
                    </span>
                    {modelData[0]?.coupon_title}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code: {modelData[0]?.coupon_code}{" "}
                  </p>
                  <p className="text-right">
                    exp: {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}{" "}
                  </p>
                  <p className="text-left">{modelData[0]?.description}</p>
                  <div className="text-right pt-2 mb-5 pb-1 px-2">
                    <SocialShareButton urlsforShare={urlsforShare} data={""} />
                  </div>
                  <div className="bttns text-right">
                    <a
                      onClick={(e: any) => hendleApplyNow(e, modelData[0]?.id)}
                      className={
                        hadleDisableApply(modelData[0]?.id)
                          ? "applybtn bggreadent disableApply"
                          : "applybtn bggreadent"
                      }
                    >
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffersScholarship;
