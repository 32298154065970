
import React, { useState, useEffect } from "react";
import { blogAction } from '../../redux/common/action'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
const Articals = () => {
  const isAuthenticated: any = getToken();
  const baseurl = URL.API_BASE_URL;
  const dispatch = useDispatch();
  const history = useHistory();

  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const country: any = useSelector((state: any) => state.country.countryDetail ? state.country.countryDetail : {});

  const countryBLog: any = useSelector((state: any) =>
    state.blog.blogCountryFindAll ? state.blog.blogCountryFindAll : []
  );


  const countryBlogCat: any = useSelector((state: any) =>
    state.blog.blogCountryCateFindAll ? state.blog.blogCountryCateFindAll : []
  );

  useEffect(() => {
    dispatch(
      blogAction.getCountryBlog({
        CountryId: country?.id,
        CategoryId: "",
        Offset: 0,
        Limit: 3
      })
    );
    return () => { }
  }, [country?.id])


  useEffect(() => {
    dispatch(
      blogAction.getCountryCategory({
        CountryId: country?.id,
        Offset: 0,
        Limit: 3
      })
    );
    return () => { }
  }, [country?.id])

  const hendlClicktopics = (id: any) => {
    dispatch(
      blogAction.getCountryBlog({
        CountryId: country?.id,
        CategoryId: id ? id : "",
        Offset: 0,
        Limit: 3
      })
    );
    return () => { };
  };
  return (
    <div className='course-offer-uni'>
      <div className="row">
        <div className="col-12">
          <div className="headingmains text-center">
            {countryBlogCat?.categories?.length > 0 ?
              <h2 className="titlewithline titlewithline01 yellow uniDetailsH2">
                articles section{" "}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.5"
                    height="31"
                    viewBox="0 0 27.5 31"
                  >
                    <g id="Accept" transform="translate(-40 -8)">
                      <path
                        id="Path_6334"
                        data-name="Path 6334"
                        d="M69,46H48V16H65l4,4Z"
                        transform="translate(-7.5 -7.5)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6335"
                        data-name="Path 6335"
                        d="M320,16v4h4"
                        transform="translate(-262.5 -7.5)"
                        fill="#cca400"
                      />
                      <g
                        id="Group_4345"
                        data-name="Group 4345"
                        transform="translate(43 14.5)"
                      >
                        <path
                          id="Path_6336"
                          data-name="Path 6336"
                          d="M88,112h16v2H88Z"
                          transform="translate(-88 -112)"
                          fill="#047475"
                        />
                        <path
                          id="Path_6337"
                          data-name="Path 6337"
                          d="M88,176h16v2H88Z"
                          transform="translate(-88 -172)"
                          fill="#047475"
                        />
                        <path
                          id="Path_6338"
                          data-name="Path 6338"
                          d="M88,240h16v2H88Z"
                          transform="translate(-88 -232)"
                          fill="#047475"
                        />
                        <ellipse
                          id="Ellipse_154"
                          data-name="Ellipse 154"
                          cx="5"
                          cy="5.5"
                          rx="5"
                          ry="5.5"
                          transform="translate(13 11.5)"
                          fill="#047475"
                        />
                      </g>
                      <path
                        id="Path_6339"
                        data-name="Path 6339"
                        d="M67,464H64.5a.5.5,0,0,0,0,1H67a.5.5,0,0,0,0-1Z"
                        transform="translate(-22.5 -427.5)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6340"
                        data-name="Path 6340"
                        d="M129,464h-.5a.5.5,0,0,0,0,1h.5a.5.5,0,1,0,0-1Z"
                        transform="translate(-82.5 -427.5)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6341"
                        data-name="Path 6341"
                        d="M80.5,107h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,107Zm.5-2H96v1H81Z"
                        transform="translate(-37.5 -90)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6342"
                        data-name="Path 6342"
                        d="M80.5,171h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,171Zm.5-2H96v1H81Z"
                        transform="translate(-37.5 -150)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6343"
                        data-name="Path 6343"
                        d="M80.5,235h16a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,80.5,235Zm.5-2H96v1H81Z"
                        transform="translate(-37.5 -210)"
                        fill="#fbb415"
                      />
                      <path
                        id="Path_6344"
                        data-name="Path 6344"
                        d="M90,296H80.5a.5.5,0,0,0,0,1H90a.5.5,0,1,0,0-1Z"
                        transform="translate(-37.5 -270)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6345"
                        data-name="Path 6345"
                        d="M87,320H80.5a.5.5,0,0,0,0,1H87a.5.5,0,0,0,0-1Z"
                        transform="translate(-37.5 -287.5)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6346"
                        data-name="Path 6346"
                        d="M81.5,345a.5.5,0,0,0,0-1h-1a.5.5,0,0,0,0,1Z"
                        transform="translate(-37.5 -315)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6347"
                        data-name="Path 6347"
                        d="M121,345a.5.5,0,0,0,0-1h-.5a.5.5,0,0,0,0,1Z"
                        transform="translate(-75 -315)"
                        fill="#047475"
                      />
                      <path
                        id="Path_6348"
                        data-name="Path 6348"
                        d="M67.5,31.5A6.008,6.008,0,0,0,62,25.521V12.5a.5.5,0,0,0-.146-.354l-4-4A.5.5,0,0,0,57.5,8h-17a.5.5,0,0,0-.5.5v30a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5V37.479A6.008,6.008,0,0,0,67.5,31.5ZM58,9.707,60.293,12H58ZM41,38V9H57v3.5a.5.5,0,0,0,.5.5H61V25.521a6,6,0,0,0,0,11.958V38Zm20.5-1.5a5,5,0,1,1,5-5A5,5,0,0,1,61.5,36.5Z"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_6349"
                        data-name="Path 6349"
                        d="M342.852,352.146a.5.5,0,0,0-.707,0l-3.224,3.224-2.165-1.3a.5.5,0,0,0-.514.858l2.5,1.5a.5.5,0,0,0,.611-.075l3.5-3.5A.5.5,0,0,0,342.852,352.146Z"
                        transform="translate(-277.499 -322.5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </span>
              </h2> : ""}
          </div>
        </div>
      </div>
      <div className="all-center sub-section pb-0">
        <div className='row articals'>
          {countryBlogCat?.categories &&
            countryBlogCat?.categories?.map((data: any, i: any) => {
              return (
                <div className="artical-items" style={{ cursor: "pointer" }} onClick={(e: any) => hendlClicktopics(data?.category_id)}>
                  <p><img src="/assets/img/icons/sholar-icon02.jpg" className='icon' alt="" /></p>
                  <p className='text'>{data?.category}</p>
                </div>
              );
            })}
        </div>
      </div>
      <div className="all-center sub-section pb-0">
        <div className='row'>
          {countryBLog?.blogs &&
            countryBLog?.blogs?.map((data: any, i: any) => {

              return (
                i < 3 && (
                  <div className="col-md-4">
                    <div className="blog-item">
                      <figure className="feat-text1 mb-0">
                        <img
                          className="scale"
                          src={baseurl + data?.images}
                        />
                      </figure>
                      <figcaption className="content-box">
                        <h6 className='pb-2'>{data?.heading && data?.heading}</h6>
                        <ul className="iconscomments country">
                          <li>
                            <a href="">
                              <i
                                className="fa fa-thumbs-o-up"
                                aria-hidden="true"
                              ></i>{" "}
                              {data?.like_count &&
                                data?.like_count}
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i
                                className="fa fa-comment yellow"
                                aria-hidden="true"
                              ></i>{" "}
                              {data?.comment_count == null
                                ? "0"
                                : data?.comment_count}
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i
                                className="fa fa-eye yellow"
                                aria-hidden="true"
                              ></i>{" "}
                              {data?.view_count == null
                                ? "0"
                                : data?.view_count}
                            </a>
                          </li>
                        </ul>
                        <hr />
                        <p>
                          {data?.short_details &&
                            data?.short_details?.length > 120
                            ? `${data?.short_details.substring(
                              0,
                              120
                            )}...`
                            : data?.short_details}
                        </p>
                        <a onClick={() => {
                          history.push(
                            "/blog/" + data?.slug,
                            {
                              blog_id: data?.id,
                            }
                          );
                        }} className="btnarrow"><i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                        <div className="clearfix"></div>
                      </figcaption>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </div>
  )
}

export default Articals
