import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Seo from "../Seo/Seo";
import Application from "./Application";
import UpdateProfile from "./UpdateProfile";
import Appointment from "./Appointment";
import PaymentHistory from "./PaymentHistory";
import Shortlist from "./Shortlist";
import Counsellor from "./Counsellor";
import Discussion from "./Discussion";
import Review from "./Review";
import Service from "./Service";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { URL } from "../../redux/common/url";
import Breadcrumb from "../Breadcrumb";

function Profile() {
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated: any = getToken();
  const [tab, setTab] = useState("updateProfile");
  const [title, settitle] = useState<any>("Student-Profile");
  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const logout = async () => {
    await localStorage.clear();
    toast.success("You have logged out successfully.");
    window.location.href = "/login";
  };
  const findGetParameter = (parameterName: any) => {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  };

  useEffect(() => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    }
    var callbackAction = findGetParameter("action");
    if (callbackAction) {
      setTab(callbackAction);
    }
  }, []);

  useEffect(() => {
    const tabName = location.pathname.split("/").slice(-1)[0];
    console.log(location.pathname, "----");
    if (location.pathname.includes(tabName)) {
      setTab(tabName);
    }
    if (location.pathname.includes("/profile/basic_details")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/education_details")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/document")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/interest")) {
      setTab("profile");
    }
  }, [location.pathname]);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        modified_time={studentProfile?.updatedAt || studentProfile?.createdAt}
        image={
          studentProfile?.profile_image
            ? URL.API_BASE_URL + studentProfile?.profile_image
            : ""
        }
      />
      <section className="categories section profile_wrap bg-grey-light login_sec">
        <Breadcrumb page={"Profile"} data={{ slug: "", path: "" }} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-3">
              <div className="shade1 user_nav bg-white">
                <ul>
                  <li>
                    <a
                      className={tab == "profile" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/basic_details");
                        setTab("updateProfile");
                      }}
                    >
                      <img src="/assets/img/icons/profile.png" alt="" /> my
                      profile
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "applications" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/applications");
                        setTab("applications");
                      }}
                    >
                      <img src="/assets/img/icons/application.png" alt="" />{" "}
                      applications
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "appointments" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/appointments");
                        setTab("appointments");
                      }}
                    >
                      <img src="/assets/img/icons/appoinment.png" alt="" /> my
                      appointments
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <img src="assets/img/icons/refer-earn.png" alt="" /> refer
                      &amp; earn
                    </a>
                  </li> */}
                  <li>
                    <a
                      className={tab == "payments" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/payments");
                        setTab("paymentHistory");
                      }}
                    >
                      <img src="/assets/img/icons/payment.png" alt="" /> payment
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "shortlist" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/shortlisted");
                        setTab("shortlist");
                      }}
                    >
                      <img src="/assets/img/icons/shortlist.png" alt="" />{" "}
                      shortlisted
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "counsellor" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/counsellor");
                        setTab("counsellor");
                      }}
                    >
                      <img src="/assets/img/icons/counsellor.png" alt="" />{" "}
                      counsellor/mentor
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0)">
                      <img src="assets/img/icons/video.png" alt="" /> recorded
                      videos
                    </a>
                  </li> */}
                  <li>
                    <a
                      className={tab == "discussions" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/discussions");
                        setTab("discussion");
                      }}
                    >
                      <img src="/assets/img/icons/discussion.png" alt="" />{" "}
                      discussions
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "reviews" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/reviews");
                        setTab("reviews");
                      }}
                    >
                      <img src="/assets/img/icons/review_profile.png" alt="" />{" "}
                      reviews
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "services" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/services");
                        setTab("service");
                      }}
                    >
                      <img src="/assets/img/icons/services.png" alt="" />{" "}
                      services
                    </a>
                  </li>
                  <li>
                    <a onClick={logout}>
                      <img src="/assets/img/icons/logout.png" alt="" /> logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {tab == "profile" ? <UpdateProfile /> : ""}
            {tab == "profile/basic_details" ? <UpdateProfile /> : ""}
            {tab == "applications" ? <Application /> : ""}
            {tab == "appointments" ? <Appointment /> : ""}
            {tab == "payments" ? <PaymentHistory /> : ""}
            {tab == "shortlisted" ? <Shortlist /> : ""}
            {tab == "counsellor" ? <Counsellor /> : ""}
            {tab == "discussions" ? <Discussion /> : ""}
            {tab == "reviews" ? <Review /> : ""}
            {tab == "services" ? <Service /> : ""}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
