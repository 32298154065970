import React, { Component } from 'react'

import { getToken } from '../Utils/Auth/Token';
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = (props: any) => {
    const isAuthenticated = getToken();
    const getUser = () => {
        return !!localStorage.getItem("access_token");
    };
    if (getUser()) {

        return (
            <Route  {...props} />
        )
    }
    return <Redirect to="/login" />;
}

export default ProtectedRoute
