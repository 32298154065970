import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UniversityAction, UniversityCourseAction } from "../../redux/common/action";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { URL } from "../../redux/common/url";
import MenuOutSideClick from "../../layout/header/outsideClick";
import ShareModal from "../UniversitiesList/ShareModal";
import SocialShareButton from "../SocialShareButton";
function CountrySlider(props: any) {
  const urlforShare = window.location.href;
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const dispatch = useDispatch();
  const history = useHistory();
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const [searchRe, setsearchRe] = useState<any>("");

  const baseurl = URL.API_BASE_URL;
  const { country } = props;
  
  console.log('country', country)

  const universities: any = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );

  const uniAndCouresHendle = (e: any) => {
    setsearchRe(e)
    dispatch(UniversityAction.getuniversities({
      search_by: e, offset: 0, country_id: [country?.id],
      /*  limit:10 */
    }));
    return () => { };
  };

  var slidercont = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div>
      <section
        className="headslider"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont uni-details countrySlider">
            <Slider {...slidercont}>
              <div className="wrapbx">
                {/* <img
                  className="scale hide_desktop"
                  src="/assets/img/uni-details-s1.jpg"
                  alt=""
                />
                <img
                  className="scale show_mobile"
                  src="/assets/img/uni-details-s1m.jpg"
                  alt=""
                /> */}
                {country?.CountryDetail != null && country?.CountryDetail.web_banners !== null ?
                  <img src={baseurl + country?.CountryDetail.web_banners} alt="" width="100%" onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/Image_not_available.png" }} /> :
                  <img src="/assets/img/Image_not_available.png" alt="" />
                }
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="textwrp text-center country">
                          <h1 className="pb-5 d-flex justify-content-center align-items-center country-detail-mob countrySliderH1">{country.flag && <span className="mr-3"><img src={`${URL.API_BASE_URL}${country.flag}`} alt="" width="24" height="24px" /></span>}Study in {country?.country_name?.toLowerCase()} college</h1>
                          <div className="form-group">
                            <div className="input-group search_slider_box">
                              <span className="input-group-prepend">
                                <div className="input-group-text border-0 bg-transparent mr-n5"><i className="fa fa-search"></i></div>
                              </span>
                              <input
                                ref={anchorRef1}
                                type="text"
                                className="form-control form-control-lg py-1 pl-5 ml-n2 bg-transparent text-white"
                                placeholder="search for colleges"
                                autoComplete="off"
                                name="searchinput"
                                value={searchRe}
                                onChange={(e) => {
                                  uniAndCouresHendle(e.target.value);
                                }}
                              />

                              {anchor1 && (
                                <div
                                  className="country_uni_"
                                >
                                  <ul>
                                    {universities &&
                                      universities?.map((data: any, i: any) => {
                                        return i < 8 && (
                                          <li style={{ color: "#000" }} onClick={() => {
                                            history.push("/university/" + data?.slug, {
                                              university_id: data?.id,
                                            });
                                            setsearchRe("")
                                          }

                                          } key={i} >

                                            {data?.university_name}

                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <p className="text-center mt-4">
                              have plans to study in {country?.country_name?.toLowerCase()} ?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="wishlisttop">
            <ul className="info text-center list-inline">
              <li>
              <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlforShare} data={""}/></div>
              </li>
            </ul>
          </div>
        </div >
      </section>
    </div >
  )
}

export default CountrySlider
