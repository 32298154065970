import React, { useState, useEffect } from "react";
import { counselloerLoginValidate, otpValidate } from "../validation/Validation";

interface error {
  [key: string]: any
} 

function CounsellorLogin() {
  const [values, setValues] = useState<any>("");
  const [otp, setOtp] = useState<any>();
  const [errors, setErrors] = useState<error>({})
  const [formValidate, setSubmitting] = useState<boolean>(false);
  const [otpErrors, setOtpErrors] = useState<error>({});
  const [otpvalid, setOtpvalid] = useState<boolean>(false);
  const handleChange = (e:any) => {
      const {name, value}=e.target;
      setValues({...values, [name]: value});
  }
  useEffect(() => {
      const validationErrors = counselloerLoginValidate(values);
      let noErrors = Object.keys(validationErrors).length == 0;
      setSubmitting(noErrors);
      setErrors(validationErrors);
  }, [values]);

  useEffect(() => {
    const validationErrors = otpValidate(otp);
    let noErrors = Object.keys(validationErrors).length == 0;
    setOtpvalid(noErrors);
    setOtpErrors(validationErrors);
  }, [otp]);

  const handleLogin = (event:any) => {
    event.preventDefault(); 
  };

  const handleVerifyOtp = (event:any) => {
    event.preventDefault(); 
  };
    return (
        <div>
            <section className="categories section bg-grey">
            <div className="container">
                <div className="loginwrap">
                    <div className="row g-0">
                      <div className="d-none d-md-flex col-md-4 col-lg-5 bg-image">
                          <div><h3 className="text-white">welcome back</h3>
                            <p className="text-center text-white">Please Login To Your Account To Get Free Expert Advice And Latest Information On Study Abroad. </p></div>
                      </div>
                      <div className="col-md-8 col-lg-7 rightbx">
                        <div className="login d-flex align-items-center py-5">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-11 col-lg-10 mx-auto">
                                    <h5 className="text-center">please sign in to your account</h5>
                                </div>
                                <div className="col-md-9 col-lg-8 mx-auto">
                                    <div className="fields-wrp">
                                        <form action="#">
                                          <div className="field">
                                            <input type="text" name="mobile" value={values?.mobile} onChange={(e:any)=>handleChange(e)} required />
                                            <label>mobile number</label>
                                            <span className="fa fa-phone p-2"></span>
                                          </div>
                                          {errors?.mobile && values?.mobile !== undefined && <span className="error">{errors?.mobile}</span>}
                                          <div className="fieldbtn mt-5">
                                            <input type="submit" data-toggle="modal" data-target="#loginwithotp" className={!formValidate ? "disabledClass bggreadent px-2" : "bggreadent px-2"} onClick={(e:any)=>handleLogin(e)} value="login with otp"  />
                                          </div>  
                                        </form>
                                      </div>  
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>   
            </div>
        </section>

<div id="loginwithotp" className="modal fade loginwithotp" tabIndex={-1} role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
        <div className="modal-content modal-login  p-3">
            <div className="modal-header border-0">
                <div className="text-center imgotp"><img src="assets/img/otp-icon.png" className="scale" alt="" /></div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">X</span>
                </button>
              </div>
            <div className="modal-body">
                <div className="col-md-11 col-lg-10 mx-auto">
                    <div className="fields-wrp">
                        
                        <h5>a text message with a verification code 
                            was just sent to xxxxxxxx{values?.mobile?.slice(-2)}</h5>
                        <form action="#">
                            <div className="pass-link text-right mt-2" style={{marginBottom : "-10px"}}><a href="#">change mobile number</a></div>
                            <div className="field">
                                <input type="text" name="otp" value={otp} onChange={(e:any)=>setOtp(e.target.value)} required />
                                <label>enter otp</label>
                                <span className="fa fa-certificate p-2"></span>
                            </div>
                            {otpErrors.otp && otp !== undefined && <span className="error">{otpErrors.otp}</span>}
                            <div className="signup-link"><a href="#">didn't receive the code?</a></div>
                            <div className="fieldbtn">
                                <input type="submit"  className={!otpvalid? "disabledClass bggreadent" : "bggreadent"} onClick={(e:any)=>handleVerifyOtp(e)} value="verify" />
                            </div>
                        </form>
                      </div>
                
              </div>
            </div>
        </div>
    </div>
</div>
        </div>
    )
}

export default CounsellorLogin
