import {
  LOADING,
  GET_UNIVERSITIES_LIST,
  GET_UNIVERSITIES_BY_ID,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_HOME,
  GET_ALL_UNIVERSITIES_COMMENT,
  ADD_UNIVERSITIES_COMMENT,
  GET_RANKING_UNIVERSITIES,
  GET_UNIVERSITIES_BY_SLUG,
  TRENDING_UNIVERSITIES,
  GET_UNIVERSITIES_COUNT,
  UNIVERSITY_LIKE_DISLIKE,
  UNIVERSITY_LIKE,
  APPLIED_COURSE,
  GET_AD_BANNER,

} from "../common/constant";
type stateType = {
  loading: boolean;
  universityList: any[];
  universityById: any[];
  universities: any[];
  universityHome: any[];
  universityComment: any[];
  addUniversityComment: any[];
  getrankinguniversity: any[];
  universityBySlug: any[];
  trendingUniversities: any[];
  uniCount: any[];
  uniLikeDislike: any[];
  uniLike: any[];
  appliedCoursesFrontendUniStud: any[];
  adBanner: any[];
}
const initialState: stateType = {
  loading: false,
  universityList: [],
  universityById: [],
  universities: [],
  universityHome: [],
  universityComment: [],
  addUniversityComment: [],
  getrankinguniversity: [],
  universityBySlug: [],
  trendingUniversities: [],
  uniCount: [],
  uniLikeDislike: [],
  uniLike: [],
  appliedCoursesFrontendUniStud: [],
  adBanner: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_UNIVERSITIES_LIST:
      return { ...state, universityList: action.payload };
    case GET_UNIVERSITIES_BY_ID:
      return { ...state, universityById: action.payload };
    case GET_UNIVERSITIES:
      return { ...state, universities: action.payload };
    case GET_UNIVERSITIES_HOME:
      return { ...state, universityHome: action.payload };
    case TRENDING_UNIVERSITIES:
      return { ...state, trendingUniversities: action.payload };
    case GET_ALL_UNIVERSITIES_COMMENT:
      return { ...state, universityComment: action.payload };
    case ADD_UNIVERSITIES_COMMENT:
      return { ...state, addUniversityComment: action.payload };
    case GET_RANKING_UNIVERSITIES:
      return { ...state, getrankinguniversity: action.payload };
    case GET_UNIVERSITIES_BY_SLUG:
      return { ...state, universityBySlug: action.payload };
    case GET_UNIVERSITIES_COUNT:
      return { ...state, uniCount: action.payload };
    case UNIVERSITY_LIKE_DISLIKE:
      return { ...state, uniLikeDislike: action.payload };
    case UNIVERSITY_LIKE:
      return { ...state, uniLike: action.payload };
    case APPLIED_COURSE:
      return { ...state, appliedCoursesFrontendUniStud: action.payload };
    case GET_AD_BANNER:
      return { ...state, adBanner: action.payload };
    default:
      return state;
  }
};