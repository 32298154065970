import { 
  LOADING,
  GET_ALL_COUNTRY,
  GET_COUNTRY_BY_SLUG
} from "../common/constant";
 type stateType = {
  loading:boolean;
  countryList: any;
  countryDetail:any;
 }
const initialState : stateType = {
    loading:false,
    countryList: [],
    countryDetail: {},
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_ALL_COUNTRY:
      return { ...state, countryList: action.payload};
    case GET_COUNTRY_BY_SLUG:
      return { ...state, countryDetail: action.payload};
    default:
      return state;
  }
};