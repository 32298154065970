import axios from "axios";
import { toast } from "react-toastify";
import {
  LOADING,
  LOGIN,
  LOGIN_WITH_OTP,
  STUDENT_REGISTER,
  STUDENT_VERFIY_OTP,
  STUDENT_CHECK_EMAIL_EXIST,
  STUDENT_CHECK_MOBILE_EXIST,
  STUDENT_UPDATE,
  GET_STUDENT_BY_ID,
  STUDENT_ATTACHMENT,
  GET_ATTACHMENT,
  DELETE_ATTACHMENT,
  EMAIL_VERIFICATION,
  VERIFICATION_OF_EMAIL,
  SHORTLIST_UNIVERSITY,
  SHORTLIST_COURSES,
  STUDENT_DISCUSSION,
  STUDENT_PAYMENT_HISTORY,
  UNI_REVIEW,
  SERVICE_LIST,
  STUDENT_REVIEW,
  UPDATE_PASSWORD,
  LOGIN_WITH_PASSWORD,
  MY_APPOINTMENT,
  COACH_COUNSELLOR_FOR_PROFILE,
} from "../common/constant";

import {
  studentAction
} from "../../redux/common/action";
import { URL } from "../common/url";
import api from "./../common/api";
import jwt_decode from "jwt-decode";

const Token = require("../../Utils/Auth/Token");

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const loginAction = (data: any) => {
  return { type: LOGIN, payload: data };
};
const loginWithOtpAction = (data: any) => {
  return { type: LOGIN_WITH_OTP, payload: data };
};
const studentSignupAction = (data: any) => {
  return { type: STUDENT_REGISTER, payload: data };
};
const studentVerfiyOtpAction = (data: any) => {
  return { type: STUDENT_VERFIY_OTP, payload: data };
};
const studentCheckEmailExistAction = (data: any) => {
  return { type: STUDENT_CHECK_EMAIL_EXIST, payload: data };
};

const studentCheckMobileExistAction = (data: any) => {
  return { type: STUDENT_CHECK_MOBILE_EXIST, payload: data };
};

const studentAttachmentAction = (data: any) => {
  return { type: STUDENT_ATTACHMENT, payload: data };
};

const getStudentAttachmentAction = (data: any) => {
  return { type: GET_ATTACHMENT, payload: data };
};

const studentUpdateAction = (data: any) => {
  return { type: STUDENT_UPDATE, payload: data };
};

const deleteAttachmentAction = (data: any) => {
  return { type: DELETE_ATTACHMENT, payload: data };
};


const getStudentByIdAction = (data: any) => {
  return { type: GET_STUDENT_BY_ID, payload: data };
};
const emailVerificationAction = (data: any) => {
  return { type: EMAIL_VERIFICATION, payload: data };
};
const verificationOfEmailAction = (data: any) => {
  return { type: VERIFICATION_OF_EMAIL, payload: data };
};
const shortListUniversityAction = (data: any) => {
  return { type: SHORTLIST_UNIVERSITY, payload: data };
};
const shortListCoursesAction = (data: any) => {
  return { type: SHORTLIST_COURSES, payload: data };
};
const studentDiscussionAction = (data: any) => {
  return { type: STUDENT_DISCUSSION, payload: data };
};
const paymentHistoryAction = (data: any) => {
  return { type: STUDENT_PAYMENT_HISTORY, payload: data };
};
const studentUpdatePasswordAction = (data: any) => {
  return { type: UPDATE_PASSWORD, payload: data };
};
const studentLoginWithPasswordAction = (data: any) => {
  return { type: LOGIN_WITH_PASSWORD, payload: data };
};
const uniReviewAction = (data: any) => {
  return { type: UNI_REVIEW, payload: data };
};
const studentReviewAction = (data: any) => {
  return { type: STUDENT_REVIEW, payload: data };
};
const serviceListAction = (data: any) => {
  return { type: SERVICE_LIST, payload: data };
};
const myAppointmentAction = (data: any) => {
  return { type: MY_APPOINTMENT, payload: data };
};
const coachCounsellorForProfileAction = (data: any) => {
  return { type: COACH_COUNSELLOR_FOR_PROFILE, payload: data };
};

export const login = (data: any, history: any) => {

  return async (dispatch: any) => {
    try {
      //dispatch(loading(true));
      const response: any = await api.post(`${URL.login}`, data);
      dispatch(loginAction(response.data?.data));
      dispatch(loading(false));
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user", JSON.stringify(response.data?.data));
      // history.push("/chat");
      // toast.success(response?.data?.message);
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const verificationOfEmail = (token: any) => {

  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.get(`${URL.verificationOfEmail}/${token}`);
      dispatch(verificationOfEmailAction(response.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
      // await localStorage.clear();
      window.location.href = "/profile"
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      window.location.href = "/email-verification-expired"
    }
  };
};

export const getStudentById = (data: any, token: any) => {

  return async (dispatch: any) => {
    try {
     let response: any;
      if(token) {
        response = await api.get(`${URL.getStudentById}?id=${data}`, {headers: {'token': token ? token: ""}});
      } else {
        response = await api.get(`${URL.getStudentById}?id=${data}`);
      }
      dispatch(getStudentByIdAction(response.data?.data));
      // dispatch(loading(false));
      localStorage.setItem("userInterest", response.data?.data?.interest);
      // toast.success(response?.data?.message);
    } catch (error: any) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const studentUpdate = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.put(`${URL.studentUpdate}/${user_id}`, data);
      dispatch(studentUpdateAction(response?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);

      window.location.reload();
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.msg[0]);
    }
  };
};
export const shortListUniversity = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.shortListUniversity}`, data);
      dispatch(shortListUniversityAction(response?.data?.data?.list));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const shortListCourses = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.get(`${URL.shortListCourses}`, data);
      dispatch(shortListCoursesAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const coachCounsellorForProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.coachCounsellorForProfile}`, data);
      dispatch(coachCounsellorForProfileAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const studentDiscussion = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.studentDiscussion}`, data);
      dispatch(studentDiscussionAction(response?.data?.data?.list));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const paymentHistoryById = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.paymentHistory}`, data);
      dispatch(paymentHistoryAction(response?.data));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const studentAddReview = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.studentAddReview}`, data);
      dispatch(studentDiscussionAction(response?.data));
      var responseData = response?.data;

      if (responseData.status == true) {
        toast.success(responseData?.message);
        window.location.reload();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatePassword = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.updatePassword}`, data);
      dispatch(studentUpdatePasswordAction(response?.data));
      var responseData = response?.data;

      if (responseData.status == true) {
        toast.success(responseData?.message);
        window.location.reload();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getStudentReview = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.getStudentReview}`, data);
      dispatch(studentReviewAction(response?.data));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};

export const uniReview = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.universityReviewList}`, data);
      dispatch(uniReviewAction(response?.data?.data?.list));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const serviceList = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.serviceList}`, data);
      dispatch(serviceListAction(response?.data?.data?.list));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const myAppointment = (data: any, user_id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.myAppointment}`, data);
      dispatch(myAppointmentAction(response?.data?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const studentSignup = (data: any, callback:any) => {
  return async (dispatch: any) => {
    //dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.studentSignup}`, data);
      if(response) {
        callback(true);
      }
      dispatch(studentSignupAction(response.data?.data));
      dispatch(loading(false));
      // history.push("/login");
      toast.success(response?.data?.message);
    } catch (error: any) {
      callback(false)
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const studentAttachment = (data: any) => {
  return async (dispatch: any) => {
    // //dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.uploadAttachment}`, data);
      dispatch(studentAttachmentAction(response.data?.data));
      // dispatch(loading(false));
      window.location.href = '/profile';
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const loginWithOtp = (data: any, history: any) => {
  return async (dispatch: any) => {
    // //dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.loginWithOtp}`, data);
      dispatch(loginWithOtpAction(response.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error: any) {
      dispatch(loading(false));
      history.push("/register")
      // window.location.href = "/register" 
      toast.error(error?.response?.data?.message);
    }
  };
};

export const forgetPassword = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.put(`${URL.forgetPassword}`, data);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.mesaage);
    }
  };
};

export const studentVerfiyOtp = (data: any, history: any, source: any = '') => {
  return async (dispatch: any) => {
    //dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.studentVerfiyOtp}`, data);
      dispatch(studentVerfiyOtpAction(response.data));
      dispatch(loading(false));


      if (response?.data?.status) {
        Token.setToken(response?.data?.token);
        toast.success(response?.data?.message);

        const isAuthenticated: any = response?.data?.token;
        var user: any = isAuthenticated && jwt_decode(isAuthenticated);
        if (source == 'register') {
          var Data = ({ mobile: data.mobile, id: user?.id, refer_url: window.location.origin });
          dispatch(studentAction.emailVerify(Data));
        }
        var lastLocation: any = localStorage.getItem("lastLocation");
        window.location.href = lastLocation ? lastLocation : "/applynow";
      } else {
        toast.error(response?.data?.message);
      }


    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const loginWithPassword = (data: any, history: any = null, source: any = '') => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.loginWithPassword}`, data);
      dispatch(studentLoginWithPasswordAction(response?.data));
      if (response?.data?.status) {
        Token.setToken(response?.data?.token);
        toast.success(response?.data?.message);

        const isAuthenticated: any = response?.data?.token;
        var user: any = isAuthenticated && jwt_decode(isAuthenticated);
        if (source == 'register') {
          var Data = ({ mobile: data.mobile, id: user?.id, refer_url: window.location.origin });
          dispatch(studentAction.emailVerify(Data));
        }
        var lastLocation: any = localStorage.getItem("lastLocation");
        window.location.href = lastLocation ? lastLocation : "/applynow";
      } else {
        toast.error(response?.data?.message);
      }

    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const studentCheckEmailExist = (data: any) => {
  return async (dispatch: any) => {
    // //dispatch(loading(true));

    try {
      const response: any = await api.post(
        `${URL.studentCheckEmailExist}`,
        data
      );
      dispatch(studentCheckEmailExistAction(response.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(studentCheckEmailExistAction(error?.response?.data));
      dispatch(loading(false));
    }
  };
};

export const studentCheckMobileExist = (data: any) => {
  return async (dispatch: any) => {
    // //dispatch(loading(true));

    try {
      const response: any = await api.post(
        `${URL.studentCheckMobileExist}`,
        data
      );
      dispatch(studentCheckMobileExistAction(response.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(studentCheckMobileExistAction(error?.response?.data));
      dispatch(loading(false));
    }
  };
};

export const getAttachmentList = (data: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.attachmentList}`, data);
      dispatch(getStudentAttachmentAction(response.data?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteAttachment = (data: any, id: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.deleteAttachments}/${id}`, data);
      dispatch(deleteAttachmentAction(response.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const emailVerify = (data: any) => {
  return async (dispatch: any) => {
    try {
      // //dispatch(loading(true));
      const response: any = await api.post(`${URL.verifyUserEmail}`, data);
      dispatch(emailVerificationAction(response.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
