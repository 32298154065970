import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    officeAction,
} from "../../redux/common/action";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import SocialShareButton from "../SocialShareButton";
import { getToken } from "../../Utils/Auth/Token";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

const token = getToken();

var other_details: any = [];
function Funding() {
    const urlsforShare = window.location.href;
    const history = useHistory();
    const dispatch = useDispatch();
    const baseurl = URL.API_BASE_URL;
    const [Internship, setInternship] = useState<any>();
    const [resume, setResume] = useState<any>("");
    const [startDate, setStartDate] = useState("");
    const [country, setcountry] = useState<any>({
        name: "",
        label: "",
        value: "",
    });
    const [passingYear, setpassingYear] = useState<any>({
        name: "",
        label: "",
        value: "",
    });

    const countrys: any[] = useSelector((state: any) =>
        state.country.countryList ? state.country.countryList : []
    );


    const hendlefiles = async (e: any) => {
        const file = e.target.files[0]
        const fileInput = document.querySelector("#resume");;
        const formData = new FormData();
        formData.append("file", fileInput.files[0]);
        const res = await axios(
            baseurl + '/api/fileUpload/upload?module_name=career',
            {
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data; ',
                    'token' : token || ""
                },
            }
        );
        let response = res.data.fileName;
        setResume(response);
    }

    const Resume = resume;


    var Degree: any = ({ name: "resume", label: "upload cv", value: resume ? '/career/' + resume : "" });



    const hendlereviews = (e: any) => {
        const { name, value } = e.target;
        setInternship({ ...Internship, [name]: value, "other_details": other_details, "service_name": "internship-placement" });
    };


    const submitQuery = async (e: any) => {

        const Name = $("#Name").val();
        const Mobile = $("#Mobile").val();
        const Email = $("#Email").val();
        const City = $("#City").val();

        const IsCountry = $("select[name=country]").val();
        const IsYear = moment(startDate).format('YYYY-MM-DD');

        $('.error-class').remove();
        var isError = false;
        var phoneno = /^[1-9]{1}[0-9]{9}$/;
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!Name) {
            $("#Name").after('<div class="error-class">This field is required.</div><br />');
            isError = true;
        }
        if (!Mobile) {
            $("#Mobile").after('<div class="error-class">This field is required.</div>');
            isError = true;
        }
        if (Mobile) {
            if (Mobile.length != 10) {
                $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
                isError = true;
            }
        }
        if (Mobile) {
            if (phoneno.test(Mobile) === false) {
                $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
                isError = true;
            }
        }
        if (!Email) {
            $("#Email").after('<div class="error-class">This field is required.</div>');
            isError = true;
        }
        if (Email) {
            if (regex.test(Email) === false) {
                $("#Email").after('<div class="error-class">Invalid email.</div>');
                isError = true;
            }
        }
        if (!City) {
            $("#City").after('<div class="error-class">This field is required.</div>');
            isError = true;
        }
        if (isError) {
            return false;
        }

        var passingYear = { name: "year", label: "passing/pursing year", value: IsYear ? IsYear : "N/A" };
        var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

        other_details.push(InCountry, passingYear, Degree);

        // return;
        $('#internshipIn').prop('disabled', true);
        dispatch(officeAction.submitQuery(Internship,history));
        // toast.success('Thanks for showing your interest, we will get back to you soon.');
    };
    return (
        <>
            <Seo slug={window.location.pathname}/>
            <div>
                <section className="tag-term">
                <Breadcrumb page={"Services"} data={{slug: "Internship & Placement", path: "/services"}}/>
                    <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
                    <div className="headingmains text-center">
                        <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                            internship & placement
                            <span>
                                <img className="service-funding" src="assets/img/iandp1.png" alt="" />
                            </span>
                        </h2>
                    </div>
                </section>

                <section className="terms">
                    <div className="container">
                        <div className="row">
                            <div className="terms-s d-flex">
                                <div className="col-lg-12">
                                    <p>Guide On Internship And Placement From Admissify
                                        When aspiring to study abroad, you must have some plans to get a bright future. No matter your discipline, a satisfying career will be waiting as you get a degree from a prestigious institution. Moreover, there are chances of job placements in the final year of your course. To carry out the everyday expenses, you can also be part of some internship programs. Admissify experts will help you in making you ready for all these opportunities. Therefore, register yourself quickly on the web portal of Admissify.
                                        The organization stores all the profiles of the students in the database. Moreover, the professionals study the profiles and select the most suitable colleges or universities depending on the match. You have to consult with the experts to identify your strongest point. It will help you and the consultants to make the right decision. Only choosing a subject will not do any good unless you can study the same in the concerned college. However, you fail to meet the criteria in one college, the experts will try for another one.
                                        Placements Are Easily Achievable
                                        Easy access to different universities is now possible online. Admissify will give you that chance at a reasonable price. Check out the courses available at various institutions abroad. You can get ready for the admission tests after taking classes from our faculty. Raise your doubt whenever you are having some problems. You will get the answer promptly, even while attending the online lectures.
                                        We conduct separate sessions for job opportunities in various countries. You can even join some part-time jobs to earn your living in the unknown land. The professionals will guide you by stating the most common and safe jobs available for the students. Some countries like Canada and Australia are considered the safest countries for females. Therefore, you can obviously go by those suggestions.
                                        Consider the feedback of the ex-students, professors, and alumni of the institutions. Admissify has linkage with all the respected members of the alumni group. So, you will not come across challenges while moving abroad.
                                    </p>
                                </div>
                                {/* <div className="col-lg-4">
                                    <ContactForm />
                                </div>    */}
                            </div>

                        </div>
                    </div>
                </section>

                <section className="terms">
                    <div className="container">
                        <div className="row">
                            <div className="terms-s">
                                <div className="col-lg-12">
                                    <p>Learn the lifestyles of the natives before stepping to that country. The experts will provide complete support in these matters.
                                        Importance Of Internships
                                        Top-class companies of the world come to the campus interviews of prestigious institutions. Therefore, you can get through the job placements in such interviews only. However, to make it on the first chance, take the preparations beforehand. The Admissify experts will teach you the tricks about cracking such interviews. Therefore, you will have your dream job in hand after completing the desired course.
                                        Internships play a significant role in shaping your personality for the future. Furthermore, they will prepare you for the real world and give you the opportunities to apply the theoretical concepts in resolving actual issues. Therefore, joining the internship programs will develop you from different aspects. Admissify gives you all the opportunities to ensure that your future becomes attractive. Learn every minor detail for staying comfortably in a foreign land.
                                        The professionals at Admissify will also arrange suitable accommodations. Mention your preferences, and we will search accordingly. We intend to make you enjoy life in your favorite country. Job placements will not be impossible to get if you are dedicated to your mission. Fix the goal and strive hard. The professors are always there to mitigate the doubts.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section>
                    <div className="postwrap">
                        <div className="container py-2">
                            <article className="postcard">
                                <img className="postcard__img-new" src="assets/img/intern-place11.png" alt="" />
                                <div className="postcard__text postcard__text1">
                                    <h4 className="pb-3 card-head1">Types Of Internships</h4>
                                    <span className="border-bt"></span>
                                    <div className="postcard__preview-txt ft-15">The internship programs do not require you to be a passed-out candidate from any institution. You can join them even while studying. Furthermore, many institutions will give you permits for work outside the premises. However, other institutions may not allow leaving the premises. In such cases, you can earn by doing part-time jobs in different departments of the universities. The authorities are always conscious of your safety and security. There are special classes to test the capabilities of international students. The ice-breaking sessions will definitely help you to come closer to the professors.
                                    </div>
                                    <h5 className="pb-3 card-head1">The most popular internships include;-</h5>
                                    <ul className="condition-list-1">
                                        <li>Advertising internships</li>
                                        <li>Marketing internships</li>
                                        <li>Technology internships</li>
                                        <li>Finance internships</li>
                                        <li>Publishing internships</li>
                                        <li>PR internships</li>
                                    </ul>
                                    <p className="ft-15">Internship in Fine Arts
                                        Show your skills in whatever work you join. Things will become smooth as you get acquainted with the respective company and its staff. Get some tips for being successful from the experts of Admissify. Enhance your communication skills by conversing with the natives more.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div></section>
                <br />
                {/*   
     
     <Contactus /> */}

                <section className="categories section bg-grey" id="c-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-sm-12">
                                <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                        <symbol viewBox="0 0 24 24" id="expand-more">
                                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                            <path d="M0 0h24v24H0z" fill="none" />
                                        </symbol>
                                        <symbol viewBox="0 0 24 24" id="close">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                            <path d="M0 0h24v24H0z" fill="none" />
                                        </symbol>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-11 col-lg-10">
                                                <h5 className="apply-n">apply now</h5>
                                            </div>
                                            <div className="col-md-12 col-lg-12">
                                                <div className="fields-wrp">
                                                    <form id="internForm">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="field">
                                                                    <input
                                                                        autoComplete="new-off" type="text" name="name" value={Internship?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                                    <label>name</label>
                                                                    <span className="fa fa-user p-2"></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="field">
                                                                    <input
                                                                        autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Internship?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                                    <label>mobile</label>
                                                                    <span className="fa fa-phone p-2"></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="field">
                                                                    <input
                                                                        autoComplete="new-off" type="text" name="email" id="Email" value={Internship?.email} onChange={(e) => { hendlereviews(e); }} />
                                                                    <label>email</label>
                                                                    <span className="fa fa-envelope p-2"></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="field">
                                                                    <input
                                                                        autoComplete="new-off" type="text" name="city" id="City" maxLength="10" value={Internship?.city} onChange={(e) => { hendlereviews(e); }} />
                                                                    <label>city</label>
                                                                    <span className="fa fa-map-marker p-2"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="field">
                                                                    <span className="fa fa-map-marker p-2"></span>
                                                                    <select className="form-control c-select" name="country" value={country?.country}>
                                                                        <option value="">preferred country</option>
                                                                        {countrys && countrys?.map((data: any, i: any) => {
                                                                            return (
                                                                                <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="field choose-f choose_file">
                                                                    <input type="file" id="resume" className="type_file" name="file" accept="application/pdf" onChange={(e) => { hendlefiles(e) }} />
                                                                    <label>upload cv</label>
                                                                    <span className="fa fa-upload p-2 r-20"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            {/* <input
                                                                autoComplete="new-off" type="date" name="year" id="year" value={passingYear?.year} />
                                                            <label>passing/pursing year</label> */}
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date: Date) => setStartDate(date)}
                                                                peekNextMonth
                                                                placeholderText="passing / pursuing year"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                name="year"
                                                                dateFormat="dd-MM-yyyy"
                                                                dropdownMode="select"
                                                                id="year"
                                                                maxDate={new Date()}
                                                            />
                                                            <span className="fa fa-calendar p-2"></span>
                                                        </div>
                                                        <div className="field">
                                                            <textarea
                                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Internship?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                                        </div><br /><br /><br />
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="fieldbtn text-center">
                                                                    <div type="button" onClick={(e) => { submitQuery('internForm') }} data-toggle="modal" id="internshipIn" className="bggreadent apply-sub-btn a-s-b" value="">submit</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Funding
