import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CounsellorList from "../CounsellorList/CounsellorList";
import { Link, useHistory } from "react-router-dom";
import Seo from "../Seo/Seo";
import ChooseAdmissify from "../../Home/component/ChooseAdmissify";
import StudyCourses from "./StudyCourse";
import Testimonial from "../../Home/component/Testimonial";
import ContactUs from "./ContactUs";
import { URL } from "../../redux/common/url";
import {
  UniversityAction,
  SettingAction,
} from "../../redux/common/action";
import Breadcrumb from "../Breadcrumb";
function UniversitiesInAustralia() {
  const history = useHistory();
  const dispatch = useDispatch();
  const baseurl = URL.API_BASE_URL;

  const university: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );

  const getSetting: any = useSelector((state: any) =>
    state.setting.getSetting ? state.setting.getSetting : []
  );

  useEffect(() => {
    dispatch(
      UniversityAction.getuniversities({
        offset: 0,
        country_id: [1],
        limit: 9,
      })
    );
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(SettingAction.getSetting({}));
    return () => { };
  }, []);
  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>
        <section>
          <header className="header_lending">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-2 logo-wrap landing-logo">
                  <div className="main-logo">
                    <img className="scale" src="/assets/img/Admissfy_Logo.png" alt="logo" />
                  </div>
                </div>
                <div className="tg-navigationarea col-lg-auto order-lg-2 landing-contact">
                  <nav id="tg-nav" className="tg-nav">
                    <div className="navbar-header mobnav">
                      <button className="menu-toggle btn-open first">
                        <span></span>
                      </button>
                    </div>
                    <div id="tg-navigation" className="collapse navbar-collapse tg-navigation main-menu header_contact">
                      <ul>
                        <li className="active f-15"><span><a href={"tel:" + getSetting[0]?.phone}><i className="fa fa-phone"></i> {getSetting[0]?.phone}</a> | <a href={"tel:" + getSetting[0]?.mobile}>{getSetting[0]?.mobile}</a></span></li>
                        <li className="ml-15 f-15">{' '}<span> <a href={"https://api.whatsapp.com/send?phone=" + getSetting[0]?.whatsapp_no + "&text= Hi, I am looking for support with my career and education planning."} target="_blank"><i className="fa fa-whatsapp"></i> whatsapp us</a></span></li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </header >
        </section>
        <section className="landing_banner">
          <div className="firstunisec ">
            <div className="container">
              <div className="topuni pl-50">
                <h4>Top universities in Australia</h4>
                <img className="landing_icon_" src="/assets/img/Australia.png" alt="" />
              </div>
            </div>
            <div className="row ">

              <div className="col-sm-12 col-md-8">
                <div className="unisecuk">
                  <div className="row container">
                    {university && university?.map((data: any, i: any) => {
                      const banners =
                        typeof data?.UniversityDetail?.banners == "string"
                          ? JSON.parse(data?.UniversityDetail?.banners)
                          : "";
                      var isImg = false;
                      if (banners.length > 0) {
                        isImg = true;
                      }
                      return (
                        <div className="col-sm-12 col-md-4 unilistpage">
                          <div className="uniimg">
                            {data?.UniversityDetail?.banners !== null && data?.UniversityDetail !== null ? (
                              <img src={baseurl + banners} alt="" />
                            ) : (
                              <img
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </div>
                          <div className="uninam">
                            <p>{data?.university_name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>



              </div>
              <div className="col-sm-12 col-md-3 bg-white gettouchform">
                <div className="login d-flex align-items-center mt-20">
                  <div className="container">
                    <div className="row">
                      <div className="fields-wrp w-100">
                        <ContactUs />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="topuni pl-50">
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-lg-8 col-sm-12">
                      <p>Click to discover university & course information, plus student reviews & more!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="postcard__text postcard__text01">
            <h2 className="pb-3 card-head1 card-headline text-center">why Australia?</h2>
            <span className="border-bt01 m-20"></span><br></br>
            <div className="postcard__preview-txt01 ft-15">Australia offers amazing opportunities for international students with top globally reputed universities& courses. Australia is an English speaking, multicultural country with many international students. Great Job & immigration opportunities exist due to many IT & Engineering firms looking to hire skilled talent.
            </div><br></br>
            <div className="row d-flex d-flex justify-content-around mb-5 mt-25">
              <div className="col-md-4">
                <div className="uninam text-right">
                  <strong><span><img className="landing_icon" src="/assets/img/visa.png" alt="" /></span>Work Visa – Temporary Graduate Visa Subclass 485</strong>
                </div>
              </div>
              <div className="col-md-4">
                <div className="uninam text-center txt_align">
                  <strong>
                  <span><img className="landing_icon" src="/assets/img/iandp1.png" alt="" /></span>High Skilled Job availability</strong>
                </div>
              </div>
              <div className="col-md-4">
                <div className="uninam text-left">
                  <strong><span><img className="landing_icon" src="/assets/img/ser03.png" alt="" /></span>Affordable living and tuition fees</strong>
                </div>
              </div>
            </div>
            <div className="uninammainsec">
              <div className="row d-flex d-flex justify-content-around">
                <div className="col-md-4">
                  <div className="uninam text-right">
                    <strong><span><img className="landing_icon" src="/assets/img/icon2.png" alt="" /></span>Work Part-time while you study – 20 hrs/week</strong>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="uninam text-center txt_align">
                    <strong><span><img className="landing_icon" src="/assets/img/icon4.png" alt="" /></span>Globally reputed & top ranked universities</strong>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="uninam text-left">
                    <strong><span><img className="landing_icon" src="/assets/img/icon8.png" alt="" /></span>1 Year Masters Courses</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div className="t-banner-img">
            <img className="banner__" src="assets/img/uk_banner.jpeg"></img>
            <div className="container">
              <div className="landing-tag">
                <h1>Want to know more about the country ?</h1>
                <a href="#"><button type="button" className="btn btn-outline-warning">Discover Australia</button></a>
              </div>
            </div>
          </div>
        </section>
        <section className="ad-exc">
          <ChooseAdmissify />
          <StudyCourses />
          <Testimonial />
        </section>

      </div>
    </>
  )
}
export default UniversitiesInAustralia
