import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { getToken } from "../../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { officeAction, UniversityAction } from "../../../redux/common/action";
import Seo from "../../../Component/Seo/Seo";
import SocialShareButton from "../../../Component/SocialShareButton";
import Breadcrumb from "../../../Component/Breadcrumb";
// import Breadcrumb from "../Breadcrumb";
// import TestimonialUpdate from "../../Home/component/TestimonialUpdate";
// import Counsellor from "../../Home/component/Counsellor";
// import SeoHeaderContent from "../Seo/SeoHeaderContent";
// import SeoFooterContent from "../Seo/SeoFooterContent";
import { addInlineStylesToHtml } from "../../../Utils/Common/Common";
import ScholarShipMobile from "../ScholarShip";
import Services from "../Services";
// import AdSlider from "../AdSlider/AdSlider";
// import { addInlineStylesToHtml } from "../../Utils/Common/Common";

function ContactUsDetail() {
  const urlsforShare = window.location.href;
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();
  const [lowercaseCityName, setLowercaseCityName] = useState("");

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const universityCommentup: any[] = useSelector((state: any) =>
    state.office.getQueryAll ? state.office.getQueryAll : []
  );
  const dispatch = useDispatch();
  const office: any = useSelector((state: any) =>
    state.office.officeList ? state.office.officeList : []
  );
  const officeslug: any = useSelector((state: any) =>
    state.office.officeBySlug ? state.office.officeBySlug : []
  );
  const [Office, setOffice] = useState<any>();
  const activeLocation =
    office &&
    office?.filter((data: any) => {
      return data?.status == 1;
    });
  useEffect(() => {
    dispatch(officeAction.getofficelist({}));
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setOffice({ ...Office, [name]: value });
    e.preventDefault();
    return false;
  };
  const addQuery = (e: any) => {
    var inputs = $("#" + e).find("input");
    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (inputs.length > 0) {
      $.each(inputs, function (k, v) {
        if (!v.value) {
          $(this).after(
            '<div class="error-class">This field is required.</div>'
          );
          isError = true;
        }
      });
      if (inputs[1].value == "") {
        $("#mob").after(
          '<div class="error-class">This field is required.</div>'
        );
        isError = true;
      }
      if (inputs[1].value) {
        if (inputs[1].value.length != 10) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[1].value) {
        if (phoneno.test(inputs[1].value) === false) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[2].value) {
        if (regex.test(inputs[2].value) === false) {
          $("#email").after('<div class="error-class">Invalid email.</div>');
          isError = true;
        }
      }
    }
    if (isError) {
      return false;
    }
    let dt: any = { ...Office };
    dt.User_id = user?.id ? user?.id : "";
    dt.CityName = officeslug?.data?.city_name;
    dispatch(officeAction.getQueryAll(dt, history));
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  const [displayCount, setDisplayCount] = useState(3);

  const handleViewMore = () => {
    setDisplayCount(displayCount + 3);
  };

  useEffect(() => {
    dispatch(officeAction.getofficebyslug(params?.slug));
    return () => {
      // Cleanup or other actions if needed
    };
  }, [dispatch, params?.slug]);

  useEffect(() => {
    // Update the lowercase city name when officeid changes
    const city_name = officeslug?.data?.city_name || "";
    setLowercaseCityName(city_name.toLowerCase());
  }, [officeslug]);

  const [showFullContent, setShowFullContent] = useState(false);
  const wordCount = officeslug?.data?.short_description.split(/\s+/).length;

  const toggleContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowFullContent(!showFullContent);
  };

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="/assets/img/banner-con-details.webp"></img>
            <div className="container">
              <div className="tag-l tag--mob-cn">
                <h1>{officeslug?.data?.title}</h1>
                <p className="mt-3">
                  Get in touch with us quickly by submitting your name, email
                  and message. Call us or write us an email for contacting the
                  executives from Admissify.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="c-add">
          <div className="container">
            <Breadcrumb
              page={"Contact us"}
              data={{ slug: params?.slug, path: "/contact-us" }}
            />
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="headingmains text-center ofc_add">
                <div className="d-flex justify-content-end mr-3">
                  <SocialShareButton urlsforShare={urlsforShare} data={""} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-deatils-address">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="add-detail-item">
                  <ul>
                    <li>
                      <img src="/assets/img/icons/icon-con1.svg"></img>
                      {officeslug?.data?.city_name}
                    </li>
                    <li>{officeslug?.data?.address}</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="add-detail-item">
                  <ul>
                    <li>
                      {" "}
                      <a href="tel:+9101141219999">
                        <img src="/assets/img/icons/icon-con3.svg"></img>
                        {officeslug?.data?.phone_number}
                      </a>
                    </li>
                    <li>
                      <a href="tel:+9101141219999">
                        <img src="/assets/img/icons/icon-con2.svg"></img>
                        {officeslug?.data?.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="add-detail-item">
                  <ul className="text-center">
                    {/* <li>
                      {" "}
                      <img
                        crossOrigin=""
                        src="/assets/img/icons/icon-con4.svg"
                      ></img>{" "}
                      {officeslug?.data?.open_time
                        ? officeslug?.data?.open_time
                        : "10:00"}{" "}
                      to{" "}
                      {officeslug?.data?.close_time
                        ? officeslug?.data?.close_time
                        : "18:30"}{" "}
                    </li> */}
                    <li>
                      {officeslug?.data?.close_day
                        ? officeslug?.data?.close_day
                        : "Sunday"}{" "}
                      closed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container mt-4">
          {seo?.header_content && (
            <div className="about-uni-text shade1">
              {/* <SeoHeaderContent /> */}
            </div>
          )}
        </div>
        <section className="contact-deatils-address">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <iframe
                  src={officeslug?.data?.location}
                  width="100%"
                  height="100%"
                  style={{ border: "0", borderRadius: "16px!important" }}
                  loading="lazy"
                ></iframe>
              </div>
              <div className="col-md-5 rightbx bg-white">
                <div className="login d-flex align-items-center contact-detail-form">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="get-touch">
                          Get free scholarship counselling
                        </h5>
                      </div>

                      <div className="fields-wrp w-100">
                        <form id="contactUs">
                          <div className="field">
                            <input
                              type="text"
                              name="name"
                              required
                              value={Office?.name}
                              onChange={(e) => {
                                hendlereviews(e);
                              }}
                            />
                            <label>name</label>
                            <span className="fa fa-user p-2"></span>
                          </div>
                          <div className="field">
                            <input
                              type="text"
                              required
                              name="mobile"
                              maxLength={10}
                              id="mob"
                              value={Office?.mobile}
                              onChange={(e) => {
                                hendlereviews(e);
                              }}
                            />
                            <label>mobile</label>
                            <span className="fa fa-phone p-2"></span>
                          </div>
                          <div className="field">
                            <input
                              type="text"
                              required
                              name="email"
                              id="email"
                              value={Office?.email}
                              onChange={(e) => {
                                hendlereviews(e);
                              }}
                            />
                            <label>email</label>
                            <span className="fa fa-envelope p-2"></span>
                          </div>
                          <div className="field">
                            <textarea
                              className="q-msg"
                              rows={4}
                              cols={50}
                              placeholder="message"
                              name="message"
                              value={Office?.message}
                              onChange={(e) => {
                                hendlereviews(e);
                              }}
                            ></textarea>
                          </div>

                          <div
                            className="fieldbtn mt-100 mob-mb-25"
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              onClick={(e) => {
                                addQuery("contactUs");
                              }}
                              className="bggreadent removeMinHeight btn btn-success br-5 rem-margin-height"
                            >
                              send
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {officeslug?.data?.short_description && (
          <section className="imp-instruction">
            <div className="container about-uni-text shade1 mt-3 d-flex-column justify-content-center">
              {officeslug?.data?.short_description.length <= 90 ||
              showFullContent ? (
                <div
                  className="seo-footer-content"
                  dangerouslySetInnerHTML={{
                    __html: officeslug?.data?.short_description
                      ? addInlineStylesToHtml(
                          officeslug?.data?.short_description
                        )
                      : "",
                  }}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: (officeslug?.data?.short_description
                      ? addInlineStylesToHtml(
                          officeslug?.data?.short_description
                        )
                      : ""
                    )
                      .split(/\s+/)
                      .slice(0, 90)
                      .join(" "), // Display the first 200 words (or any desired length)
                  }}
                />
              )}
              <div className=" d-flex justify-content-center">
                {officeslug?.data?.short_description && wordCount > 90 && (
                  <button
                    className="view-more readbtn-dynamic "
                    onClick={toggleContent}
                  >
                    {showFullContent ? "Read Less" : "Read More"}{" "}
                    {showFullContent ? (
                      <i className="fa fa-angle-up"></i>
                    ) : (
                      <i className="fa fa-angle-down"></i>
                    )}
                  </button>
                )}
              </div>
            </div>
          </section>
        )}
        <section>
          <div className="container">
            <div className="row">
              <div className="headingmains text-center ofc_add">
                <h4 className="titlewithline course-head" id="offices">
                  Other Location
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.032"
                      height="35.894"
                      viewBox="0 0 43.032 35.894"
                    >
                      <path
                        id="mortarboard"
                        d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                        transform="translate(0 -42.469)"
                        fill="#fbb415"
                      ></path>
                    </svg>
                  </span>
                </h4>
              </div>
              {activeLocation &&
                activeLocation
                  ?.filter((data: any) => data?.office_type === 2)
                  .slice(0, displayCount)
                  .map((data: any, i: any) => (
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={i}>
                      <div className="shade1 text-center h-100">
                        <iframe
                          src={data?.location}
                          width="100%"
                          height="200"
                          style={{ border: "0" }}
                          loading="lazy"
                        ></iframe>
                        <div className="add-detail">
                          <h1>
                            {data?.slug ? (
                              <Link
                                className="__hover"
                                to={"/location/" + data?.slug}
                                target="_blank"
                              >
                                {data?.city_name}
                              </Link>
                            ) : (
                              data?.city_name
                            )}
                          </h1>
                          <p>{data?.address}</p>
                          <div className="c-info">
                            <p>
                              <a href="tel:+9101141219999">
                                <span className="c-tag">phone: </span>
                                {data?.phone_number}
                              </a>
                            </p>
                            <p>
                              <a href="tel:+9101141219999">
                                <span className="c-tag">email: </span>
                                {data?.email}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              {activeLocation &&
                activeLocation.filter((data: any) => data?.office_type === 2)
                  .length > displayCount && (
                  <div
                    className="all-center aos-init aos-animate view-more-cust mb-4"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                  >
                    <a onClick={handleViewMore} className="view-more">
                      {" "}
                      View all
                    </a>
                  </div>
                )}
            </div>
          </div>
        </section>
        {/* <TestimonialUpdate /> */}
        {/* <Counsellor /> */}
        {/* <AppOn /> */}
        <ScholarShipMobile />
        <Services />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "1%",
          }}
          className="container"
        >
          {/* <AdSlider /> */}
        </div>
        {/* <div className="container mt-4">
          {seo?.footer_content && (
            <div className="about-uni-text shade1 mt-5">
              <SeoFooterContent />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
export default ContactUsDetail;
