import {
  LOADING,
  LOGIN,
  LOGIN_WITH_OTP,
  STUDENT_REGISTER,
  STUDENT_VERFIY_OTP,
  STUDENT_CHECK_EMAIL_EXIST,
  STUDENT_CHECK_MOBILE_EXIST,
  STUDENT_UPDATE,
  GET_STUDENT_BY_ID,
  STUDENT_ATTACHMENT,
  GET_ATTACHMENT,
  DELETE_ATTACHMENT,
  EMAIL_VERIFICATION,
  VERIFICATION_OF_EMAIL,
  SHORTLIST_UNIVERSITY,
  SHORTLIST_COURSES,
  STUDENT_DISCUSSION,
  UNI_REVIEW,
  STUDENT_REVIEW,
  SERVICE_LIST,
  MY_APPOINTMENT,
  COACH_COUNSELLOR_FOR_PROFILE,
  STUDENT_PAYMENT_HISTORY,
} from "../common/constant";
type stateType = {
  loading: boolean;
  login: any;
  studentSignup: any;
  studentVerfiyOtpSignup: any;
  studentCheckEmailExist: any;
  studentCheckMobileExist: any;
  studentUpdate: any;
  getstudentById: any;
  studentAttachment: any;
  getAttachment: any;
  loginWithOtp: any;
  deleteAttachment: any;
  verifyEmail: any;
  verification: any;
  shortListUniversity: any;
  shortListCourses: any;
  studentDiscussion: any;
  uniReview: any;
  serviceList: any;
  myAppointment: any;
  coachCounsellorForProfile: any;
  getStudentReview: any;
  getPaymentHistory: any;
}
const initialState: stateType = {
  loading: false,
  login: {},
  studentSignup: {},
  studentVerfiyOtpSignup: {},
  studentCheckEmailExist: {},
  studentCheckMobileExist: {},
  studentUpdate: {},
  getstudentById: {},
  studentAttachment: {},
  getAttachment: {},
  loginWithOtp: {},
  deleteAttachment: {},
  verifyEmail: {},
  verification: {},
  shortListUniversity: {},
  shortListCourses: {},
  studentDiscussion: {},
  uniReview: {},
  serviceList: {},
  myAppointment: {},
  getStudentReview: {},
  coachCounsellorForProfile: {},
  getPaymentHistory: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case LOGIN:
      return { ...state, login: action.payload };
    case STUDENT_REGISTER:
      return { ...state, studentSignup: action.payload }
    case STUDENT_VERFIY_OTP:
      return { ...state, studentVerfiyOtpSignup: action.payload }
    case STUDENT_CHECK_EMAIL_EXIST:
      return { ...state, studentCheckEmailExist: action.payload }
    case STUDENT_CHECK_MOBILE_EXIST:
      return { ...state, studentCheckMobileExist: action.payload }
    case STUDENT_UPDATE:
      return { ...state, studentUpdate: action.payload }
    case GET_STUDENT_BY_ID:
      return { ...state, getstudentById: action.payload }
    case STUDENT_ATTACHMENT:
      return { ...state, studentAttachment: action.payload }
    case GET_ATTACHMENT:
      return { ...state, getAttachment: action.payload }
    case DELETE_ATTACHMENT:
      return { ...state, deleteAttachment: action.payload }
    case LOGIN_WITH_OTP:
      return { ...state, loginWithOtp: action.payload }
    case EMAIL_VERIFICATION:
      return { ...state, verifyEmail: action.payload }
    case VERIFICATION_OF_EMAIL:
      return { ...state, verification: action.payload }
    case SHORTLIST_UNIVERSITY:
      return { ...state, shortListUniversity: action.payload }
      case SHORTLIST_COURSES:
        return { ...state, shortListCourses: action.payload }
    case STUDENT_PAYMENT_HISTORY:
      return { ...state, getPaymentHistory: action.payload }
    case STUDENT_DISCUSSION:
      return { ...state, studentDiscussion: action.payload }
    case UNI_REVIEW:
      return { ...state, uniReview: action.payload }
    case STUDENT_REVIEW:
      return { ...state, getStudentReview: action.payload }
    case SERVICE_LIST:
      return { ...state, serviceList: action.payload }
    case MY_APPOINTMENT:
      return { ...state, myAppointment: action.payload }
    case COACH_COUNSELLOR_FOR_PROFILE:
      return { ...state, coachCounsellorForProfile: action.payload }
    default:
      return state;
  }
};