import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {  
  LOADING,
  GET_FAQ_CATEGORY_LIST,GET_FAQ_BY_ID,GET_FAQ_LIST
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getfaqcategoryListAction = (data:any) => {
  return { type: GET_FAQ_CATEGORY_LIST, payload: data };
};
const getfaqByIdAction = (data:any) => {
  return { type: GET_FAQ_BY_ID, payload: data };
};
const getfaqListAction = (data:any) => {
  return { type: GET_FAQ_LIST, payload: data };
};

export const getfaqcategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getfaqcategory}`);
          dispatch(getfaqcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getfaqbyid = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getfaqbyid}?id=${data?.id}`);
          dispatch(getfaqByIdAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getfaqlist = (data:any) => {
  return async (dispatch:any) => {
    try {
      /* dispatch(loading(true)); */
      const response:any = await api.get(`${URL.getfaqlist+'?status=1'}`);
          dispatch(getfaqListAction(response?.data?.data));
          /* dispatch(loading(false)); */
    }
    catch(error:any) {
      /* dispatch(loading(false)); */
      toast.error(error?.response?.data?.message);
    }
  };
};