import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_JOB_LIST, 
  APPLY_FOR_JOB,
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getJobAction = (data:any) => {
  return { type: GET_JOB_LIST, payload: data };
}; 
const addJobAction = (data:any) => {
  return { type: APPLY_FOR_JOB, payload: data };
};

export const jobList = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.jobList}`);
          dispatch(getJobAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
 

export const applyForJob = (data:any, history:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.post(`${URL.applyJob}`,data);
          dispatch(addJobAction(response?.data?.data));
          dispatch(loading(false));
          if(response?.data?.status) {
            history.push("/thank-you");
          }
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};