import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { studentAction } from "../redux/common/action";
import { userLoginValidate, otpValidate, counselloerLoginValidate } from "../validation/Validation";
import Seo from "../Component/Seo/Seo";
import { getToken } from "../Utils/Auth/Token";
import jwt_decode from "jwt-decode";

interface error {
  [key: string]: any;
}

function SignIn(props: any) {
  const dispatch = useDispatch();
  const history = useHistory()
  const id: any = useLocation()
  const [values, setValues] = useState<any>({ receive_Whatsapp: false });
  const [otp, setOtp] = useState<any>();
  const [errors, setErrors] = useState<error>({});
  const [formValidate, setSubmitting] = useState<boolean>(false);
  const [otpLoginerrors, setOtpLoginerrors] = useState<error>({});
  const [otpLoginValidate, setOtpLoginValidate] = useState<boolean>(false);
  const [otpErrors, setOtpErrors] = useState<error>({});
  const [otpvalid, setOtpvalid] = useState<boolean>(false);
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const [minutes, setMinutes] = useState<any>(0);
  const [seconds, setSeconds] = useState<any>(0);

  values.otp = otp?.otp



  $(document).on("input", ".number", function () {
    this.value = this.value.replace(/\D/g, "");
  });







  const handleChange = (e: any) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target?.name]: e.target?.checked });
    } else {
      setValues({ ...values, [e.target?.name]: e.target?.value });
    }

  };


  const userLogin: any = useSelector((state: any) =>
    state.student.studentVerfiyOtpSignup ? state.student.studentVerfiyOtpSignup : []
  );

 

  useEffect(() => {
    if (userLogin?.statusCode == 200) {
      if (id?.state?.logback == 1) {
        history.goBack()
      }
      else {
        history.push("/applynow");
      }
    }
  }, [userLogin]);




  const handleChangeotp = (e: any) => {
    const { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
  };


  useEffect(() => {
    const validationErrors = userLoginValidate(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setSubmitting(noErrors);
    setOtpLoginerrors(validationErrors);
  }, [values]);


  useEffect(() => {
    const validationErrors = counselloerLoginValidate(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setOtpLoginValidate(noErrors);
    setErrors(validationErrors);
  }, [values]);

  useEffect(() => {

    const validationErrors = otpValidate(otp);
    let noErrors = Object.keys(validationErrors).length == 0;
    setOtpvalid(noErrors);
    setOtpErrors(validationErrors);
  }, [otp]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {

      clearInterval(myInterval);
    };
  });

  const handleLogin = (event: any) => {
    event.preventDefault();
    dispatch(studentAction.loginWithPassword(values, history));
    setSeconds(30)
    // createUser(values);
  };
  const handleOtpLogin = (event: any, resend:any) => {
    event.preventDefault(); 
    let newValue = {...values};
    if(resend){
      newValue.is_resent = true
    }
    dispatch(studentAction.loginWithOtp(newValue, history));
    setSeconds(30);
    // createUser(values);
  };
  const handleVerifyOtp = (event: any) => {
    event.preventDefault(); 
    dispatch(studentAction.studentVerfiyOtp(values, history));
    // createUser(values);
  };
  const handleForgetPassword = (event: any) => {
    event.preventDefault();
    dispatch(studentAction.forgetPassword({mobile:values?.mobile}));
  };


  return (
    <div>
      <Seo slug={window.location.pathname}/>
      <section className="categories section bg-grey login_sec">
        <div className="container">
          <div className="loginwrap">
            <div className="row g-0">
              <div className="d-none d-md-flex col-md-4 col-lg-5 bg-image">
                <div>
                  <h3 className="text-white">welcome back</h3>
                  <p className="text-center text-white">
                    Please Login To Your Account To Get Free Expert Advice And Latest Information On Study Abroad.
                  </p>
                </div>
              </div>
              <div className="col-md-8 col-lg-7 rightbx">
                <div className="login d-flex align-items-center py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10 mx-auto">
                        <h5 className="text-center">
                          please sign in to your account with mobile number
                        </h5>
                      </div>
                      <div className="col-md-9 col-lg-8 mx-auto">
                        <div className="fields-wrp">
                          <form >
                            <div className="field"  >
                              <input
                                type="text"
                                name="mobile"
                                value={values?.mobile}
                                maxLength={10}
                                onChange={(e: any) => handleChange(e)}
                                required
                                className="number"
                              />
                              <label>mobile number</label>
                              <span className="fa fa-phone p-2"></span>
                            </div>
                            {errors?.mobile && values?.mobile !== undefined && (
                              <span className="error">{errors?.mobile}</span>
                            )}
                            {/* <div className="field">
                              <input
                                type="password"
                                name="password"

                                value={values?.password}
                                onChange={(e: any) => handleChange(e)}
                                required
                              />
                              <label>password</label>
                              <span className="fa fa-lock p-2"></span>
                            </div> */}

                            {/* {errors?.password &&
                              values?.password !== undefined && (
                                <span className="error">
                                  {errors?.password}
                                </span>
                              )} */}

                            <div className="pt-3">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="remember-me"
                                  name="receive_Whatsapp"
                                  onChange={(e: any) => handleChange(e)}
                                />
                                <label>receive updates on whatsapp</label>
                              </div>
                            </div>
                            <div className="signup-link">
                              don't have an account?
                              <Link to={"/register"}>register now</Link> 
                            </div>
                            <div className="fieldbtn">
                              <input
                                type="submit"
                                data-toggle="modal"
                                data-target="#loginwithotp"

                                onClick={(e: any) => handleOtpLogin(e, false)}
                                className={
                                  !otpLoginValidate
                                    ? "disabledClass bggreadent"
                                    : "bggreadent"
                                }
                                value="login with otp"
                              />
                              <input
                                type="button"
                                data-toggle="modal"
                                data-target="#loginwithPass"
                                // className="bggreadent px-2"
                                className={
                                  !otpLoginValidate
                                    ? "disabledClass bggreadent px-2"
                                    : "bggreadent px-2"
                                } 
                                value="login with password"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="loginwithotp"
        className="modal fade loginwithotp"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content modal-login p-3">
            <div className="modal-header border-0">
              <div className="text-center imgotp">
                <img src="assets/img/otpa.png" className="scale" alt="" />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-11 col-lg-10 mx-auto">
                <div className="fields-wrp">
                  <h5>
                    a text message with a verification code was just sent to
                    xxxxxxxx{values?.mobile?.slice(-2)}
                  </h5>
                  <form>
                    <div
                      className="pass-link text-right mt-2 "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <a
                        data-dismiss="modal"
                        aria-label="Close">change mobile number</a>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        name="otp"
                        value={otp?.otp}
                        onChange={(e: any) => handleChangeotp(e)}
                        required
                        id="OTP"
                        maxLength="6"
                        className="number"
                      />
                      <label>enter otp</label>
                      <span className="fa fa-certificate p-2"></span>
                    </div>

                    {otpErrors.otp && otp !== undefined && (
                      <span className="error">{otpErrors.otp}</span>
                    )}
                    <div className="signup-link">
                      <a data-dismiss="modal">didn't receive the code?</a>{" "}
                      {minutes === 0 && seconds === 0
                        ? null
                        : minutes < 10
                          ? `0${minutes}`
                          : minutes}
                      {minutes === 0 && seconds === 0
                        ? null
                        : seconds < 10 ? ":" + `0${seconds}` : ":" + seconds}
                        {minutes === 0 && seconds === 0 ? (
                          <a 
                            className={
  
                              "text_hyper"
                            }
                            onClick={(e:any)=>handleOtpLogin(e,true)} 
                          >resend otp</a>
                        ) : ""}
                    </div>
                    <div className="fieldbtn">
                      <input
                        type="submit" 
                        className={
                          !otpvalid ? "disabledClass bggreadent" : "bggreadent"
                        }
                        onClick={(e: any) => handleVerifyOtp(e)}
                        value="verify"
                      />
                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="loginwithPass"
        className="modal fade loginwithotp"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content modal-login p-3">
            <div className="modal-header border-0">
              <div className="text-center imgotp">
                <img src="assets/img/pass.png" className="scale" alt="" />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-11 col-lg-10 mx-auto">
                <div className="fields-wrp">
                  <h5>
                    your login mobile number is {values?.mobile}
                  </h5>
                  <form>
                    <div
                      className="pass-link text-right mt-2 "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <a
                        data-dismiss="modal"
                        aria-label="Close">change mobile number</a>
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        name="password"
                        value={values?.password}
                        onChange={(e: any) => handleChange(e)}
                        required
                      />
                      <label>enter password</label>
                      <span className="fa fa-lock p-2"></span>
                    </div>
                    {errors?.password &&
                      values?.password !== undefined && (
                        <span className="error">
                          {errors?.password}
                        </span>
                      )}
                    <div className="signup-link">
                    </div>
                    <div className="fieldbtn">
                      <input
                        type="submit"
                        data-dismiss="modal"
                        aria-label="Close"
                        className={
                          !formValidate
                            ? "disabledClass bggreadent px-2"
                            : "bggreadent px-2"
                        }
                        onClick={(e: any) => handleLogin(e)}
                        value="login"
                      />
                      <input
                        type="submit"
                        data-dismiss="modal"
                        aria-label="Close"
                        className={"bggreadent px-2"}
                        onClick={(e: any) => handleForgetPassword(e)}
                        value="Forget Password"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
