import { 
  LOADING,
  UPLOAD_FILE,  
} from "../common/constant";
 type stateType = {
  loading:boolean;  
  uploadFile: any[];
 }
const initialState : stateType = {
    loading:false, 
    uploadFile: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case UPLOAD_FILE:
      return { ...state, uploadFile: action.payload};  
    default:
      return state;
  }
};