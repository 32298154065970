import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const ToelfMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">TOEFL<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p> The 'Exam of English as a Foreign Language' (TOEFL) is administered by the Educational Testing Service. The TOEFL exam is available in two formats: iBT (internet-based test) and PBT (paper-based test). The TOEFL iBT, released in 2005, is an updated version of the TOEFL CBT (Computer Based Test). Even though the scoring on each part differs between the two versions (iBT and PBT), both have the same exam design. Among the two, the TOEFL iBT is preferred by 97 per cent of students.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/toefl01.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What is the importance of the TOEFL Exam?</h4>
                                    <div className="postcard-para-txt">TOEFL is one of the most widely accepted English-language examinations for students wishing to study abroad, with over 11,000 universities and institutions worldwide accepting it. Universities in Australia, Canada, New Zealand, the United Kingdom, the United States, and across Europe and Asia are a few who accept TOEFL. Students who take the TOEFL test may very well be eligible for a variety of scholarships and assistance with the visa application process for students and individuals seeking employment overseas.s
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gre01.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Types of TOEFL Exam</h4>
                                    <div className="postcard-para-txt">There are two basic forms of TOEFL exams: PBT and IBT and alternative TOEFL tests like TOEFL Junior and IPT.
                                    </div>
                                </div>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1">  TOEFL PBT</h4>
                                </div>
                                <p>TOEFL PBT stands for Paper-Based Test. The Paper-based examination is one of the most prevalent varieties of the TOEFL exam and is taken in offline mode. It is only available for those who do not have access to the internet and has a total duration of 2 hours 25 minutes. It is divided into three sections and has a total duration of 2 hours 25 minutes. </p>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1">  TOEFL IBT</h4>
                                </div>
                                <p> TOEFL iBT in its full form is "Internet-Based Test." The Internet-based TOEFL is divided into four sections: reading, listening, speaking, and writing, with a total duration of four hours. It is one of the most popular TOEFL exam formats, and it has been in use since 2006, when the computer-based test was phased out.</p>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/toefl02.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Who accepts TOEFL scores</h4>
                                    <div className="postcard-para-txt"> According to the official TOEFL ETS website, TOEFL scores are accepted by over 9,000 schools (colleges, community colleges, and universities) in over 130 countries. TOEFL scores are now widely accepted as the most reliable indicator of English competence in speaking, writing, reading, and listening. Here are some more organisations that accept TOEFL results in addition to academic institutions like universities: They are accepted by immigration authorities to process residence and work permits. Boards of Pharmacy, Dentistry, Physical Therapy, and other areas of medicine Is anyone interested in determining their English proficiency level?</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">How to register for TOEFL Exam</h4>
                                    <div className="postcard-para-txt">Visit the Official Website for registration.<br/>Create a profile for the TOEFL exam taker.<br/>Fill out the TOEFL application form.<br/>Choose your TOEFL exam day and location, or choose TOEFL at Home.<br/>Pay the $190 TOEFL registration cost.<br/>Confirm your TOEFL application and submit it.<br/>Take a printout of your TOEFL confirmation page or save it to your computer for future reference.<br/>You can register for the exam via phone, online, mail, or in person at the official website too. The TOEFL exam can be taken at any time of the year. TOEFL applicants who choose to take the TOEFL exam at an authorised TOEFL test centre can choose from various test centres.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/toefl03.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Where can TOEFL Exam take you?</h4>
                                    <div className="postcard-para-txt">More than 11,000 colleges, universities, and other organisations across 150 countries accept TOEFL, including universities in the US, universities in the UK, universities in Australia, universities in New Zealand, France's universities, Germany's universities, and Canada's universities.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gre03.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of TOEFL Exam</h4>
                                    <div className="postcard-para-txt"> The TOEFL is a well-known English language exam. It is recognised by more than 9,000 colleges and universities in more than 130 countries. The TOEFL test is administered over 50 times each year in over 4,500 test centres in over 165 countries worldwide. Because the test is only for one day, it saves time. One of the main reasons this test is so popular among universities and colleges is that it accurately assesses English-language abilities. Hence, it is extremely dependable. Want to take the TOEFL but don't know where to start? Don't be concerned! Admissify is ready to help you! Admissify is India's top foreign education consultancy, with offices in London, Boston, Hanover, Vancouver, Sydney, and other cities. By giving aid and counselling tailored to their personal needs, we hope to empower students to pursue their ambition of studying abroad.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamForm service={"TOEFL-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ToelfMobile