import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment"
import {
  applyNoAction,
  UniversityCourseAction,
  countryAction,
  courseAction,
  streamAction,
} from "../../redux/common/action";
import Slider from "react-slick";
import jwt_decode from "jwt-decode";
import { getToken } from "../../Utils/Auth/Token";
import { getQuarters } from "../../Utils/Common/Common";
import Seo from "../Seo/Seo";
import { useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { toast } from "react-toastify";
import { $CombinedState } from "redux";

function ApplyNow() {

  const isAuthenticated: any = getToken();
  const getQuartersArr = getQuarters();

  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState("steps1");
  const [stepvalue, setStepvalue] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [highest_edu, sethighest_edu] = useState<any>({});
  const [tests, settests] = useState<any>({});
  const [testNot, settestNot] = useState<any>("");
  const [Specialization, setSpecialization] = useState<any[]>([]);
  const [intake, setintake] = useState<any>({});
  const [stream, setstreams] = useState<any>([]);
  const [title, settitle] = useState<any>("applynow");
  const [countryFilter, setcountryFilter] = useState<any>([]);
  const [applyNow, setapplyNow] = useState<any>({});
  const [streamId, setstreamId] = useState<any>({});
  const [objective, setobjective] = useState<any>({});
  const baseurl = URL.API_BASE_URL

  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const Interest = studentProfile?.interest ? JSON.parse(studentProfile?.interest) : "";
  console.log(studentProfile?.interest);


  const university: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );

  const testValue =
    testNot == "I have not given any exam" ? testNot : tests;

  // applyNow.student_id = user?.id;
  applyNow.highest_edu = highest_edu;
  applyNow.tests = testValue;
  applyNow.course_id = Specialization;
  applyNow.country_id = country;
  applyNow.objective = objective;
  applyNow.intake = intake?.intake;
  // applyNow.university_id = university?.id;

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const courses: any[] = useSelector((state: any) =>
    state.course.allCourse ? state.course.allCourse : []
  );

  const applyData: any = useSelector((state: any) =>
    state.applyNow.applicatadd ? state.applyNow.applicatadd : []
  );

  const allstreams: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );


  var Months = [];

  Months['1'] = 'Jan - Mar';
  Months['2'] = 'Apr - Jun';
  Months['3'] = 'Jul - Sep';
  Months['4'] = 'Oct - Dec';


  const activeStream = allstreams && allstreams?.filter((data: any) => {
    return data?.status == 1
  })

  const activeCourse = courses && courses?.filter((data: any) => {
    return data?.status == 1;
  })


  // const hendlback = (e: any) => {
  //   history.push("/universities", {
  //     country_id: applyNow.country_id, course_id: applyNow.course_id
  //   }); 
  // }

  const hendlback = (e: any) => {
    history.push("/");
  }

  const handleProfile = (e: any) => {
    history.push("/profile");
  }

  const getApplicant: any = useSelector((state: any) =>
    state.applyNow.getAllaplicant ? state.applyNow.getAllaplicant : []
  );

  const filterApplicant = getApplicant && getApplicant?.filter((data: any) => {
    return data?.student_id == user?.id
  }
  )
 

  const course =
    activeCourse &&
    activeCourse?.filter((data: any) => {
      return data?.parent_id == streamId;
    });

  const streams =
    activeStream &&
    activeStream?.filter((data: any) => {
      return data?.course_level_id == applyNow?.course_level;
    });

 
  useEffect(() => {
    dispatch(courseAction.getAllCourses({}));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(streamAction.getstreamlist({}));

    return () => { };
  }, []);


  useEffect(() => {
    dispatch(applyNoAction.getallapplicant({}));
    return () => { };
  }, []);

  const filterCountry = (e: any) => {
    const countryvalue = e.target.value.trim().toLowerCase();
    const countryFil = countrys && countrys?.filter((data) => {
      return data?.country_name?.toLowerCase().match(countryvalue);
    });
    setcountryFilter(countryFil);
  };

  const hendlClickSpecialization = (event: any) => {
    var course = event.target.value.split('&');
    const id = course[0];
    const cName = course[1];

    var NewArr = ({ courseId: id, courseName: cName });
    let array = [...Specialization];

    if (event.target.checked) {
      if (Specialization?.length >= 3) {
        event.target.checked = false;
        return false;
      } else {
        var isExist = false;
        if (Specialization?.length > 0) {
          Specialization.map((v: any, k: any) => {
            if (v.courseId == id) {
              isExist = true;
            }
          });
        }
        if (!isExist) {
          array.push(NewArr);
          setSpecialization(array);
        }
      }
    } else {
      var newArray: any = [];
      if (Specialization?.length > 0) {
        Specialization.map((v: any, k: any) => {
          if (v.courseId != id) {
            newArray.push({ courseId: v.courseId, courseName: v.courseName });
          }
        });
        setSpecialization(newArray);
      }

    }
  };


  const hendlClickCountry = (event: any) => {
    var countries = event.target.value.split('&');
    const Id = countries[0];
    const CountryName = countries[1];
    var countryArr = ({ countryId: Id, countryName: CountryName });
    let array = [...country];

    if (event.target.checked) {
      if (country.length >= 3) {
        event.target.checked = false;
        return false;
      } else {
        var isExist = false;
        if (country?.length > 0) {
          country.map((v: any, k: any) => {
            if (v.countryId == Id) {
              isExist = true;
            }
          });
        }
        if (!isExist) {
          array.push(countryArr);
          setcountry(array);
        }
      }
    } else {
      var newArray: any = [];
      if (country?.length > 0) {
        country.map((v: any, k: any) => {
          if (v.countryId != Id) {
            newArray.push({ countryId: v.countryId, countryName: v.countryName });
          }
        });
        setcountry(newArray);
      }
    }
  };

  const hendleTestNot = (e: any) => {
    if (e.target.checked) {
      settestNot(e.target.value);
    } else {
      settestNot("");
    }
  };

  const hendleTests = (e: any) => {
    const { name, value } = e.target;
    settests({ ...tests, [name]: value });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setapplyNow({ ...applyNow, [name]: value });
  };




  const handleStreamChange = (e: any, streamName: any) => {
    var StreamId = e.target.value;
    const { name, value } = e.target;
    var streamData = ({ streamId: StreamId, streamName: streamName })
    setapplyNow({ ...applyNow, streamData });
    setstreams(streamName);
    setstreamId(StreamId);
  };
  var studentInterest = ({ mobile: user?.mobile, id: user?.id, updated_by: user?.id, interest: JSON.stringify([applyNow]) })

  const handleApply = (event: any, value: any) => {

    $('.error-class').remove();
    var isChecked = $("input[name=highest_edu]");
    const ResultType = $("#resultType_").val();
    const highest_edu_ = $("#highest_edu_").val();
    if (!$(isChecked).is(':checked')) {
      $("#highest_edu_").after('<div class="error-class">select highest education level.</div>');
      return false;
    }
    if (!ResultType) {
      $("#highest_edu_").after('<div class="error-class">select result type.</div>');
      return false;
    }
    if (!highest_edu_) {
      $("#highest_edu_").after('<div class="error-class">enter achieved or expected marks.</div>');
      return false;
    }
    if (ResultType == 'Percentage') {
      if (highest_edu_) {
        var isError = false;
        if (highest_edu_ > 100) {
          $("#highest_edu_").after('<div class="error-class">maximum marks upto 100.</div>');
          isError = true;
        }
        if (isError) {
          return false;
        }
      }
    }
    if (ResultType == 'CGPA') {
      if (highest_edu_) {
        $('.error-class').remove();
        var isError = false;
        if (highest_edu_ > 10) {
          $("#highest_edu_").after('<div class="error-class">maximum cgpa upto 10.</div>');
          isError = true;
        }
        if (isError) {
          return false;
        }
      }
    }
    event.preventDefault();
    let arr = [...stepvalue];
    arr.push(value);
    setStepvalue(arr);
    setStep(value);
    dispatch(applyNoAction.applicantAdd(studentInterest));
  };

  const hendleHestEdu = (e: any) => {
    const { name, value } = e.target;
    sethighest_edu({ ...highest_edu, [name]: value });
  };
  const handleObjective = (e: any) => {
    const { name, value } = e.target;
    setobjective({ ...objective, [name]: value });
  };
  const hendleintake = (e: any) => {
    const { name, value } = e.target;
    setintake({ ...intake, [name]: value });
  };

  const hendlClick = (value: string) => {
    $('.error-class').remove();
    const isExamSelected = $("input[name=test]");
    const AchievedMark = $("#achieved_marks").val();
    const notexam = $("#notexam");
    if (value === 'steps8') {
      if (!$(notexam).is(':checked') && !$(isExamSelected).is(':checked')) {
        $("#achieved_marks").after('<div class="error-class">Need to select 1 option.</div>');
        return false;
      }
      if ($(isExamSelected).is(':checked')) {
        if (!AchievedMark) {
          $("#achieved_marks").after('<div class="error-class">Enter your obtain marks.</div>');
          return false;
        }
      }
      if (tests?.test == 'PTE') {
        if (AchievedMark) {
          $('.error-class').remove();
          var isError = false;
          if (AchievedMark > 90) {
            $("#achieved_marks").after('<div class="error-class">Maximum marks upto 90.</div>');
            isError = true;
          }
          if (isError) {
            return false;
          }
        }
      }
      if (tests?.test == 'IELTS') {
        if (AchievedMark) {
          $('.error-class').remove();
          var isError = false;
          if (AchievedMark > 9) {
            $("#achieved_marks").after('<div class="error-class">Maximum marks upto 9.</div>');
            isError = true;
          }
          if (isError) {
            return false;
          }
        }
      }
      if (tests?.test == 'TOEFL') {
        if (AchievedMark) {
          $('.error-class').remove();
          var isError = false;
          if (AchievedMark > 120) {
            $("#achieved_marks").after('<div class="error-class">Maximum marks upto 120.</div>');
            isError = true;
          }
          if (isError) {
            return false;
          }
        }
      }
    }

    let arr = [...stepvalue];
    arr.push(value);
    setStepvalue(arr);
    setStep(value);
  };

  const hendlbackClick = (e: any, value: string) => {
    let arr = [...stepvalue];
    let coun = arr.indexOf(e.target.name);
    if (coun > -1) {
      arr.splice(coun, 1);

      setStepvalue(arr);
      setStep(value);
    }
  };

  // Slider

  var applymapslider = {
    dots: false,
    arrows: true,
    infinite: false,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Seo slug={window.location.pathname}/>
      <ScrollToTop />
      {Interest.length == 1 ? history.push("/profile") :
        <section className="categories section login_sec">
          <div className="container">
            <div className="form-wizard">
              <div className="steps">
                <ul>
                  <li className={step == "steps1" ? "done active" : "done"}>
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps2"
                        ? "done active"
                        : stepvalue.includes("steps3")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps3"
                        ? "done active"
                        : stepvalue.includes("steps4")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps4"
                        ? "done active"
                        : stepvalue.includes("steps5")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps5"
                        ? "done active"
                        : stepvalue.includes("steps6")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps6"
                        ? "done active"
                        : stepvalue.includes("steps7")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps7"
                        ? "done active"
                        : stepvalue.includes("steps8")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li
                    className={
                      step == "steps8"
                        ? "done active"
                        : stepvalue.includes("steps9")
                          ? "done"
                          : ""
                    }
                  >
                    <span></span>
                  </li>
                  <li className={step == "steps9" ? "done " : ""}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <div className="myContainer">
                <div
                  className={
                    step == "steps1"
                      ? "form-container animated steps1 active flipInX"
                      : "form-container animated steps1 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      What course level are you <b>targeting?</b>{" "}
                    </h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image">
                      <li>
                        <input
                          type="radio"
                          id="option1"
                          name="course_level"
                          value="1"
                          onChange={(e: any) => handleChange(e)}

                        />
                        <label htmlFor="option1">
                          <span>
                            <img
                              src="assets/img/steps/step1_icon01.png"
                              className="scale"
                            />
                          </span>
                          <p>Bachelor</p>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="option2"
                          name="course_level"
                          value="2"
                          onChange={(e: any) => handleChange(e)}
                        />
                        <label htmlFor="option2">
                          <span>
                            <img
                              src="assets/img/steps/step1_icon02.png"
                              className="scale"
                            />
                          </span>
                          <p>Master</p>
                        </label>
                      </li>
                    </ul>
                    <div className="form-group text-center mar-b-0">
                      <button
                        type="button"
                        className={applyNow?.course_level == undefined ? "stepdisabled view-more btn2 next" : "view-more btn2 next"}
                        onClick={(e) => {
                          hendlClick("steps2");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps2"
                      ? "form-container animated steps2 active flipInX"
                      : "form-container animated steps2 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      What course are you <b>targeting?</b>{" "}
                    </h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image">
                      {streams &&
                        streams?.map((data: any, i: any) => {

                          return (
                            <li key={i}>
                              <input
                                type="radio"
                                id={"option1s2" + data?.id}
                                name="stream_id"
                                // defaultChecked={i == 0}
                                checked={applyNow?.stream_id?.includes(data?.id)}
                                value={data?.id}
                                onChange={(e: any) =>
                                  handleStreamChange(e, data?.stream_name)
                                }

                              />
                              <label htmlFor={"option1s2" + data?.id}>
                                <p></p>
                                <span>

                                  {data?.stream_icon !== null ? <img src={baseurl + data?.stream_icon} alt="" onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/noImage.jpeg" }} className="scale applyCourse" /> : <img src="/assets/img/noImage.jpeg" alt="" className="scale applyCourse" />}

                                </span>
                                <span>{data?.stream_name && data?.stream_name}</span>
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="form-group text-center mar-b-0">
                      <button
                        type="button"
                        name="steps2"
                        className="view-more btn2 back"
                        onClick={(e) => {
                          hendlbackClick(e, "steps1")
                          setapplyNow({ ...applyNow })
                          // settests({ ...tests, [name]: value });
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className={
                          stream?.length == 0
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps3");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps3"
                      ? "form-container animated steps3 active flipInX"
                      : "form-container animated steps3 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      When would you like to start <b>university/college?</b>{" "}
                    </h3>
                  </div>
                  <form className="text-center">

                    {getQuartersArr && getQuartersArr?.map((data: any, index: any) => {
                      const today = new Date();
                      var year = today.getFullYear();
                      return (<ul className="radio-image">
                        <li>
                          <span>
                            {index == 0 ? year : year + 1} &nbsp;{" "}
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                        </li>
                        {data.map((d: any, dk: any) => {
                          var dateV = d[0] + ' - ' + d[1] + ' - ' + d[2];
                          var date = d[0] + ' - ' + d[1];
                          return (<li>
                            <input
                              type="radio"
                              id={"options" + d[2] + dk}
                              name="intake"
                              value={dateV}
                              onChange={(e: any) => hendleintake(e)}
                            />
                            <label htmlFor={"options" + d[2] + dk}>
                              <span>{date}</span>
                            </label>
                          </li>)
                        })}
                      </ul>)
                    })}

                    
                    
                    <div className="form-group text-center mar-b-0 mt-4">
                      <button
                        type="button"
                        name="steps3"
                        className="view-more btn2 back"
                        onClick={(e) => {
                          hendlbackClick(e, "steps2");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className={
                          intake?.intake == undefined
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps4");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps4"
                      ? "form-container animated steps4 active flipInX"
                      : "form-container animated steps4 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      Select Specialization in <b>{stream}</b>{" "}
                    </h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image">
                      {course?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <input
                              type="checkbox"
                              id={"option1s4" + data?.id}
                              name="check-group"
                              value={data?.id + '&' + data?.title}
                              // disabled={
                              //   Specialization?.length < 2
                              //     ? false
                              //     : Specialization.includes(data?.id.toString())
                              //       ? false
                              //       : true
                              // }
                              onChange={(e: any) => hendlClickSpecialization(e)}
                              defaultChecked={Specialization.includes(data?.id)}
                            />
                            <label htmlFor={"option1s4" + data?.id}>
                              <p></p>
                              <span>

                                {data?.course_icon !== null ? <img src={baseurl + data?.course_icon} alt="" onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/noImage.jpeg" }} className="scale applyCourse" /> : <img src="/assets/img/noImage.jpeg" alt="" className="scale applyCourse" />}
                              </span>
                              <span>{data?.title}</span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="form-group text-center mar-b-0">
                      <button
                        type="button"
                        name="steps4"
                        className="view-more btn2 back"
                        // className={
                        //   Specialization.length == 1
                        //     ? "stepdisabled view-more btn2 back"
                        //     : "view-more btn2 back"
                        // }
                        onClick={(e) => {
                          hendlbackClick(e, "steps3");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className={
                          Specialization.length == 0
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps5");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps5"
                      ? "form-container animated steps2 step5 active flipInX"
                      : "form-container animated steps5 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      What is your primary objective for<b> studying overseas?</b>{" "}
                    </h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image step5label">
                      <li>
                        <input
                          type="radio"
                          id="option1s5"
                          name="objective"
                          value="I want to settle outside of my country and go for
                        Permanent Residency (PR)"
                          onChange={(e: any) => handleObjective(e)}

                        />
                        <label htmlFor="option1s5">
                          <p></p>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="34.875"
                              height="31"
                              viewBox="0 0 34.875 31"
                            >
                              <path
                                id="Icon_awesome-house-damage"
                                data-name="Icon awesome-house-damage"
                                d="M17.438,6.96,4.206,18.631a2.8,2.8,0,0,1-.331.2v11.2A.968.968,0,0,0,4.844,31h9.035l-2.254-4.408,6.3-3.875L14.286,15.5,23.25,23.78l-6.3,3.875L19.364,31H30.031A.968.968,0,0,0,31,30.031v-11.2a2.825,2.825,0,0,1-.318-.194Zm17.116,7.346L31,11.168V2.906a.968.968,0,0,0-.969-.969H26.156a.968.968,0,0,0-.969.969v3.13L19.057.624a2.413,2.413,0,0,0-3.236,0L.322,14.306a.969.969,0,0,0-.073,1.368l1.3,1.442a.969.969,0,0,0,1.368.073L16.8,4.942a.97.97,0,0,1,1.282,0L31.963,17.189a.97.97,0,0,0,1.368-.073l1.3-1.442A.968.968,0,0,0,34.554,14.306Z"
                                transform="translate(0)"
                                fill="#047475"
                              />
                            </svg>
                          </span>
                          <span>
                            I want to settle outside of my country and go for
                            Permanent Residency (PR)
                          </span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="option2s5"
                          name="objective"
                          value="I want to get better job and earning opportunities"
                          onChange={(e: any) => handleObjective(e)}
                        />
                        <label htmlFor="option2s5">
                          <p></p>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="31"
                              height="31"
                              viewBox="0 0 36 36"
                            >
                              <path
                                id="Icon_awesome-coins"
                                data-name="Icon awesome-coins"
                                d="M0,28.5v3C0,33.982,6.047,36,13.5,36S27,33.982,27,31.5v-3c-2.9,2.046-8.212,3-13.5,3S2.9,30.544,0,28.5ZM22.5,9C29.953,9,36,6.982,36,4.5S29.953,0,22.5,0,9,2.018,9,4.5,15.047,9,22.5,9ZM0,21.122V24.75c0,2.482,6.047,4.5,13.5,4.5S27,27.232,27,24.75V21.122c-2.9,2.391-8.22,3.628-13.5,3.628S2.9,23.513,0,21.122Zm29.25.773C33.279,21.115,36,19.666,36,18V15a17.267,17.267,0,0,1-6.75,2.426ZM13.5,11.25C6.047,11.25,0,13.767,0,16.875S6.047,22.5,13.5,22.5,27,19.983,27,16.875,20.953,11.25,13.5,11.25Zm15.42,3.959c4.219-.759,7.08-2.25,7.08-3.959v-3c-2.5,1.765-6.785,2.714-11.3,2.939A7.874,7.874,0,0,1,28.92,15.209Z"
                                fill="#047475"
                              />
                            </svg>
                          </span>
                          <span>
                            I want to get better job and earning opportunities
                          </span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="option3s5"
                          name="objective"
                          value="I want to get education from topmost universities"
                          onChange={(e: any) => handleObjective(e)}
                        />
                        <label htmlFor="option3s5">
                          <p></p>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28.5"
                              height="31"
                              viewBox="0 0 31.5 36"
                            >
                              <path
                                id="Icon_awesome-book"
                                data-name="Icon awesome-book"
                                d="M31.5,25.313V1.688A1.683,1.683,0,0,0,29.813,0H6.75A6.752,6.752,0,0,0,0,6.75v22.5A6.752,6.752,0,0,0,6.75,36H29.813A1.683,1.683,0,0,0,31.5,34.313V33.188a1.7,1.7,0,0,0-.626-1.315,15.68,15.68,0,0,1,0-5.252A1.676,1.676,0,0,0,31.5,25.313ZM9,9.422A.423.423,0,0,1,9.422,9H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,10.828Zm0,4.5a.423.423,0,0,1,.422-.422H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,15.328ZM26.817,31.5H6.75a2.25,2.25,0,0,1,0-4.5H26.817A25.313,25.313,0,0,0,26.817,31.5Z"
                                fill="#047475"
                              />
                            </svg>
                          </span>
                          <span>
                            I want to get education from topmost universities
                          </span>
                        </label>
                      </li>
                    </ul>
                    <div className="form-group text-center mar-b-0">
                      <button
                        name="steps5"
                        type="button"
                        className="view-more btn2 back"
                        onClick={(e) => {
                          hendlbackClick(e, "steps4");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button" 
                        className={
                          Object.keys(objective).length == 0
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps6");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps6"
                      ? "form-container animated steps6 active flipInX"
                      : "form-container animated steps6 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      What is your preferred country for <b>admission?</b>{" "}
                    </h3>
                    <p className="country_text">select upto three countries</p>
                  </div>

                  <form className="text-center">
                    <div className="program-search">

                    </div>

                    <ul className="radio-image applymapslider">
                      <Slider {...applymapslider}>
                        {countryFilter.length > 0
                          ? countryFilter?.map((data: any, i: any) => {

                            return (
                              <li key={i}>
                                <input
                                  type="checkbox"
                                  id={"option1s6" + data?.id}
                                  name="check-group"
                                  className="abcd"
                                  value={data?.id + '&' + data?.country_name}
                                  
                                  onChange={(e: any) => hendlClickCountry(e)}
                                  defaultChecked={country.includes(data?.id)}
                                />

                                <label htmlFor={"option1s6" + data?.id}>
                                  <p></p>
                                  <span>
                                    {data?.flag !== null ? <img src={baseurl + data?.flag} alt="" onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/noImage.jpeg" }} className="scale applyCourse" /> : <img src="/assets/img/noImage.jpeg" alt="" className="scale applyCourse" />}


                                  </span>
                                  <h6>{data?.country_name}</h6>
                                </label>
                              </li>
                            );
                          })
                          : countrys && countrys?.map((data: any, i: any) => { 
                            return (
                              <li key={i}>
                                <input
                                  type="checkbox"
                                  id={"option1s6" + data?.id}
                                  name="check-group"
                                  className="abcd"
                                  value={data?.id + '&' + data?.country_name}
                           
                                  onChange={(e: any) => hendlClickCountry(e)}
                                  defaultChecked={country.includes(data?.id)}
                                />

                                <label htmlFor={"option1s6" + data?.id}>
                                  <p></p>
                                  <span>
                                    {data?.flag !== null ? <img src={baseurl + data?.flag} alt="" onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/noImage.jpeg" }} className="scale applycountry" /> : <img src="/assets/img/noImage.jpeg" alt="" className="scale applycountry" />}

                                    
                                  </span>
                                  <h6>{data?.country_name}</h6>
                                </label>
                              </li>
                            );
                          })}
                      </Slider>
                    </ul>
                    <div className="form-group text-center mar-b-0 mt-4">
                      <button
                        type="button"
                        name="steps6"
                        className="view-more btn2 back"
                        onClick={(e) => {
                          hendlbackClick(e, "steps5");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className={
                          country.length == 0
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps7");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps7"
                      ? "form-container animated steps3 steps7 flipInX active flipInY"
                      : "form-container animated steps7 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>
                      which english proficiency exam you have
                      <br />
                      given (or planning to give)?{" "}
                    </h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image">
                      <li>
                        <input
                          type="radio"
                          id="options71"
                          name="test"
                          value="IELTS"
                          checked={tests?.test?.includes("IELTS")}
                          disabled={
                            testValue == "I have not given any exam"
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            hendleTests(e)
                            settests({ marks: "", test: "IELTS" })
                          }}
                        />
                        <label htmlFor="options71">
                          <span>IELTS</span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="options72"
                          name="test"
                          value="PTE"
                          checked={tests?.test?.includes("PTE")}
                          disabled={
                            testValue == "I have not given any exam"
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            hendleTests(e)
                            settests({ marks: "", test: "PTE" })
                          }}
                        />
                        <label htmlFor="options72">
                          <span>PTE</span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="options73"
                          name="test"
                          value="TOEFL"
                          checked={tests?.test?.includes("TOEFL")}
                          disabled={
                            testValue == "I have not given any exam"
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            hendleTests(e)
                            settests({ marks: "", test: "TOEFL" })
                          }}
                        />
                        <label htmlFor="options73">
                          <span>TOEFL</span>
                        </label>
                      </li>
                    </ul>
                    <div className="form-group text-center form-box">
                      <input
                        type="text"
                        placeholder="Achieved Marks"
                        name="marks"
                        id="achieved_marks"
                        disabled={
                          testValue == "I have not given any exam" ? true : false
                        }
                        value={tests?.marks}
                        onChange={(e: any) =>
                          hendleTests(e)
                        }
                      />
                      {/* <input
                        type="text"
                        placeholder="Achieved Marks"
                        name="marks"
                        id="achieved_marksxyz"
                      /> */}
                      <p>
                        Enter marks between{" "}
                        {tests?.test == "IELTS"
                          ? " 0 - 9"
                          : tests?.test == "PTE"
                            ? " 10 - 90"
                            : tests?.test == "TOEFL"
                              ? " 0 - 120"
                              : "0"}
                      </p>
                      <div className="anyexam">
                        <input
                          type="checkbox"
                          id="notexam"
                          name="check-group"
                          value="I have not given any exam"
                          onChange={(e) => {
                            hendleTestNot(e)
                            settests({ marks: "", test: "" })
                          }}
                        />
                        <label htmlFor="notexam">
                          <span>I have not given any exam</span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group text-center mar-b-0 mt-4">
                      <button
                        type="button"
                        name="steps7"
                        className="view-more btn2 back"
                        onClick={(e) => {
                          hendlbackClick(e, "steps6");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className={
                          testValue.length == 2
                            ? "stepdisabled view-more btn2 next"
                            : "view-more btn2 next"
                        }
                        onClick={(e) => {
                          hendlClick("steps8");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps8"
                      ? "form-container animated steps1 steps7 steps8 active flipInX"
                      : "form-container animated steps1 steps7 steps8 "
                  }
                >
                  <div className="headingmains text-center mb-5">
                    <h3>What is your highest education level?</h3>
                  </div>
                  <form className="text-center">
                    <ul className="radio-image">
                      <li style={{ display: (applyNow?.course_level == 1) ? "" : "none" }}>
                        <input
                          type="radio"
                          id="option1s8"
                          name="highest_edu"
                          value="12th"
                          onChange={(e: any) => hendleHestEdu(e)}
                        />
                        <label htmlFor="option1s8">
                          <span>
                            <img
                              src="assets/img/steps/step8_icon01.png"
                              className="scale"
                            />
                          </span>
                          <p>12th</p>
                        </label>
                      </li>
                      <li style={{ display: (applyNow?.course_level == 2) ? "" : "none" }}>
                        <input
                          type="radio"
                          id="option2s8"
                          name="highest_edu"
                          value="Bachelor"
                          onChange={(e: any) => hendleHestEdu(e)}
                        />
                        <label htmlFor="option2s8">
                          <span>
                            <img
                              src="assets/img/steps/step8_icon02.png"
                              className="scale"
                            />
                          </span>
                          <p>Bachelor</p>
                        </label>
                      </li>
                    </ul>

                    <div className="form-group text-center form-box">
                      <select
                        className="mb-4"
                        name="resultType"
                        id="resultType_"
                        onChange={(e) => hendleHestEdu(e)}
                      >
                        <option value="">Result Type</option>
                        <option value="CGPA">CGPA</option>
                        <option value="Grade">Grade</option>
                        <option value="Percentage">Percentage</option>

                      </select>
                      <input
                        type="text"
                        name="Achieved_Or_Expected_marks"
                        id="highest_edu_"
                        placeholder="Achieved or Expected marks"
                        value={highest_edu?.value}
                        onChange={(e: any) => hendleHestEdu(e)}
                      />
                      <p>Enter expected marks if result is not announced</p>
                    </div>

                    <div className="form-group text-center mar-b-0 mt-4">
                      <button
                        type="button"
                        className="view-more btn2 back"
                        name="steps8"
                        onClick={(e) => {
                          hendlbackClick(e, "steps7");
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Previous
                      </button>
                      <button
                        type="button"
                        className="view-more btn2 next"
                        onClick={(event) => {
                          handleApply(event, "steps9");
                        }}
                      >
                        Next{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className={
                    step == "steps9"
                      ? "form-container animated steps9 active flipInX"
                      : "form-container animated steps9 "
                  }
                >
                  <h2>
                    Great !{" "}
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                  </h2>
                  <h5>
                    You’re on the right path to achieve your dream admission with
                    Admissify. A certified counsellor will be in touch shortly to
                    answer all your questions
                  </h5>

                  <div className="form-group text-center mar-b-0 mt-4">
                    <button /* type="button" */ onClick={hendlback} className="view-more btn2">
                      back to home{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                    <button /* type="button" */ onClick={handleProfile} className="view-more btn2">
                      go to profile{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      }
    </div >
  );
}

export default ApplyNow;
