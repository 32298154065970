import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const SatMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">SAT<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>The SAT Examination is a standardized test that most students who want to go for undergraduate
                        studies take. The broad form of the SAT is Scholastic Aptitude Test. Some people also refer to
                        it as the Scholastic Assessment Test. It mainly targets evaluating the writing, verbal, and
                        mathematical skills of the candidates. All those students who want to pursue an undergraduate
                        program in the US and Canada are supposed to take the test.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/sat01.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What Is the Importance Of SAT?</h4>
                                    <div className="postcard-para-txt">SAT scores are important as they help various
                                        colleges differentiate amongst students from different high schools. The scores
                                        mainly show the students capacity and readiness for college work. The colleges
                                        in the US and Canada have stringent guidelines when it comes to choosing
                                        international students. The SAT scores also enable students to get scholarships
                                        based on merit.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/sat02.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Types of SAT Exam</h4>
                                    <div className="postcard-para-txt">There are two types of SAT exams, the SAT 1 is also
                                        called the Scholastic Assessment Test is required to be taken by students who
                                        want to seek admission to undergraduate courses. It is a general test that aims
                                        to determine the writing abilities, verbal abilities, and mathematical abilities
                                        of students. The second type, which is known as SAT 2, is a more subject-based
                                        test. Students who are looking for a particular course, are required to the take
                                        the SAT subject-based test. It helps them demonstrate their knowledge of the
                                        subject. However, the latter is discontinued now by the College Boards. Now,
                                        aspirants can take only SAT 1 to get admission to the stream of their choice.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/sat03.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">There Are Four Categories Under SAT Exam:</h4>
                                    <div className="postcard-para-txt"> The SAT examination comprises four sections. They
                                        are reading, writing, language, mathematics, and essay. These sections mainly
                                        allow the authorities to determine, a students’ suitability for a particular
                                        stream. The reading section mainly analyses the reading comprehension and
                                        vocabulary understanding of the student. The writing test analyses the
                                        grammatical parts and style of writing. The SAT mathematics section mainly tests
                                        the student’s ability to perform real-world mathematical calculations. It also
                                        measures the problem-solving capabilities, which are essential for college work.
                                        For one section, students are allowed to use a calculator and none for the
                                        other.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coun11.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Who Accepts SAT Scores?</h4>
                                    <div className="postcard-para-txt"> Universities in the US accept SAT scores, and so do
                                        Canadian universities. Some of the other countries also accept SAT scores. They
                                        are
                                        the UK, Australia, Germany, and Singapore. SAT scores allow candidates to apply
                                        for
                                        undergraduate courses in around 800+ universities globally. You can also get
                                        guidance on the exam from Admissify.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">HOW TO REGISTER FOR THE SAT EXAMINATION?</h4>
                                    <div className="postcard-para-txt"> Students who are willing to sit for the SAT
                                        examination need to apply in advance for the same. The application process is
                                        available offline as well as online. If the student is applying through the
                                        online method, then they are required to create a login ID on the official site.
                                        The steps after that are self-explanatory. You can read them below. </div>
                                </div>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1">online</h4>
                                </div>
                                <p>The student has to visit the college
                                    site.<br/>Create an account.<br/>Then the student is required to fill in all the
                                    details.<br/>The student has to choose the subject for which he or she is
                                    interested.<br/>The site asks the student to upload the most recent
                                    photograph.<br/>The student needs to pay the application fee.</p>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1">Offline</h4>
                                </div>
                                <p>If the student is applying offline, a SAT
                                    registration form is required, which is available in the Guide. The form comes with
                                    a return envelope. You, as a student, need to send the form duly filled up along
                                    with the demand draft. The forms are directed to the local regional office for
                                    processing.</p>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/sat04.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of GRE test</h4>
                                    <div className="postcard-para-txt"> Aids high-scoring applicants in gaining admission
                                        and scholarships to top-ranked universities and business schools. The GRE
                                        General Test is treated the same way as other graduate admissions examinations
                                        by the world's leading business schools. It gives colleges a thorough evaluation
                                        of the talents you'll need to succeed in MBA and master's degree programmes.
                                        Unlike other examinations, you can preview and skip questions within each
                                        section and go back to review and amend answers. This can make you feel more at
                                        ease and help you attain your best test results. The results are valid for five
                                        years. That means you can take it now and still have plenty of time to decide
                                        where and when you want to continue your degree. GRE scores are accepted by over
                                        1,300 business institutions worldwide for admission to MBA and speciality
                                        master's programmes. You can attend top colleges in the United States or famous
                                        programmes throughout Europe. Want to take the GRE but don't know where to
                                        start? Admissify is here to assist you! With offices in London, Boston, Hanover,
                                        Vancouver, Sydney, and other locations across India, Admissify is India's
                                        globally recognized education consultancy. After you arrive, we may provide
                                        in-country assistance such as internship aid, immigration, and career services,
                                        thanks to our global network.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamForm service={"SAT-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default SatMobile