
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { officeAction } from '../../../redux/common/action';
import { getAllCountry } from '../../../redux/country/action';
import { validateFundingForm } from '../../../validation/Validation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useHistory } from 'react-router-dom';

const AccomodationForm = () => {
	const history = useHistory();
    const dispatch = useDispatch();
    const [values, setValues] = useState < any > ({});
    const [errors, setErrors] = useState < any > (undefined);
    const [resume, setResume] = useState < any > ("");
    const country: any[] = useSelector((state: any) =>
        state.country.countryList ? state.country.countryList : []
    );
    const user: any = useSelector((state:any) => state?.student?.getstudentById || {});

    useEffect(() => {
        dispatch(getAllCountry(""));
    }, [])
    const handleOnChnage = (e: any) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    const handleOnSubmit = (e: any) => {
        const validationErrors = validateFundingForm(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data: any = { ...values };
            data.name = user?.name;
            data.email = user?.email;
            data.mobile = user.mobile;
			data.move_in = values?.move_in ? moment(values?.move_in).format('YYYY-MM-DD') : "";
			data.move_out = values?.move_out ? moment(values?.move_out).format('YYYY-MM-DD') : "";
            data.other_details = [
                { name: "college", label: "college/university", value: values?.college || "N/A" },
                { name: "country", label: "preferred country", value: values?.country || "N/A" },
                { name: "property", label: "property", value: values?.property || "N/A" },
                { name: "move_out", label: "move out", value: data?.move_out || "N/A" },
                { name: "move_in", label: "move in", value: data?.move_in || "N/A" },
                { name: "bathroom", label: "bathroom", value: values?.bathroom || "N/A" },
                { name: "bedroom", label: "bedroom", value: values?.bedroom || "N/A" }
            ]
            data.service_name = "accommodation";
			data.thank = true;
            delete data.property;
            delete data.move_out;
             delete data.college;
            delete data.move_in;
            delete data.bathroom;
             delete data.country;
            delete data.bedroom;
            dispatch(officeAction.submitQuery(data,history));
        }
    }
  return (
    <div className="apply-box">
						<div className="container p-2">
							<div className="row">
								<div className="col-md-11">
									<h5 className="apply-head">apply now</h5>
									<p className="appli-c">Flexible living. Monthly stays. Anywhere. Move-in ready
										apartments across 9 Countries &amp; 500+ cities</p>
								</div>
								<div className="col-md-12 col-lg-12">
									<div className="fields-wrap">
										<form id="fundingForm">
											<div className="row">
												<div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="city" id="City" maxLength={10}
                                                value={values?.city || ""} onChange={handleOnChnage} />
                                            <label>city</label>
                                            <span className="fa fa-map-marker p-2"></span>
                                        </div>
                                        {errors?.city && <p className='error-class'>{errors?.city}</p>}
                                    </div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="field"><input autoComplete="new-off" type="text"
															name="bedroom" value={values?.bedroom || ""} onChange={handleOnChnage}/><label>bedroom</label><span
															className="fa fa-bed p-2"></span></div>
												</div>
												<div className="col-md-6">
													<div className="field"><input autoComplete="new-off" type="text"
															name="bathroom" value={values?.bathroom || ""} onChange={handleOnChnage}/><label>bathroom</label><span
															className="fa fa-bath p-2"></span></div>
												</div>
												<div className="col-md-6">
													<div className="field">
															<DatePicker
																selected={values?.move_in || ""}
																onChange={(date: Date) => {
																	setValues({...values, ["move_in"]: date})
																}}
																peekNextMonth
																placeholderText="Move in"
																showMonthDropdown
																showYearDropdown
																name="move_in"
																dateFormat="yyyy-MM-dd"
																dropdownMode="select"
																autoComplete="new-off"
																id="move_in"
																minDate={new Date()}
																maxDate={values?.move_out || null}
															/>
														<span className="fa fa-male p-2"></span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="field">
															<DatePicker
																selected={values?.move_out || ""}
																onChange={(date: Date) => {
																	setValues({...values, ["move_out"]: date})
																}}
																peekNextMonth
																placeholderText="Move out"
																showMonthDropdown
																showYearDropdown
																name="move_out"
																dateFormat="yyyy-MM-dd"
																dropdownMode="select"
																autoComplete="new-off"
																id="move_out"
																minDate={values?.move_in || null}
															/>
															<span className="fa fa-male p-2"></span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="field"><input autoComplete="new-off" type="text"
															name="college"
															value={values?.college || ""} onChange={handleOnChnage}/><label>college/university</label><span
															className="fa fa-university p-2"></span></div>
												</div>
												<div className="col-md-6">
													<div className="field"><span className="fa fa-home p-2"></span><select
															className="form-control c-select" name="property" value={values?.property || ""} onChange={handleOnChnage}>
															<option value="">property type</option>
															<option value="Studio">Studio</option>
															<option value="Shared">Shared</option>
															<option value="Private">Private</option>
														</select></div>
												</div>
												<div className="col-md-6">
													<div className="field"><select className="form-control c-select"
															name="country" value={values?.country || ""} onChange={handleOnChnage}>
															<option value="">preferred country</option>
															{country && country?.map((data: any, i: any) => {
                                                                return (
                                                                <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                                )
                                                            })}
														</select><span className="fa fa-map-marker p-2"></span></div>
												</div>
												<div className="col-md-6">
													<div className="field"><textarea autoComplete="new-off" className="ques-msg border__"
                                                        rows={4} cols={50} placeholder="message" name="message" value={values?.message || ""} onChange={handleOnChnage}></textarea>
                                                        {errors?.message && <p className='error-class'>{errors?.message}</p>}
													</div>
												</div>
											</div>
												<div className="row">
												<div className="col-md-6 mt-4 p-1">
													<div className="fieldbtn text-center mt-5"><input type="button"
															className="bggreadent-n" id="fundingIn" value="submit" onClick={(e)=> handleOnSubmit(e)}/>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
  )
}

export default AccomodationForm