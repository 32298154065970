import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_BLOG_LIST,
  GET_BLOG_BY_ID,
  GET_BLOG_CATEGORY,
  BLOG_UPDATE_LIKE_COMMENT,
  BLOG_LIKE_UNLIKE,
  GET_USER_ACTION_FOR_BLOG_DETAILS,
  BLOG_BY_SLUG,
  BLOG_COMMENT_ADD,
  BLOG_COMMENT_FIND_ALL,
  COUNTRY_CATEGORY,
  COUNTRY_BLOG,
  V_BLOG,
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getCountryBlogAction = (data:any) => {
  return { type: COUNTRY_BLOG, payload: data };
};
const getVBlogAction = (data:any) => {
  return { type: V_BLOG, payload: data };
};
const getCountryCategoryAction = (data:any) => {
  return { type: COUNTRY_CATEGORY, payload: data };
};
const getbloglistAction = (data:any) => {
  return { type: GET_BLOG_LIST, payload: data };
};

const getblogbyidAction = (data:any) => {
  return { type: GET_BLOG_BY_ID, payload: data };
};
const getBlogCategoryAction = (data:any) => {
  return { type: GET_BLOG_CATEGORY, payload: data };
};
const blogupdatelikecommentcountAction = (data:any) => {
  return { type: BLOG_UPDATE_LIKE_COMMENT, payload: data };
};
const blogLikeUnlikeAction = (data:any) => {
  return { type: BLOG_LIKE_UNLIKE, payload: data };
};
const getUserActionForBlogDetailsAction = (data:any) => {
  return { type: GET_USER_ACTION_FOR_BLOG_DETAILS, payload: data };
};

const blogBySlugAction = (data:any) => {
  return { type: BLOG_BY_SLUG, payload: data };
};
const blogCommentAddAction = (data:any) => {
  return { type: BLOG_COMMENT_ADD, payload: data };
};
const blogCommentFindAllAction = (data:any) => {
  return { type: BLOG_COMMENT_FIND_ALL, payload: data };
};

export const getVBlog = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.vBLog}`,data);
          dispatch(getVBlogAction(response?.data?.data));
          /* dispatch(loading(false)); */
    }
    catch(error:any) {
      /* dispatch(loading(false)); */
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCountryBlog = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.blogsList}`,data);
          dispatch(getCountryBlogAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCountryCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.blogsCategories}`,data);
          dispatch(getCountryCategoryAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getbloglist = (data:any) => {
  return async (dispatch:any) => {
    try {
      //dispatch(loading(true));
      const response:any = await api.post(`${URL.getbloglist}`,data);
          dispatch(getbloglistAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getBlogCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      //dispatch(loading(true));
      const response:any = await api.get(`${URL.getBlogCategory}`);
      dispatch(getBlogCategoryAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getblogbyid = (data:any) => {
  return async (dispatch:any) => {
    try {
     // dispatch(loading(true));
      const response:any = await api.get(`${URL.getblogbyid}?id=${data}`);
      dispatch(getblogbyidAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const blogBySlug = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.get(`${URL.blogBySlug}?slug=${data}`);
      dispatch(blogBySlugAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const blogupdatelikecommentcount = (data:any, datas:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.put(`${URL.blogupdatelikecommentcount}/${data}`,datas);
          dispatch(blogupdatelikecommentcountAction(response?.data?.data));
          // dispatch(loading(false));
    }
    catch(error:any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const blogLikeUnlike = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.blogLikeUnlike}`,data);
      dispatch(blogLikeUnlikeAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserActionForBlogDetails = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.getUserActionForBlogDetails}`,data);
      dispatch(getUserActionForBlogDetailsAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const blogCommentAdd = (data:any) => {
  return async (dispatch:any) => {
    try {
      // dispatch(loading(true));
      const response:any = await api.post(`${URL.blogCommentAdd}`,data);
      dispatch(blogCommentAddAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const blogCommentFindAll = (data:any) => {
  return async (dispatch:any) => {
    try {

    
      
      // dispatch(loading(true));
      const response:any = await api.get(`${URL.blogCommentFindAll}`, data);
      dispatch(blogCommentFindAllAction(response?.data?.data));
   
      
      // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};