import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DiscussionAction } from "../../redux/common/action";
import Slider from "react-slick";
import dateFormat from "dateformat";
import { format } from "timeago.js";
import { Link, useHistory } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";

function TrendingDiscussion() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const discuss: any[] = useSelector((state: any) =>
    state.discussion.getTrendingDiscussion
      ? state.discussion.getTrendingDiscussion
      : []
  );
  useEffect(() => {
    dispatch(DiscussionAction.getTrendingDiscussion({}));
    return () => {};
  }, []);

  const handleFreeLike = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="categories section">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline right aos-init aos-animate">
              Trending Discussion{" "}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40.5"
                  height="31.5"
                  viewBox="0 0 40.5 31.5"
                >
                  <path
                    id="Icon_awesome-comments"
                    data-name="Icon awesome-comments"
                    d="M29.25,13.5c0-6.216-6.546-11.25-14.625-11.25S0,7.284,0,13.5a9.539,9.539,0,0,0,2.672,6.469A15.826,15.826,0,0,1,.155,23.8a.559.559,0,0,0-.105.612.551.551,0,0,0,.513.337A12.38,12.38,0,0,0,6.8,22.992a17.891,17.891,0,0,0,7.826,1.758C22.7,24.75,29.25,19.716,29.25,13.5Zm8.578,15.469A9.526,9.526,0,0,0,40.5,22.5c0-4.7-3.762-8.733-9.091-10.413A10.449,10.449,0,0,1,31.5,13.5c0,7.446-7.573,13.5-16.875,13.5a21.081,21.081,0,0,1-2.229-.134c2.215,4.043,7.418,6.884,13.479,6.884A17.793,17.793,0,0,0,33.7,31.992a12.38,12.38,0,0,0,6.237,1.758.554.554,0,0,0,.513-.338.563.563,0,0,0-.105-.612A15.658,15.658,0,0,1,37.828,28.969Z"
                    transform="translate(0 -2.25)"
                    fill="#fbb415"
                  />
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
              Enhance your knowledge base through exclusive discussions. We are
              making it decent and effortless. Come and join now.{" "}
            </p>
          </div>
          <div className="all-center sub-section pb-0">
            <div className="col-md-12 all-center pb-5">
              <div className="tranding_slide">
                <Slider {...settings}>
                  {discuss?.map((data: any, i: any) => {
                    const agodate = format(data?.created_at);
                    return (
                      i < 6 && (
                        <div className="abc" key={i}>
                          <div className="tranding-item">
                            <div className="feat-text">
                              <h6
                                onClick={() => {
                                  history.push(
                                    "/about-discussion/" + data?.slug + "",
                                    {
                                      discussion_id: data?.id,
                                    }
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                                className="pb-3"
                              >
                                {data?.category?.category_name}
                              </h6>

                              <p
                                onClick={() => {
                                  history.push(
                                    "/about-discussion/" + data?.slug + "",
                                    {
                                      discussion_id: data?.id,
                                    }
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {data?.description}
                              </p>
                              <p className="p-3">
                                <span className="time active">
                                  <i className="fa fa-clock-o"></i>
                                  {agodate ? agodate : " 2 hour ago"}
                                </span>
                              </p>
                              <a
                                onClick={() => {
                                  history.push(
                                    "/about-discussion/" + data?.slug + "",
                                    {
                                      discussion_id: data?.id,
                                    }
                                  );
                                }}
                                className="mt_btn_yellow"
                              >
                                comment
                              </a>
                            </div>

                            <ul className="iconscomments">
                              <li>
                                <span>
                                  <img
                                    className="scale"
                                    src="assets/img/thumbs-up.png"
                                  />{" "}
                                  {data?.likes ? data?.likes : "0"}
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img
                                    className="scale"
                                    src="assets/img/comment.png"
                                  />{" "}
                                  {data?.DiscussionsComments
                                    ? data?.DiscussionsComments.length
                                    : "0"}
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img
                                    className="scale"
                                    src="assets/img/file.png"
                                  />{" "}
                                  {data?.views ? data?.views : "0"}
                                </span>
                              </li>
                            </ul>

                            <div className="overlayPort">
                              <ul className="info text-center list-inline">
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40.5"
                                    height="31.5"
                                    viewBox="0 0 40.5 31.5"
                                  >
                                    <path
                                      id="Icon_awesome-comments"
                                      data-name="Icon awesome-comments"
                                      d="M29.25,13.5c0-6.216-6.546-11.25-14.625-11.25S0,7.284,0,13.5a9.539,9.539,0,0,0,2.672,6.469A15.826,15.826,0,0,1,.155,23.8a.559.559,0,0,0-.105.612.551.551,0,0,0,.513.337A12.38,12.38,0,0,0,6.8,22.992a17.891,17.891,0,0,0,7.826,1.758C22.7,24.75,29.25,19.716,29.25,13.5Zm8.578,15.469A9.526,9.526,0,0,0,40.5,22.5c0-4.7-3.762-8.733-9.091-10.413A10.449,10.449,0,0,1,31.5,13.5c0,7.446-7.573,13.5-16.875,13.5a21.081,21.081,0,0,1-2.229-.134c2.215,4.043,7.418,6.884,13.479,6.884A17.793,17.793,0,0,0,33.7,31.992a12.38,12.38,0,0,0,6.237,1.758.554.554,0,0,0,.513-.338.563.563,0,0,0-.105-.612A15.658,15.658,0,0,1,37.828,28.969Z"
                                      transform="translate(0 -2.25)"
                                      fill="#fbb415"
                                    />
                                  </svg>
                                </li>
                                <li className="tdanding">
                                  <p>Trending</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </Slider>
              </div>
            </div>
            <div
              className="all-center aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-offset="200"
            >
              <Link to={"/discussions"} className="view-more">
                View all
              </Link>
              {/* <a href="/discussionlist" className="view-more">
                View all
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrendingDiscussion;
