import React from 'react'

const OnlineCoachingMobile = () => {
  return (
    <>
    <section className="sec_post-c">
        <div className="postwrap-c bluedark-c">
            <div className="container py-2">
                <article className="postcard-c"><img className="postcard__img-c" src="/assets/img/coaching_01.png" alt=""/>
                    <div className="postcard__text-c">
                        <h4 className="pb-3">virtual classNameNameroom</h4>
                        <div className="postcard__preview-txt-c"><img className="scale" src="/assets/img/coaching_icon01.png"
                                alt=""/>
                            <p className="p">Our advanced virtual classNameNamerooms allow you to fully interact with your
                                faculty &amp; learning material. Put your hand up &amp; ask questions at will! Record
                                sessions &amp; save presentations too! Discover the best IELTS online coaching.</p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <div className="postwrap-c">
            <div className="container py-2">
                <article className="postcard-c"><img className="postcard__img-c" src="/assets/img/coaching_02.png" alt=""/>
                    <div className="postcard__text-c">
                        <h4 className="pb-3">interactive &amp; local</h4>
                        <div className="postcard__preview-txt-c"><img className="scale" src="/assets/img/coaching_icon02.png"
                                alt=""/>
                            <p className="p">Learn and interact with the best teaching faculty from around the world and all
                                over India from the comfort of your home, via your phone or PC, or at one of our
                                regional offices, in person!</p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <div className="postwrap-c bluelight">
            <div className="container py-2">
                <article className="postcard-c"><img className="postcard__img-c" src="/assets/img/coaching_03.png" alt=""/>
                    <div className="postcard__text-c">
                        <h4 className="pb-3">record &amp; playback</h4>
                        <div className="postcard__preview-txt-c"><img className="scale" src="/assets/img/coaching_icon03.png"
                                alt=""/>
                            <p className="p">Easily Record amazing learning sessions so you can make notes and review
                                material for great exam preparation. You never have to miss any important learning again
                                !</p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <div className="postwrap-c-c">
            <div className="container py-2">
                <article className="postcard-c"><img className="postcard__img-c" src="/assets/img/coaching_04.png" alt=""/>
                    <div className="postcard__text-c">
                        <h4 className="pb-3">manage &amp; schedule</h4>
                        <div className="postcard__preview-txt-c"><img className="scale" src="/assets/img/coaching_icon04.png"
                                alt=""/>
                            <p className="p">Effortlessly manage your session schedule from the Admissify app, You can even
                                record sessions on the go so you never have to miss a session.</p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
    </>
  )
}

export default OnlineCoachingMobile