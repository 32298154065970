import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouterProps,
  Switch,
  useHistory,
} from "react-router-dom";
import PageNotFound from "../Component/PageNotFound/PageNotFound";
import MobileLayout from "../MobileContainer";
import AboutUsMobile from "../MobileContainer/page/AboutUs";
import AccomodationMobile from "../MobileContainer/page/Accomodation";
import AlumniChatMobile from "../MobileContainer/page/AlumniChat";
import CoachMobile from "../MobileContainer/page/Coach";
import CotactUsMobile from "../MobileContainer/page/ContactUs";
import CounsollerMobile from "../MobileContainer/page/Counsoller";
import FaqMobile from "../MobileContainer/page/Faq";
import FundingMobile from "../MobileContainer/page/Funding";
import GmatMobile from "../MobileContainer/page/Gmat";
import GresMobile from "../MobileContainer/page/Gre";
import HomeMobile from "../MobileContainer/page/Home";
import IeltsMobile from "../MobileContainer/page/Ielts";
import InternshipMobile from "../MobileContainer/page/Internship";
import OnlineCoachingMobile from "../MobileContainer/page/OnlineCoaching";
import PackagesMobile from "../MobileContainer/page/Packages";
import PrivacyPolicyMobile from "../MobileContainer/page/PrivacyPolicy";
import PteMobile from "../MobileContainer/page/Pte";
import SatMobile from "../MobileContainer/page/Sat";
import ScholarShipMobile from "../MobileContainer/page/ScholarShip";
import Services from "../MobileContainer/page/Services";
import TermsConditionMobile from "../MobileContainer/page/Terms";
import TestPreparationMobile from "../MobileContainer/page/TestPreparation";
import ToelfMobile from "../MobileContainer/page/Toefl";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getStudentById } from "../redux/student/action";
import Thankyou from "../MobileContainer/page/Thankyou/Thankyou";
import ContactUsDetail from "../MobileContainer/page/contactUsDetails";

function MobileRoute() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search: any = new URLSearchParams(document.location.search);
  const isAuthenticated: any =
    search?.get("token") || sessionStorage.getItem("mobile:token");
  if (isAuthenticated) {
    sessionStorage.setItem("mobile:token", isAuthenticated || "");
  } else {
    //history.push("/login");
  }
  var user: any;
  const jwt = async () => {
    try {
      const res = jwt_decode(isAuthenticated);
      user = res;
    } catch (err: any) {
      sessionStorage.removeItem("mobile:token");
      history.push("/login");
    }
  };
  isAuthenticated && jwt();
  useEffect(() => {
    if (user?.id) {
      dispatch(getStudentById(user?.id, isAuthenticated));
    }
  }, [user?.id]);

  return (
    <Switch>
      <Route exact path="/app" component={HomeMobile} />
      <Route exact path="/app/services" component={Services} />
      <Route exact path="/app/mentor" component={CoachMobile} />
      <Route exact path="/app/counsellor" component={CounsollerMobile} />
      <Route exact path="/app/scholarship" component={ScholarShipMobile} />
      <Route exact path="/app/contact-us" component={CotactUsMobile} />
      <Route exact path="/app/location/:slug" component={ContactUsDetail} />
      <Route exact path="/app/about-us" component={AboutUsMobile} />
      <Route exact path="/app/funding" component={FundingMobile} />
      <Route exact path="/app/accomadation" component={AccomodationMobile} />
      <Route
        exact
        path="/app/alumni-chat-details"
        component={AlumniChatMobile}
      />
      <Route exact path="/app/faq" component={FaqMobile} />
      <Route exact path="/app/privacy-policy" component={PrivacyPolicyMobile} />
      <Route
        exact
        path="/app/terms-conditon"
        component={TermsConditionMobile}
      />
      <Route exact path="/app/ielts-exam" component={IeltsMobile} />
      <Route exact path="/app/gre-exam" component={GresMobile} />
      <Route exact path="/app/gmat-exam" component={GmatMobile} />
      <Route
        exact
        path="/app/internship-placement"
        component={InternshipMobile}
      />
      <Route
        exact
        path="/app/online-coaching"
        component={OnlineCoachingMobile}
      />
      <Route exact path="/app/pte-exam" component={PteMobile} />
      <Route exact path="/app/sat-exam" component={SatMobile} />
      <Route
        exact
        path="/app/test-preparation"
        component={TestPreparationMobile}
      />
      <Route exact path="/app/toefl-exam" component={ToelfMobile} />
      <Route exact path="/app/packages" component={PackagesMobile} />
      <Route exact path="/app/thank-you" component={Thankyou} />
      <Route
        exact
        path="/app/ielts-details"
        render={(props: RouterProps) => <Redirect to="/app/ielts-exam" />}
      />
      <Route
        exact
        path="/app/gre-details"
        render={(props: RouterProps) => <Redirect to="/appgre-exam" />}
      />
      <Route
        exact
        path="/app/sat-details"
        render={(props: RouterProps) => <Redirect to="/app/sat-exam" />}
      />
      <Route
        exact
        path="/app/pte-details"
        render={(props: RouterProps) => <Redirect to="/app/pte-exam" />}
      />
      <Route
        exact
        path="/app/toefl-details"
        render={(props: RouterProps) => <Redirect to="/app/toefl-exam" />}
      />
      <Route
        exact
        path="/app/gmat-details"
        render={(props: RouterProps) => <Redirect to="/app/gmat-exam" />}
      />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default MobileRoute;
