import React from 'react'
import CoachForm from '../../Component/CoachForm/CoachForm'

const CoachMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">mentor<span><img src="/assets/img/counsellor.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>Should a mentor's goal be to become perfect? This is the ideal workout, training program, or
                        practice routine. A perfect sequence of signals or progressions. It's a seductive goal and one
                        that can be classNameified as aspirational. The goal of positivism, like that of a scientist's
                        experimental design, is to create a perfect world. But, just as that promise has proven elusive,
                        and with it, the growth of knowing and doing as more philosophical and paradoxical than certain
                        and certain (at least to some), can't striving for perfection as a mentor be problematic as well?
                        Besides, the perfect mentor is rational and productive… As a result, giving learners the space
                        and time, they need to develop more suitably may gradually decrease. Even the well-meaning
                        coaching mantra of 'focus on the process aimed at learners has come to mean 'training in a
                        specific way' rather than learning and developing according to the unique context of 'need' and
                        'reality'.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/c-r23.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3 card-head1">Qualities we exhibit.</h4><span
                                        className="border-bt"></span>
                                    <div className="postcard__preview-txt">
                                        <h5 className="pb-3 card-head1">1. Establishing Connections</h5>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <p>Building relationships is the most important aspect of instructional coaching
                                            that must be in place to assure success in any coaching approach. To have an
                                            impact on teaching and learning, there must be space for communication,
                                            expression of concerns and fears, and the opportunity to exhibit your
                                            vulnerabilities. The mentor-teacher connection needs to be safeguarded and
                                            fostered. Students are at the center of all we do in education. It's crucial
                                            to remember that, while data is important for shaping instruction and
                                            decisions, the ultimate goal is to keep the focus on the kids. Effective
                                            coaching entails mentors and teachers collaborating in student-centered
                                            interactions that are tailored to the needs of the students.</p>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <h5 className="pb-3 card-head1">2. Data-Driven Decisions</h5>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <p>BIf we employ data-driven decisions to truly modify our training, we have a
                                            powerful instrument. Being a mentor necessitates the ability to see and
                                            interpret data. Then move on to the next phase of addressing the "so what?"
                                            question. To acquire perspective as a mentor, one must be able to take a
                                            bird's eye view and ask, "What can we modify about our instruction to better
                                            affect student learning?"</p>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <h5 className="pb-3 card-head1">3. Inquiring without Passing Judgment</h5>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <p>While other approaches do not have the same impact as questioning, they can
                                            push change. Mentors can preserve the teacher-mentor relationship by asking
                                            questions. Mentors must be able to ask thought-provoking questions to
                                            instructors. Questions should not be asked for the sake of asking them, but
                                            rather to ensure that student learning is the ultimate goal.</p>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <h5 className="pb-3 card-head1">4. Understanding of High-Impact Instructional
                                            Practices</h5>
                                    </div>
                                    <div className="postcard__preview-txt">
                                        <p>Teachers can be given all of the resources and materials they require in the
                                            classNameroom. The most effective educational tactics and techniques are not
                                            used. Mentors must be aware of the high-impact tactics available. Finally,
                                            they must be able to provide practical suggestions for how a teacher might
                                            begin to improve such practices in their classNameroom.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">why to pick us?</h4>
                                    <div className="postcard-para-txt">Being authentic involves discovering the true you,
                                        the person you were born to be, and realizing your full potential as a result.
                                        We do what we do because we can't see any other option. These behaviors,
                                        however, are in direct opposition to our current views and ideals. As a result,
                                        we are weary and stressed, which affects our motivation, creativity, and
                                        happiness. As a Mentor, we help you become more aware of these conflicts and
                                        stumbling blocks in your life, and how to remove them so that your real self may
                                        shine through and you can live a life that is more in line with your truth.
                                        Finally, we have the personal and professional abilities, expertise, and
                                        experience to assist you with time management, goal-setting, and motivation
                                        tactics.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/concul.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">conclusion</h4>
                                    <div className="postcard-para-txt">Mentors provide a fresh viewpoint and can spot things
                                        you're doing wrong or need to improve on, allowing you to perform at a higher
                                        level. The mentor assists in the facilitation of dialogues that result in
                                        collaborative, introspective, accountable, self-evaluative, and participatory
                                        practices that promote all students' and instructors' educational processes.
                                        Mentors clap us on the back when we succeed, but we concentrate our efforts so
                                        we don't rest on our laurels for too long. That fresh perspective can assist you
                                        in identifying your "blind spots."</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <CoachForm service={"coach"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default CoachMobile