import React from 'react'
import CoachForm from '../../Component/CoachForm/CoachForm'

const CounsollerMobile = () => {
  return (
    <>
        <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">counsellor<span><img src="/assets/img/counsellor.png"
                        alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>You've labored tough to urge during which you're now. You understand you've got the potential to
                        be among the first-rate for your field. You would like to form the right selections
                        approximately your subsequent steps now if you would like to get your goals. But there are such
                        tons of alternatives to be had it's tough to know what's first-rate for you. With a considerable
                        sort of enjoying and expert careers steerage qualifications, we permit you to pick the right
                        professional direction with inside the twenty-first century. For school students who're
                        uncertain of which profession first-rate fits their capabilities and aptitudes, we'll assist in
                        slender your alternatives. For those who've selected their direction, we'll advocate at the
                        right publications and maximum suitable profile-constructing sports to beautify your
                        possibilities of success. Studying overseas could also be high-priced and involve a large
                        investment. Applying to the 'proper' institutes can maximize your possibilities of getting
                        want-primarily based economic resources or merit-primarily based totally scholarships. Our
                        counselors assist you in filling the specified bureaucracy and checking your documents, advising
                        you with the first-rate techniques to urge the simplest feasible economic resource and
                        scholarships from institutes overseas.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coun11.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What is Educational Counselling?</h4>
                                    <div className="postcard-para-txt">Educational counseling suggests that schooling
                                        performs a key role. The counselors will assist you to obtain instructional
                                        achievement. You'll inform everyone approximately schooling and therefore the
                                        way schooling is significant. Educational counselors additionally assist
                                        children to develop and turn into assured of their research. The utmost critical
                                        issue for the scholars is schooling counseling. Proper mentoring permits
                                        students to satisfy their objectives.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/c-r23.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3 card-head1">Who are Educational Counsellors?</h4><span
                                        className="border-bt"></span>
                                    <p>Thinking approximately a counselor, we're considering a photograph of a pleasant,
                                        well-manned, and pushed individual. Career courses have grown to be so vital
                                        that faculties and establishments have attached unique counseling cells for
                                        school students. Furthermore, a counselor has numerous capabilities to hold out
                                        within side the cutting-edge schooling system. Additionally, to allow you to
                                        know the best things, those professionals can assist you to create a choice on
                                        the utmost vital intersection of your life. In each stage of your instructional
                                        and expert direction, schooling counselor features a sizable characteristic
                                        because it enables you to make knowledgeable judgments. Many of us sense that in
                                        our education or activity pursuit we don't want expert supervision, but the
                                        proper mentor may additionally certainly enhance your life. Types of Educational
                                        Counselling.</p>

                                    <div className="postcard__preview-txt ft-15">
                                        <h4 className="pb-3 card-head1">There are 3 center sorts of Educational Counselling:
                                        </h4>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <h5 className="pb-3 card-head1">1. Educational Guidance and Counselling</h5>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <p>The observe overseas steerage and counseling service, whether or not or not
                                            it's choice of the proper move to seek out out the first-rate university and
                                            direction after graduating excessive school, will offer college students the
                                            important steerage keep with instructional demands. Educational counselors
                                            are typically positioned at faculties and universities and you'll
                                            additionally be trying to find assistance from schooling advisory offerings
                                            to reap the simplest mentoring to your instructional profession.</p>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <h5 className="pb-3 card-head1">2. Vocational Counselling</h5>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <p>Vocational counseling is simply like professional counseling, however focuses
                                            extra on deciding the proper employment or employment possibility withinside
                                            the place or enterprise during which they need to figure out.</p>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <h5 className="pb-3 card-head1">3. Career Counselling</h5>
                                    </div>
                                    <div className="postcard__preview-txt ft-15">
                                        <p>Career consulting may be a whole mixture of educational and vocational
                                            counseling because it focuses each on college students and operating experts
                                            and offers them the simplest mentoring at every degree of the direction!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What does Admissify Offer?</h4>
                                    <div className="postcard-para-txt">Admissify sticks through its call and ensures that the moral responsibility lies above the organizations on the topic of presenting excellent offerings to school students and expert workers. Many students are counseled through pioneers of their respective professions. They gather schooling. At Admissify, we suppose its miles best whilst a pupil obtains most education regardless of limits and budgetary regulations that the goal of consulting is achieved.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/concul.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">conclusion</h4>
                                    <div className="postcard-para-txt">We recognize how hard it might be to pursue your research overseas. We understand that it’s about your dream that is about to be real. We value your each and every doubt equally. We provide a variety of Student Essentials offerings to eliminate complexity and strain making your worldwide observe enjoy the journey, a hassle-loose adventure. So that you only focus on your studies. Need to assist? Explore our offerings and an Admissify counselor could also be assigned to manual via each step of your manner to remain and observe overseas.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <CoachForm service={"counsellor"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default CounsollerMobile