import { combineReducers } from "redux";
import userReducer from "./../user/reducer";
import commenReducer from "./../Loding/reducer";
import discussionReducer from "./../discussion/reducer";
import universityReducer from "./../university/reducer";
import faqReducer from "./../faq/reducer";
import countryReducer from "./../country/reducer";
import universitycourseReducer from "./../course/reducer";
import courseReducer from "./../course/reducer";
import testimonialReducer from "./../testimonial/reducer";
import packageReducer from "./../package/reducer";
import counsellorReducer from "./../counsellor/reducer";
import coachReducer from "./../coach/reducer";
import officeReducer from "./../office/reducer";
import articaleReducer from "./../artical/reducer";
import cityReducer from "./../city/reducer";
import streamReducer from "./../stream/reducer";
import studentReducer from "./../student/reducer";
import applyNowReducer from "./../ApplyNow/reducer";
import blogReducer from "./../blog/reducer";
import scholershipReducer from "./../SholarshipAndOffer/reducer";
import settingReducer from "./../setting/reducer";
import jobReducer from "./../Job/reducer";
import uploadReducer from "./../Upload/reducer";





export default combineReducers({
    user: userReducer,
    commenreducer: commenReducer,
    discussion: discussionReducer,
    unversity:universityReducer,
    faq:faqReducer,
    country:countryReducer,
    universitycourse:universitycourseReducer,
    course:courseReducer,
    testimonial:testimonialReducer,
    package:packageReducer,
    counsellor:counsellorReducer,
    coach:coachReducer,
    office:officeReducer,
    articale:articaleReducer,
    city:cityReducer,
    stream:streamReducer,
    student:studentReducer,
    applyNow:applyNowReducer,
    blog:blogReducer,
    scholershipAndOffer:scholershipReducer,
    setting:settingReducer,
    jobs:jobReducer,
    upload:uploadReducer,
    
});