import React from 'react'
import AccomodationForm from '../../Component/AccomodationForm'

const AccomodationMobile = () => {
  return (
    <>
      <section className="terms-content">
			<div className="headingmains text-center">
				<h4 className="titlewithline m-4">
                    accommodation
                    <span><img className="service-funding1" src="/assets/img/ser03.png" alt=""/></span>
				</h4>
			</div>
			<div className="container">
				<div className="terms-cont">
					<p>Are you planning to study in another country and need to find suitable accommodation? Accommodation is almost certainly one of the first things that come to mind when thinking about reading abroad, and your final decision may be dependent entirely on a variety of criteria. There are numerous options for international scholar housing, as well as various concerns and checkpoints you must go through before making your choice - which is almost certain to be partially based entirely on personal desire. Whatever your budget, whether you're looking for college housing or anything in the private sector, there may be an option for you. Simply look through a few of the greatest accommodations on our website to make the procedure as simple and painless as possible.</p>
				</div>
			</div>
		</section>
        <section className="terms-content">
			<div className="ser-img text-center">
			<img src="/assets/img/acc1.png" alt=""/>
			</div>
			<div className="container">
				<div className="accom-bx">
					<h4 className="accom-head1">What is the definition of accommodation?</h4>
				
                <p>If you've decided to study in a foreign nation, you'll have to make a number of important decisions. One of the most important decisions you'll make is where to reside for the duration of your program. Where you choose to live will have an impact on your entire overseas experience. It will have an impact on your social life as well as your academic performance. Finding a place to live that meets all of your requirements might be difficult. As a global student, this may be your first time living on your own or even traveling outside of your home country. It'll also be the first time you've had to deal with fees and any type of accommodation. The concept behind University Rooms is that vacant dorm rooms are rented out, with availability typically during the summer months, but also during the academic year. This allows people (who are no longer just students) to live in otherwise-empty university dorm rooms in shared, single, or studio residences. This appears to be a viable option for university sports or visits, particularly with large groups, for shorter trips abroad. As a result, it's vital to recognize that this is a primary option right away. Your student accommodation could be a place where you unwind, explore, and, most importantly, meet new people. As a result, to make the process easier, we provide student accommodation that will assist you in finding a suitable residence.</p>
			</div>
        </div>
		</section>
        <section className="terms-content">
			<div className="ser-img text-center">
			<img src="/assets/img/acc2.png" alt=""/>
			</div>
			<div className="container">
				<div className="accom-bx">
					<h4 className="accom-head1">How does it work?</h4>
				
                <p>Are you looking for an accommodation that has been provided? We've got you covered! We provide the largest selection of residences to help you find your next home. Simply type in the town where you want to stay and hit the seek button! You may be offered apartments with condominium rates, services, and specific neighborhoods. We supply particular types of provided lodgings such as rooms, residences, flats, houses, scholar residencies, and studios from Europe to America, Australia to Asia. Moving to a new area is usually a difficult process, and the first and most important step is to find the right apartment. Because we're aware of the issue, we need to make this process easier for everyone seeking a place to stay in a new location.
                </p>
            </div>
        </div>
		</section>
        <section className="terms-content">
			<div className="ser-img text-center">
			<img src="/assets/img/acc3.png" alt=""/>
			</div>
			<div className="container">
				<div className="accom-bx">
					<h4 className="accom-head1">Why pick out us?</h4>
				
                <p>If you want to start your foreign adventure with new friends, Admissify is a fantastic way to do so. While it's best for those who don't mind sharing a flat (or room) with others, the number of housing criteria and options for finding the ideal vegan yoga master is rather astounding (this listing is going all of the manners right all the way down to puppy kind filters, in-unit washers, and wifi connection types). The ability to connect with other consumers right away is top-notch fast, easy, and efficient, so you don't have to wait for long and awkward "you've been matched!" results prior to concluding who you need to be companions with. International student accommodation options are just as simple and entertaining to look through. Even better: they may be tailored to you, the harried and ecstatic student, in order to focus on removing all of your worries and problems from the house-hunting process. Now that staying in a foreign country is just another fun step in the process, the desire to travel abroad can be reignited in full force. Whether you're relocating to distant Kansas or far Amsterdam, be prepared to say "home," "sweet," and "home" in whichever new language you're about to learn! </p>
            </div>
        </div>
		</section>
		<section className="bg-grey">
			<div className="container">
				<div className="row">
					<div className="col-md-2 col-sm-12">
						<div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
							<svg xmlns="http://www.w3.org/2000/svg">
								<symbol viewBox="0 0 24 24" id="expand-more">
									<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
									<path d="M0 0h24v24H0z" fill="none"></path>
								</symbol>
								<symbol viewBox="0 0 24 24" id="close">
									<path
										d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
									</path>
									<path d="M0 0h24v24H0z" fill="none"></path>
								</symbol>
							</svg>
						</div>
					</div>
					<div className="col-sm-12">
						<AccomodationForm/>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default AccomodationMobile