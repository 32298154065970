import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FaqAction } from '../../../redux/common/action';
import Accordion from './Accordion';

const FaqMobile = () => {
  const dispatch = useDispatch();
  const [showId, setShowId]= useState("");
  const [LastCategoryId, setLastCategoryId] = useState<any>(null);
  const faqCatList: any[] = useSelector((state: any) =>
    state.faq.faqCatList ? state.faq.faqCatList : []
  );

  const faqList: any[] = useSelector((state: any) =>
    state.faq.faqList ? state.faq.faqList : []
  );

  useEffect(() => {
    dispatch(FaqAction.getfaqcategory({}));
    return () => { };
  }, []);

  useEffect(() => {
    faqCatList.map((data, index) => {
      if (index == 0)
        setLastCategoryId(data?.id);
    });
  }, [faqCatList]);

  useEffect(() => {
    dispatch(FaqAction.getfaqlist({}));
    return () => { };
  }, []);

  const onchangefaq = (event: any, data: any) => {
    event.preventDefault();
    setLastCategoryId(data);
  };

  const handleShow = (id:any) => {
   setShowId(showId === id ? "" : id);
  }
  return (
    <>
      <section className="faqpage-web bg-light-g pt-4">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-7 faqleft-web">
          <div className="heading">
            <h1><span>Faq's</span></h1>
            <p>Here you’ll find a collection of the most frequently asked questions regarding how to study
              abroad. Find information on where to study, how to apply, entry requirements, getting a
              student visa, tuition fees and funding, finding study abroad scholarships and other
              practical information.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="faqright-web"><img src="/assets/img/faq-right.png" className="scale" alt=""/></div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-5">
          <div className="contentbx choachwhat faqtabs-web">
            <ul className="nav nav-tabs-web">
            {faqCatList && faqCatList?.map((data, index) => (
              <li className="nav-item">
                <a 
                  className={LastCategoryId == data?.id ? "nav-link active" : "nav-link"} 
                  onClick={(event) => onchangefaq(event, data?.id)}>
                  {data?.category && data?.category}
                </a>
              </li>
            ))}
            </ul>
            <div className="tab-content">
              <div id="menu1" className="tab-pane active" >
                <div className="mobile_acc accordion accordion-flush" id="accordionFlushExample">
                {faqList && faqList?.map((data, index) => {
                  if (data?.faq_category_id == LastCategoryId) {
                  return (
                  <Accordion data={data} key={index+"faqContent"} showId={showId} handleShow={handleShow}/>
                )}})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default FaqMobile