import React from 'react'
import { Link } from 'react-router-dom'

const HomeMobile = () => {
  return (
    <>
      <div style={{display: "grid",lineHeight: "80px", fontSize: "50px", margin: "20px"}}>
        <Link to={"/app/about-us"} >About Us</Link>
        <Link to={"/app/accomadation"} >Accomadation</Link>
        <Link to={"/app/contact-us"} >Contact Us</Link>
        <Link to={"/app/funding"} >Funding</Link>
        <Link to={"/app/privacy-policy"} >Privacy Policy</Link>
        <Link to={"/app/services"} >Services</Link>
        <Link to={"/app/terms-conditon"} >Terms&amp;Conditions</Link>
        <Link to={"/app/mentor"} >mentor</Link>
        <Link to={"/app/counsellor"} >counsellor</Link>
        <Link to={"/app/internship-placement"} >internship&amp;placement</Link>
        <Link to={"/app/scholarship"} >scholarship</Link>
        <Link to={"/app/faq"} >faq</Link>
        <Link to={"/app/online-coaching"} >online coaching</Link>
        <Link to={"/app/alumni-chat-details"} >alumni-chat</Link>
        <Link to={"/app/packages"} >packages</Link>
        <Link to={"/app/test-preparation"} >test-preparation</Link>
        <Link to={"/app/ielts-exam"} >ielts-details</Link>
        <Link to={"/app/pte-exam"} >pte-details</Link>
        <Link to={"/app/toefl-exam"} >toefl-details</Link>
        <Link to={"/app/gmat-exam"} >gmat-details</Link>
        <Link to={"/app/gre-exam"} >gre-details</Link>
        <Link to={"/app/sat-exam"} >sat-details</Link>
      </div>
    </>
  )
}

export default HomeMobile