import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { officeAction } from '../../../redux/common/action';
import { URL } from '../../../redux/common/url';
import { getAllCountry } from '../../../redux/country/action';
import { getToken } from '../../../Utils/Auth/Token';
import { validateFundingForm, validateInternShipForm } from '../../../validation/Validation';

const FundingForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [values, setValue] = useState < any > ({});
    const [errors, setErrors] = useState < any > (undefined);
    const [resume, setResume] = useState < any > ("");
    const country: any[] = useSelector((state: any) =>
        state.country.countryList ? state.country.countryList : []
    );
    const user: any = useSelector((state:any) => state?.student?.getstudentById || {});
    useEffect(() => {
        dispatch(getAllCountry(""));
    }, [])
    const handleOnChnage = (e: any) => {
        setValue({ ...values, [e.target.name]: e.target.value });
    }
    const handleFiles = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const res: any = await axios(
            URL.API_BASE_URL + '/api/fileUpload/upload?module_name=career',
            {
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data; ',
                    'token': getToken() || ""
                },
            }
        );
        setResume(res?.data?.fileName || "");
    }
    const handleOnSubmit = (e: any) => {
        let dt = { ...values };
        dt.file = resume;
        const validationErrors = validateFundingForm(dt);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data: any = { ...values };
            data.name = user?.name;
            data.email = user?.email;
            data.mobile = user.mobile;
            data.other_details = [
                { name: "intake", label: "tentative course intake", value: values?.intake || "N/A" },
                { name: "offer", label: "do you have an offer?", value: values?.offer || "N/A" },
                { name: "college", label: "college/university", value: values?.college || "N/A" },
                { name: "cLevel", label: "course level", value: values?.cLevel || "N/A" },
                { name: "collateral", label: "choose collateral", value: values?.collateral || "N/A" },
                { name: "income", label: "applicant monthly income", value: values?.income || "N/A" },
                { name: "country", label: "preferred country", value: values?.country || "N/A" },
                { name: "salary", label: "co-applicant salary", value: values?.salary || "N/A" },
                { name: "degree", label: "college degree", value: resume ? '/career/' + resume : "N/A" }
            ]
            data.service_name = "funding";
            data.thank = true;
            delete data.intake;
            delete data.offer;
             delete data.college;
            delete data.cLevel;
             delete data.collateral;
            delete data.income;
             delete data.salary;
            delete data.country;
            dispatch(officeAction.submitQuery(data, history));
        }
    }
    return (
        <div className="apply-box">
            <div className="container p-2">
                <div className="row">
                    <div className="col-md-11">
                        <h5 className="apply-head">apply now</h5>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <div className="fields-wrap">
                            <form id="fundingForm">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="name" id="Name" value={values?.name || ""} onChange={handleOnChnage} />
                                            <label>name</label>
                                            <span className="fa fa-user p-2"></span>
                                        </div>
                                        {errors?.name && <p className='error-class'>{errors?.name}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="mobile" maxLength={10} id="Mobile"
                                                value={values?.mobile || ""} onChange={handleOnChnage} />
                                            <label>mobile</label>
                                            <span className="fa fa-phone p-2"></span>
                                        </div>
                                        {errors?.mobile && <p className='error-class'>{errors?.mobile}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="email" id="Email" value={values?.email || ""} onChange={handleOnChnage} />
                                            <label>email</label>
                                            <span className="fa fa-envelope p-2"></span>
                                        </div>
                                        {errors?.email && <p className='error-class'>{errors?.email}</p>}
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="city" id="City" maxLength={10}
                                                value={values?.city || ""} onChange={handleOnChnage} />
                                            <label>city</label>
                                            <span className="fa fa-map-marker p-2"></span>
                                        </div>
                                        {errors?.city && <p className='error-class'>{errors?.city}</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="salary" value={values?.salary || ""} onChange={handleOnChnage} />
                                            <label>co-applicant salary</label>
                                            <span className="fa fa-inr p-2"></span>
                                        </div>
                                        {errors?.salary && <p className='error-class'>{errors?.salary}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input autoComplete="new-off" type="text"
                                                name="income" value={values?.income || ""} onChange={handleOnChnage} />
                                            <label>applicant monthly income</label>
                                            <span className="fa fa-inr p-2"></span>
                                        </div>
                                        {errors?.income && <p className='error-class'>{errors?.income}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field"><span className="fa fa-user p-2"></span>
                                            <select
                                                className="form-control c-select" name="collateral" value={values?.collateral || ""} onChange={handleOnChnage}>
                                                <option value="">choose collateral</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        {errors?.collateral && <p className='error-class'>{errors?.collateral}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field"><span className="fa fa-book p-2"></span>
                                            <select
                                                className="form-control c-select" name="cLevel" value={values?.cLevel || ""} onChange={handleOnChnage}>
                                                <option value="">course level</option>
                                                <option className="" value="1">UG</option>
                                                <option className="" value="2">PG</option>
                                            </select>
                                        </div>
                                        {errors?.cLevel && <p className='error-class'>{errors?.cLevel}</p>}
                                    </div>
                                </div>
                                <p><span className="note-text"><b>Note:-</b></span> <span className="note-text1">Key Benefits
                                    of Providing a Collateral -Lower Interest Rate By ~ 2% -No
                                    Repayments During the Study Period*</span> </p>
                                <div className="field choose-f sel_file">
                                    <input type="file" className="type_file"
                                        id="resume" name="file" accept="application/pdf" onChange={(e:any) => { handleFiles(e) }}/>
                                    <label>upload degree</label>
                                    <span className="fa fa-upload p-2 r-20"></span>
                                    {errors?.file && <p className='error-class'>{errors?.file}</p>}
                                </div>
                                <div className="field">
                                    <input autoComplete="new-off" type="text" name="college"
                                        value={values?.college || ""} onChange={handleOnChnage} />
                                    <label>college/university</label>
                                    <span className="fa fa-university p-2"></span>
                                    {errors?.college && <p className='error-class'>{errors?.college}</p>}
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field"><span className="fa fa-gift p-2"></span><select
                                            className="form-control c-select" name="offer" value={values?.offer || ""} onChange={handleOnChnage}>
                                            <option value="">do you have an offer?</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select></div>
                                        {errors?.offer && <p className='error-class'>{errors?.offer}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field"><input autoComplete="new-off" type="text"
                                            name="intake" value={values?.intake || ""} onChange={handleOnChnage} /><label>tentative course
                                                intake</label><span className="fa fa-calendar p-2 r-20"></span>
                                        </div>
                                        {errors?.intake && <p className='error-class'>{errors?.intake}</p>}
                                    </div>
                                </div>
                                <div className="field"><span className="fa fa-map-marker p-2"></span><select
                                    className="form-control c-select" name="country" value={values?.country || ""} onChange={handleOnChnage}>
                                    <option value="">preferred country</option>
                                    {country && country?.map((data: any, i: any) => {
                                        return (
                                        <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                        )
                                    })}
                                </select>
                                {errors?.country && <p className='error-class'>{errors?.country}</p>}
                                </div>
                                <div className="field"><textarea autoComplete="new-off" className="ques-msg border__"
                                    rows={4} cols={50} placeholder="message" name="message" value={values?.message || ""} onChange={handleOnChnage}></textarea>
                                    {errors?.message && <p className='error-class'>{errors?.message}</p>}
                                </div><br /><br /><br /><br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="fieldbtn text-center">
                                            <input type="button" className="bggreadent-n" id="fundingIn" value="submit" onClick={(e)=> handleOnSubmit(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundingForm