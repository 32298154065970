import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const IeltsMobile = () => {
  return (
    <>
    <section >
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">IELTS<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>
                        IELTS stands for International English Language Testing System. The International English Language Testing System (IELTS) is a set of exams designed to help you work, study, or migrate to a country where English is spoken as a first language. The material of the IELTS exam is based on real-life situations. Regardless of their background, everyone who takes the exam is treated equally and fairly. During the test, your abilities to listen, read, write, and converse in English will be evaluated. The exam is evaluated on a scale of 1 to 9. Your English comprehension and communication abilities will improve as your IELTS score rises.
                  </p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coun11.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-0">Why is the importance of IELTS?</h4>
                                    <div className="postcard-para-txt">IELTS is the most extensively used test for individuals who desire to move to Australia, Canada, New Zealand, or the United Kingdom. It is recognised by over 11,000 employers, universities, institutions, and immigration authorities worldwide, with 3,400 in the United States. It is owned jointly by the British Council, IDP: IELTS Australia, and Cambridge Assessment English. If you wish to work, live, or study in an English-speaking country, you must demonstrate a high level of English language proficiency. The capacity to communicate in the native language of the country where you want to work or study has several benefits. It is also required for finding work and assimilating into the community.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/ielts03.png" alt=""/>
                                <h5 className="pt-4">There are three categories under IELTS:</h5>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Types of IELTS Exam</h4>
                                    <h6><strong>1. IELTS Academic Test</strong></h6>
                                <p>It's for students who want to study at a university or work in an English-speaking environment (higher education). Professional registration can be done using IELTS Academic as well.</p>
                                </div>
                                <div className="postcard-sec-text">
                                <h6><strong>2. IELTS General Test</strong></h6>
                                <p>The IELTS General Training test is for people who want to study at a level below a bachelor's degree. It is a school or college that teaches English as a second language and can also be utilised in the workplace for work experience or other training. This test is recommended for work or immigration. IELTS General Training is also necessary for migration to Australia, Canada, New Zealand, and the United Kingdom. The test typically includes English language skills that you'll need in social and professional situations both.</p>
                           </div>
                           <div className="postcard-sec-text">
                            <h6><strong>3. IELTS for UKVI</strong></h6>
                            <p>If you're seeking a UK visa, you'll need to take an IELTS test called IELTS for UKVI, recognised for UK Visa and Immigration (UKVI) reasons. The IELTS partners, including The British Council, IDP: IELTS Australia, and Cambridge Assessment English, oversee IELTS assessments for UK Visas and Immigration. IELTS Academic, IELTS General Training, and IELTS Life Skills are accepted by the UK Visas and Immigration (UKVI) as proof of English proficiency for anyone planning to live, work, or study in the UK. IELTS Life Abilities tests people who need to prove their English speaking and listening abilities to get a UK VISA.</p>  </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">How to register for the IELTS Examination?</h4>
                                    <div className="postcard__preview-txt ft-15"> <strong> Step one:</strong> Locate an IELTS test centre near you.</div>
                                    <div className="postcard__preview-txt ft-15 pb-2"><strong> Step two:</strong> Schedule your IELTS test.</div>
                                    <h4 className="pb-3 mt-2">Who accepts IELTS scores? </h4>
                                    <div className="postcard-para-txt">
                                        <p>The criterion for an IELTS score will differ depending on the immigration body, university, workplace, or institution.<br/>
                                        What you want to do in the country, such as work or study, will decide the score you'll need.</p>
                                    </div>
                                    <h4 className="pb-3 mt-2">Where can IELTS take you?</h4>
                                    <p> Over 11,000 organisations in 140 countries, including Australia, Canada, New Zealand, the United Kingdom, and the United States, recognise IELTS.</p>
                                </div>
                            </article>
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/ielts04.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of IELTS Test</h4>
                              
                                    <div className="postcard-para-txt">
                                        <p>IELTS is the most widely accepted English language test globally, with over 11,000 organisations accepting it. The four language abilities – listening, speaking, writing, and reading – are assessed in the IELTS test, which helps you acquire several study techniques to improve your English language skills. IELTS requires you and the examiner to communicate in real-time. Do you want to study overseas but don't know where to start? Exams and their protocols have you stumped? Admissify is ready to help you out! Admissify is India's premier overseas education consultancy, with offices in London, Boston, Hanover, Vancouver, Sydney, and other cities. Our global network allows us to provide in-country help such as internship assistance, immigration, and career services after you arrive.</p>
                                    </div>
                                  
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>  
                <div className="col-sm-12">
                    <ExamForm service={"IELTS-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default IeltsMobile