import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { officeAction } from '../../../redux/common/action';
import { validateCoachForm } from '../../../validation/Validation';

const CoachForm = (props:any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [values, setValue] = useState<any>({});
    const [errors, setErrors] = useState<any>(undefined);
    const user: any = useSelector((state:any) => state?.student?.getstudentById || {});
    const handleOnChnage = (e:any) => {
        setValue({...values, [e.target.name]:e.target.value});
    }
    const handleOnSubmit = (e:any) => {
        const validationErrors = validateCoachForm(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data:any = {...values};
            data.name = user?.name;
            data.email = user?.email;
            data.mobile = user.mobile;
            data.other_details = [
                { name: "about", label: "what are you most curious about?", value: values?.about || "N/A" },
                { name: "training", label: "how did you hear about mentor training EDU?", value: values?.training || "N/A" },
                { name: "interest", label: "i am interested in", value: values?.interest || "N/A" },
                { name: "area", label: "in what areas do you have questions?", value: values?.areas || "N/A" },
                { name: "program", label: "in which program(s) are you interested?", value: values?.program || "N/A" }
            ]
            data.service_name = props?.service;
            data.thank = true;
            delete data.about;
            delete data.training;
            delete data.interest;
            delete data.areas;
            delete data.program;
            dispatch(officeAction.submitQuery(data, history));
        }
    }
  return (
    <div className="apply-box">
        <div className="container p-2">
            <div className="row">
                <div className="col-md-11">
                    <h5 className="apply-head">apply now</h5>
                </div>
                <div className="col-md-12 col-lg-12">
                    <div className="fields-wrap">
                        <form id="fundingForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text" name="name" id="Name" value={values?.name || ""} onChange={handleOnChnage}/>
                                        <label>name</label>
                                        <span className="fa fa-user p-2"></span>
                                    </div>
                                    {errors?.name && <p className='error-class'>{errors?.name}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text" name="mobile" maxLength={10} id="Mobile" value={values?.mobile || ""} onChange={handleOnChnage}/>
                                        <label>mobile</label>
                                        <span className="fa fa-phone p-2"></span>
                                    </div>
                                    {errors?.mobile && <p className='error-class'>{errors?.mobile}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text" name="email" id="Email" value={values?.email || ""} onChange={handleOnChnage}/>
                                        <label>email</label>
                                        <span className="fa fa-envelope p-2"></span>
                                    </div>
                                    {errors?.email && <p className='error-class'>{errors?.email}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field"><input autoComplete="new-off" type="text" name="city" id="City" maxLength={10} value={values?.city || ""} onChange={handleOnChnage}/>
                                        <label>city</label>
                                        <span className="fa fa-map-marker p-2"></span>
                                    </div>
                                    {errors?.city && <p className='error-class'>{errors?.city}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <span className="fa fa-book p-2"></span>
                                        <select className="form-control c-select" name="program" value={values?.program || ""} onChange={handleOnChnage}>
                                            <option value="">in which program(s) are you interested?</option>
                                            <option value="Academic Life Coaching">Academic Life Coaching</option>
                                            <option value="University Academic Life Coaching">University Academic Life Coaching</option>
                                            <option value="Executive Coaching">Executive Coaching</option>
                                        </select>
                                    </div>
                                    {errors?.program && <p className='error-class'>{errors?.program}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <span className="fa fa-question p-2"></span>
                                        <select className="form-control c-select" name="areas" value={values?.areas || ""} onChange={handleOnChnage}>
                                            <option value="">in what areas do you have questions? </option>
                                            <option value="Course Schedule">Course Schedule</option>
                                            <option value="Program Specializations">Program Specializations</option>
                                            <option value="Program Logistics (length, frequency, topics, etc.)">Program Logistics (length, frequency, topics, etc.)</option>
                                            <option value="Other (please share below)">Other (please share below)</option>
                                        </select>
                                    </div>
                                    {errors?.areas && <p className='error-class'>{errors?.areas}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field"><span className="fa fa-hand-o-up p-2"></span><select
                                            className="form-control c-select" name="interest" value={values?.interest || ""} onChange={handleOnChnage}>
                                            <option value="">i am interested in..</option>
                                            <option value="receiving an information packet">receiving an
                                                information packet</option>
                                            <option
                                                value="scheduling a 20-minute call with an Admission Advisor">
                                                scheduling a 20-minute call with an Admission Advisor
                                            </option>
                                        </select>
                                    </div>
                                    {errors?.interest && <p className='error-class'>{errors?.interest}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field"><textarea autoComplete="new-off"
                                            className="ques-msg border__" rows={4} cols={50}
                                            placeholder="how did you hear about mentor training EDU?"
                                            name="training" value={values?.training || ""} onChange={handleOnChnage}></textarea>
                                    </div>
                                    <br/><br/><br/><br/>
                                    {errors?.training && <p className='error-class'>{errors?.training}</p>}
                                </div>
                            </div>
                            <div className="field"><textarea autoComplete="new-off"
                                    className="ques-msg border__" rows={4} cols={50}
                                    placeholder="what are you most curious about?"
                                    name="about" value={values?.about || ""} onChange={handleOnChnage}></textarea>
                                    {errors?.about && <p className='error-class'>{errors?.about}</p>}
                            </div>
                            <br/><br/><br/><br/>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="fieldbtn text-center">
                                        <input type="button" className="bggreadent-n" id="fundingIn"
                                            value="submit" onClick={(e)=> handleOnSubmit(e)}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CoachForm