import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

var other_details: any = [];
function IELTS() {
   const urlsforShare = window.location.href;
   const dispatch = useDispatch();
   const history = useHistory();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );






   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "IELTS-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();


      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();

      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;


      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#ieltsIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));

   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "IELTS", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     IELTS
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              IELTS stands for International English Language Testing System. The International English Language Testing System (IELTS) is a set of exams designed to help you work, study, or migrate to a country where English is spoken as a first language.
                              The material of the IELTS exam is based on real-life situations. Regardless of their background, everyone who takes the exam is treated equally and fairly.
                              During the test, your abilities to listen, read, write, and converse in English will be evaluated.
                              The exam is evaluated on a scale of 1 to 9. Your English comprehension and communication abilities will improve as your IELTS score rises.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/coun11.jpg" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Why is the importance of IELTS?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">IELTS is the most extensively used test for individuals who desire to move to Australia, Canada, New Zealand, or the United Kingdom.
                                    It is recognised by over 11,000 employers, universities, institutions, and immigration authorities worldwide, with 3,400 in the United States. It is owned jointly by the British Council, IDP: IELTS Australia, and Cambridge Assessment English.
                                    If you wish to work, live, or study in an English-speaking country, you must demonstrate a high level of English language proficiency.
                                    The capacity to communicate in the native language of the country where you want to work or study has several benefits. It is also required for finding work and assimilating into the community.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              {/* <img className="postcard__img-new postcard__img-new1" src="assets/img/c-r23.jpg" alt=""/> */}
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/ielts03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Types of IELTS Exam</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">
                                    <h5 className="pb-3 card-head1">There are three categories under IELTS:
                                    </h5>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <h6 className="pb-3 card-head1">1. IELTS Academic Test</h6>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <p>It's for students who want to study at a university or work in an English-speaking environment (higher education). Professional registration can be done using IELTS Academic as well.
                                    </p>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <h6 className="pb-3 card-head1">2. IELTS General Test</h6>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <p>The IELTS General Training test is for people who want to study at a level below a bachelor's degree.
                                       It is a school or college that teaches English as a second language and can also be utilised in the workplace for work experience or other training.
                                       This test is recommended for work or immigration.
                                       IELTS General Training is also necessary for migration to Australia, Canada, New Zealand, and the United Kingdom.
                                       The test typically includes English language skills that you'll need in social and professional situations both.
                                    </p>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <h6 className="pb-3 card-head1">3. IELTS for UKVI</h6>
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <p>If you're seeking a UK visa, you'll need to take an IELTS test called IELTS for UKVI, recognised for UK Visa and Immigration (UKVI) reasons.
                                       The IELTS partners, including The British Council, IDP: IELTS Australia, and Cambridge Assessment English, oversee IELTS assessments for UK Visas and Immigration.
                                       IELTS Academic, IELTS General Training, and IELTS Life Skills are accepted by the UK Visas and Immigration (UKVI) as proof of English proficiency for anyone planning to live, work, or study in the UK.
                                       IELTS Life Abilities tests people who need to prove their English speaking and listening abilities to get a UK VISA.
                                    </p>
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">How to register for the IELTS Examination?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> <strong> Step one:</strong> Locate an IELTS test centre near you.
                                 </div>
                                 <div className="postcard__preview-txt ft-15">
                                    <strong> Step two:</strong> Schedule your IELTS test.
                                 </div>
                                 <br />
                                 <h4 className="pb-3 card-head1">Who accepts IELTS scores? </h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The criterion for an IELTS score will differ depending on the immigration body, university, workplace, or institution. <br />
                                    What you want to do in the country, such as work or study, will decide the score you'll need.
                                 </div>
                                 <br />
                                 <h4 className="pb-3 card-head1">Where can IELTS take you?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> Over 11,000 organisations in 140 countries, including Australia, Canada, New Zealand, the United Kingdom, and the United States, recognise IELTS.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/ielts04.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of IELTS Test</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">IELTS is the most widely accepted English language test globally, with over 11,000 organisations accepting it.
                                    The four language abilities – listening, speaking, writing, and reading – are assessed in the IELTS test, which helps you acquire several study techniques to improve your English language skills.
                                    IELTS requires you and the examiner to communicate in real-time.
                                    Do you want to study overseas but don't know where to start? Exams and their protocols have you stumped?
                                    Admissify is ready to help you out! Admissify is India's premier overseas education consultancy, with offices in London, Boston, Hanover, Vancouver, Sydney, and other cities.
                                    Our global network allows us to provide in-country help such as internship assistance, immigration, and career services after you arrive.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country} >
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input
                                                autoComplete="new-off" type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" id="ieltsIn" className="bggreadent apply-sub-btn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default IELTS
