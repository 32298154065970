import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_STREAM_LIST,
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getstreamlistAction = (data:any) => {
  return { type: GET_STREAM_LIST, payload: data };
};

export const getstreamlist = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getstreamlist+'?status=1'}`);
          dispatch(getstreamlistAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};