import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const GresMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">GRE<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>The GRE General Test, administered by ETS, is one of the most common graduate admissions tests
                        for students interested in pursuing MS or graduate courses abroad. The Graduate Record
                        Examination, or GRE test, is the complete version of the Graduate Record Examination. The GRE
                        General test, administered by ETS (Educational Testing Service), is one of the world's most
                        widely accepted assessment programmes for graduate admissions to business and law schools.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gre01.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What is the importance of GRE?</h4>
                                    <div className="postcard-para-txt">The GRE is a prominent exam that stands out among the
                                        other foreign language exams. A good GRE score gives a student an advantage over
                                        other students when it comes to gaining admission to a top university or
                                        business school to pursue a master's degree, MBA, or business and law
                                        programmes.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gre02.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Types of GRE Exams</h4>
                                    <div className="postcard-para-txt">There are two versions of the GRE General Exam:
                                        computer-based and paper-based. Furthermore, not everyone can take the
                                        paper-based test. It's available in places where the computer-based GRE isn't
                                        available. It depends on the location of the exam. GRE General Test and GRE
                                        Subject Test are the two categories of GRE. The GRE general test is available in
                                        both computer and paper format. The computer-based GRE is section adaptive, one
                                        of the major differences between the two GRE designs. The difficulty level of
                                        the second segment is determined by your performance in the first section of
                                        verbal and quantitative. As a result, a computer-based exam provides a more
                                        accurate assessment of your talents than a paper-based exam. You will also
                                        receive your scores at the exam centre if you take the computer-based GRE. You
                                        must wait around six weeks for the paper-based test. The Verbal reasoning,
                                        quantitative reasoning, and analytical writing sections are all formats, and the
                                        scoring system is the same.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">How to register for the GRE Exam?</h4>
                                    <div className="postcard-para-txt"> The GRE test can be taken in a variety of ways.
                                        Aspirants can register for the GRE in various ways, including online and over
                                        the phone. Candidates can also register by mail or fax. Candidates will need a
                                        debit or credit card to pay the $213 registration cost and a valid passport or
                                        Aadhar Card during the registration process.</div>
                                </div>
                                <ul className="condition-list-sec">
                                    <li>Online GRE Registration</li>
                                    <li>GRE Phone Registration</li>
                                    <li>GRE Mail Registration</li>
                                    <li>GRE Fax Registration</li>
                                </ul>
                                <div className="postcard__preview-txt">
                                    <h5 className="pb-3 card-head1">Where can GRE take you?</h5>
                                </div>
                                <div className="postcard-para-txt"> Countries like Australia, the United Kingdom, India, Canada, Belgium, Hong Kong, Belgium, and others accept GRE results.</div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gmat03.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of GRE test</h4>
                                    <div className="postcard-para-txt">Aids high-scoring applicants in gaining admission and
                                        scholarships to top-ranked universities and business schools. The GRE General
                                        Test is treated the same way as other graduate admissions examinations by the
                                        world's leading business schools. It gives colleges a thorough evaluation of the
                                        talents you'll need to succeed in MBA and master's degree programmes. Unlike
                                        other examinations, you can preview and skip questions within each section and
                                        go back to review and amend answers. This can make you feel more at ease and
                                        help you attain your best test results. The results are valid for five years.
                                        That means you can take it now and still have plenty of time to decide where and
                                        when you want to continue your degree. GRE scores are accepted by over 1,300
                                        business institutions worldwide for admission to MBA and speciality master's
                                        programmes. You can attend top colleges in the United States or famous
                                        programmes throughout Europe. Want to take the GRE but don't know where to
                                        start? Admissify is here to assist you! With offices in London, Boston, Hanover,
                                        Vancouver, Sydney, and other locations across India, Admissify is India's
                                        globally recognized education consultancy. After you arrive, we may provide
                                        in-country assistance such as internship aid, immigration, and career services,
                                        thanks to our global network.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamForm service={"GRE-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default GresMobile