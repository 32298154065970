import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import AppRoute from "../route";
import ScrollToTop from "../Component/ScrollToTop/ScrollToTop";
import LoadingScreen from "../Component/LoadingScreen/LoadingScreen";
import { addChatWidget } from "../Utils/Common/Common";
import DownloadAppPopup from "../Component/downloadAppPopup";

import Modal from "react-responsive-modal";
import Popup from "../Component/downloadAppPopup/popup";

function Layout() {
  const [downloadAppPopup, setDownloadAppPopup] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const appSuggestionDisplayed: string | null =
        sessionStorage.getItem("itemClicked");
      const windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (!appSuggestionDisplayed && windowWidth < 600) {
        setDownloadAppPopup(true);
        // sessionStorage.setItem("appSuggestion", "true");
      } else {
        setDownloadAppPopup(false);
      }
    };
    // Initial check on mount
    handleResize();
    // Add event listener to resize event
    window.addEventListener("resize", handleResize);
    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  addChatWidget();
  const commenreducer: any = useSelector(
    (state: any) => state.commenreducer.loading && state.commenreducer.loading
  );
  return (
    <>
      <Header></Header>
      <ScrollToTop />
      <AppRoute></AppRoute>
      {commenreducer && <LoadingScreen />}
      {downloadAppPopup && <Popup myFunction={setDownloadAppPopup} />}
      <Footer></Footer>
    </>
  );
}

export default Layout;
