import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { officeAction } from '../../../redux/common/action';
import { validateContactUsForm } from '../../../validation/Validation';

const GetInTouchForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [values, setValue] = useState<any>({});
    const [errors, setErrors] = useState<any>(undefined);
    const handleOnChnage = (e: any) => {
        let name:any = e.target.name;
        let value:any = e.target.value;
        if(name === "mobile" && /\D/g.test(value)) {
            return
        }
        setValue({ ...values, [e.target.name]: e.target.value });
    }

    const handleOnSubmit = (e: any) => {
        const validationErrors = validateContactUsForm(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data: any = {
                Name: values?.name,
                Mobile: values?.mobile,
                Email: values?.email,
                Message: values?.message,
                thank:true
            }
            dispatch(officeAction.getInTouch(data, history));
        }
    }
  return (
    <div className="apply-box">
        <div className="container p-2">
            <div className="row">
                <div className="col-md-11">
                    <h5 className="apply-head">apply now</h5>
                </div>
                <div className="col-md-12 col-lg-12">
                    <div className="fields-wrap">
                        <form id="fundingForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text"
                                            name="name" id="Name" value={values?.name || ""} onChange={handleOnChnage} />
                                        <label>name</label>
                                        <span className="fa fa-user p-2"></span>
                                    </div>
                                    {errors?.name && <p className='error-class'>{errors?.name}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text"
                                            name="mobile" maxLength={10} id="Mobile"
                                            value={values?.mobile || ""} onChange={handleOnChnage} />
                                        <label>mobile</label>
                                        <span className="fa fa-phone p-2"></span>
                                    </div>
                                    {errors?.mobile && <p className='error-class'>{errors?.mobile}</p>}
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <input autoComplete="new-off" type="text"
                                            name="email" id="Email" value={values?.email || ""} onChange={handleOnChnage} />
                                        <label>email</label>
                                        <span className="fa fa-envelope p-2"></span>
                                    </div>
                                    {errors?.email && <p className='error-class'>{errors?.email}</p>}
                                </div>
                            </div>
                            <div className="field"><textarea autoComplete="new-off" className="ques-msg border__"
                                rows={4} cols={50} placeholder="message" name="message" value={values?.message || ""} onChange={handleOnChnage}></textarea>
                                {errors?.message && <p className='error-class'>{errors?.message}</p>}
                            </div><br /><br /><br /><br />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="fieldbtn text-center">
                                        <input type="button" className="bggreadent-n" id="fundingIn" value="submit" onClick={(e)=> handleOnSubmit(e)}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GetInTouchForm