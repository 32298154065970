import React from "react";
import { Route, Redirect } from "react-router-dom";
import SignIn from "../SignIn";

function LoginProtectRoute(props: any) {
  const getUser = () => {
    return !!localStorage.getItem("access_token");
  };
  if (getUser()) {
    return <Redirect to="/" />;
  }
  return (
    <Route exact path="/login" render={(props) => <SignIn {...props} />} />
  );
}

export default LoginProtectRoute;
