import React, { useState, useEffect } from "react";
import Testimonial from "../../Home/component/Testimonial";
import { Link, useHistory } from "react-router-dom";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

function Exams() {
  const history = useHistory();
  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="assets/img/Term&Conditions.jpg"></img>
            <div className="container">
              <div className="tag-l">
                <h1>test preparation</h1>
                {/* <p>This content will present the importance and overview of these exams. Such information will certainly make the process simpler for you. So, keep on reading for removing all sorts of doubts.</p> */}
              </div>
            </div>
          </div>
        </section>

        <section className="categories section choosetxt">
          <div className="container">
          <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services"}}/>
            <div className="headingmains text-center">
              <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                test preparation
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                    <path id="mortarboard" d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z" transform="translate(0 -42.469)" fill="#fbb415"></path>
                  </svg>
                </span>
              </h2>
              <p className="text-center pl-2 pr-2">This content will present the importance and overview of these exams. Such information will certainly make the process simpler for you. So, keep on reading for removing all sorts of doubts.
              </p>
            </div>

            <div className="all-center sub-section pb-0">
              <div className="col-md-12 all-center pb-5">
                <div className="why_slide">
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/ielts-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/IELTS-11.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">IELTS</h5>
                        <p className="service-c" style={{ color: "rgb(109 109 109)" }}>The (IELTS) International English Language Testing System is the world's most popular English language proficiency test for study, work and migration. It includes verbal, listening, reading and writing components
                        </p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/ielts-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/pte-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/PTE-12.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">PTE</h5>
                        <p className="service-c" style={{ color: "rgb(109 109 109)" }}>PTE Academic is a language aptitude test used by educational institutions, professionals, and government agencies that demand a standard of academic English language proficiency for entrance purposes.
                        </p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/pte-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/toefl-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/TOEFL-13.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">TOEFL</h5>
                        <p className="service-c">TOEFL is a popular English proficiency examination widely recognised amongst American and Canadian universities. It includes verbal, listening, reading and writing components.
                        </p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/toefl-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="all-center sub-section pb-0">
              <div className="col-md-12 all-center pb-5">
                <div className="why_slide">
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/gmat-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/GMAT-14.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">GMAT
                        </h5>
                        <p className="service-c">The GMAT (Graduate Management Admission Test)  is a 3½-hour standardized exam designed to predict how students will perform academically in their MBA.  GMAT scores are used by graduate business schools to make admission decisions. Discover the best GMAT coaching in Delhi & India</p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/gmat-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  ><Link to={"/gre-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/GRE-15.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">GRE</h5>
                        <p className="service-c">The GRE (Graduate Record Exam) Test is the standardized test used to get admissions in various graduate schools or business graduate schools in various English speaking countries, especially the United States.</p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/gre-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                  <div
                    className="why-choose-ad box-zoom"
                    data-aos="fade-up-left"
                    data-aos-duration="1000"
                  >
                    <Link to={"/sat-exam"}>
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/SAT-16.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h5 className="pb-2">SAT
                        </h5>
                        <p className="service-c"><p>The SAT is a standardized test widely used for college admissions in the United States. Introduced in 1926 it is intended to assess students' readiness for college. It takes 3 hours and costs approx $50. The ACT exam is similar</p></p>
                      </figcaption>
                    </Link>
                    <div className="all-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                      <a onClick={() => { history.push("/sat-exam"); }} className="view-more readbtn">know more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
        <Testimonial />

      </div>
    </>
  )
}
export default Exams
