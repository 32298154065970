import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { testimonialAction } from "../../redux/common/action";
import Slider from "react-slick";
import StarRatings from "react-star-ratings";
import { URL } from "../../redux/common/url";
import ReactPlayer from "react-player";

function Testimonial() {
  const dispatch = useDispatch();
const [show, setShow] = useState(true);
  const baseurl = URL.API_BASE_URL

  const testimonial: any = useSelector((state: any) =>
    state.testimonial.testimonialList ? state.testimonial.testimonialList : []
  );

  useEffect(() => {
    dispatch(testimonialAction.gettestimoniallist({ is_visible: 1, status: 1}));
    return () => { };
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };
  return (
    <div>
      <section className="section greybg tesi-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6">
              {/* <!-- section title --> */}
              <div className="inner-heading">
                <div className="headingmains" style={{ marginTop: "15px" }}>
                  <h2 className="pb-3">Testimonial</h2>
                  <p>
                    Our students get the fabulous chance to fulfill their dreams by the support of the admissify. Read about their thoughts over here.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-6">
              <div className="testi_slider">
                <Slider {...settings}>
                  {testimonial && testimonial?.map((data: any, i: any) => {
                    return (
                      <div key={i}>
                        <div className="col-md-12 col-sm-12 col-xs-12 item">
                          <div className="team_member">
                            <figure className="effect-julia">

                              {data?.profile_image !== null ?
                                <img
                                  className="scale"
                                  src={baseurl + data?.profile_image}
                                  alt="team"
                                /> :
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                  alt="team"
                                />
                              }
                            </figure>
                            <div className="member_name">
                              {data?.testimonial_type == 2 && data?.video_url ?
                                <p>
                                  <ReactPlayer controls url={data?.video_url} width="100%" height="100%"/>
                                </p>
                                :
                                <p className="auto_scroll">
                                  {data?.content}
                                </p>
                              }
                              <h5>
                                <span></span>
                                {data?.name}
                              </h5>
                              <div className="feat-inn">
                                <ul>
                                  <StarRatings
                                    rating={
                                      parseFloat(data?.rating)
                                        ? parseFloat(data?.rating)
                                        : 0
                                    }
                                    starRatedColor="#fcbd30"
                                    starDimension="15px"
                                    starSpacing="0px"
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div>
                    <div className="col-md-12 col-sm-12 col-xs-12 item">
                      <div className="team_member">
                        <figure className="effect-julia">
                          <img
                            className="scale"
                            src="assets/img/Ellipse 42.png"
                            alt="team"
                          />
                        </figure>
                        <div className="member_name">
                          <p>
                            My counsellor was amazing and I love how easy and
                            informative the website and app are. This is
                            definitely the way to go. I highly recommend
                            admissify{" "}
                          </p>
                          <h5>
                            <span></span>Yash Bansal
                          </h5>
                          <div className="feat-inn">
                            <ul>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div>
                    <div className="col-md-12 col-sm-12 col-xs-12 item">
                      <div className="team_member">
                        <figure className="effect-julia">
                          <img
                            className="scale"
                            src="assets/img/Ellipse 43.png"
                            alt="team"
                          />
                        </figure>
                        <div className="member_name"> */}
                  {/* <!-- <iframe width="350" height="138"
												src="https://www.youtube.com/embed/WwDzTcwA4Bs"
												title="YouTube video player" frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen></iframe>
                                             --> */}
                  {/* <div className="videobx">
                            <a href="#">
                              <img
                                src="assets/img/video1.png"
                                className="img-responsive"
                              />
                            </a>
                          </div>
                          <h5>
                            <span></span>Sarthak Chopra
                          </h5>
                          <div className="feat-inn">
                            <ul>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div>
                    <div className="col-md-12 col-sm-12 col-xs-12 item">
                      <div className="team_member">
                        <figure className="effect-julia">
                          <img
                            className="scale"
                            src="assets/img/Ellipse 42.png"
                            alt="team"
                          />
                        </figure>
                        <div className="member_name">
                          <p>
                            My counsellor was amazing and I love how easy and
                            informative the website and app are. This is
                            definitely the way to go. I highly recommend
                            admissify{" "}
                          </p>
                          <h5>
                            <span></span>Yash Bansal
                          </h5>
                          <div className="feat-inn">
                            <ul>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                              <li>
                                <i className="fa fa-star"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonial;
