import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Calendar from "react-calendar";
import dateFormat from "dateformat";
import "react-calendar/dist/Calendar.css";
import "../../assets/css/calendar-new.css";
import {
  counselloerAction,
  coachAction,
  UniversityAction,
  SettingAction,
  FaqAction,
  blogAction,
  DiscussionAction,
  articaleAction,
} from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import Exe from "../../assets/img/icons/exp.png";
import Edu from "../../assets/img/icons/edu.png";
import Loc from "../../assets/img/icons/location.png";
import certificate from "../../assets/img/certificate.svg";
import degree from "../../assets/img/degree.svg";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import avail from "../../assets/img/days_availability.png";
import SocialShareButton from "../SocialShareButton";
import ReactPlayer from "react-player";
import Breadcrumb from "../Breadcrumb";
import Modal from "react-responsive-modal";

function CounsellorDetails() {
  const urlsforShare = window.location.href;
  const counselloerSlug: any = useParams();
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const id: any = useLocation();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const isAuthenticated: any = getToken();
  const [faqdata, setfaqdata] = useState();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [date, setDate] = useState(new Date());
  const [CoachCounsellorId, setCoachCounsellorId] = useState();
  const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [CurrentTime, setCurrentTime] = useState(new Date().getHours());
  const [CurrentDate, setCurrentDate] = useState(new Date().getDate());
  const [showModal, setShowModal] = useState(false);

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const timings = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
  ];
  var noAvailable = false;
  // const counsellor: any = useSelector((state: any) =>
  //   state.counsellor.counsellordetail ? state.counsellor.counsellordetail : []
  // );
  const counsellor: any = useSelector((state: any) =>
    state.counsellor.counsellordetailBySlug
      ? state.counsellor.counsellordetailBySlug
      : []
  );

  const appointments: any[] = useSelector((state: any) =>
    state.coach.bookAppointment ? state.coach.bookAppointment : []
  );

  // const relatedcounsellor: any[] = useSelector((state: any) =>
  //   state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  // );
  const relatedcounsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorinfo ? state.counsellor.counsellorinfo : []
  );

  const activeRelatedcounsellor =
    relatedcounsellor &&
    relatedcounsellor?.filter((data: any) => {
      return data?.status == 1;
    });

  ////////////////////////// Start Faq
  const faqList: any[] = useSelector((state: any) =>
    state.faq.faqList ? state.faq.faqList : []
  );

  const dc = faqList.filter((data) => {
    return data.faq_category_id === faqList[0].faq_category_id;
  });

  const faqL = typeof faqdata == "undefined" ? dc : faqdata;
  const faqLNew = faqList.filter((data) => {
    return (
      data?.user_reference_id == counsellor?.user_id && data?.user_type == 3
    );
  });
  const CFaqs = () => {
    dispatch(FaqAction.getfaqlist({}));
    return () => {};
  };
  // const onchangefaq = (event: any, data: any) => {
  //   const faqlist: any = faqList.filter((datas) => {
  //     return datas.faq_category_id === data;
  //   });
  //   setfaqdata(faqlist);
  // };
  ////////////////////////// End Faq

  ////////////////////////// Start Vblog
  /* const vBlog: any[] = useSelector((state: any) =>
    state.blog.vBlog ? state.blog.vBlog : []
  );
  const vblog = (e: any) => {
    dispatch(blogAction.getVBlog({
      UserType: 2,
      UserId: counsellor?.id
    }));
    return () => { };
  } */
  ////////////////////////// End  Vblog

  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities?.data ? state.unversity.universities?.data : []
  );

  const getSetting: any = useSelector((state: any) =>
    state.setting.getSetting ? state.setting.getSetting : []
  );

  const discuss: any[] = useSelector((state: any) =>
    state.discussion.discussionList ? state.discussion.discussionList : []
  );

  const articalList: any[] = useSelector((state: any) =>
    state.articale.articaleList ? state.articale.articaleList : []
  );
  const reviewList: any[] = useSelector((state: any) =>
    state.articale.reviewList ? state.articale.reviewList : []
  );
  // const handleFreeCoaching = (e: any) => {
  //   if (isAuthenticated == false) {
  //     history.push("/login", { logback: 1 });
  //   } else {
  //     history.push("/");
  //   }
  // };

  $("#tenam").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#elevenam").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#twelvepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#onepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#twopm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#threepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#fourpm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#fivepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#sixpm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  const handleAppointment = (e: any) => {
    setLoading(true);
    if (isAuthenticated === false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var Date = dateFormat(date, "yyyy-mm-dd");
      var Data = {
        refer_url: window.location.origin,
        id: user?.id,
        mobile: user?.mobile,
        reference_type: 1,
        reference_user_id: e,
        date: Date,
        time: CoachCounsellorId,
      };
      dispatch(coachAction.bookAppointment(Data));
    }
  };

  useEffect(() => {
    console.log(loading);

    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  useEffect(() => {
    if (appointments[0] === 1) {
      setShowModal(false);
    }
  }, [appointments]);

  const reletedcounsellor = (e: any) => {
    history.push("/counsellor/" + e);
    dispatch(counselloerAction.getCounsellorBySlug(e));
    return () => {};
  };
  useEffect(() => {
    dispatch(SettingAction.getSetting({}));
    return () => {};
  }, []);
  useEffect(() => {
    // dispatch(counselloerAction.getCounsellorbyId(id?.search));
    dispatch(counselloerAction.getCounsellorBySlug(counselloerSlug?.slug));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      UniversityAction.getuniversities({
        search_by: "",
        offset: 0,
        country_id: [],
        limit: 5,
      })
    );
    return () => {};
  }, []);

  var preferredCountry: any = [];
  var preferred_country = counsellor?.preferred_country
    ? counsellor?.preferred_country.split(",")
    : [];
  var preferred_universities = counsellor?.preferred_universities
    ? counsellor?.preferred_universities.split(",")
    : [];

  useEffect(() => {
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);

  // useEffect(() => {
  //   const LoadExternalScript = () => {
  //     const externalScript = document.createElement("script");
  //     // externalScript.onerror = loadError;
  //     externalScript.id = "external";
  //     externalScript.async = false;
  //     externalScript.type = "text/javascript";
  //     externalScript.setAttribute("crossorigin", "*");
  //     document.body.appendChild(externalScript);
  //     externalScript.src = `https://js.instamojo.com/v1/checkout.js`;
  //   };
  //   LoadExternalScript();
  // }, []);
  const getdiscussionList = () => {
    dispatch(
      DiscussionAction.getDiscussionList({
        user_id: counsellor?.user_id,
        user_type: 3,
      })
    );
    return () => {};
  };
  const getArticalList = () => {
    dispatch(articaleAction.getarticlelist(counsellor?.user_id));
    return () => {};
  };
  const getReviewList = () => {
    dispatch(
      articaleAction.getreviewlist({
        reference_id: counsellor?.user_id,
        testimonial_for: 3,
        status: "1",
      })
    );
    return () => {};
  };
  const [LastCategoryId, setLastCategoryId] = useState<any>(null);
  const faqCatList: any[] = useSelector((state: any) =>
    state.faq.faqCatList ? state.faq.faqCatList : []
  );
  const onchangefaq = (event: any, data: any) => {
    setLastCategoryId(data);
  };
  useEffect(() => {
    dispatch(FaqAction.getfaqcategory({}));
    return () => {};
  }, []);

  useEffect(() => {
    faqCatList.map((data, index) => {
      if (index == 0) setLastCategoryId(data?.id);
    });
  }, [faqCatList]);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={counsellor?.name ? `Counsellor | ${counsellor?.name}` : ""}
        meta_description={counsellor?.additional_info}
        meta_keywords={""}
        modified_time={counsellor?.updatedAt || counsellor?.createdAt}
        image={
          counsellor?.profile_image ? baseurl + counsellor?.profile_image : ""
        }
      />
      <section className="unifilter-page counsellor_details">
        <Breadcrumb
          page={"Counsellor"}
          data={{ slug: counsellor?.name, path: "/counsellor" }}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-pull-10 order-lg-2">
              <div className="page-sidebar">
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    suggested <b>university</b>{" "}
                  </h5>

                  <ul className="bg-white">
                    {universities &&
                      universities?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data?.university_logo != null ? (
                              <img
                                style={{ cursor: "pointer" }}
                                src={baseurl + data?.university_logo}
                                alt=""
                              />
                            ) : (
                              <img
                                style={{ cursor: "pointer" }}
                                src="/assets/img/university-icon.png"
                                alt=""
                              />
                            )}
                            <div className="counsellorName">
                              <h6
                                className="__hover"
                                onClick={() => {
                                  history.push("/university/" + data?.slug, {
                                    university_id: data?.id,
                                  });
                                }}
                              >
                                {data?.university_name && data?.university_name}
                              </h6>
                              <div className="feat-inn">
                                <ul>
                                  <StarRatings
                                    rating={
                                      parseFloat(data?.rating)
                                        ? parseFloat(data?.rating)
                                        : 0
                                    }
                                    starRatedColor="#fcbd30"
                                    starDimension="13px"
                                    starSpacing="0px"
                                  />
                                </ul>
                                <p>
                                  <small>
                                    {" "}
                                    {data?.review_count
                                      ? data?.review_count
                                      : 0}{" "}
                                    student counselled
                                  </small>
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                {/* <!-- WIDGET --> */}
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    related <b>counsellor</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {activeRelatedcounsellor &&
                      activeRelatedcounsellor?.map((data: any, i: any) => {
                        return (
                          i < 6 && (
                            <li
                              key={i}
                              style={{
                                display:
                                  counsellor?.slug == data?.slug ? "none" : "",
                              }}
                            >
                              <div className="counsellorBox">
                                <div
                                  className="relatedCounsellor"
                                  style={{
                                    background: `url(${
                                      baseurl + data?.profile_image
                                    })`,
                                    cursor: "pointer",
                                  }}
                                ></div>
                              </div>
                              {/* <img src="assets/img/testi.png" alt="" /> */}
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    reletedcounsellor(data?.slug);
                                  }}
                                >
                                  {data?.gender == 1 ? "Mr." : "Ms. "}{" "}
                                  {data?.name && data?.name}{" "}
                                </h6>
                                <div className="feat-inn">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                  <p>
                                    {/* <small>| 500+ student counselled</small> */}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-9 col-md-push-2">
              <div className="filter-page__content">
                <div className="filter-item-wrapper counsellorlist counsellor_details">
                  <div className="shade1">
                    <div className="uni-item-list">
                      <div className="item-media">
                        <div className="image-cover">
                          <div
                            className="counsellorAneCoach_details"
                            style={{
                              background: `url(${
                                baseurl + counsellor?.profile_image
                              })`,
                            }}
                          ></div>
                          {/* <img
                            src="assets/img/pankaj.png"
                            alt=""
                            className="scale"
                          /> */}
                        </div>
                      </div>
                      <div className="item-body">
                        <div className="item-title">
                          <div className="pricedegree">
                            <div className="sos-icon">
                              <SocialShareButton
                                urlsforShare={urlsforShare}
                                data={""}
                              />
                            </div>
                            <p>
                              {counsellor?.rate_per_hr
                                ? `INR ${counsellor?.rate_per_hr}/60 min`
                                : "Free"}{" "}
                              <span>
                                {counsellor?.admissify_certified == 1 ? (
                                  <img src={certificate} alt="" />
                                ) : (
                                  ""
                                )}
                              </span>
                              <span>
                                {" "}
                                {counsellor?.premium == 1 ? (
                                  <img src={degree} alt="" />
                                ) : (
                                  ""
                                )}{" "}
                              </span>
                            </p>
                          </div>
                          <h3>
                            {counsellor?.gender == 1 ? "Mr." : "Ms. "}{" "}
                            {counsellor?.name && counsellor?.name}
                            {counsellor?.admissify_verified == 1 ? (
                              <sup className="color-green">
                                <small>
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              </sup>
                            ) : (
                              ""
                            )}
                            <span className="online">
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                                style={{
                                  color: counsellor?.User?.is_online
                                    ? "green"
                                    : "red",
                                }}
                              ></i>{" "}
                              {counsellor?.User?.is_online
                                ? "online"
                                : "offline"}
                            </span>
                          </h3>
                          <p>counsellor</p>
                          <p className="__content_">
                            {counsellor?.additional_info &&
                              counsellor?.additional_info}
                          </p>
                          <p className="yellow pt-2">
                            {counsellor?.passout_university &&
                              counsellor?.passout_university}
                          </p>
                        </div>
                        <div className="item-footer mt-0">
                          <div className="item-hotel-star">
                            <div>
                              <StarRatings
                                rating={
                                  parseFloat(counsellor?.rating)
                                    ? parseFloat(counsellor?.rating)
                                    : 0
                                }
                                starRatedColor="#fcbd30"
                                starDimension="13px"
                                starSpacing="0px"
                              />{" "}
                              <span>
                                |{" "}
                                {counsellor?.counselled &&
                                counsellor?.counselled > 500
                                  ? "500+"
                                  : counsellor?.counselled}{" "}
                                student google reviews
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item-abouttxt">
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody className="mob-po-relative">
                              <tr>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Exe}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>experience</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {counsellor?.experience == null
                                              ? ""
                                              : counsellor?.experience +
                                                " yr"}{" "}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ width: "165px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Loc}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>country preference </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {countrys.map((v, k) => {
                                            if (
                                              preferred_country &&
                                              preferred_country.includes(
                                                v?.id.toString()
                                              )
                                            ) {
                                              preferredCountry.push(
                                                v?.country_name
                                              );
                                            }
                                          })}
                                          <>
                                            <span className="yellow">
                                              {preferredCountry.length != 0
                                                ? preferredCountry.join(", ")
                                                : "N/A"}
                                            </span>
                                          </>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Edu}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>highest education</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {counsellor?.qualification}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody className="mob-po-relative">
                              <tr>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={avail}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>availability</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {counsellor?.days_availability
                                              ? counsellor?.days_availability
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="item-btn-more">
                            <div className="btnbox">
                              <p
                                // data-toggle="modal"
                                // data-target="#date_foo"
                                style={{
                                  backgroundColor: "#FBB415",
                                  padding: "10px 15px",
                                  color: "white",
                                  fontSize: "12px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                className="awe-btnbook download"
                                onClick={() => setShowModal(true)}
                              >
                                Book Appointment
                              </p>
                              <a
                                href={"tel:" + getSetting[0]?.mobile}
                                className="awe-btnbook"
                              >
                                <i
                                  className="fa fa-phone"
                                  aria-hidden="true"
                                ></i>{" "}
                                connect support
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contentbx tabdetails">
                      <ul className="nav nav-tabs bggreadent" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                          >
                            about counsellor{" "}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                            onClick={getReviewList}
                          >
                            {" "}
                            reviews
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-3"
                            role="tab"
                            onClick={CFaqs}
                          >
                            faqs
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-4"
                            role="tab"
                            onClick={getArticalList}
                          >
                            article
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-5"
                            role="tab"
                            onClick={vblog}
                          >
                            vblog
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-6"
                            role="tab"
                            onClick={getdiscussionList}
                          >
                            discussion
                          </a>
                        </li>
                      </ul>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="tabs-1"
                          role="tabpanel"
                        >
                          <p className="f-size">
                            <div
                              className="editor_css"
                              dangerouslySetInnerHTML={{
                                __html: counsellor?.description
                                  ? counsellor?.description
                                  : "no content available",
                              }}
                            ></div>
                          </p>
                        </div>
                        <div className="tab-pane" id="tabs-2" role="tabpanel">
                          <div className="f-size row tesi-wrap no-bg">
                            {reviewList &&
                              reviewList?.length > 0 &&
                              reviewList?.map((data, idx) => {
                                if (data?.status == 1)
                                  return (
                                    <div className="col-md-6 col-sm-12 col-xs-12 item mb-3">
                                      <div className="team_member">
                                        {/* <figure className="effect-julia">
                                          {data?.profile_image &&
                                          data?.profile_image !== null ? (
                                            <img
                                              className="scale"
                                              src={
                                                baseurl + data?.profile_image
                                              }
                                              alt="team"
                                            />
                                          ) : (
                                            <img
                                              className="scale"
                                              src="/assets/img/noImage.jpeg"
                                              alt="team"
                                            />
                                          )}
                                        </figure> */}
                                        <div className="member_name">
                                          {data?.testimonial_type == 2 &&
                                          data?.video_url ? (
                                            <p style={{minHeight:"auto"}}>
                                              <ReactPlayer
                                                controls
                                                url={data?.video_url}
                                                width="100%"
                                                height="100%"
                                              />
                                            </p>
                                          ) : (
                                            <p className="auto_scroll" style={{minHeight:"auto"}}>
                                              {data?.content}
                                            </p>
                                          )}
                                          <h5>
                                            <span></span>
                                            {data?.name}
                                          </h5>
                                          <div className="feat-inn">
                                            <ul>
                                              <StarRatings
                                                rating={
                                                  parseFloat(data?.rating)
                                                    ? parseFloat(data?.rating)
                                                    : 0
                                                }
                                                starRatedColor="#fcbd30"
                                                starDimension="15px"
                                                starSpacing="0px"
                                              />
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                              })}
                          </div>
                        </div>
                        <div className="tab-pane" id="tabs-3" role="tabpanel">
                          <div className="row">
                            <div className="col-sm-12">
                              <div
                                className="contentbx choachwhat cFaq__ faqtabs mt-5"
                                style={{ maxWidth: "100%" }}
                              >
                                <ul className="nav nav-tabs" role="tablist">
                                  {faqCatList &&
                                    faqCatList?.map((data, index) => {
                                      return (
                                        <li className="nav-item" key={index}>
                                          <a
                                            className={
                                              index == 0
                                                ? "nav-link active"
                                                : "nav-link"
                                            }
                                            data-toggle="tab"
                                            href={"#tabs_1"}
                                            onClick={(event) =>
                                              onchangefaq(event, data?.id)
                                            }
                                            role="tab"
                                            style={{
                                              backgroundColor: "#e6e6e6",
                                              color: "#555555",
                                            }}
                                          >
                                            {data?.category && data?.category}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                                {/* <!-- Tab panes --> */}
                                <div
                                  className="tab-content"
                                  style={{ padding: "0" }}
                                >
                                  <div
                                    className="tab-pane active"
                                    id="tabs_1"
                                    role="tabpanel"
                                  >
                                    <div className="accordion" id="faq">
                                      {faqLNew &&
                                        faqLNew?.map((data, index) => {
                                          if (
                                            data?.faq_category_id ==
                                            LastCategoryId
                                          ) {
                                            return (
                                              <div className="card" key={index}>
                                                <div
                                                  className="card-header"
                                                  id={"faqhead1" + data?.id}
                                                >
                                                  <a
                                                    href="#"
                                                    className={
                                                      index == 0
                                                        ? "btn btn-header-link"
                                                        : "btn btn-header-link collapsed"
                                                    }
                                                    data-toggle="collapse"
                                                    data-target={
                                                      "#faq1" + data?.id
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls={
                                                      "faq1" + data?.id
                                                    }
                                                  >
                                                    {data?.title}
                                                  </a>
                                                </div>

                                                <div
                                                  id={"faq1" + data?.id}
                                                  className={
                                                    index == 0
                                                      ? "collapse show"
                                                      : "collapse"
                                                  }
                                                  aria-labelledby={
                                                    "faqhead1" + data?.id
                                                  }
                                                  data-parent="#faq"
                                                >
                                                  <div className="card-body">
                                                    {data?.description &&
                                                      data?.description}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p
                            className="f-size"
                            style={{
                              display: faqList.length == 0 ? "" : "none",
                            }}
                          >
                            {" "}
                            currently not available.
                          </p>
                        </div>
                        <div className="tab-pane" id="tabs-4" role="tabpanel">
                          <div className="faqtabs articaltabs">
                            <div className="tab-content">
                              <div className="accordion" id="artical">
                                {articalList &&
                                  articalList?.length > 0 &&
                                  articalList?.map((data, index) => {
                                    return (
                                      <div className="card" key={index}>
                                        <div
                                          className="card-header"
                                          id={"articalhead1" + data?.id}
                                        >
                                          <a
                                            href="#"
                                            className={
                                              index == 0
                                                ? "btn btn-header-link"
                                                : "btn btn-header-link collapsed"
                                            }
                                            data-toggle="collapse"
                                            data-target={"#artical1" + data?.id}
                                            aria-expanded="true"
                                            aria-controls={
                                              "artical1" + data?.id
                                            }
                                          >
                                            {data?.heading}
                                          </a>
                                        </div>
                                        <div
                                          id={"artical1" + data?.id}
                                          className={
                                            index == 0
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                          aria-labelledby={
                                            "articalhead1" + data?.id
                                          }
                                          data-parent="#artical"
                                        >
                                          <div
                                            className="card-body editor_css"
                                            dangerouslySetInnerHTML={{
                                              __html: data?.details
                                                ? data?.details
                                                : " ",
                                            }}
                                          />
                                          <a
                                            className="blog_url_button text-center  mx-auto"
                                            href={data?.blog_url}
                                            target="_blank"
                                          >
                                            Blog Url
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="tab-pane" id="tabs-5" role="tabpanel">
                          <div className="row">
                            {vBlog && vBlog?.map((data, index) => {
                              const videoUrl = data?.video_url;
                              const res = videoUrl ? videoUrl.split("=") : '';
                              const embeddedUrl = "https://www.youtube.com/embed/" + res[1];
                              return (
                                <div className="col-12 __vblog" key={index}>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <iframe
                                        src={embeddedUrl}
                                        width="600"
                                        height="500"
                                        style={{ border: "0" }}
                                        allowFullScreen
                                        loading="lazy"
                                      ></iframe>
                                    </div>
                                    <div className="col-md-6">
                                      <h4>{data?.heading}</h4>
                                      <p className="vblog-text coach-view">{data?.short_details}</p>
                                    </div>
                                    <div className="col-md-12 coach-view">
                                      <p className="vblog-text">{data?.details}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <p className="f-size" style={{ display: vBlog.length == 0 ? "" : "none" }}>
                            {" "}
                            currently not available.
                          </p>
                        </div> */}
                        <div className="tab-pane" id="tabs-6" role="tabpanel">
                          <p className="f-size">
                            {discuss &&
                              discuss?.length > 0 &&
                              discuss?.map((data, idx) => (
                                <div className="shade1 reviewstops bg-grey-light review-discussion">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="counsellorName">
                                        <h6
                                          onClick={() => {
                                            history.push(
                                              "/about-discussion/" +
                                                data?.slug +
                                                "",
                                              {
                                                discussion_id: data?.slug,
                                              }
                                            );
                                          }}
                                        >
                                          <b>
                                            {data?.topic ? data?.topic : " "}
                                          </b>
                                        </h6>
                                        <div className="reviews_item">
                                          <a
                                            onClick={() => {
                                              history.push(
                                                "/about-discussion/" +
                                                  data?.slug +
                                                  "",
                                                {
                                                  discussion_id: data?.slug,
                                                }
                                              );
                                            }}
                                          >
                                            <p className="pt-1 pb-1">
                                              {data?.description
                                                ? data?.description + " "
                                                : " "}
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="modal-body p-0">
          <div className="off-detail-item">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              {/* <span aria-hidden="true">×</span> */}
            </button>
          </div>
          <p className="calen-head">select date & time</p>
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="calendar-container">
                <Calendar
                  onChange={setDate}
                  value={date}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="timepicker" ng-if="picktime == 'true'">
                <div ng-className="{'am': timeframe == 'am', 'pm': timeframe == 'pm' }">
                  <div
                    className="timepicker-container-outer"
                    selectedtime="time"
                    timetravel
                  >
                    <div className="timepicker-container-inner">
                      <div
                        className="timeline-container"
                        ng-mousedown="timeSelectStart($event)"
                        sm-touchstart="timeSelectStart($event)"
                      >
                        <div className="timeline"></div>
                      </div>

                      {timings.map((value, index) => {
                        var currtime = index > 12 ? value + 12 : value;
                        var timeString =
                          (value == 0 ? 12 : value) +
                          ":00" +
                          (currtime >= 12 && value != 0 ? " pm" : " am");
                        if (
                          CurrentYear == date.getFullYear() &&
                          CurrentMonth == date.getMonth() &&
                          CurrentDate == date.getDate() &&
                          CurrentTime < currtime
                        ) {
                          // alert("one");
                          noAvailable = true;
                          return (
                            <>
                              <div className="display-time">
                                <div
                                  className="time__"
                                  ng-className="{'time-active': edittime.active}"
                                >
                                  <input
                                    type="text"
                                    className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                    ng-model="edittime.input"
                                    ng-keydown="changeInputTime($event)"
                                    ng-focus="edittime.active = true; edittime.digits = [];"
                                    ng-blur="edittime.active = false"
                                    data-id={timeString}
                                    onClick={(e) => {
                                      setCoachCounsellorId(timeString);
                                    }}
                                    // id="tenam"
                                  />

                                  <div className="formatted-time ng-binding">
                                    {console.log(timeString, "===")}
                                    {timeString}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else if (
                          CurrentYear == date.getFullYear() &&
                          CurrentMonth == date.getMonth() &&
                          CurrentDate < date.getDate()
                        ) {
                          noAvailable = true;
                          // alert("two");
                          return (
                            <>
                              <div className="display-time">
                                <div
                                  className="time__"
                                  ng-className="{'time-active': edittime.active}"
                                >
                                  <input
                                    type="text"
                                    className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                    ng-model="edittime.input"
                                    ng-keydown="changeInputTime($event)"
                                    ng-focus="edittime.active = true; edittime.digits = [];"
                                    ng-blur="edittime.active = false"
                                    data-id={timeString}
                                    onClick={(e) => {
                                      setCoachCounsellorId(timeString);
                                    }}
                                    // id="tenam"
                                  />

                                  <div className="formatted-time ng-binding">
                                    {timeString}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else if (
                          CurrentYear == date.getFullYear() &&
                          CurrentMonth < date.getMonth()
                        ) {
                          noAvailable = true;
                          // alert("three");
                          return (
                            <>
                              <div className="display-time">
                                <div
                                  className="time__"
                                  ng-className="{'time-active': edittime.active}"
                                >
                                  <input
                                    type="text"
                                    className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                    ng-model="edittime.input"
                                    ng-keydown="changeInputTime($event)"
                                    ng-focus="edittime.active = true; edittime.digits = [];"
                                    ng-blur="edittime.active = false"
                                    data-id={timeString}
                                    onClick={(e) => {
                                      setCoachCounsellorId(timeString);
                                    }}
                                    // id="tenam"
                                  />

                                  <div className="formatted-time ng-binding">
                                    {timeString}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          // alert("four");
                        }
                      })}
                      {noAvailable ? (
                        ""
                      ) : (
                        <div className="slot text-center">
                          <strong> No slot available </strong>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figcaption className="details-box p-3">
            <div className="bttns text-right">
              <a className="applybtn bggreadent">
                <button
                  className="btn btn-success font-weight-bold resolve-btn"
                  onClick={(e) => handleAppointment(counsellor?.user_id)}
                  disabled={loading}
                >
                  submit
                </button>
              </a>
            </div>
          </figcaption>
        </div>
      </Modal>

      {/* <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <p className="calen-head">select date & time</p>
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="calendar-container">
                    <Calendar
                      onChange={setDate}
                      value={date}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="timepicker" ng-if="picktime == 'true'">
                    <div ng-className="{'am': timeframe == 'am', 'pm': timeframe == 'pm' }">
                      <div
                        className="timepicker-container-outer"
                        selectedtime="time"
                        timetravel
                      >
                        <div className="timepicker-container-inner">
                          <div
                            className="timeline-container"
                            ng-mousedown="timeSelectStart($event)"
                            sm-touchstart="timeSelectStart($event)"
                          >
                            <div className="timeline"></div>
                          </div>

                          {timings.map((value, index) => {
                            var currtime = index > 12 ? value + 12 : value;
                            var timeString =
                              (value == 0 ? 12 : value) +
                              ":00" +
                              (currtime >= 12 && value != 0 ? " pm" : " am");
                            if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate == date.getDate() &&
                              CurrentTime < currtime
                            ) {
                              // alert("one");
                              noAvailable = true;
                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {console.log(timeString, "===")}
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate < date.getDate()
                            ) {
                              noAvailable = true;
                              // alert("two");
                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth < date.getMonth()
                            ) {
                              noAvailable = true;
                              // alert("three");
                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                              // alert("four");
                            }
                          })}
                          {noAvailable ? (
                            ""
                          ) : (
                            <div className="slot text-center">
                              <strong> No slot available </strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption className="details-box p-3">
                <div className="bttns text-right">
                  <a className="applybtn bggreadent">
                    <span
                      className="btn btn-success font-weight-bold resolve-btn"
                      onClick={(e) => handleAppointment(counsellor?.user_id)}
                    >
                      submit
                    </span>
                  </a>
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default CounsellorDetails;
