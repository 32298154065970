import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { officeAction } from '../../../redux/common/action';
import { getToken } from '../../../Utils/Auth/Token';
import { validateContactUsForm } from '../../../validation/Validation';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';

const ContactUsForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated: any = getToken();
    var user: any = isAuthenticated && jwt_decode(isAuthenticated);
    const [values, setValue] = useState<any>({});
    const [errors, setErrors] = useState<any>(undefined);
    const handleOnChnage = (e:any) => {
        let name:any = e.target.name;
        let value:any = e.target.value;
        if(name === "mobile" && /\D/g.test(value)) {
            return
        }
        setValue({...values, [e.target.name]:e.target.value});
    }
    const handleOnSubmit = (e:any) => {
        const validationErrors = validateContactUsForm(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setErrors(validationErrors);
        if (noErrors) {
            let data:any = {
                Name: values?.name,
                Email: values?.email,
                Message: values?.message,
                Mobile: values?.mobile,
                User_id: user?.id || "",
                thank:true
            };
            dispatch(officeAction.getInTouch(data, history));
            setValue({});
        }
    }
  return (
    <section className="bg-grey bg-grey-pa">
        <div className="apply-box">
            <div className="container p-2">
                <div className="row">
                    <div className="col-md-11">
                        <h5 className="apply-head">get in touch</h5>
                    </div>
                    <div className="col-md-12">
                        <div className="fields-wrap">
                            <form id="fundingForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input type="text" name="name" value={values?.name || ""} onChange={handleOnChnage}/>
                                            <label>name</label><span className="fa fa-user p-2"></span>
                                        </div>
                                        {errors?.name && <p className='error-class'>{errors?.name}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input type="text" name="mobile" maxLength={10} id="mob"
                                               value={values?.mobile || ""} onChange={handleOnChnage}/>
                                            <label>mobile</label><span className="fa fa-phone p-2"></span>
                                        </div>
                                        {errors?.mobile && <p className='error-class'>{errors?.mobile}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <input type="text"  name="email" id="email" value={values?.email || ""} onChange={handleOnChnage}/>
                                            <label>email</label><span className="fa fa-envelope p-2"></span>
                                        </div>
                                        {errors?.email && <p className='error-class'>{errors?.email}</p>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field">
                                            <textarea className="ques-msg ques-msg1" rows={4} cols={50}
                                                placeholder="Message" name="message" value={values?.message || ""} onChange={handleOnChnage}></textarea>
                                            {errors?.message && <p className='error-class'>{errors?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="fieldbtn fbtn-mt-100 text-center">
                                            <input type="button" className="bggreadent-n" id="fundingIn" value="send" onClick={(e)=> handleOnSubmit(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ContactUsForm