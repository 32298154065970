import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
import { Link, useHistory, useLocation } from "react-router-dom";
import StarRatings from "react-star-ratings";
import AdSlider from "../AdSlider/AdSlider";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import "../../assets/css/calendar-new.css";
import Calendar from "react-calendar";
import dateFormat from "dateformat";
import "react-calendar/dist/Calendar.css";

function CounsellorList(props: any) {
  const history = useHistory();

  const id: any = useLocation();
  const lastLocation = useLocation();
  const baseurl = URL.API_BASE_URL;
  const dispatch = useDispatch();
  const isAuthenticated: any = getToken();
  const [CurrentDate, setCurrentDate] = useState(new Date().getDate());
  const [CurrentTime, setCurrentTime] = useState(new Date().getHours());
  const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const timings = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
  ];
  var noAvailable = false;
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [date, setDate] = useState(new Date());
  const [CoachCounsellorId, setCoachCounsellorId] = useState();
  const [cId, setcId] = useState();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const counsellorinfo: any[] = useSelector((state: any) =>
    state.counsellor.counsellorinfo ? state.counsellor.counsellorinfo : []
  );

  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  const activeCounselloer =
    counsellorinfo &&
    counsellorinfo?.filter((data: any) => {
      return data?.status == 1;
    });

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );

  const handleId = (e) => {
    setcId(e);
  };

  $("#tenam").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#elevenam").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#twelvepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#onepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#twopm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#threepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#fourpm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#fivepm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  $("#sixpm").click(function (k, v) {
    var id = $(this).attr("data-id");
    setCoachCounsellorId(id);
  });
  const handleAppointment = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var Date = dateFormat(date, "yyyy-mm-dd");
      var Data = {
        refer_url: window.location.origin,
        id: user?.id,
        mobile: user?.mobile,
        reference_type: 1,
        reference_user_id: cId,
        date: Date,
        time: CoachCounsellorId,
      };
      dispatch(coachAction.bookAppointment(Data));
    }
  };
  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);

  return (
    <div>
      <div className="filter-page__content">
        <div className="filter-item-wrapper counsellorlist">
          .
          {activeCounselloer?.length == 0 ? (
            <div className="nodataFound">
              <img src="/assets/img/noDataFound.jpeg" alt="" />
              {/* <h4>No blog found!</h4> */}
            </div>
          ) : (
            ""
          )}
          {activeCounselloer &&
            activeCounselloer
              .filter((item) => item.is_visible === 1)
              ?.map((data: any, i: any) => {
                var preferredCountry: any = [];
                var preferred_country =
                  data?.preferred_country !== null
                    ? data?.preferred_country.split(",")
                    : "";
                return (
                  <>
                    {(() => {
                      if (i > 0 && i % 2 === 0) {
                        i = 1;
                        return (
                          <div className="ad-slider mb-4">
                            <AdSlider />
                          </div>
                        );
                      } else {
                        i++;
                      }
                    })()}
                    <div className="uni-item-list " key={i}>
                      <div className="item-media">
                        <div className="image-cover">
                          <div
                            onClick={() => {
                              history.push("/counsellor/" + data?.slug, {
                                coach_id: data?.id,
                              });
                            }}
                            className="counsellorAneCoachCover"
                            style={{
                              background: `url(${
                                baseurl + data?.profile_image
                              })`,
                              cursor: "pointer",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="item-body">
                        <div className="item-title">
                          <div className="pricedegree">
                            <p>
                              {data?.rate_per_hr
                                ? `INR ${data?.rate_per_hr}/60 min`
                                : "Free"}{" "}
                              {data?.admissify_certified == 1 ? (
                                <span>
                                  {" "}
                                  <img
                                    src="assets/img/certificate.svg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                              {data?.premium == 1 ? (
                                <span>
                                  {" "}
                                  <img src="assets/img/degree.svg" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <h5
                            onClick={() => {
                              history.push("/counsellor/" + data?.slug, {
                                coach_id: data?.id,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {data?.gender == 1 ? "Mr." : "Ms."}{" "}
                            {data?.name && data?.name}
                            {data?.admissify_verified == 1 ? (
                              <sup className="color-green">
                                <i
                                  className="fa fa-check-circle"
                                  aria-hidden="true"
                                ></i>
                              </sup>
                            ) : (
                              ""
                            )}
                            <span className="online">
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                                style={{
                                  color: data?.is_online ? "green" : "red",
                                }}
                              ></i>{" "}
                              {data?.is_online ? "online" : "offline"}
                            </span>
                          </h5>
                          <p>
                            <small>counsellor</small>
                          </p>
                          <p className="yellow">
                            <small>
                              {data?.passout_university &&
                                data?.passout_university}
                            </small>
                          </p>
                        </div>
                        <div className="item-footer mt-2">
                          <div className="item-hotel-star">
                            <div>
                              <StarRatings
                                rating={
                                  parseFloat(data?.rating)
                                    ? parseFloat(data?.rating)
                                    : 0
                                }
                                starRatedColor="#fcbd30"
                                starDimension="13px"
                                starSpacing="0px"
                              />{" "}
                              <span style={{ fontWeight: "normal" }}>
                                |{" "}
                                {data?.counselled && data?.counselled > 500
                                  ? "500+"
                                  : data?.counselled}{" "}
                                student google reviews
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item-abouttxt coachAndCounselloer counsellor01 mt-0">
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: "40px" }}
                                        >
                                          <img
                                            src="assets/img/icons/exp.png"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>experience</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {data?.experience == null
                                              ? ""
                                              : data?.experience + " yr"}{" "}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: "40px" }}
                                        >
                                          <img
                                            src="assets/img/icons/location.png"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>country preference </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {countrys.map((v, k) => {
                                            if (
                                              preferred_country &&
                                              preferred_country.includes(
                                                v?.id.toString()
                                              )
                                            ) {
                                              preferredCountry.push(
                                                v?.country_name
                                              );
                                            }
                                          })}
                                          <>
                                            <span className="yellow">
                                              {preferredCountry.length != 0
                                                ? preferredCountry.join(", ")
                                                : "N/A"}
                                            </span>
                                          </>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: "40px" }}
                                        >
                                          <img
                                            src="assets/img/icons/edu.png"
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>higheset education</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {data?.qualification &&
                                              data?.qualification}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="item-btn-more">
                        <div className="btnbox">
                          {/* <a href="javascript:void(0)" className="awe-btnbook" style={{ cursor: "not-allowed" }}>
                            Vblog
                          </a> */}

                          <a
                            onClick={() => {
                              history.push("/counsellor/" + data?.slug, {
                                counselloer_id: data?.id,
                              });
                            }}
                            className="awe-btnbook download"
                          >
                            view profile
                          </a>
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#date_foo"
                            className="awe-btnbook download"
                            onClick={(e) => handleId(data?.user_id)}
                          >
                            book appointment
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
      </div>
      <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <p className="calen-head">select date & time</p>
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="calendar-container">
                    <Calendar
                      onChange={setDate}
                      value={date}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="timepicker" ng-if="picktime == 'true'">
                    <div ng-className="{'am': timeframe == 'am', 'pm': timeframe == 'pm' }">
                      <div
                        className="timepicker-container-outer"
                        selectedtime="time"
                        timetravel
                      >
                        <div className="timepicker-container-inner">
                          <div
                            className="timeline-container"
                            ng-mousedown="timeSelectStart($event)"
                            sm-touchstart="timeSelectStart($event)"
                          >
                            <div className="timeline"></div>
                          </div>
                          {timings.map((value, index) => {
                            var currtime = index > 12 ? value + 12 : value;
                            var timeString =
                              (value == 0 ? 12 : value) +
                              ":00" +
                              (currtime >= 12 && value != 0 ? " pm" : " am");
                            if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate == date.getDate() &&
                              CurrentTime < currtime
                            ) {
                              noAvailable = true;
                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate < date.getDate()
                            ) {
                              noAvailable = true;

                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth < date.getMonth()
                            ) {
                              noAvailable = true;

                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                            }
                          })}
                          {noAvailable ? (
                            ""
                          ) : (
                            <div className="slot text-center">
                              <strong> No slot available </strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption className="details-box p-3">
                <div className="bttns text-right">
                  <a className="applybtn bggreadent">
                    <span
                      className="btn btn-success font-weight-bold resolve-btn"
                      onClick={handleAppointment}
                    >
                      submit
                    </span>
                  </a>
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CounsellorList;
