import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";

function ApplyThankYou() {
  const [title, settitle] = useState<any>("terms-conditions");
  const history = useHistory();
  const hendlback = (e: any) => {
    history.push("/universities");
  }

  const getSetting: any = useSelector((state: any) =>
    state.setting.getSetting ? state.setting.getSetting : []
  );
  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>

        <section className="tag-term">
        <Breadcrumb page={"Thank-you"} data={{slug: "", path: ""}}/>
          <div
            style={{ paddingBottom: "100px" }}
            className="form-container animated steps9 active flipInX"
          >

            <h2>
              <span>Thank You</span> {" "}
              <video src="../../assets/video/thumb.mp4" loop="" autoplay="" style={{ width: '40px' }}></video>
            </h2>
            <h5>thank you for applying, our executive will reach you soon.</h5>
            <br />
            <strong className="__mr-10">Contact our experts on</strong>

            <p><span><span className="fa fa-envelope icon_color"></span> </span> <a className="__hyperlinks comp_url" href={"mailto:" + getSetting[0]?.email}>{getSetting[0]?.email}</a></p>
            <p><span><span className="fa fa-phone-square icon_color"></span> </span>  <a className="__hyperlinks comp_url" href={"tel:" + getSetting[0]?.phone}> {getSetting[0]?.phone}</a></p>
            <div className="form-group text-center mar-b-0 mt-4">
              <button /* type="button" */ onClick={hendlback} className="view-more btn2">
                okay
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default ApplyThankYou
