import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_ALL_COUNTRY,
  GET_COUNTRY_BY_SLUG
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const getAllCountryAction = (data:any) => {
  return { type: GET_ALL_COUNTRY, payload: data };
};
const getCountryBySlugAction = (data:any) => {
  return { type: GET_COUNTRY_BY_SLUG, payload: data };
};

export const getAllCountry = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getAllCountry}?status=`+1);
          dispatch(getAllCountryAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCountryBySlug = (data:any) => {
  return async (dispatch:any) => {
    dispatch(getCountryBySlugAction({}));
    try {
      // dispatch(loading(true));
      const response:any = await api.get(`${URL.getCountryBySlug}/${data.slug}`);
          dispatch(getCountryBySlugAction(response?.data?.data));
          // dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      if(error?.response?.status == 404) {
        window.location.href = "/page-not-found";
      }
    }
  };
};