import { 
  LOADING,
  GET_ALL_COUNSELLOR,
  GET_COUNSELLOR_BY_ID,
  GET_COACH_AND_COUNSELLOR,
  GET_COUNSELLOR_INFO,
  GET_COUNSELLOR_BY_SLUG,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  counsellorList: any;
  counsellordetail: any;
  coachAndCounsellor: any;
  counsellorinfo: any;
  counsellordetailBySlug: any;
}
const initialState : stateType = {
    loading:false,
    counsellorList: [],
    counsellordetail: [],
    coachAndCounsellor: [],
    counsellorinfo: [],
    counsellordetailBySlug: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_ALL_COUNSELLOR:
      return { ...state, counsellorList: action.payload};
       case GET_COUNSELLOR_BY_ID:
      return { ...state, counsellordetail: action.payload};
      case GET_COACH_AND_COUNSELLOR:
      return { ...state, coachAndCounsellor: action.payload};
      case GET_COUNSELLOR_INFO:
      return { ...state, counsellorinfo: action.payload}; 
      case GET_COUNSELLOR_BY_SLUG:
      return { ...state, counsellordetailBySlug: action.payload};
    default:
      return state;
  }
};