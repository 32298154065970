import {
  LOADING,
  GET_ARTICALE_LIST,
  GET_ARTICALE_BY_ID,
  GET_REVIEW_LIST
} from "../common/constant";
type stateType = {
  loading: boolean;
  articaleList: any;
  articaleById: any;
  reviewList:any;
};
const initialState: stateType = {
  loading: false,
  articaleList: [],
  reviewList: [],
  articaleById: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_ARTICALE_LIST:
      return { ...state, articaleList: action.payload };
      case GET_REVIEW_LIST:
      return { ...state, reviewList: action.payload };
    case GET_ARTICALE_BY_ID:
      return { ...state, articaleById: action.payload };
    default:
      return state;
  }
};
