import React from 'react'
import FundingForm from '../../Component/FundingForm'

const FundingMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">funding<span><img src="/assets/img/ser02.png" alt=""/></span></h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>You've determined to look overseas and find out the globe, that's great! Let's start now with an
                        inventory of estimations so that you recognize wherein your cash got to be spent. Our
                        recommendation will assist you with withinside the layout and control of your budgets without
                        annoying you in the least. And indeed, the straightforward fees, which include accommodations,
                        food, fitness, communication, and journey, had been paid through us! Here's the value to think
                        about for Study Abroad The expected fees are calculated through every faculty to line up the
                        extent of the economic necessity of a scholar. Total tuition, compulsory prices, rooms, boards
                        (wherein vital) and books, and different dwelling fees (and once during a while journey
                        expenses) are calculated for school kids on the given faculty. Separate determinations are often
                        made at the discretion of the group for school kids who occupy domestic or independently.
                        Funding sources Some colleges, public and private, offer economic funding to school students.
                        The bulk of the institutional help presented to foreign places college students in particular
                        for undergraduate education withinside the form of help and bursaries. As assist to foreign
                        places undergraduates is usually uncommon for few of the universities, such bursaries are
                        typically extraordinarily aggressive. Please take care to check together alongside your college
                        to utilize any additions or exemptions that they will give, the insight that every personal and
                        public university can waive software fees. Special skills, abilities, or abilities are wont to
                        supply merit-primarily based totally scholarships. You’ll also get TOEFL-primarily based
                        scholarships, educational data, innovative abilities, musical or athletic skills.
                        Merit-primarily based scholarships are regularly pretty aggressive than want-primarily based
                        scholarships. You've got to show wonderful competence withinside the place vital to be taken
                        under consideration. Based on economic necessities, scholarship funding is provided. Students
                        with a described stage who can display want could also be eligible for this type of assist.
                        University educational departments might offer presents to help foreign places college students
                        with specific necessities and/or abilities. There are distinctive kinds of supply funds. Here
                        they're</p>
                    <ul className="condition-list-sec">
                        <li>Scholarships and presents</li>
                        <li>Private loans</li>
                        <li>Personal sources</li>
                    </ul>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/scholarship_1.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">study abroad scholarships</h4>
                                    <div className="postcard-para-txt">Special skills, abilities, or abilities are
                                        wont to supply merit-primarily based totally scholarships. Merit-primarily based
                                        scholarships: Distinguished college students line up with economic assistance
                                        thru Merit-primarily based total scholarships to help them attain their academic
                                        pursuits of an entire career. Need-primarily based scholarships: Students with
                                        an economic want line up with a bursary. These prizes are based totally on
                                        family profits and choose eligibility. Many bourses are best available to
                                        residents and everlasting citizens for undergraduate studies. However, there are
                                        unfastened studies and studies databases for overseas undergraduate college
                                        students additionally to non-public (loan), corporate, non-profit, and
                                        authorities borrowing funds. Be cautious of cheating studies gives: don't ship
                                        any borrowing organization cash, financial organization account details, or
                                        credit score card numbers.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">why to pick us?</h4>
                                    <div className="postcard-para-txt">Our economic or scholarship steering
                                        consultancy place gives you vital sources of overseas economic help. However,
                                        many universities comprehend this and offer reading bonds to admire your
                                        determination to look at. We apprehend that global schooling overseas is often
                                        costly. These fundings are intended to reduce the load for aspiring college
                                        students. We'll assist you to perceive and observe for bursaries conveniently.
                                        You'd possibly keep your direction fees in many dollars.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/concul.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">conclusion</h4>
                                    <div className="postcard-para-txt">Adjusting to a brand new country, culture,
                                        and educational machine may be interesting and difficult. Transitions may be
                                        specifically difficult whilst you are a long way far from folks that normally
                                        assist you. Counselors on the Admissify recognize this. We are right here to
                                        assist college students to attain their academic dreams without annoying about
                                        funding. We recognize that issues on your non-public existence can affect your
                                        capacity to learn. We, at Admissify, recognize how difficult it could be to
                                        pursue your research abroad. We offer more than a few Student Essentials
                                        offerings to get rid of complexity and strain making your global have a look at
                                        revel in journey, a hassle-loose adventure. Need to assist? Explore our
                                        offerings and depart us your information withinside the inquiry and an Admissify
                                        counselor might be assigned to manual thru each step of your manner to stay and
                                        have a look at abroad.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <FundingForm/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FundingMobile