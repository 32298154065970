import { 
  LOADING,
  GET_STREAM_LIST,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  streamList: any;
 }
const initialState : stateType = {
    loading:false,
    streamList: []
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_STREAM_LIST:
      return { ...state, streamList: action.payload};
    default:
      return state;
  }
};