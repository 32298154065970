import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../redux/common/api";
import { URL } from "../../redux/common/url";

const PayRedirect = () => {
  const { id } = useParams<any>();
  useEffect(() => {
    getPay(id);
    return () => {};
  }, []);
  const getPay = (id: any) => {
    Api.get(`${URL.PayRedirect}/${id}`)
      .then((res: any) => {
        window.location.href = res.data?.data?.payment_request?.longurl;
      })
      .catch((error: any) => {
        window.location.href = "/";
        toast.error(error?.response?.data?.message || "error occured !");
      });
  };
  return (
    <div className="t-banner">
      <section className="container">
        <div className="headingmains text-center ofc_add pt-5 pb-5">
          <h1>Please don't go back or refresh window</h1>
          <br />
          <h1>Redirecting to payment page</h1>
          <div className="loadingPay">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                height: "100%",
              }}
            >
              <Loader
                type="ThreeDots"
                color="#047475"
                height={120}
                width={120}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PayRedirect;
