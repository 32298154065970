import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";

function PaymentVerification(props: any) {
  const history = useHistory();

  const baseurl = URL.API_BASE_URL;
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [status, setStatus] = useState<any>("");

  const dispatch = useDispatch();
  const validatePaymentStatus = async (order_id: any, payment_id: any, payment_status: any, payment_request_id: any) => {
    try {
      var response: any = await axios.get(`${URL.API_BASE_URL + `` + URL.paymentReturn + `?order_id=` + order_id + `&payment_id=` + payment_id + `&payment_status=` + payment_status + `&payment_request_id=` + payment_request_id}`);
      console.log(response?.data);
      if (response?.data?.success) {
        window.location.href = '/thank-you-page';
      } else {
        setStatus('error');
      }
      return false;
    }
    catch (error: any) {
      toast.error(error?.response?.data?.message);
      setStatus('error');
    }
  }
  useEffect(() => {
    var url_string = window.location.href;
    var url = new window.URL(url_string);
    var order_id = url.searchParams.get("order_id");
    var payment_id = url.searchParams.get("payment_id");
    var payment_status = url.searchParams.get("payment_status");
    var payment_request_id = url.searchParams.get("payment_request_id");
    if (payment_request_id && order_id) {
      validatePaymentStatus(order_id, payment_id, payment_status, payment_request_id);
    } else {
      setStatus('error');
    }
  }, [])
  console.log(status);
  return (
    <div>
      <section className="tag-term">
        <div className="headingmains text-center" style={{ paddingBottom: "30px", paddingTop: "20px" }}>
          <div className="email_content text-center">
            <center>
              <div
                className="why-choose-ad pt-3"
              >
                {(() => {
                  if (status == 'error') {
                    return <figcaption className="content-box text-center">
                      <h3 className="error-failed text-center">
                        <i class="fa fa-warning text-danger" aria-hidden="true"></i> <br></br>payment transaction failed.
                      </h3>
                      <div className="text-center">
                        <a href="/profile?action=appointments" className="view-more btn2">my appointments</a>
                      </div>
                    </figcaption>
                  } else {
                    return <figcaption className="content-box text-center">
                      <h3 className="pb-2 text-center">
                        please wait while we verifying your payment...
                      </h3>

                      <div className="form-group text-center mar-b-0 mt-4">
                      </div>
                    </figcaption>
                  }
                })()}
              </div>
            </center>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentVerification;
