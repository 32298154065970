import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Seo from "../Seo/Seo";
import axios from "axios";
import { studentAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url"
import { toast } from "react-toastify";
import Email from "../../assets/img/email-verify.png"


function VerifyEmail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const baseurl = URL.API_BASE_URL;
  const [title, settitle] = useState<any>("Email-VerifiCation");

  const handleVerify = (e: any) => {
    //Getting Token From URL
    var url = window.location.pathname;
    var token = url.split("/");
    var Token = token[2];
    dispatch(studentAction.verificationOfEmail(Token));
    return () => { };
  }
  // useEffect(() => {
  //   //Getting Token From URL
  //   var url = window.location.pathname;
  //   var token = url.split("/");
  //   var Token = token[2];
  //   dispatch(studentAction.verificationOfEmail(Token));
  //   return () => { };
  // }, []);

  return (
    <div>
      <Seo settitle={settitle} title={title} />
      <section className="tag-term">
        <div className="headingmains text-center" style={{ paddingBottom: "30px", paddingTop: "20px" }}>
          <div className="email_content text-center">
            <center>
              <div
                className="why-choose-ad"
              >
                <figure className="feat-text1">
                  <img className="scale" src={Email} />
                </figure>
                <figcaption className="content-box text-center">
                  <h5 className="pb-2 text-center">
                    Email Verification
                  </h5>
                  <p className="email_text">
                    Simply click the button below to verify your email address.
                  </p>
                  <div className="form-group text-center mar-b-0 mt-4">
                    <button onClick={handleVerify} className="view-more btn2">
                      verify email address
                    </button>
                  </div>
                </figcaption>
              </div>
            </center>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyEmail;
