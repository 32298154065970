import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,
  GET_TESTIMONIAL_lIST,
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};
const gettestimoniallistAction = (data:any) => {
  return { type: GET_TESTIMONIAL_lIST, payload: data };
};

export const gettestimoniallist = (data:any) => {
  return async (dispatch:any) => {
    try {
      dispatch(loading(true));
      const response:any = await api.get(`${URL.gettestimoniallist}`, {params: data});
          dispatch(gettestimoniallistAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};