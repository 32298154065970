import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { studentAction } from "../redux/common/action";
import { userRegisterValidate, otpValidate } from "../validation/Validation";
import Seo from "../Component/Seo/Seo";
import { getToken } from "../Utils/Auth/Token";
import jwt_decode from "jwt-decode";

interface error {
  [key: string]: any;
}
function SignUp() {
  const dispatch = useDispatch();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const history = useHistory();
  const [values, setValues] = useState<any>("");
  const [otp, setOtp] = useState<any>();
  const [errors, setErrors] = useState<error>({});
  const [formValidate, setSubmitting] = useState<boolean>(false);
  const [otpErrors, setOtpErrors] = useState<error>({});
  const [otpvalid, setOtpvalid] = useState<boolean>(false);

  const [minutes, setMinutes] = useState<any>(0);
  const [seconds, setSeconds] = useState<any>(0);

  const student: any = useSelector((state: any) =>
    state.student.studentSignup ? state.student.studentSignup : []
  );

  $(document).on("input", ".number", function () {
    this.value = this.value.replace(/\D/g, "");
  });

  /* const handleBluremail = (e: any) => {
    const email = { email: e.target.value };

    dispatch(studentAction.studentCheckEmailExist(email));
  }; */

  /* const emailCheck: any = useSelector((state: any) =>
    state.student?.studentCheckEmailExist
      ? state.student?.studentCheckEmailExist
      : {}
  ); */

  /* const handleBlurmobile = (e: any) => {
    const mobile = { mobile: e.target.value };
    if (e.target.value.length == 10) {
      dispatch(studentAction.studentCheckMobileExist(mobile));
    }
  }; */

  /* const mobileCheck: any = useSelector((state: any) =>
    state.student?.studentCheckMobileExist
      ? state.student?.studentCheckMobileExist
      : {}
  ); */

  const verfitStudent: any[] = useSelector((state: any) =>
    state.student?.studentVerfiyOtpSignup
      ? state.student?.studentVerfiyOtpSignup
      : {}
  );

  useEffect(() => {
    if (isAuthenticated == false) {
      history.push("/register", { logback: 1 });
    } else {
      history.goBack();
    }
  }, []);

  const verfiyotp = { otp: otp?.otp, mobile: values.mobile };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    var Value = e.target.checked;
    var isChecked = 0;
    if (Value == true) {
      isChecked = 1;
    }
    setValues({ ...values, [name]: value, whatsapp_notification: isChecked });
  };

  const handleChangeotp = (e: any) => {
    const { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    const validationErrors = userRegisterValidate(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setSubmitting(noErrors);
    setErrors(validationErrors);
  }, [values]);

  useEffect(() => {
    const validationErrors = otpValidate(otp);
    let noErrors = Object.keys(validationErrors).length == 0;
    setOtpvalid(noErrors);
    setOtpErrors(validationErrors);
  }, [otp]);

  const handleRegister = (event: any) => {
    event.preventDefault();
    setSeconds(30);
    dispatch(
      studentAction.studentSignup(
        { ...values, device: "desktop",source:"website"},
        (data: boolean) => {
          if (data) {
            $("#loginwithotp").modal("show");
          }
        }
      )
    );
  };

  const handleresendotp = (event: any) => {
    event.preventDefault();
    setSeconds(30);
    dispatch(
      studentAction.studentSignup(
        { ...values, device: "desktop", is_resent: true,source:"website"},
        (data: boolean) => {}
      )
    );
  };
  const handleVerifyOtp = (event: any) => {
    var source = "register";
    event.preventDefault();
    dispatch(studentAction.studentVerfiyOtp(verfiyotp, history, source));
  };

  return (
    <div>
      <Seo slug={window.location.pathname} />
      <section className="categories section bg-grey login_sec">
        <div className="container">
          <div className="loginwrap">
            <div className="row g-0">
              <div className="d-none d-md-flex col-md-4 col-lg-5 bg-image">
                <div>
                  <h3 className="text-white">welcome</h3>
                  {/* <h6 className="pb-3 text-white text-center">why sign up?</h6> */}
                  <p className="text-center text-white">
                    Are you interested to study abroad? Are you confused with
                    the selection of the college? Don't worry Admissify will
                    surely help you to get through the best possible education
                    platform apt for your niche. <br /> <br />{" "}
                    <span style={{ fontSize: "12px" }}>
                      Sign up/Register Now!
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-8 col-lg-7 rightbx">
                <div className="login d-flex align-items-center py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10 mx-auto">
                        <h5 className="text-center">
                          create your admissify account
                        </h5>
                      </div>
                      <div className="col-md-9 col-lg-8 mx-auto">
                        <h6 className="text-center">
                          use mobile number for registration{" "}
                        </h6>
                        <div className="fields-wrp">
                          <form /* action="#" */>
                            <div className="field">
                              <input
                                type="text"
                                name="name"
                                value={values?.name}
                                onChange={(e: any) => handleChange(e)}
                                required
                              />
                              <label>name</label>
                              <span className="fa fa-user p-2"></span>
                            </div>
                            {errors?.name && values?.name !== undefined && (
                              <span className="error">{errors?.name}</span>
                            )}
                            <div className="field">
                              <input
                                type="text"
                                name="mobile"
                                value={values?.mobile}
                                maxLength={10}
                                /* onKeyUp={handleBlurmobile} */
                                // onBlur={handleBlurmobile}
                                onChange={(e: any) => handleChange(e)}
                                required
                                className="number"
                              />
                              <label>mobile number</label>
                              <span className="fa fa-phone p-2"></span>
                            </div>
                            {errors.mobile && values.mobile !== undefined ? (
                              <span className="error">{errors.mobile}</span>
                            ) : (
                              ""
                            )}

                            <div className="field">
                              <input
                                type="text"
                                name="email"
                                value={values?.email}
                                /* onKeyUp={handleBluremail} */
                                // onBlur={handleBluremail}
                                onChange={(e: any) => handleChange(e)}
                                required
                              />
                              <label>email</label>
                              <span className="fa fa-envelope p-2"></span>
                            </div>
                            {errors.email && values.email !== undefined ? (
                              <span className="error">{errors.email}</span>
                            ) : (
                              ""
                            )}

                            <div className="pt-3">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="remember-me"
                                  value="1"
                                  onChange={(e: any) => handleChange(e)}
                                />
                                <label>receive updates on whatsapp</label>
                              </div>
                            </div>
                            <div className="signup-link">
                              already a member?
                              <Link to={"/login"}>login here</Link>
                              {/* <a href="#">login here</a> */}
                            </div>
                            <div className="fieldbtn">
                              <input
                                type="submit"
                                /* data-toggle="modal"
                                data-target="#loginwithotp" */
                                onClick={(e: any) => handleRegister(e)}
                                className={
                                  !formValidate
                                    ? "disabledClass bggreadent"
                                    : "bggreadent"
                                }
                                value="get otp"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="loginwithotp"
        className="modal fade loginwithotp"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content modal-login  p-3">
            <div className="modal-header border-0">
              <div className="text-center imgotp">
                <img src="assets/img/otpa.png" className="scale" alt="" />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-11 col-lg-10 mx-auto">
                <div className="fields-wrp">
                  <h5>
                    a text message with a verification code was just sent to
                    xxxxxxxx{values?.mobile?.slice(-2)}
                  </h5>
                  <form action="#">
                    <div
                      className="pass-link text-right mt-2"
                      style={{ marginBottom: "-10px" }}
                    >
                      <a data-dismiss="modal" aria-label="Close">
                        change mobile number
                      </a>
                    </div>
                    <div className="field">
                      <input
                        type="text"
                        name="otp"
                        value={otp?.otp}
                        onChange={(e: any) => handleChangeotp(e)}
                        required
                      />
                      <label>enter otp</label>
                      <span className="fa fa-certificate p-2"></span>
                    </div>
                    {otpErrors.otp && otp?.otp !== undefined && (
                      <span className="error">{otpErrors.otp}</span>
                    )}
                    <div className="signup-link">
                      <a href="#">didn't receive the code?</a>
                      {minutes === 0 && seconds === 0
                        ? null
                        : minutes < 10
                        ? `0${minutes}`
                        : minutes}
                      {minutes === 0 && seconds === 0
                        ? null
                        : seconds < 10
                        ? ":" + `0${seconds}`
                        : ":" + seconds}
                      {minutes === 0 && seconds === 0 ? (
                        <a
                          className={"text_hyper"}
                          onClick={(e: any) => handleresendotp(e)}
                        >
                          resend otp
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="fieldbtn">
                      <input
                        type="submit"
                        className={
                          !otpvalid ? "disabledClass bggreadent" : "bggreadent"
                        }
                        value="verify"
                        onClick={(e: any) => handleVerifyOtp(e)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
