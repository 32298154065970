import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { courseAction } from "../../redux/common/action";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";

function StudyCourses() {
  const dispatch = useDispatch();
  const history = useHistory();

  const baseurl = URL.API_BASE_URL
  const course: any = useSelector((state: any) =>
    state.course.allCourse ? state.course.allCourse : []
  );



  const activecourse = course && course?.filter((data: any) => {
    return data?.status == 1;
  })



  // useEffect(() => {
  //   dispatch(courseAction.getAllCourses({}));
  //   return () => {};
  // }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="categories section greybg studybg">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline">Study Courses</h2>
            <p className="text-center pl-2 pr-2">
              learn about & choose from the best globally recognised courses at the world’s top universities
            </p>
          </div>
          <div className="all-center sub-section pb-0">
            <div className="col-md-12 all-center pb-5">
              <div className="studyslider">
                <Slider {...settings}>
                  {activecourse && activecourse?.map((data: any, i: any) => {




                    return (
                      <div key={i}>
                        <div className="why-item">
                          <figure className="feat-text1">
                            {data?.course_small_img !== null ?
                              <img
                                onClick={() => {
                                  let level=data?.level == 1 ? "ug": data?.level == 2 ? "pg" : "";
                                  history.push("/course/" + data?.code + '/' + level, {
                                    course_id: data?.code,
                                    level: data?.level
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                                className="scale homeStudyCourses"
                                src={baseurl + data?.course_small_img} onError={(e: any) => { e.target.onerror = null; e.target.src = "/assets/img/noImage.jpeg" }}
                              /> :
                              <img
                                onClick={() => {
                                  let level=data?.level == 1 ? "ug": data?.level == 2 ? "pg" : "";
                                  history.push("/course/" + data?.code + '/' + level, {
                                    course_id: data?.code,
                                    level: data?.level
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                                className="scale homeStudyCourses"
                                src={"/assets/img/noImage.jpeg"}
                              />
                            }

                          </figure>

                          <figcaption className="content-box studyDe">
                            <h6 className="pb-2">
                              {data?.title ? data?.title : ""}
                            </h6>
                            <p className="eligibility_content">{data?.specialization}</p>
                            <div className="coach-view">
                              <a
                                onClick={() => {
                                  let level=data?.level == 1 ? "ug": data?.level == 2 ? "pg" : "";
                                  history.push("/course/" + data?.code + '/' + level, {
                                    course_id: data?.code,
                                    level: data?.level
                                  });
                                }}
                                className="mt_btn_yellow">
                                view course
                              </a>
                            </div>
                          </figcaption>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div>
                    <div className="why-item">
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/std02.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h6 className="pb-2">Law</h6>
                        <p>
                          Our counsellors are trained from our London Head
                          Office and comprise Alumni from the World’s most{" "}
                        </p>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view course
                          </a>
                        </div>
                      </figcaption>
                    </div>
                  </div>
                  <div>
                    <div className="why-item">
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/std03.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h6 className="pb-2">IT</h6>
                        <p>
                          admissify is with you from AI based searching of over
                          3000 reputed international universities to counsellor
                        </p>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view course
                          </a>
                        </div>
                      </figcaption>
                    </div>
                  </div>
                  <div>
                    <div className="why-item">
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/std04.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h6 className="pb-2">Engineering</h6>
                        <p>
                          admissify is with you from AI based searching of over
                          3000 reputed international universities to counsellor
                        </p>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view course
                          </a>
                        </div>
                      </figcaption>
                    </div>
                  </div>
                  <div>
                    <div className="why-item">
                      <figure className="feat-text1">
                        <img className="scale" src="assets/img/std02.png" />
                      </figure>
                      <figcaption className="content-box">
                        <h6 className="pb-2">Law</h6>
                        <p>
                          Our counsellors are trained from our London Head
                          Office and comprise Alumni from the World’s most{" "}
                        </p>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view course
                          </a>
                        </div>
                      </figcaption>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StudyCourses;
