import React from 'react'

const PackagesMobile = () => {
  return (
    <>
      <div className="mainsheading text-center">
        <h2 className="headingwithline">packages<span><svg xmlns="http://www.w3.org/2000/svg" width="33.333" height="33.667"
                    viewBox="0 0 33.333 33.667">
                    <path id="Icon_material-local-offer" data-name="Icon material-local-offer"
                        d="M35.35,19.126l-15-15.15A3.3,3.3,0,0,0,18,3H6.333A3.36,3.36,0,0,0,3,6.367V18.15a3.376,3.376,0,0,0,.983,2.39l15,15.15a3.3,3.3,0,0,0,2.35.976,3.243,3.243,0,0,0,2.35-.993L35.35,23.89a3.308,3.308,0,0,0,.983-2.374,3.417,3.417,0,0,0-.983-2.39ZM8.833,11.417a2.525,2.525,0,1,1,2.5-2.525A2.509,2.509,0,0,1,8.833,11.417Z"
                        transform="translate(-3 -3)" fill="#fbb415"></path>
                </svg></span></h2>
                <div className="main-pak-s">
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly
            used to demonstrate thbe used as a placeholder before final copy is available. </p>
        </div>
    </div>
    <div className="row packagelist">
        <div className="col-md-12 mb-3 item-package">
            <div className="card h-100 text-center">
                <div className="card-header">
                    <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                    <ul>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                    </ul>
                </div>
                <div className="card-footer bg-white border-pack pt-0">
                    <div className="pack-btn pb-2"><a href="#" onClick={(e:any)=> e.preventDefault()} className="red-mor bggreadent-n">request price</a></div>
                </div>
            </div>
        </div>
        <div className="col-md-12 mb-3 mt-5 item-package">
            <div className="card h-100 text-center">
                <div className="pricebox">
                    <p className="color-green"><small>INR</small><br/>5000 <br/><small className="text-danger"><s>7000</s></small>
                    </p>
                </div>
                <div className="card-header">
                    <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                    <ul>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                    </ul>
                </div>
                <div className="card-footer bg-white border-pack pt-0">
                    <div className="pack-btn pb-2"><a href="#" onClick={(e:any)=> e.preventDefault()} className="red-mor bggreadent-n">pay now</a></div>
                </div>
            </div>
        </div>
        <div className="col-md-12 mb-3 item-package">
            <div className="card h-100 text-center">
                <div className="card-header">
                    <h5>scholarship assured services</h5>
                </div>
                <div className="card-body">
                    <ul>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                        <li>travel insurancefacilitation services</li>
                    </ul>
                </div>
                <div className="card-footer bg-white border-pack pt-0">
                    <div className="pack-btn pb-2"><a href="#" onClick={(e:any)=> e.preventDefault()} className="red-mor bggreadent-n">request now</a></div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default PackagesMobile