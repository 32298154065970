import React, { useState, useEffect } from "react";
import {
  officeAction,
} from "../../redux/common/action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
function ContactUs() {
  const [Office, setOffice] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistory();

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setOffice({ ...Office, [name]: value });
  };


  const submitQuery = (e: any) => {
    var inputs = $('#' + e).find('input');
    $('.error-class').remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (inputs.length > 0) {
      $.each(inputs, function (k, v) {
        if (!v.value) {
          $(this).after('<div class="error-class">This field is required.</div>');
          isError = true;
        }
      });
    }
    if (inputs[1].value == "") {
      $("#mob").after('<div class="error-class">This field is required.</div>');
      isError = true;
    }
    if (inputs[1].value) {
      if (inputs[1].value.length != 10) {
        $("#mob").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (inputs[1].value) {
      if (phoneno.test(inputs[1].value) === false) {
        $("#mob").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (inputs[2].value) {
      if (regex.test(inputs[2].value) === false) {
        $("#email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (isError) {
      return false;
    }
    dispatch(officeAction.getInTouch(Office, history));
  };
  return (
    <>
      <div>
        <section className="categories">
          <div className="container">
            <div className="">
              <div className="row g-0 justify-content-center">
                <div className="col-md-12 col-lg-12 rightbx bg-white">
                  <div className="login d-flex align-items-center">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-11 col-lg-10">
                          <h5 className="get-touch">get in touch</h5>
                        </div>
                        <form id="queryFrom">
                          <div className="field">
                            <input
                              autoComplete="new-off" type="text" name="Name" required value={Office?.Name} id="name" onChange={(e) => { hendlereviews(e); }} />
                            <label>name</label>
                            <span className="fa fa-user p-2"></span>
                          </div>
                          <div className="field">
                            <input
                              autoComplete="new-off" type="text" required name="Mobile" maxLength="10" id="mob" value={Office?.Mobile} onChange={(e) => { hendlereviews(e); }} />
                            <label>mobile</label>
                            <span className="fa fa-phone p-2" ></span>
                          </div>
                          <div className="field">
                            <input
                              autoComplete="new-off" type="text" required name="Email" id="email" value={Office?.Email} onChange={(e) => { hendlereviews(e); }} />
                            <label>email</label>
                            <span className="fa fa-envelope p-2" ></span>
                          </div>
                          <div className="field">
                            <textarea
                              autoComplete="new-off" className="q-msg" rows="4" cols="50" placeholder="message" name="Message" value={Office?.Message} onChange={(e) => { hendlereviews(e); }}></textarea>
                          </div>

                          <div className="fieldbtn mt-100 mob-mb-25" style={{ marginBottom: "15px" }}>
                            <div onClick={(e) => { submitQuery('queryFrom') }} className="bggreadent btn btn-success br-5 landing_btn">submit</div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default ContactUs
