import React from 'react'
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

const SocialShareButton = (props: any) => {
    const { urlsforShare } = props;
    return (
        <div className="shareblog">
            <ul>
                <li className="faceIcon">
                    <FacebookShareButton
                        url={urlsforShare}
                    >
                        <FacebookIcon
                            size={26}
                            round={true}
                        />
                    </FacebookShareButton>
                </li>
                <li className="tiwwIcon">
                    <TwitterShareButton
                        url={urlsforShare}
                    >
                        <TwitterIcon
                            size={26}
                            round={true}
                        />
                    </TwitterShareButton>
                </li>
                <li className="linkIcon">
                    <LinkedinShareButton
                        url={urlsforShare}
                    >
                        <LinkedinIcon
                            size={26}
                            round={true}
                        />
                    </LinkedinShareButton>
                </li>
                <li className="whatsIcon">
                    <WhatsappShareButton
                        url={urlsforShare}
                    >
                        <WhatsappIcon
                            size={26}
                            round={true}
                        />
                    </WhatsappShareButton>
                </li>
            </ul>
        </div>
    )
}

export default SocialShareButton