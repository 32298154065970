import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const PteMobile = () => {
  return (
    <>
    <section>
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">PTE<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>Students and aspirants who want to study or immigrate to a major English-speaking country take
                        the PTE-Academic English test. In its complete form, PTE stands for Pearson Test of English. To
                        demonstrate their English language skills, students must take the PTE exam. It's the world's
                        first computer-based English language test, and it evaluates your English speaking, reading,
                        writing, and listening skills to ensure that you can study in an international setting. The PTE
                        test uses Artificial Intelligence (AI) technology to provide a quick and easy assessment while
                        eliminating human bias. The PTE results are accessible within 48 hours of taking the test. On a
                        scale of ten to ninety points, the PTE score is calculated.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/pte02.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What is the importance of the PTE Exam?</h4>
                                    <div className="postcard-para-txt">PTE Academic is a language aptitude test used by educational institutions, professionals, and government agencies that demand a standard of academic English language proficiency for entrance purposes.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coun11.jpg" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Types of PTE Exam</h4>
                                    <div className="postcard-para-txt">The PTE exam is available in two formats: Academic and General.
                                    </div>
                                </div>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1"> PTE Academic</h4>
                                </div>
                                <p>This test style assesses the candidate's reading, writing, speaking, and listening abilities. </p>
                                <div className="postcard-sec-text sec-text-mar">
                                    <h4 className="pb-3 card-head1"> PTE General</h4>
                                </div>
                                <p> This test has two parts: a written paper and a spoken test, similar to an interview, in which both sections measure the test taker's reading, writing, speaking, and listening skills in a more realistic manner. </p>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/pte03.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Who accepts PTE Scores?</h4>
                                    <div className="postcard-para-txt"> Australia, the United States, the United Kingdom, Ireland, Singapore, Canada, and New Zealand are some nations that recognise the PTE exam and are popular with non-native applicants looking to study or immigrate. Apart from these, Russia, Spain, South Africa, Germany, France, Poland, Argentina, Italy, Japan, the Czech Republic, China, and Brazil are among the nations that accept PTE scores.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">How to register for PTE academic exam?</h4>
                                    <div className="postcard-para-txt">log into PTE Academic's official website and create your account<br/>You will receive an email with your login information 48 hours after your registration.<br/>Locate a PTE test centre near you.<br/>Choose a convenient time and date to take the PTE exam.<br/>After that, pay the PTE Exam Fee.<br/> Candidates can now register for the PTE exam over the phone, in addition to the online method. Students from India who desire to take the PTE Exam should contact the PTE Academic Center to register for the exam.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/pte04.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Where can PTE Exam take you?</h4>
                                    <div className="postcard-para-txt"> Thousands of academic programmes worldwide accept PTE Academic for study applications, and the Australian, UK, and New Zealand governments have recognised PTE Academic for all student visa and migration applications. You can use PTE Academic to prove your English ability as part of all Australian visa applications; it is also accepted by most Canadian universities, is accepted by 98 per cent of UK universities, and is approved by the UK Home Office for all visa and immigration applications; is accepted by thousands of academic programmes in the United States. Apart from these, several well-known colleges worldwide, including those in South Korea, Germany and China, have begun to accept PTE results. </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/ielts04.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of PTE academic</h4>
                                    <div className="postcard-para-txt"> More than 6000 organisations throughout the world accept and recognise the PTE score. Within five business days of the exam date, PTE sends out scores. As a result, PTE is a great way for students to avoid waiting for their test results before applying to international universities. Candidates' replies are checked automatically using the software. In the checking and marking of tests, no human intervention is authorised. There are no uncertain questions. Are you interested in studying abroad and wondering where to begin? Admissify is here to assist you! With offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India, Admissify is India's leading international education consultant. We seek to empower individuals to realise their dream of studying abroad by providing assistance and counselling targeted to their specific needs. Are you interested in studying abroad and wondering where to begin? Admissify is here to assist you! With offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India, Admissify is India's leading international education consultant. We seek to empower individuals to realise their dream of studying abroad by providing assistance and counselling targeted to their specific needs.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamForm service={"PTE-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PteMobile