import { LOADING, GET_SETTING, SEO } from "../common/constant";
type stateType = {
  loading: boolean,
  getSetting: any,
  seo:any,
};
const initialState: stateType = {
  loading: false,
  getSetting: [],
  seo: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_SETTING:
      return { ...state, getSetting: action.payload };
    case SEO:
      return { ...state, seo: action.payload };
    default:
      return state;
  }
};
