import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  studentAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

function Service() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modelData, setmodelData] = useState<any>("");

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const baseurl = URL.API_BASE_URL;

  const serviceList: any = useSelector((state: any) =>
    state.student.serviceList ? state.student.serviceList : []
  );

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  $(".track-appli").click(function (k, v) {
    var id = $(this).attr("data-id");
    console.log(id);
    $("#track-applicants-" + id).fadeIn();
  });

  useEffect(() => {
    var Data = { id: user?.id, mobile: user?.mobile };
    dispatch(studentAction.serviceList(Data, user?.id));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);

  return (
    <div className="col-md-12 col-lg-9">
      <div className="row">
        <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
          <div className="page-sidebar">
            {getOFferAndScholar &&
              getOFferAndScholar?.map((data: any, i: any) => {
                return (
                  i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a
                            onClick={(e) => {
                              data?.type == "Offers"
                                ? opneModel(data?.id)
                                : history.push(
                                    "/scholarships/" + data?.sch_slug
                                  );
                            }}
                            data-toggle="modal"
                            data-target="#offer-pop"
                          >
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a
                              className="margin_auto"
                              onClick={(e) => {
                                data?.type == "Offers"
                                  ? opneModel(data?.id)
                                  : history.push(
                                      "/scholarships/" + data?.sch_slug
                                    );
                              }}
                              data-toggle="modal"
                              data-target="#offer-pop"
                            >
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? "Get " + data?.max_off + "%"
                                      : "Get " + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                  ? `${data?.sch_title.substring(0, 40)}.`
                                  : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="contentbx shade1 bg-white application_wrap h-100">
            <section className="categories">
              <div className="col-md-12 col-lg-12">
                <div className="appli-t">
                  <h1>services</h1>
                  <img src="/assets/img/appoint--icon.svg" />
                </div>
                <div className="col-md-12 " id="servicesForm">
                  <div className="profile_field fields-wrp">
                    {serviceList.length == 0 ? (
                      <div className="col-lg-12 p-2">
                        <div className="appli-box shade1">
                          <div className="col-md-12 appli-box-t">
                            <p>
                              You have not applied for any services{" "}
                              <a
                                className="text_hyper"
                                onClick={() => {
                                  history.push("/services");
                                }}
                                id="DiscussionBtn"
                              >
                                apply now
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {serviceList.length > 0 &&
                          serviceList?.map((data: any, i: any) => {
                            return (
                              <div>
                                <div className="row mt-5">
                                  <div className="col-lg-4">
                                    <div>
                                      applied date:{" "}
                                      <strong>
                                        {moment(data?.createdAt).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className=" text-center service-heading">
                                      <strong>{data?.service_name}</strong>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 text-right">
                                    <div>
                                      status:{" "}
                                      <strong>
                                        {data?.status == 1 ? (
                                          <span style={{ color: "green" }}>
                                            applied
                                          </span>
                                        ) : data?.status == 2 ? (
                                          <span style={{ color: "red" }}>
                                            under review
                                          </span>
                                        ) : data?.status == 3 ? (
                                          <span style={{ color: "red" }}>
                                            in progress
                                          </span>
                                        ) : data?.status == 4 ? (
                                          <span style={{ color: "red" }}>
                                            completed
                                          </span>
                                        ) : (
                                          "N/A"
                                        )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <table
                                      className="table table-bordered"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <tr>
                                        <td width="50%">name</td>
                                        <td>
                                          : <strong>{data?.name}</strong>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>mobile</td>
                                        <td>
                                          : <strong>{data?.mobile}</strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>email</td>
                                        <td>
                                          : <strong>{data?.email}</strong>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                                <table
                                  className="table table-bordered"
                                  style={{ marginTop: "10px" }}
                                >
                                  <tbody>
                                    {data?.other_details?.map(
                                      (v: any, index: any) => {
                                        return (
                                          <tr key={index}>
                                            <td width="50%">{v?.label}</td>
                                            <td>
                                              {(() => {
                                                if (
                                                  v?.label === "upload cv" ||
                                                  v?.label === "college degree"
                                                ) {
                                                  return (
                                                    <a
                                                      className="view__"
                                                      href={baseurl + v?.value}
                                                      target="_blank"
                                                    >
                                                      View
                                                    </a>
                                                  );
                                                } else if (
                                                  v?.label === "move in" ||
                                                  v?.label === "move out"
                                                ) {
                                                  return moment(
                                                    v?.value
                                                  ).format("DD-MM-YYYY");
                                                } else {
                                                  return v?.value;
                                                }
                                              })()}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.coupon_code}</span>
                    )}{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
