import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import CoachList from "../CounsellorList/CoachList";
import { useHistory } from "react-router-dom";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
import { useSelector, useDispatch } from "react-redux";
import { officeAction } from "../../redux/common/action";
import { toast } from "react-toastify";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";

var other_details: any = [];
function Coach() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const [available, setavailable] = useState<any[]>([]);
  const [availableid, setavailableid] = useState<any[]>([]);
  const [fees, setfees] = useState<any[]>([]);
  const [feesid, setfeesid] = useState<any[]>([]);
  const [experience, setexperience] = useState<any[]>([]);
  const [experienceid, setexperienceid] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [availableOpne, setavailableOpne] = useState<any>(false);
  const [feesOpne, setfeesOpne] = useState<any>(false);
  const [experienceOpne, setexperienceOpne] = useState<any>(false);
  const [countryOpne, setcountryOpne] = useState<any>(false);
  const dispatch = useDispatch();
  const [Coach, setCoach] = useState<any>();

  const [program, setprogram] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [areas, setareas] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [interestedIn, setinterestedIn] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [Training, setTraining] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [About, setAbout] = useState<any>({
    name: "",
    label: "",
    value: "",
  });

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setCoach({
      ...Coach,
      [name]: value,
      service_name: "coach",
      other_details: other_details,
    });
  };

  ///////////////////
  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const hendleavailableOpne = (e: any) => {
    if (availableOpne == true) {
      setavailableOpne(false);
    } else {
      setavailableOpne(true);
    }
  };
  const hendlefeesOpne = (e: any) => {
    if (feesOpne == true) {
      setfeesOpne(false);
    } else {
      setfeesOpne(true);
    }
  };
  const hendleexperienceOpne = (e: any) => {
    if (experienceOpne == true) {
      setexperienceOpne(false);
    } else {
      setexperienceOpne(true);
    }
  };

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  useEffect(() => {
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);

  const submitQuery = (e: any) => {
    const Name = $("#Name").val();
    const Mobile = $("#Mobile").val();
    const Email = $("#Email").val();
    const City = $("#City").val();

    const IsProgram = $("select[name=program]").val();
    const InArea = $("select[name=areas]").val();
    const IsInterest = $("select[name=interest]").val();
    const IsTraining = $("textarea[name=training]").val();
    const IsAbout = $("textarea[name=about]").val();

    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!Name) {
      $("#Name").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Mobile) {
      $("#Mobile").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Mobile) {
      if (Mobile.length != 10) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (Mobile) {
      if (phoneno.test(Mobile) === false) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (!Email) {
      $("#Email").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Email) {
      if (regex.test(Email) === false) {
        $("#Email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (!City) {
      $("#City").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }

    var InAbout = {
      name: "about",
      label: "what are you most curious about?",
      value: IsAbout ? IsAbout : "N/A",
    };
    var InTraining = {
      name: "training",
      label: "how did you hear about mentor training EDU?",
      value: IsTraining ? IsTraining : "N/A",
    };
    var InInterest = {
      name: "interest",
      label: "i am interested in",
      value: IsInterest ? IsInterest : "N/A",
    };
    var InAreas = {
      name: "area",
      label: "in what areas do you have questions?",
      value: InArea ? InArea : "N/A",
    };
    var InProgram = {
      name: "program",
      label: "in which program(s) are you interested?",
      value: IsProgram ? IsProgram : "N/A",
    };

    other_details.push(InProgram, InAreas, InInterest, InTraining, InAbout);
    $("#coachFormIn").prop("disabled", true);
    dispatch(officeAction.submitQuery(Coach, history));
    // toast.success('Thanks for showing your interest, we will get back to you soon.');
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  // });

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div className="container">
        <section className="tag-term">
          <Breadcrumb page={"Mentor"} data={{ slug: "", path: "" }} />
          <div className="text-right pb-4 mr-3">
            <SocialShareButton urlsforShare={urlsforShare} data={""} />
          </div>
          <div className="headingmains text-center">
            <h2
              className="titlewithline aos-init aos-animate"
              data-aos="flip-left"
            >
              mentor
              <span>
                <img
                  className="service-funding"
                  src="assets/img/counsellor.png"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </section>
        <div className="counsellorBox1">
          <div className="counsellor-section">
            <section className="">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-12">
                    {seo?.header_content && (
                      <div className="about-uni-text shade1">
                        <SeoHeaderContent />
                      </div>
                    )}
                    <CoachList
                      available={available}
                      fees={fees}
                      experience={experience}
                      country={country}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="banner-section">
            <VerticalAddSlider />
          </div>
        </div>

        <section className="terms">
          <div className="container">
            <div className="row">
              <div className="terms-s">
                <p>
                  Should a mentor's goal be to become perfect? This is the ideal
                  workout, training program, or practice routine. A perfect
                  sequence of signals or progressions. It's a seductive goal and
                  one that can be classified as aspirational. The goal of
                  positivism, like that of a scientist's experimental design, is
                  to create a perfect world. But, just as that promise has
                  proven elusive, and with it, the growth of knowing and doing
                  as more philosophical and paradoxical than certain and certain
                  (at least to some), can't striving for perfection as a mentor
                  be problematic as well? Besides, the perfect mentor is
                  rational and productive… As a result, giving learners the
                  space and time, they need to develop more suitably may
                  gradually decrease. Even the well-meaning coaching mantra of
                  'focus on the process aimed at learners has come to mean
                  'training in a specific way' rather than learning and
                  developing according to the unique context of 'need' and
                  'reality'.
                </p>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new postcard__img-new1"
                        src="assets/img/c-r23.jpg"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">
                          Qualities we exhibit.
                        </h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            1. Establishing Connections
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            Building relationships is the most important aspect
                            of instructional coaching that must be in place to
                            assure success in any coaching approach. To have an
                            impact on teaching and learning, there must be space
                            for communication, expression of concerns and fears,
                            and the opportunity to exhibit your vulnerabilities.
                            The mentor-teacher connection needs to be
                            safeguarded and fostered. Students are at the center
                            of all we do in education. It's crucial to remember
                            that, while data is important for shaping
                            instruction and decisions, the ultimate goal is to
                            keep the focus on the kids. Effective coaching
                            entails mentors and teachers collaborating in
                            student-centered interactions that are tailored to
                            the needs of the students.
                          </p>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            2. Data-Driven Decisions
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            BIf we employ data-driven decisions to truly modify
                            our training, we have a powerful instrument. Being a
                            mentor necessitates the ability to see and interpret
                            data. Then move on to the next phase of addressing
                            the "so what?" question. To acquire perspective as a
                            mentor, one must be able to take a bird's eye view
                            and ask, "What can we modify about our instruction
                            to better affect student learning?"
                          </p>
                        </div>

                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            3. Inquiring without Passing Judgment
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            While other approaches do not have the same impact
                            as questioning, they can push change. Mentors can
                            preserve the teacher-mentor relationship by asking
                            questions. Mentors must be able to ask
                            thought-provoking questions to instructors.
                            Questions should not be asked for the sake of asking
                            them, but rather to ensure that student learning is
                            the ultimate goal.
                          </p>
                        </div>

                        <div className="postcard__preview-txt ft-15">
                          <h5 className="pb-3 card-head1">
                            4. Understanding of High-Impact Instructional
                            Practices
                          </h5>
                        </div>
                        <div className="postcard__preview-txt ft-15">
                          <p>
                            Teachers can be given all of the resources and
                            materials they require in the classroom. The most
                            effective educational tactics and techniques are not
                            used. Mentors must be aware of the high-impact
                            tactics available. Finally, they must be able to
                            provide practical suggestions for how a teacher
                            might begin to improve such practices in their
                            classroom.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard postcard-n1">
                      <img
                        className="postcard__img-new"
                        src="assets/img/coaching_05.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">why to pick us?</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Being authentic involves discovering the true you, the
                          person you were born to be, and realizing your full
                          potential as a result. We do what we do because we
                          can't see any other option. These behaviors, however,
                          are in direct opposition to our current views and
                          ideals. As a result, we are weary and stressed, which
                          affects our motivation, creativity, and happiness. As
                          a Mentor, we help you become more aware of these
                          conflicts and stumbling blocks in your life, and how
                          to remove them so that your real self may shine
                          through and you can live a life that is more in line
                          with your truth. Finally, we have the personal and
                          professional abilities, expertise, and experience to
                          assist you with time management, goal-setting, and
                          motivation tactics.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/concul.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">conclusion</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Mentors provide a fresh viewpoint and can spot things
                          you're doing wrong or need to improve on, allowing you
                          to perform at a higher level. The mentor assists in
                          the facilitation of dialogues that result in
                          collaborative, introspective, accountable,
                          self-evaluative, and participatory practices that
                          promote all students' and instructors' educational
                          processes. Mentors clap us on the back when we
                          succeed, but we concentrate our efforts so we don't
                          rest on our laurels for too long. That fresh
                          perspective can assist you in identifying your "blind
                          spots."
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
                <SeoFooterContent />
              </div>
            )}
          </div>
        </section>
        <br />
        {/*   
     
     <Contactus /> */}

        <section className="categories section bg-grey" id="c-form">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                <div
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 24 24" id="expand-more">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="close">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                  </svg>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="apply-n">apply now</h5>
                        <p className="b-10 coach-view">
                          We'd love to hear from you! Please fill out the form
                          below so we may best support you
                        </p>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="fields-wrp">
                          <form id="coachForm">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="name"
                                    value={Coach?.name}
                                    id="Name"
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />

                                  <label>name</label>
                                  <span className="fa fa-user p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="mobile"
                                    maxLength="10"
                                    id="Mobile"
                                    value={Coach?.mobile}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>mobile</label>
                                  <span className="fa fa-phone p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="email"
                                    id="Email"
                                    value={Coach?.email}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>email</label>
                                  <span className="fa fa-envelope p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="city"
                                    id="City"
                                    value={Coach?.city}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>city</label>
                                  <span className="fa fa-map-marker p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-book p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="program"
                                    value={program?.program}
                                  >
                                    <option value="">
                                      in which program(s) are you interested?
                                    </option>
                                    <option value="Academic Life Coaching">
                                      Academic Life Coaching
                                    </option>
                                    <option value="University Academic Life Coaching">
                                      University Academic Life Coaching
                                    </option>
                                    <option value="Executive Coaching">
                                      Executive Coaching
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-question p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="areas"
                                    value={areas?.areas}
                                  >
                                    <option value="">
                                      in what areas do you have questions?
                                    </option>
                                    <option value="Course Schedule">
                                      Course Schedule
                                    </option>
                                    <option value="Program Specializations">
                                      Program Specializations
                                    </option>
                                    <option value="Program Logistics (length, frequency, topics, etc.)">
                                      Program Logistics (length, frequency,
                                      topics, etc.)
                                    </option>
                                    <option value="Other (please share below)">
                                      Other (please share below)
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <span className="fa fa-hand-o-up p-2"></span>
                              <select
                                className="form-control c-select"
                                name="interest"
                                value={interestedIn?.interest}
                              >
                                <option value="">i am interested in..</option>
                                <option value="receiving an information packet">
                                  receiving an information packet
                                </option>
                                <option value="scheduling a 20-minute call with an Admission Advisor">
                                  scheduling a 20-minute call with an Admission
                                  Advisor
                                </option>
                              </select>
                            </div>
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                rows="4"
                                cols="50"
                                placeholder="how did you hear about mentor training EDU?"
                                name="training"
                                value={Training?.training}
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                rows="4"
                                cols="50"
                                placeholder="what are you most curious about?"
                                name="about"
                                value={About?.about}
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="fieldbtn text-center">
                                  <input
                                    onClick={(e) => {
                                      submitQuery("coachForm");
                                    }}
                                    type="button"
                                    data-toggle="modal"
                                    className="bggreadent apply-sub-btn"
                                    value="submit"
                                    id="coachFormIn"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Coach;
