import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { LOADING, GET_SETTING, SEO } from "../common/constant";

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const getSettingAction = (data) => {
  return { type: GET_SETTING, payload: data };
};
const getSeoDetailAction = (data) => {
  return { type: SEO, payload: data };
};
export const getSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await api.get(`${URL.getSetting}`);
      dispatch(getSettingAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSeoDetail = (data) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.seoDetails}`, data);
      dispatch(getSeoDetailAction(response?.data?.data));
    } catch (error) {
      dispatch(getSeoDetailAction({}));
      console.log(error?.response?.data?.message)
      /* toast.error(error?.response?.data?.message); */
    }
  };
};
