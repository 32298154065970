import {
  LOADING,
  GET_DISCUSSION_LIST,
  GET_DISCUSSION_BY_ID,
  GET_DISCUSSION,
  GET_DISCUSSION_COMMENT_LIST,
  DISCUSSION_COMMENT_ADD,
  GET_DISCUSSION_CATEGORY_LIST,
  TRENDING_DISCUSSION,
  RELATED_DISCUSSION,
  DISCUSSION_LIKE_UNLIKE,
  DISCUSSION_COMMENT_LIKE_UNLIKE,
  ADD_DISCUSSION,
} from "../common/constant";
type stateType = {
  loading: boolean;
  discussionList: any;
  discussionById: any;
  getDiscussion: any;
  getDiscussionComment: any;
  DiscussionCommentAdd: any;
  getDiscussionCategory: any;
  getTrendingDiscussion: any;
  getRelatedDiscussion: any;
  likeUnlike: any;
  commentlikeUnlike: any;
  addDiscussion: any;
}
const initialState: stateType = {
  loading: false,
  discussionList: [],
  discussionById: [],
  getDiscussion: [],
  getDiscussionComment: [],
  DiscussionCommentAdd: [],
  getDiscussionCategory: [],
  getTrendingDiscussion: [],
  getRelatedDiscussion: [],
  likeUnlike: [],
  commentlikeUnlike: [],
  addDiscussion: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_DISCUSSION_LIST:
      return { ...state, discussionList: action.payload };
    case GET_DISCUSSION_BY_ID:
      return { ...state, discussionById: action.payload };
    case GET_DISCUSSION:
      return { ...state, getDiscussion: action.payload };
    case GET_DISCUSSION_COMMENT_LIST:
      return { ...state, getDiscussionComment: action.payload };
    case DISCUSSION_COMMENT_ADD:
      return { ...state, DiscussionCommentAdd: action.payload };
    case GET_DISCUSSION_CATEGORY_LIST:
      return { ...state, getDiscussionCategory: action.payload };
    case TRENDING_DISCUSSION:
      return { ...state, getTrendingDiscussion: action.payload };
    case RELATED_DISCUSSION:
      return { ...state, getRelatedDiscussion: action.payload };
    case DISCUSSION_LIKE_UNLIKE:
      return { ...state, likeUnlike: action.payload };
    case DISCUSSION_COMMENT_LIKE_UNLIKE:
      return { ...state, commentlikeUnlike: action.payload };
    case ADD_DISCUSSION:
      return { ...state, addDiscussion: action.payload };
    default:
      return state;
  }
};