import React from 'react'
import ExamForm from '../../Component/ExamForm/ExamForm'

const GmatMobile = () => {
  return (
    <>
    <section >
        <div className="mainsheading text-center">
            <h2 className="headingwithline" data-aos="flip-left">GMAT<span><img src="/assets/img/test-icon002.png" alt=""/></span>
            </h2>
        </div>
    </section>
    <section className="terms-ser">
        <div className="container">
            <div className="row">
                <div className="main-terms-s">
                    <p>The GMAT is a computer-adaptive test that assesses a candidate's critical writing, quantitative,
                        oral, and reading skills in standard written English. This exam is used to get admission to a
                        graduate management programme at one of the world's top business institutions, such as an MBA or
                        a Masters in Finance-related degree. GMAC, a testmaker, created and administers the GMAT to give
                        business schools standardised evaluations of applicants' readiness for graduate-level academic
                        work.</p>
                </div>
                <div className="main-terms-s">
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gmat01.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">What is the importance of the GMAT?</h4>
                                    <div className="postcard-para-txt">It is the world's most widely accepted MBA entrance
                                        exam. No other exam is as widely accepted as the GMAT; according to the GMAC,
                                        GMAT scores account for 9 out of 10 MBA enrollments worldwide. Furthermore, GMAT
                                        scores are accepted by over 2,300 schools. This is because the GMAT Exam allows
                                        you to demonstrate the most important skills in business school and your career.
                                    </div>
                                </div>
                                <div className="postcard__preview-txt">
                                    <h5 className="pb-3 card-head1"> Eligibility</h5>
                                    <ul className="condition-list-sec">
                                        <li>Candidates should be aware of the qualifying requirements before
                                            registering. The following criteria must be met to take the GMAT</li>
                                        <li>Applicants must possess a minimum educational qualification from a
                                            recognised institution or college.</li>
                                        <li>Candidates must be at least 18 years old; if a candidate is under 18, a
                                            no-objection certificate from a parent or guardian is required.</li>
                                        <li>To appear for the GMAT, an applicant must have a valid passport.</li>
                                        <li>The GMAT can only be taken five times per year and eight times in a
                                            lifetime.</li>
                                        <li>Only 16 days following the first exam candidates can take the GMAT.</li>
                                        <li>Although work experience is not required, leading business schools worldwide
                                            desire it.</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gmat02.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Who accepts GMAT scores?</h4>
                                    <div className="postcard-para-txt">Aspirants for the GMAT can apply to over 7000
                                        programmes at over 2300 business schools worldwide. Furthermore, leading
                                        business schools worldwide accept GMAT scores of over 720, including Harvard,
                                        INSEAD, and Stanford. Furthermore, management institutions should award students
                                        a degree for GMAT results to be accepted. GMAT scores are one of the essential
                                        variables in the admissions process to top business schools throughout the
                                        world.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/coaching_05.png"
                                    alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">How to register for the GMAT Exam?</h4>
                                    <div className="postcard-para-txt">According to GMAC, you can register for the GMAT
                                        entrance exam six months before the Graduate Management Admission Test date or
                                        no later than 24 hours before the date of the GMAT 2022 exam; however, the slot
                                        is not available at the last minute. As a result, it is recommended that you
                                        reserve your desired slot well in advance of the scheduled exam day.<br/>GMAT
                                        registration options include:<br/>Phone<br/>Postal mail<br/>Online<br/> Candidates
                                        can now register for the PTE exam over the phone, in addition to the online
                                        method. Students from India who desire to take the PTE Exam should contact the
                                        PTE Academic Center to register for the exam.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/gmat03.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Where can GMAT take you?</h4>
                                    <div className="postcard-para-txt">Your GMAT cutoff scores allow you to apply to some of
                                        the world's best business schools. Your GMAT cutoff scores allow you to apply to
                                        some of the world's best business schools. University of Pennsylvania (Wharton),
                                        Stanford University (CA), Yale University (CT), Harvard University (MA),
                                        Columbia University (NY), and the University of California are just a few among
                                        them.</div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="postwrap">
                        <div className="container p-2 py-2">
                            <article className="postcard-sec"><img className="postcard-sec-img" src="/assets/img/concul.png" alt=""/>
                                <div className="postcard-sec-text">
                                    <h4 className="pb-3">Benefits of GMAT</h4>
                                    <div className="postcard-para-txt">The difficulty of the following question on the GMAT
                                        exam is determined by adaptive computer testing (CAT), based on the candidate's
                                        previous response and ability. The GMAT employs CAT across the Quantitative and
                                        Verbal sections, resulting in a more efficient, secure, and accurate assessment
                                        of a candidate's abilities. Schools know they'll have a pipeline of individuals
                                        devoted to a business education if they take the GMAT exam. The GMAT Total Score
                                        is an industry-standard that ETS uses to provide a comparison score. A
                                        comprehensive total score allows colleges to look at a single figure to measure
                                        a candidate's competence while still allowing specific section scores to be
                                        considered. The free benchmarking data in GMAT score reports assists
                                        institutions in making better admissions decisions and understanding their
                                        market and competitors. For your convenience, information is updated daily and
                                        made available online. Want to take the GMAT but not sure how? are you stumped
                                        by a slew of protocols? Admissify is here to assist you! We are India's leading
                                        international education consultant with offices in London, Boston, Hanover,
                                        Vancouver, Sydney, and India. Thanks to our global network, we provide
                                        in-country assistance such as internship aid, immigration, and career services.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <div style={{visibility: "hidden", position: "absolute", width: "0px", height: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <symbol viewBox="0 0 24 24" id="expand-more">
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                            <symbol viewBox="0 0 24 24" id="close">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className="col-sm-12">
                    <ExamForm service={"GMAT-Exam"}/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default GmatMobile