import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Seo from "../Seo/Seo";
import axios from "axios";
import { studentAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url"
import { toast } from "react-toastify";
import Email from "../../assets/img/red-cross.jpg"


function VerifyEmailVerification() {
  const history = useHistory();
  const dispatch = useDispatch();
  const baseurl = URL.API_BASE_URL;
  const [title, settitle] = useState<any>("Email-VerifiCation");

  const handleVerify = async(e: any) => {
    await localStorage.clear();
    window.location.href = "/login"
  }

  return (
    <div>
      <Seo settitle={settitle} title={title} />
      <section className="tag-term">
        <div className="headingmains text-center" style={{ paddingBottom: "30px", paddingTop: "20px" }}>
          <div className="email_content text-center">
            <center>
              <div
                className="why-choose-ad"
              >
                <figure className="feat-text1" style={{ padding: "30px" }}>
                  <img className="red_cross" src={Email} />
                </figure>
                <figcaption className="content-box text-center">
                  <h5 className="pb-2 text-center">
                    Email Verification Expired
                  </h5>
                  <p className="expired-text">The link your're trying to access is expired. Try requesting a new verification link.</p>
                  <div className="form-group text-center mar-b-0 mt-4">
                    <button onClick={handleVerify} className="view-more btn2">
                      go back
                    </button>
                  </div>
                </figcaption>
              </div>
            </center>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyEmailVerification;
