import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { URL } from "../../redux/common/url";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
   officeAction,
} from "../../redux/common/action";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";


var other_details: any = [];
function GRE() {
   const urlsforShare = window.location.href;
   const dispatch = useDispatch();
   const history = useHistory();
   const baseurl = URL.API_BASE_URL;
   const [Exam, setExam] = useState<any>();
   const [resume, setResume] = useState<any>("");
   const [country, setcountry] = useState<any>({
      name: "",
      label: "",
      value: "",
   });
   const [intake, setintake] = useState<any>({
      name: "",
      label: "",
      value: "",
   });

   const countrys: any[] = useSelector((state: any) =>
      state.country.countryList ? state.country.countryList : []
   );



   const hendlereviews = (e: any) => {
      const { name, value } = e.target;
      setExam({ ...Exam, [name]: value, "other_details": other_details, "service_name": "GRE-Exam" });
   };


   const submitQuery = async (e: any) => {

      const Name = $("#Name").val();
      const Mobile = $("#Mobile").val();
      const Email = $("#Email").val();

      const IsCountry = $("select[name=country]").val();
      const IsIntake = $("input[name=intake]").val();

      $('.error-class').remove();
      var isError = false;
      var phoneno = /^[1-9]{1}[0-9]{9}$/;
      const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!Name) {
         $("#Name").after('<div class="error-class">This field is required.</div><br />');
         isError = true;
      }
      if (!Mobile) {
         $("#Mobile").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Mobile) {
         if (Mobile.length != 10) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (Mobile.length == 10) {
         if (phoneno.test(Mobile) === false) {
            $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
            isError = true;
         }
      }
      if (!Email) {
         $("#Email").after('<div class="error-class">This field is required.</div>');
         isError = true;
      }
      if (Email) {
         if (regex.test(Email) === false) {
            $("#Email").after('<div class="error-class">Invalid email.</div>');
            isError = true;
         }
      }
      if (isError) {
         return false;
      }
      // return;
      var InIntake = { name: "year", label: "tentative course intake(month and year)", value: IsIntake ? IsIntake : "N/A" };
      var InCountry = { name: "country", label: "preferred country", value: IsCountry ? IsCountry : "N/A" };

      other_details.push(InCountry, InIntake);
      $('#greIn').prop('disabled', true);
      dispatch(officeAction.submitQuery(Exam,history));
      // toast.success('Thanks for showing your interest, we will get back to you soon.');
      // window.location.href = "/thank-you";

   };
   return (
      <>
         <Seo slug={window.location.pathname}/>
         <div>
            <section className="tag-term">
            <Breadcrumb page={"Services"} data={{slug: "Test preparation", path: "/services", slug2: "GRE", path2: "/test-preparation"}}/>
            <div className="text-right pb-4 mr-3"><SocialShareButton urlsforShare={urlsforShare} data={""}/></div>
               <div className="headingmains text-center">
                  <h2 className="titlewithline aos-init aos-animate" data-aos="flip-left">
                     GRE
                     <span>
                        <img className="service-funding" src="assets/img/test-icon002.png" alt="" />
                     </span>
                  </h2>
               </div>
            </section>
            <section className="terms">
               <div className="container">
                  <div className="row">
                     <div className="terms-s d-flex">
                        <div className="col-lg-12">
                           <p>
                              The GRE General Test, administered by ETS, is one of the most common graduate admissions tests for students interested in pursuing MS or graduate courses abroad. The Graduate Record Examination, or GRE test, is the complete version of the Graduate Record Examination. The GRE General test, administered by ETS (Educational Testing Service), is one of the world's most widely accepted assessment programmes for graduate admissions to business and law schools.
                           </p>
                        </div>
                        {/* <div className="col-lg-5">
                           <ContactForm />
                        </div> */}
                     </div>
                  </div>
                  <div className="terms-s">
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/gre01.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">What is the importance of GRE?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">The GRE is a prominent exam that stands out among the other foreign language exams. A good GRE score gives a student an advantage over other students when it comes to gaining admission to a top university or business school to pursue a master's degree, MBA, or business and law programmes.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new postcard__img-new1" src="assets/img/gre02.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Types of GRE Exams</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">There are two versions of the GRE General Exam: computer-based and paper-based. Furthermore, not everyone can take the paper-based test. It's available in places where the computer-based GRE isn't available. It depends on the location of the exam.
                                    GRE General Test and GRE Subject Test are the two categories of GRE. The GRE general test is available in both computer and paper format. The computer-based GRE is section adaptive, one of the major differences between the two GRE designs. The difficulty level of the second segment is determined by your performance in the first section of verbal and quantitative. As a result, a computer-based exam provides a more accurate assessment of your talents than a paper-based exam. You will also receive your scores at the exam centre if you take the computer-based GRE. You must wait around six weeks for the paper-based test.
                                    The Verbal reasoning, quantitative reasoning, and analytical writing sections are all formats, and the scoring system is the same.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard">
                              <img className="postcard__img-new" src="assets/img/coaching_05.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">How to register for the GRE Exam?</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> The GRE test can be taken in a variety of ways. Aspirants can register for the GRE in various ways, including online and over the phone. Candidates can also register by mail or fax. Candidates will need a debit or credit card to pay the $213 registration cost and a valid passport or Aadhar Card during the registration process.</div>
                                 <ul className="condition-list-1">
                                    <li>Online GRE Registration</li>
                                    <li>GRE Phone Registration</li>
                                    <li>GRE Mail Registration</li>
                                    <li>GRE Fax Registration</li>
                                 </ul>
                                 <br />
                                 <h4 className="pb-3 card-head1">Where can GRE take you?
                                 </h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15"> Countries like Australia, the United Kingdom, India, Canada, Belgium, Hong Kong, Belgium, and others accept GRE results.</div>
                              </div>
                           </article>
                        </div>
                     </div>
                     <div className="postwrap">
                        <div className="container py-2">
                           <article className="postcard postcard-n1">
                              <img className="postcard__img-new" src="assets/img/gre03.png" alt="" />
                              <div className="postcard__text postcard__text1">
                                 <h4 className="pb-3 card-head1">Benefits of GRE test</h4>
                                 <span className="border-bt"></span>
                                 <div className="postcard__preview-txt ft-15">Aids high-scoring applicants in gaining admission and scholarships to top-ranked universities and business schools.
                                    The GRE General Test is treated the same way as other graduate admissions examinations by the world's leading business schools. It gives colleges a thorough evaluation of the talents you'll need to succeed in MBA and master's degree programmes.
                                    Unlike other examinations, you can preview and skip questions within each section and go back to review and amend answers. This can make you feel more at ease and help you attain your best test results.
                                    The results are valid for five years. That means you can take it now and still have plenty of time to decide where and when you want to continue your degree.
                                    GRE scores are accepted by over 1,300 business institutions worldwide for admission to MBA and speciality master's programmes. You can attend top colleges in the United States or famous programmes throughout Europe.
                                    Want to take the GRE but don't know where to start? Admissify is here to assist you! With offices in London, Boston, Hanover, Vancouver, Sydney, and other locations across India, Admissify is India's globally recognized education consultancy. After you arrive, we may provide in-country assistance such as internship aid, immigration, and career services, thanks to our global network.
                                 </div>
                              </div>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*   
     
     <Contactus /> */}

            <section className="categories section bg-grey" id="c-form">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-sm-12">
                        <div style={{ visibility: "hidden", position: "absolute", width: "0px", height: "0px" }}>
                           <svg xmlns="http://www.w3.org/2000/svg">
                              <symbol viewBox="0 0 24 24" id="expand-more">
                                 <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                              <symbol viewBox="0 0 24 24" id="close">
                                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                 <path d="M0 0h24v24H0z" fill="none" />
                              </symbol>
                           </svg>
                        </div>
                     </div>
                     <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-11 col-lg-10">
                                    <h5 className="apply-n">apply now</h5>
                                 </div>
                                 <div className="col-md-12 col-lg-12">
                                    <div className="fields-wrp">
                                       <form id="examForm">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="name" value={Exam?.name} id="Name" onChange={(e) => { hendlereviews(e); }} />

                                                   <label>name</label>
                                                   <span className="fa fa-user p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="mobile" maxLength="10" id="Mobile" value={Exam?.mobile} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>mobile</label>
                                                   <span className="fa fa-phone p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <input
                                                      autoComplete="new-off" type="text" name="email" id="Email" value={Exam?.email} onChange={(e) => { hendlereviews(e); }} />
                                                   <label>email</label>
                                                   <span className="fa fa-envelope p-2"></span>
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="field">
                                                   <span className="fa fa-map-marker p-2"></span>
                                                   <select className="form-control c-select" name="country" value={country?.country}>
                                                      <option value="">preferred country</option>
                                                      {countrys && countrys?.map((data: any, i: any) => {
                                                         return (
                                                            <option key={i} value={data?.country_name}>{data?.country_name}</option>
                                                         )
                                                      })}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="field">
                                             <input
                                                autoComplete="new-off" type="text" name="intake" id="intake" value={intake?.intake} />
                                             <label>tentative course intake(month and year)</label>
                                             <span className="fa fa-calendar p-2"></span>
                                          </div>
                                          <div className="field">
                                             <textarea
                                                autoComplete="new-off" className="q-msg border__" rows="4" cols="50" placeholder="message" name="message" value={Exam?.message} onChange={(e) => { hendlereviews(e); }}></textarea>
                                          </div><br /><br /><br />
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="fieldbtn text-center">
                                                   <input type="button" id="greIn" onClick={(e) => { submitQuery('examForm') }} data-toggle="modal" className="bggreadent apply-sub-btn" value="submit" />
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}
export default GRE
