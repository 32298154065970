import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { counsellorRegisterValidate, otpValidate } from '../validation/Validation';

interface error {
  [key: string]: any
} 

function CounsellorRegister() {
  const [values, setValues] = useState<any>("");
    const [otp, setOtp] = useState<any>();
    const [errors, setErrors] = useState<error>({})
    const [formValidate, setSubmitting] = useState<boolean>(false);
    const [otpErrors, setOtpErrors] = useState<error>({});
    const [otpvalid, setOtpvalid] = useState<boolean>(false);
    const handleChange = (e:any) => {
        const {name, value}=e.target;
        setValues({...values, [name]: value});
    }
    useEffect(() => {
        const validationErrors = counsellorRegisterValidate(values);
        let noErrors = Object.keys(validationErrors).length == 0;
        setSubmitting(noErrors);
        setErrors(validationErrors);
    }, [values]);

    useEffect(() => {
      const validationErrors = otpValidate(otp);
      let noErrors = Object.keys(validationErrors).length == 0;
      setOtpvalid(noErrors);
      setOtpErrors(validationErrors);
    }, [otp]);

    const handleRegister = (event:any) => {
      event.preventDefault(); 
     // createUser(values);
    };
    const handleVerifyOtp = (event:any) => {
      event.preventDefault(); 
     // createUser(values);
    };
    return (
        <div>
             <section className="categories section bg-grey">
            <div className="container">
                <div className="loginwrap">
                    <div className="row g-0">
                      <div className="d-none d-md-flex col-md-4 col-lg-5 bg-image">
                          <div><h3 className="text-white">welcome</h3>
                            {/* <h6 className="pb-3 text-white text-center">why sign up?</h6> */}
                            <p className="text-center text-white">
                  Are you interested to study abroad? Are you confused with the selection of the college? Don't worry Admissify will surely help you to get through the best possible education platform apt for your niche. <br />  <br /> <span style={{fontSize:"12px"}}>Sign up/Register Now!</span>
                  </p></div>
                      </div>
                      <div className="col-md-8 col-lg-7 rightbx">
                        <div className="login d-flex align-items-center py-5">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-11 col-lg-10 mx-auto">
                                    <h5 className="text-center">create a counsellor/mentor account</h5>
                                </div>
                                <div className="col-md-9 col-lg-8 mx-auto">
                                    <h6 className="text-center">use mobile number for registration </h6>
                                    <div className="fields-wrp">
                                        <form action="#">
                                          <div className="field">
                                            <input type="text" name="name" value={values?.name}  onChange={(e:any)=>handleChange(e)} required />
                                            <label>name</label>
                                            <span className="fa fa-user p-2"></span>
                                          </div>
                                          {errors?.name && values?.name !== undefined && <span className="error">{errors?.name}</span>}
                                          <div className="field">
                                            <input type="text" name="mobile"  value={values?.mobile}  onChange={(e:any)=>handleChange(e)} required />
                                            <label>mobile number</label>
                                            <span className="fa fa-phone p-2"></span>
                                          </div>
                                          {errors.mobile && values.mobile !== undefined && <span className="error">{errors.mobile}</span>}
                                          <div className="field">
                                            <input type="text" required name="email"  value={values?.email}  onChange={(e:any)=>handleChange(e)}/>
                                            <label>email</label>
                                            <span className="fa fa-envelope p-2"></span>
                                          </div>
                                          {errors.email && values.email !== undefined && <span className="error">{errors.email}</span>}
                                          <div className="field">
                                            <select name="roles" value={values?.roles} onChange={(e:any)=>handleChange(e)}>
                                                <option value="">--select counsellor/mentor --</option>
                                                <option value="1"> counsellor</option>
                                                <option value="2">mentor </option>
                                            </select>
                                            <span className="fa fa-user-o p-2"></span>
                                          </div>
                                          {errors.roles && values.roles !== undefined && <span className="error">{errors.roles}</span>}
                                          <div className="field">
                                            <input type="text" name="linkedin" value={values?.linkedin} onChange={(e:any)=>handleChange(e)} required />
                                            <label>linkedin url</label>
                                            <span className="fa fa-linkedin p-2"></span>
                                          </div>
                                          {errors.linkedin && values.linkedin !== undefined && <span className="error">{errors.linkedin}</span>}
                                          <div className="signup-link">already a member?
                                           {/* <a href="#">login here</a> */}
                                           <Link to={"/counsellorlogin"}>login here</Link>
                                           </div>
                                          <div className="fieldbtn">
                                            <input type="submit" onClick={(e:any)=>handleRegister(e)} className={!formValidate ? "disabledClass bggreadent" : "bggreadent"} value="get otp" />
                                          </div>
                                        </form>
                                      </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
            </div>
        </section> 
        </div>
    )
}

export default CounsellorRegister
