import React, { useState } from 'react'

const Accordion = (props:any) => {
    const {data, showId, handleShow}= props;
  return (
    <div className="accordion-item acc-item-faq">
        <h2 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button acc-faq" type="button" onClick={()=> handleShow(data?.id)}>
            {data?.title}
            </button>
        </h2>
        {showId === data?.id &&
        <div id="flush-collapseOne" className="accordion-collapse">
            <div className="accordion-body acc-content">
            {data?.description && data?.description}
            </div>
        </div>
}
    </div>
  )
}

export default Accordion